export function formatNumberWithCommas(number: number): string {
  // Convert the number to a string
  const numberString = number.toString();

  // Split the string into integer and decimal parts (if any)
  const [integerPart, decimalPart] = numberString.split(".");

  // Add commas to the integer part
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  // If there's a decimal part, join it back with a period
  const formattedNumber = decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;

  return formattedNumber;
}

export function convertStringToFloat(input: string | number): number {
  // Remove commas from the input string
  const sanitizedInput = input.toString().replace(/,/g, "");

  // Parse the string to a floating-point number
  const floatValue = parseFloat(sanitizedInput);

  // Check if the conversion was successful
  if (isNaN(floatValue)) {
    // If the conversion failed, return null or handle the error as needed
    return 0;
  }

  return floatValue;
}

export function formatFloatToString(value: number): string {
  // Use toLocaleString to add commas and format the number
  return value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
