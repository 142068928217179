export function maskAccountNumber(accountNumber: string | number) {
  if (!accountNumber) {
    return "-";
  } else {
    // Convert the number to a string
    const accountStr = accountNumber.toString();

    // Check if the account number is valid
    if (!accountStr || accountStr.length < 4) {
      return "Invalid account number";
    }

    // Replace all characters except the last 4 with asterisks
    return accountStr.slice(0, -4).replace(/./g, "*") + accountStr.slice(-4);
  }
}
