import { FC, SetStateAction, useEffect, useState } from "react";
import { Button, Form, Modal, message } from "antd";
import { CustomInput } from "../../customFields/CustomInput";
import { yupSync } from "../../utils";
import { usePreventSpaceTyping } from "../../hooks/usePreventSpaceTyping";
import * as Yup from "yup";
import { useForgotPasswordMutation } from "../../api/auth";
import { SuccessPopup } from "./SuccessPopup";
import { Spinner } from "../../components/Spinner/Spinner";
import { isApiError } from "../../utils/general";

interface ForgotPasswordProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSuccessPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is invalid")
    .required("This field is required"),
});

interface ForgotPasswordI {
  email: string;
}

export const ForgotPassword: FC<ForgotPasswordProps> = ({
  openModal,
  setOpenModal,
  setIsSuccessPopup,
}) => {
  const [form] = Form.useForm<ForgotPasswordI>();
  const [messageApi, contextHolder] = message.useMessage();
  const { handleKeyPress } = usePreventSpaceTyping();
  const [forgotPassword, { data, isSuccess, isLoading, isError, error }] =
    useForgotPasswordMutation();

  const errorMessage = (text: string) => {
    messageApi.open({
      type: "error",
      content: text,
      style: {
        marginTop: "90vh",
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setIsSuccessPopup(true);
      setOpenModal(false);
    }
    if (isError) {
      setIsSuccessPopup(true);
      setOpenModal(false);
    }
  }, [isSuccess]);

  const forgotPasswordFunc = async (email: string) => {
    try {
      await forgotPassword(email).unwrap();
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || "Forgot password failed");
    }
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: ForgotPasswordI) => {
        forgotPasswordFunc(values.email.toLowerCase());
      })
      .catch(
        () => {}
        // setFileErr('You can upload files in the following formats: doc, pdf with a maximum size of 100MB')
      );
  };

  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
  };

  return (
    <>
      {contextHolder}
      <Modal
        destroyOnClose
        className="forgot-password-modal"
        centered
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={<></>}
        width={344}
      >
        <div className="forgot-password-modal__container">
          <div className="forgot-password-modal__title">Forgot password</div>
          <div className="forgot-password-modal__subtitle">
            No worries! We will send you an email with further instructions
          </div>

          <Form form={form} onFinish={handleFormSubmit}>
            <Form.Item
              className="input-wrapper"
              name="email"
              rules={yupSync("email", validationSchema, true)}
            >
              <CustomInput
                name="Email"
                className="input"
                maxLength={100}
                onKeyPress={handleKeyPress}
                placeholder="example@gmail.com"
                onBlur={() => validateFormOnBlur("email")}
              />
            </Form.Item>
            <div className="forgot-password-modal__btn-wrapper">
              <Button className="blue-btn" htmlType="submit">
                Send {isLoading && <Spinner />}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
