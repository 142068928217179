import { Button, message } from 'antd';
import { IOffer } from '../../../../../api/types/offer';
import {
  useApproveDeliveryMutation,
  useConfirmDeliveryMutation,
} from '../../../../../api/applicants';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../../hooks';
import { setMessage } from '../../../../../reducers/common/commonSlice';

interface OfferItemProps {
  offer: any;
  setOpenItem?: any;
  selectedOffer: number | null | undefined;
  setSelectedOffer?: any;
  ApproveBtn?: boolean;
  ApprovedBtn?: boolean;
  ConfirmDPBtn?: boolean;
  ConfirmedDPBtn?: boolean;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Offer = ({
  offer,
  setOpenItem,
  selectedOffer,
  setSelectedOffer,
  ApproveBtn = false,
  ApprovedBtn = false,
  ConfirmDPBtn = false,
  ConfirmedDPBtn = false,
  setTrigger,
}: OfferItemProps) => {
  const location = useLocation();
  const [approve, { isSuccess }] = useApproveDeliveryMutation();
  const [confirm, { isSuccess: isSuccessConfirmed, isError }] =
    useConfirmDeliveryMutation();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (isSuccess) {
      setTrigger((prev: boolean) => !prev);
      dispatch(setMessage('The application has been successfully approved'));
    }
    if (isSuccessConfirmed) {
      setTrigger((prev: boolean) => !prev);
      dispatch(setMessage('The application has been successfully confirmed'));
    }
  }, [isSuccess, isSuccessConfirmed]);

  useEffect(() => {
    if (isError) {
      messageApi.error('Status cannot be changed!');
    }
  }, [isError]);

  const approveFunc = () => {
    try {
      const id = location.pathname.split('/')[3];
      approve(id);
    } catch {}
  };
  const confirmFunc = () => {
    try {
      const id = location.pathname.split('/')[3];
      confirm(id);
    } catch {}
  };

  return (
    <div className="OfferItem offer">
      {contextHolder}
      <div className={`OfferItem__item`}>
        <div>{offer.name}</div>
      </div>

      <div className={`OfferItem__openItem open`}>
        {offer?.banks?.map((elem: any) => {
          return (
            <>
              <div className="OfferItem__head">
                <div className="OfferItem__title">
                  <img
                    src={elem.bank_logo}
                    className="OfferItem__logo"
                    alt="bank logo"
                  />
                  <span>{elem.bank_name}</span>
                  {offer.name === "Promotional" && <span className="OfferItem__offer_name">{elem.loan_type}</span>}

                </div>
              </div>
              <div className="OfferItem__line"></div>
              {elem.offers?.map((item: any, index: number) => {
                return (
                  <div
                    className="OfferItem__option"
                    onClick={() => setSelectedOffer(item.id)}
                  >
                    {elem.offers.length > 1 && (
                      <div className="OfferItem__row-title">
                        Option {index + 1}
                      </div>
                    )}
                    {item.name && <div className="OfferItem__promo-text" >{item.name}</div>}
                    <div className="OfferItem__row">
                      <div className="OfferItem__wrapper">
                        <p className="OfferItem__name">Term</p>
                        <p className="OfferItem__value bold">{item.term}</p>
                      </div>
                      <div className="OfferItem__wrapper">
                        <p className="OfferItem__name">Monthly payment </p>
                        <p className="OfferItem__value bold">
                          $ {item.monthly_payment}
                        </p>
                      </div>
                    </div>
                    <div className="OfferItem__row">
                      <div className="OfferItem__wrapper">
                        <p className="OfferItem__name">Loan Amount</p>
                        <p className="OfferItem__value">
                          ${' '}
                          {item.offer_value
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </p>
                      </div>
                      {elem.key !== 'okinus' ? (
                        <div className="OfferItem__wrapper">
                          <p className="OfferItem__name">APR </p>
                          <p className="OfferItem__value">{item.rate} %</p>
                        </div>
                      ) : (
                        <div className="OfferItem__wrapper">
                          <p className="OfferItem__name">Down Payment</p>
                          <p className="OfferItem__value">
                            $ {item.data.due_today}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="OfferItem__row">
                      <div className="OfferItem__wrapper">
                        <p className="OfferItem__name"></p>
                        <p className="OfferItem__value">
                          {elem.secured ? 'Secured' : ''}
                        </p>
                      </div>
                      {ApproveBtn && (
                        <div className="OfferItem__wrapper">
                          <Button
                            className="violet-btn"
                            style={{ width: '150px' }}
                            onClick={approveFunc}
                          >
                            Approve
                          </Button>
                        </div>
                      )}
                      {ConfirmDPBtn &&
                        elem.key === 'medallion' &&
                        !ConfirmedDPBtn && (
                          <div className="OfferItem__wrapper">
                            <Button
                              className="violet-btn"
                              style={{ width: '190px' }}
                              onClick={confirmFunc}
                            >
                              Confirm Down Payment
                            </Button>
                          </div>
                        )}

                      {ApprovedBtn && (
                        <div className="OfferItem__wrapper">
                          <Button
                            className="violet-light-btn"
                            style={{ width: '150px' }}
                          >
                            &#10004; Approved
                          </Button>
                        </div>
                      )}
                      {ConfirmedDPBtn && elem.key === 'medallion' && (
                        <div className="OfferItem__wrapper">
                          <Button
                            className="violet-light-btn"
                            style={{ width: '150px' }}
                          >
                            &#10004; Comfirmed
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="OfferItem__line"></div>
                  </div>
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
};
