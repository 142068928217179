import { FC } from 'react';
import { Form, FormInstance } from 'antd';
import { CustomInput } from '../../customFields/CustomInput';
import { yupSync } from '../../utils';
import { validationSchema } from '../../pages/Partners/CreatePartner/CreatePartner';
import { FormDataPartner } from '../../pages/Partners/CreatePartner/CreatePartner';
import './index.scss';

interface BankingInfoFormI {
  form: FormInstance<FormDataPartner>;
}

export const BankingInfoForm: FC<BankingInfoFormI> = ({ form }) => {
  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
  };

  return (
    <div className="partner-info">
      <div className="partner-info__row" id="bank_name">
        <Form.Item
          className="input-wrapper"
          name="bank_name"
          rules={yupSync('bank_name', validationSchema, true)}
        >
          <CustomInput
            name="Bank Name"
            className="input"
            maxLength={240}
            placeholder="Enter Bank Name"
            onBlur={() => validateFormOnBlur('bank_name')}
            // error={credError}
            // onChange={() => setCredError('')}
          />
        </Form.Item>
      </div>
      <div className="partner-info__row">
        <div className="item" id="legal_business_name">
          <Form.Item
            className="input-wrapper"
            name="legal_business_name"
            rules={yupSync('legal_business_name', validationSchema, true)}
          >
            <CustomInput
              name="Legal Business Name"
              placeholder="Enter Legal Business Name"
              onBlur={() => validateFormOnBlur('legal_business_name')}
              maxLength={240}
            />
          </Form.Item>
        </div>
        <div className="item" id="accounting_number">
          <Form.Item
            className="input-wrapper"
            name="accounting_number"
            rules={yupSync('accounting_number', validationSchema, true)}
          >
            <CustomInput
              name="Accounting Number"
              placeholder="Enter Accounting Number"
              onBlur={() => validateFormOnBlur('accounting_number')}
              maxLength={12}
              validation="onlyNumbers"
            />
          </Form.Item>
        </div>
      </div>
      <div className="partner-info__row">
        <div className="item" id="account_holder_name">
          <Form.Item
            className="input-wrapper"
            name="account_holder_name"
            rules={yupSync('account_holder_name', validationSchema, true)}
          >
            <CustomInput
              name="Account Holder’s Name"
              placeholder="Enter Account Holder’s Name"
              onBlur={() => validateFormOnBlur('account_holder_name')}
              maxLength={240}
            />
          </Form.Item>
        </div>
        <div className="item" id="routing_number">
          <Form.Item
            className="input-wrapper"
            name="routing_number"
            rules={yupSync('routing_number', validationSchema, true)}
          >
            <CustomInput
              name="Routing Number"
              placeholder="Enter Routing Number"
              onBlur={() => validateFormOnBlur('routing_number')}
              maxLength={9}
              validation="onlyNumbers"
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};
