import { Button, Form, message } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../components/icons/HomeIcon';
import { ArrowIcon } from '../../../components/icons/ArrowIcon';
import * as Yup from 'yup';
import { yupSync } from '../../../utils';
import { ConfirmationModal } from '../../../components/Modals/ConfirmationModal';
import { Spinner } from '../../../components/Spinner/Spinner';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { CustomInput } from '../../../customFields/CustomInput';
import { usePreventSpaceTyping } from '../../../hooks/usePreventSpaceTyping';
import { PhoneNumberInput } from '../../../customFields/PhoneNumberInput';
import {
  useCreateAdminMutation,
  useEditAdminMutation,
  useLazyGetAdminQuery,
} from '../../../api/admins';
import {
  setIsDirtyAction,
  setMessage,
} from '../../../reducers/common/commonSlice';
import './index.scss';
import { CustomErrorType } from '../../../types.ts/commonTypes';
import {
  useEditProfileInfoMutation,
  useLazyGetProfileInfoQuery,
} from '../../../api/profile';
import { useLazyGetMeQuery } from '../../../api/common';
import { isApiError } from '../../../utils/general';
import { unstable_usePrompt } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed'),
  last_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed'),
  contact_number: Yup.string()
    .required('This field is required')
    .matches(/^[^_]*$/, 'This field is required'),
  contact_email: Yup.string()
    .required('This field is required')
    .email('Email is invalid'),
});

interface FormData {
  first_name: string;
  last_name: string;
  contact_number: string;
  contact_email: string;
}

interface CreateAdminProps {
  isProfile?: boolean;
}

export const CreateAdmin = ({ isProfile }: CreateAdminProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm<FormData>();
  const [ContractNumber, setContractNumber] = useState<string>('');
  const [messageApi, contextHolder] = message.useMessage();
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const user = useAppSelector((state) => state.profile);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [isClickOnHome, setIsClickOnHome] = useState<boolean>(false);
  const location = useLocation();
  const [isDirty, setIsDirty] = useState(false);
  const [getMe, { isError: isGetMeInfoError }] = useLazyGetMeQuery();
  const [getAdmin, { data: adminData, isError: isGetAdminError }] =
    useLazyGetAdminQuery();
  const [
    getProfileInfo,
    {
      data: profileInfo,
      isLoading: isProfileInfoLoading,
      isError: isGetProfileInfoError,
    },
  ] = useLazyGetProfileInfoQuery();
  const { handleKeyPress } = usePreventSpaceTyping();
  const [createAdmin, { data, isSuccess, isError, error }] =
    useCreateAdminMutation();
  const [editAdmin] = useEditAdminMutation();
  const [editProfileInfo] = useEditProfileInfoMutation();
  const dispatch = useAppDispatch();
  const [clickOnSave, setClickOnSave] = useState(false);

  unstable_usePrompt({
    message: 'You have unsaved changes. Do you want to leave this page?',
    //@ts-ignore
    when: ({ currentLocation, nextLocation }) =>
      isDirty &&
      currentLocation !== nextLocation &&
      nextLocation.pathname !== '/login' &&
      !clickOnSave,
  });

  const beforeUnLoad = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    //@ts-ignore
    e.returnValue = '';
  };

  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', beforeUnLoad);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnLoad);
    };
  }, [isDirty]);

  const initialValues = {
    first_name: '',
    last_name: '',
    contact_number: '',
    contact_email: '',
  };

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (isGetMeInfoError) {
      errorMessage('Fetch me info failed');
    }
    if (isGetAdminError) {
      errorMessage('Fetch admin failed');
    }
    if (isGetProfileInfoError) {
      errorMessage('Fetch profile info failed');
    }
  }, [isGetMeInfoError, isGetAdminError, isGetProfileInfoError]);

  useEffect(() => {
    if (!adminData) return;
    form.setFieldsValue({
      first_name: adminData?.data?.first_name,
      last_name: adminData?.data?.last_name,
      contact_number: adminData?.data?.contact_number,
      contact_email: adminData?.data?.contact_email,
    });
    setContractNumber(adminData?.data?.contact_number);
  }, [adminData]);

  useEffect(() => {
    if (!profileInfo) return;
    form.setFieldsValue({
      first_name: profileInfo?.data?.resource.first_name,
      last_name: profileInfo?.data?.resource.last_name,
      contact_number: profileInfo?.data?.resource.contact_number,
      contact_email: profileInfo?.data?.resource.contact_email,
    });
    setContractNumber(profileInfo?.data?.resource.contact_number);
  }, [profileInfo]);

  useEffect(() => {
    if (!location) return;
    if (isProfile) {
      getProfileInfo();
    } else {
      if (location.pathname.includes('edit')) {
        const id: string = location.pathname.split('/')[3];
        getAdmin(id);
      }
    }
  }, [location, isProfile]);

  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
  };

  useEffect(() => {
    if (isError) {
      if (
        (error as CustomErrorType)?.data?.message.includes(
          'A user with such  Email already exists'
        )
      ) {
        setEmailError(true);
      }
    }

    if (isSuccess) {
      setSuccessPopup(true);
    }
  }, [isSuccess, isError]);

  const editPartnerFunc = async (params: FormData) => {
    const data: any = { ...params, id: adminData?.data?.id };

    try {
      isProfile
        ? await editProfileInfo({
            data: data,
          }).unwrap()
        : await editAdmin({ id: adminData?.data?.id, data: data }).unwrap();
      isProfile && getMe();
      dispatch(setMessage('Changes have been successfully saved'));
      isProfile
        ? navigate('/profile')
        : navigate(`/admins/${adminData?.data?.id}`);
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Edit failed');
    }
  };

  const creactePartnerFunc = async (params: FormData) => {
    try {
      await createAdmin(params).unwrap();
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Create admin failed');
    }
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: FormData) => {
        if (location.pathname.includes('edit')) {
          editPartnerFunc(values);
        } else {
          creactePartnerFunc(values);
        }
      })
      .catch((error: { errorFields: [{ name: string[] }] }) => {});
  };

  const onValuesChange = () => {
    setIsDirty(true);
  };

  useEffect(() => {
    if (!user.email) return;
    if (!isProfile && user.role.key !== 'super_admin') {
      navigate('/403');
    }
  }, [user.email, isProfile]);

  return (
    <>
      {contextHolder}
      {'' ? (
        <Spinner size="large" />
      ) : (
        <div className="container">
          <div className="new-member">
            {!isProfile && (
              <div className="new-member__breadscrumbs breadscrumbs">
                <div
                  className="breadscrumbs__title"
                  onClick={() => {
                    setClickOnSave(true);
                    if (location.pathname.includes('edit')) {
                      if (isDirty) {
                        setIsClickOnHome(true);
                        setConfirmationModal(true);
                      } else {
                        navigate('/dashboard');
                      }
                    } else {
                      if (form.isFieldsTouched()) {
                        setIsClickOnHome(true);
                        setConfirmationModal(true);
                      } else {
                        navigate('/dashboard');
                      }
                    }
                  }}
                >
                  <HomeIcon fill="" />
                </div>
                <div className="breadscrumbs__separatop">
                  <ArrowIcon fill="" />
                </div>
                <div
                  className="breadscrumbs__title"
                  onClick={() => {
                    setClickOnSave(true);
                    if (location.pathname.includes('edit')) {
                      if (isDirty) {
                        setIsClickOnHome(false);
                        setConfirmationModal(true);
                      } else {
                        navigate('/admins');
                      }
                    } else {
                      if (form.isFieldsTouched()) {
                        setIsClickOnHome(false);
                        setConfirmationModal(true);
                      } else {
                        navigate('/admins');
                      }
                    }
                  }}
                >
                  Admins
                </div>
                <div className="breadscrumbs__separatop">
                  <ArrowIcon fill="#4D4D56" />
                </div>
                <div className="breadscrumbs__title breadscrumbs__title-active">
                  {location.pathname.includes('edit') ? 'Edit' : 'Create'} Admin
                </div>
              </div>
            )}
            <div className="new-member__main main">
              <Form
                form={form}
                initialValues={initialValues}
                onSubmitCapture={handleFormSubmit}
                scrollToFirstError={true}
                onValuesChange={onValuesChange}
              >
                <div className="main__container">
                  <div className="main__title-block">
                    <div className="main__title">Admin info</div>
                    <div className="main__subtitle">
                      {isProfile
                        ? 'Edit Admin details'
                        : 'Create Admin and details'}
                    </div>
                  </div>
                  <div className="main__form-block">
                    <div className="admin-info">
                      {adminData && (
                        <div
                          className="partner-info__id"
                          style={{ margin: '0 0 20px 0' }}
                        >
                          Admin ID:{' '}
                          <span style={{ color: '#67666E' }}>
                            {adminData?.data?.item_id}
                          </span>
                        </div>
                      )}
                      {profileInfo?.data.resource?.item_id && (
                        <div
                          className="partner-info__id"
                          style={{ margin: '0 0 20px 0' }}
                        >
                          Admin ID:{' '}
                          <span style={{ color: '#67666E' }}>
                            {profileInfo?.data.resource?.item_id}
                          </span>
                        </div>
                      )}
                      <div className="partner-info__row">
                        <div className="item" id="first_name">
                          <Form.Item
                            className="input-wrapper"
                            name="first_name"
                            rules={yupSync(
                              'first_name',
                              validationSchema,
                              true
                            )}
                          >
                            <CustomInput
                              name="First Name"
                              className="input"
                              maxLength={100}
                              placeholder={`Enter ${
                                isProfile ? 'Your' : 'Admin’s'
                              } First Name`}
                              onBlur={() => validateFormOnBlur('first_name')}
                              validation={'noNumbers'}
                            />
                          </Form.Item>
                        </div>
                        <div className="item" id="last_name">
                          <Form.Item
                            className="input-wrapper"
                            name="last_name"
                            rules={yupSync('last_name', validationSchema, true)}
                          >
                            <CustomInput
                              name="Last Name"
                              className="input"
                              maxLength={100}
                              placeholder={`Enter ${
                                isProfile ? 'Your' : 'Admin’s'
                              } Last Name`}
                              onBlur={() => validateFormOnBlur('last_name')}
                              validation={'noNumbers'}
                            />
                          </Form.Item>
                        </div>
                      </div>{' '}
                      <div className="partner-info__row">
                        <div className="item" id="company_name">
                          <Form.Item
                            className="input-wrapper"
                            name="contact_number"
                            rules={yupSync(
                              'contact_number',
                              validationSchema,
                              true
                            )}
                          >
                            <PhoneNumberInput
                              mask="(099) 999 9999"
                              name="Contact Number"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => setContractNumber(e.target.value)}
                              className="input"
                              value={undefined}
                              onBlur={() =>
                                validateFormOnBlur('contact_number')
                              }
                              placeholder={`Enter ${
                                isProfile ? 'Your' : 'Admin’s'
                              } Contact Number`}
                              hasError={() =>
                                !!form.getFieldError('contact_number').length
                              }
                            />
                          </Form.Item>
                        </div>
                        {isProfile ? (
                          <div className="item prolile-contact-item">
                            <p className="contact-email-label">Contact Email</p>
                            <p className="profile-contact-email">
                              {profileInfo?.data.resource.contact_email}
                            </p>
                          </div>
                        ) : (
                          <div className="item" id="contact_email">
                            <Form.Item
                              className="input-wrapper"
                              name="contact_email"
                              rules={yupSync(
                                'contact_email',
                                validationSchema,
                                true
                              )}
                            >
                              <CustomInput
                                disabled={
                                  location.pathname.includes('edit')
                                    ? true
                                    : false
                                }
                                style={{ padding: '0 35px 0 10px' }}
                                error={emailError ? 'err' : ''}
                                name="Contact Email"
                                className="input"
                                maxLength={240}
                                placeholder={`Enter ${
                                  isProfile ? 'Your' : 'Admin’s '
                                } Contact Email`}
                                onBlur={() =>
                                  validateFormOnBlur('contact_email')
                                }
                                onKeyPress={handleKeyPress}
                                onChange={() => setEmailError(false)}
                              />
                            </Form.Item>
                            {emailError && (
                              <div
                                className="error-text"
                                style={{
                                  margin: '-34px 0px 0px 4px',
                                  height: '32px',
                                }}
                              >
                                A user with such Email already exists
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="main__btns">
                  <Button
                    className="gray-btn"
                    style={{ margin: '0 16px 0 0' }}
                    onClick={() => {
                      setClickOnSave(true);
                      if (location.pathname.includes('edit')) {
                        if (isDirty) {
                          setIsClickOnHome(false);
                          setConfirmationModal(true);
                        } else {
                          isProfile
                            ? navigate('/dashboard')
                            : navigate('/admins');
                        }
                      } else {
                        if (isDirty) {
                          setIsClickOnHome(false);
                          setConfirmationModal(true);
                        } else {
                          isProfile
                            ? navigate('/dashboard')
                            : navigate('/admins');
                        }
                      }
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="blue-btn"
                    htmlType="submit"
                    onClick={() => setClickOnSave(true)}
                  >
                    {location.pathname.includes('edit')
                      ? 'Save Changes'
                      : 'Create'}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
      {confirmationModal && (
        <ConfirmationModal
          openModal={confirmationModal}
          setOpenModal={setConfirmationModal}
          type="warning"
          title={
            location.pathname.includes('edit') && !isProfile
              ? 'Are you sure you want to discard the Admin Editing?'
              : location.pathname.includes('edit') && isProfile
              ? 'Are you sure you want to discard the profile editing?'
              : !location.pathname.includes('edit') && !isProfile
              ? 'Are you sure you want to discard the Admin Creation?'
              : ''
          }
          cancelText="No"
          confirmText="Yes"
          cancel={() => {
            setClickOnSave(false);
            setConfirmationModal(false);
          }}
          confirm={() => {
            if (isClickOnHome) {
              navigate('/dashboard');
            } else {
              isProfile ? navigate('/dashboard') : navigate('/admins');
            }
          }}
          closeIcon={true}
        />
      )}
      {successPopup && data?.data && (
        <ConfirmationModal
          openModal={successPopup}
          setOpenModal={setSuccessPopup}
          type="success"
          title="Admin was successfully created"
          subtitle={`Admin ID: ${data?.data?.item_id}`}
          cancelText=""
          confirmText="Okay"
          confirm={() => {
            navigate('/admins');
          }}
          closeIcon={false}
          maskClosable={false}
        />
      )}
    </>
  );
};
