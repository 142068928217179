import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { message } from 'antd';

import {
  IOffer,
  ISelectOfferReq,
  ISendOfferTypeReq,
  ISendWithDrawReq,
} from '../../../api/types/offer';
import {
  useGetOffersQuery,
  useSelectOfferMutation,
  useSendOffersByTypeMutation,
  useSendWithDrawMutation,
} from '../../../api/offer';
import { useAppDispatch } from '../../../hooks';
import { setMessage } from '../../../reducers/common/commonSlice';
import { isApiError } from '../../../utils/general';

export default function useOfferController(data: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [openItem, setOpenItem] = useState<number | null>(null);
  const [selectOption, setSelectOption] = useState<number | null>(null);

  const options = [
    { value: 'By Phone', label: 'phone' },
    { value: 'By Email', label: 'email' },
  ];

  const [isWaitingModal, setIsWaitingModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<IOffer>();
  const [selectedValue, setSelectedValue] = useState<
    string | number | undefined
  >('email');
  const [isWithDrawModal, setIsWithDrawModal] = useState<boolean>(false);

  const handleChange = (value: string | number | undefined) => {
    setSelectedValue(value);
  };

  const handleOfferSelection = (offer: IOffer) => {
    setSelectedOffer(offer);
  };

  const [
    sendSelectedOffer,
    {
      data: selectedOfferData,
      isLoading: isSelectedOfferLoading,
      isError: isSelectedOfferError,
    },
  ] = useSelectOfferMutation();

  const {
    data: offersData,
    isLoading: isGetOffersLoading,
    isSuccess: isGetOffersSuccess,
    isError: isGetOffersError,
    refetch: refetchGetOffers,
  } = useGetOffersQuery(data?.id, {
    skip: !data?.id,
    refetchOnMountOrArgChange: true,
  });

  const [sendOffersByType, { isSuccess: isSendOffersByTypeSuccess }] =
    useSendOffersByTypeMutation();

  const [sendWithDraw, { isSuccess: isSendWithDrawSuccess }] =
    useSendWithDrawMutation();

  const { status, offers } = offersData || {};
  const shouldSetPollingInterval =
    status === 'offers_in_progress' && !offers?.length;

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const handleOfferSend = async (applicantId: number, offerId: number) => {
    try {
      const data: ISelectOfferReq = {
        applicant_id: applicantId,
        offer: {
          offer: {
            id: offerId,
          },
        },
      };
      await sendSelectedOffer(data);
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Selected offer failed');
    }
  };

  const handleSendOffersByType = async (applicantId: number) => {
    try {
      const data: ISendOfferTypeReq = {
        applicant_id: applicantId,
        type: {
          type: selectedValue,
        },
      };
      await sendOffersByType(data);
      dispatch(setMessage('Offers list has been successfully sent!'));
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Send offer failed');
    }
  };

  const handleSendWithDraw = async (applicantId: number) => {
    try {
      const data: ISendWithDrawReq = {
        applicant_id: applicantId,
      };
      await sendWithDraw(data);
      navigate('/applications');
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Send offer with draw failed');
      setIsWithDrawModal(false);
    }
  };

  useEffect(() => {
    if (isGetOffersLoading || offersData?.status) {
      setIsWaitingModal(true);
    } else {
      setIsWaitingModal(false);
    }
  }, [isGetOffersLoading, offersData]);

  useEffect(() => {
    if (shouldSetPollingInterval) {
      const intervalId = setInterval(() => {
        refetchGetOffers();
      }, 10000);

      // Cleanup the interval when the component unmounts or when you no longer need the polling
      return () => clearInterval(intervalId);
    }
  }, [shouldSetPollingInterval, refetchGetOffers]);

  return {
    options,
    selectedOffer,
    selectedValue,
    handleChange,
    handleOfferSelection,
    offers: offersData?.offers ?? [],
    offersMessage: offersData?.status,
    isGetOffersLoading,
    selectedOfferDataResult: {
      handleOfferSend,
      selectedOfferData,
      isSelectedOfferError,
      isSelectedOfferLoading,
    },
    handleSendOffersByType,
    isWithDrawModal,
    setIsWithDrawModal,
    handleSendWithDraw,
    isWaitingModal,
    setIsWaitingModal,
    contextHolder,
    openItem,
    selectOption,
    setOpenItem,
    setSelectOption,
  };
}
