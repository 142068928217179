import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface UserI {
  isSuccessMessage: string;
  isDirty: boolean;
}

const initialState: UserI = {
  isSuccessMessage: '',
  isDirty: false,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.isSuccessMessage = action.payload;
    },
    setIsDirtyAction: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
  },
});

export const selectAuthUser = (state: RootState) => state.common;

export const { setMessage, setIsDirtyAction } = commonSlice.actions;

export default commonSlice;
