import { InputProps, Input } from 'antd';
import Alert from '../assets/alert-circle.svg';
import './index.scss';

interface FloatInputProps extends InputProps {
  name?: string;
  error?: string;
  onBlur?: () => void;
  onChange?: any;
  onKeyPress?: () => void;
}

export const CustomTextArea = (props: FloatInputProps) => {
  const { TextArea } = Input;
  let { placeholder, name, error, onChange, maxLength, onKeyPress, onBlur } =
    props;

  return (
    <div className="custom-field">
      <div className="custom-field__title">{name}</div>
      <TextArea
        rows={3}
        onChange={onChange}
        autoSize={{ minRows: 3, maxRows: 6 }}
        value={props.value}
        maxLength={maxLength}
        bordered={true}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        className="input"
        onBlur={onBlur}
      />
      {error && props.type !== 'password' && (
        <img src={Alert} className="alert" />
      )}
    </div>
  );
};
