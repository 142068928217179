import { RouteObject } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { Login } from '../pages/Auth/Login';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { MainLayout } from '../components/MainLayout/MainLayout';
import { TokenPage } from '../pages/Auth/TokenPage';
import { ExpiredLink } from '../pages/Auth/ExpiredLink';
import { ResetPassword } from '../pages/Auth/ResetPassword';
import { Clients } from '../pages/Clients/Clients';
import { Partners } from '../pages/Partners/Partners';
import { CreatePartner } from '../pages/Partners/CreatePartner/CreatePartner';
import { Applications } from '../pages/Applications/Applications';
import { Reports } from '../pages/Reports/Reports';
import { ViewPartner } from '../pages/Partners/ViewPartner.tsx/ViewPartner';
import { PermissionDenied } from '../pages/ErrorPages/PermissionDenied';
import { ManageAdmins } from '../pages/ManageAdmins/ManageAdmins';
import { CreateAdmin } from '../pages/ManageAdmins/CreateAdmin/CreateAdmin';
import { ViewAdmin } from '../pages/ManageAdmins/ViewAdmin/ViewAdmin';
import { MainAccounts } from '../pages/MainAccounts/MainAccounts';
import { CreateMainAccount } from '../pages/MainAccounts/CreateMainAccount/CreateMainAccount';
import { ViewMainAccount } from '../pages/MainAccounts/ViewMainAccount/ViewMainAccount';
import { CreateApplications } from '../pages/Applications/CreateApplications/CreateApplications';
import { Customers } from '../pages/Customers/Customers';
import { ApplicationContainer } from '../pages/Applications/ApplicationContainer';
import { CustomerRecord } from '../pages/CustomerRecord';
import { ProfilePage } from '../pages/Profile';
import { EditProfilePage } from '../pages/EditProfile';
import { NotFound } from '../pages/ErrorPages/NotFound';
import { ServerError } from '../pages/ErrorPages/ServerError';
import { LendersPageList } from '../pages/Lenders';
import { ViewLenders } from '../pages/Lenders/ViewLenders';
import { EditLenders } from '../pages/Lenders/EditLenders/EditLenders';

enum AuthRoutePaths {
  LOGIN = '/login',
  CONFIRMATION = '/confirmation',
  RESET_PASSWORD = '/reset_password',
  INVITATION = '/invitation',
}

enum CommonRoutePaths {
  EXPIRED = '/expired',
  PERMISSION_DENIED = '/403',
  NOT_FOUND = '/404',
  SERVER_ERROR = '/500',
}

enum PrivateRoutePaths {
  DASHBOARD = '/dashboard',
  CLIENTS = '/clients',
  TRANSACTIONS = '/transactions',
  PARTNERS = '/partners',
  CREATEPARTNERS = '/new-partner/:is_associated_main_account',
  VIEW_PARTNER = '/partners/:id',
  CREATE_EDIT_PARTNER = '/partners/edit/:is_associated_main_account/:id',
  APPLICATIONS = '/applications',
  CREATE_APPLICATION = '/new-application',
  EDIT_APPLICATION = '/applications/edit/:id',
  REPORTS = '/reports',
  ADMIN_DASHBOARD = '/admins',
  CREATE_ADMIN = '/new-admin',
  VIEW_ADMIN = '/admins/:id',
  EDIT_ADMIN = '/admins/edit/:id',
  MAIN_ACCOUNTS = '/main_accounts',
  CUSTOMERS = '/customers',
  CUSTOMER_RECORD = '/customers/:id',
  CREATE_MAIN_ACCOUNT = '/new-main-account',
  VIEW_MAIN_ACCOUNT = '/main_accounts/:id',
  EDIT_MAIN_ACCOUNT = '/main_accounts/edit/:id',
  PROFILE = '/profile',
  EDIT_PROFILE = '/profile/edit/:id',
  EDIT_PROFILE_ASSOCIATED = '/profile/edit/:is_associated_main_account/:id',
  LENDERS = '/lenders',
  VIEW_LENDERS = '/lenders/:id',
  EDIT_LENDERS = '/lenders/edit/:id',
}

const authRoutes: RouteObject[] = [
  { path: AuthRoutePaths.LOGIN, element: <Login /> },
  { path: AuthRoutePaths.CONFIRMATION, element: <TokenPage /> },
  { path: AuthRoutePaths.RESET_PASSWORD, element: <ResetPassword /> },
  { path: AuthRoutePaths.INVITATION, element: <ResetPassword /> },
];

const commonRoutes: RouteObject[] = [
  { path: CommonRoutePaths.EXPIRED, element: <ExpiredLink /> },
  { path: CommonRoutePaths.PERMISSION_DENIED, element: <PermissionDenied /> },
  { path: CommonRoutePaths.NOT_FOUND, element: <NotFound /> },
  { path: CommonRoutePaths.SERVER_ERROR, element: <ServerError /> },
];

const privateRoutes: RouteObject[] = [
  { path: PrivateRoutePaths.DASHBOARD, element: <Dashboard /> },
  { path: PrivateRoutePaths.CLIENTS, element: <Clients /> },
  { path: PrivateRoutePaths.PARTNERS, element: <Partners /> },
  { path: PrivateRoutePaths.CREATEPARTNERS, element: <CreatePartner /> },
  { path: PrivateRoutePaths.VIEW_PARTNER, element: <ViewPartner /> },
  { path: PrivateRoutePaths.CREATE_EDIT_PARTNER, element: <CreatePartner /> },
  { path: PrivateRoutePaths.APPLICATIONS, element: <Applications /> },
  {
    path: PrivateRoutePaths.CREATE_APPLICATION,
    element: <CreateApplications />,
  },
  {
    path: PrivateRoutePaths.EDIT_APPLICATION,
    element: <ApplicationContainer />,
  },
  { path: PrivateRoutePaths.REPORTS, element: <Reports /> },
  { path: PrivateRoutePaths.ADMIN_DASHBOARD, element: <ManageAdmins /> },
  { path: PrivateRoutePaths.CREATE_ADMIN, element: <CreateAdmin /> },
  { path: PrivateRoutePaths.VIEW_ADMIN, element: <ViewAdmin /> },
  { path: PrivateRoutePaths.EDIT_ADMIN, element: <CreateAdmin /> },
  { path: PrivateRoutePaths.MAIN_ACCOUNTS, element: <MainAccounts /> },
  { path: PrivateRoutePaths.CUSTOMERS, element: <Customers /> },
  { path: PrivateRoutePaths.CUSTOMER_RECORD, element: <CustomerRecord /> },
  {
    path: PrivateRoutePaths.CREATE_MAIN_ACCOUNT,
    element: <CreateMainAccount />,
  },
  { path: PrivateRoutePaths.VIEW_MAIN_ACCOUNT, element: <ViewMainAccount /> },
  { path: PrivateRoutePaths.EDIT_MAIN_ACCOUNT, element: <CreateMainAccount /> },
  { path: PrivateRoutePaths.PROFILE, element: <ProfilePage /> },
  { path: PrivateRoutePaths.EDIT_PROFILE, element: <EditProfilePage /> },
  {
    path: PrivateRoutePaths.EDIT_PROFILE_ASSOCIATED,
    element: <EditProfilePage />,
  },
  { path: PrivateRoutePaths.LENDERS, element: <LendersPageList /> },
  { path: PrivateRoutePaths.VIEW_LENDERS, element: <ViewLenders /> },
  { path: PrivateRoutePaths.EDIT_LENDERS, element: <EditLenders /> },
];

export const routes = [
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: '/',
        element: <MainLayout />,
        children: privateRoutes,
      },
    ],
  },
  ...authRoutes,
  ...commonRoutes,
];
