import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';

import { isApiError } from '../../utils/general';

import { useLogoutMutation } from '../../api/common';

import { useClickOutside } from '../../hooks/useClickOutSide';
import { useAppSelector } from '../../hooks';

import { SettingsIcon } from '../icons/SettingsIcon';
import { PartnersIcon } from '../icons/PartnersIcon';
import arrow from '../../assets/arrow.svg';
import logoutImage from '../../assets/logout.svg';
import Settings from '../../assets/Settings.svg';
import Support from '../../assets/Support.svg';
import Nitifications from '../../assets/Nitifications.svg';

import './index.scss';
import { Notifications } from '../Notifications/Notifications';
import { NotifIcon } from '../icons/NotifIcon';
import close from '../../assets/x-close.svg';

export const Header = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openNotif, setOpenNotif] = useState<boolean>(false);
  const [open2Fmodal, setOpen2Fmodal] = useState(false)
  const [verifySuccess, setVerifySuccess] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();
  const [logout, {}] = useLogoutMutation();
  const navigation = useNavigate();
  const ref = useRef(null);
  const refNotif = useRef(null);
  useClickOutside(ref, () => setOpenMenu(false));
  useClickOutside(refNotif, () => setOpenNotif(false));
  const location = useLocation();
  const user = useAppSelector((state) => state.profile);
  const isAuth = localStorage.getItem('token');
  const [openBillInfo, setOpenBillInfo] = useState(false);

  useEffect(() => {
    setOpenBillInfo(user.need_bill_2fa)
  },[user.need_bill_2fa])


  const successMessage = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (verifySuccess) {
      successMessage('2FA for Bill.com has been successfully passed. All transactions are resumed.')
      setVerifySuccess(false)
    }
  },[verifySuccess])

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
    });
  };

  const maxLengthUserName = 13;
  const truncatedUserName =
    user.name.length > maxLengthUserName
      ? user.name.substring(0, maxLengthUserName) + '...'
      : user.name;

  useEffect(() => {
    if (!isAuth) {
      navigation('/login');
    }
  }, [isAuth]);

  const logoutFunc = async () => {
    try {
      await logout('').unwrap();
      localStorage.removeItem('token');
      navigation('/login');
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Logout failed');
    }
  };

  const viewProfile = () => {
    setOpenMenu(false);
    navigation('/profile');
  };

  // useEffect(() => {
  //   setOpenBillInfo(true);
  // }, [location]);

  return (
    <>
      {contextHolder}
      <div className="header">
        <div className="header__row">
          <div className="header__settings">
            <div className="header__wrapper-notif">
              <NotifIcon
                fill={openNotif ? '#1F44DD' : '#808086'}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  setOpenNotif(true);
                }}
              />
              {user.notifications ? (
                <div className="header__notif-count"></div>
              ) : (
                <></>
              )}
            </div>

            <img src={Support} className="header__image" />

            <img src={Settings} className="header__image" />
          </div>
          <Notifications
            refNotif={refNotif}
            openNotif={openNotif}
            setOpenNotif={setOpenNotif}
            openModal={open2Fmodal}
            setOpenModal={setOpen2Fmodal}
            setVerifySuccess={setVerifySuccess}
          />
          {!openNotif && openBillInfo && user.need_bill_2fa && (
            <div className={`notif__container ${openBillInfo ? 'open' : ''}`}>
              <div className="notif__rhombus"></div>
              <div
                className="notif__main"
                style={{ padding: '2px 25px 2px 0' }}
              >
                <div className="notif__bill">
                  <div className="notif__bill-text">
                    Your transactions have been suspended. 2FA for Bill.com is
                    required.
                  </div>
                  <div className="notif__bill-btn violet-btn" onClick={() => {setOpen2Fmodal(true)}}>Pass 2FA</div>
                  <div>
                    <img
                      src={close}
                      onClick={() => setOpenBillInfo(false)}
                      className="notif__close-btn"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div ref={ref}>
            <div className="header__user-info">
              <div
                className={`header__profile ${openMenu ? 'open' : ''}`}
                onClick={() => setOpenMenu((prev: boolean) => !prev)}
              >
                <div className="header__name">{truncatedUserName}</div>
                <img
                  className={`header__arrow ${openMenu ? 'open' : ''}`}
                  src={arrow}
                />
              </div>
            </div>
            <div className={`header__menu ${openMenu ? '' : 'close'}`}>
              {user.role.key === 'super_admin' && (
                <div
                  className={`header__my-profile ${
                    location.pathname.includes('admins') ? 'active' : ''
                  }`}
                  onClick={() => {
                    navigation('/admins');
                  }}
                >
                  <SettingsIcon
                    fill={
                      location.pathname.includes('admins') ? 'white' : `#1A1A26`
                    }
                  />
                  <div
                    className="header__item-title"
                    style={{ margin: '0 0 0 8px' }}
                  >
                    Manage Admins
                  </div>
                </div>
              )}
              {user.role.key !== 'super_admin' && (
                <div className="header__my-profile" onClick={viewProfile}>
                  <PartnersIcon fill="#1A1A26" />
                  <div className="header__item-title">My Profile</div>
                </div>
              )}
              <div className="header__logout" onClick={logoutFunc}>
                <div>Log Out</div>
                <img src={logoutImage} />
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
