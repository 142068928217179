import { Button, Form, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import close from '../../../assets/x-close.svg';
import { CustomInput } from '../../../customFields/CustomInput';
import { yupSync } from '../../../utils';
import * as Yup from 'yup';
import './index.scss';
import { CustomSelect } from '../../../customFields/CustomSelect';
import { ficoProps } from './LoanTypeTab';
import { useLazyGetFicoQuery } from '../../../api/lenders';

interface ModalI {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFicoData: any;
  editFico?: number | null;
  ficoData?: ficoProps[];
  id: number;
}

export const AddFicoModal: FC<ModalI> = ({
  openModal,
  setOpenModal,
  setFicoData,
  editFico,
  ficoData,
  id,
}) => {
  const [data, setData] = useState<ficoProps>({
    fico_range_from: '',
    fico_range_to: '',
    apr: id === 3 ? '1' : '',
  });
  const [err, setErr] = useState(false);

  useEffect(() => {
    if (+data.fico_range_from > +data.fico_range_to) {
      setErr(true);
    } else {
      setErr(false);
    }
  }, [data]);

  useEffect(() => {
    if ((editFico || editFico === 0) && ficoData) {
      setData({
        fico_range_from: ficoData?.[editFico].fico_range_from ?? '',
        fico_range_to: ficoData?.[editFico].fico_range_to ?? '',
        apr: ficoData?.[editFico].apr ?? '',
      });
    }
  }, [editFico, ficoData]);

  const addFico = () => {
    if (editFico || editFico === 0) {
      if (ficoData) {
        const newData = [...ficoData];
        newData.splice(editFico, 1, data);
        setFicoData(newData);
        setOpenModal(false);
      }
    } else {
      const newFico: any = ficoData;
      newFico?.push(data);
      setFicoData(newFico);
      setOpenModal(false);
    }
  };

  const currencyMaskProcent = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    const number = value.replace(/,/g, '');
    const regex = /^\d+(\.\d{0,2})?$/;

    if (number && !regex.test(number)) {
      const fixedNumber = parseFloat(number).toFixed(2);
      e.target.value = fixedNumber.toString();
    }

    if (+number > 100.0) {
      e.target.value = '100.0';
    }
    return e.target.value;
  };

  function onBlurHandler(e: React.FocusEvent<HTMLInputElement>) {
    const inputValue = e.target.value;
    if (inputValue.endsWith('.')) {
      e.target.value = inputValue + '0';
      setData((prev: ficoProps) => ({ ...prev, apr: e.target.value }));
    }
  }

  const currencyMaskTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/^0/, '');
    value = value.replace(/\D/g, '');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');
    e.target.value = value;
    const number = value.replace(/,/g, '');

    if (+number > 850) {
      e.target.value = '850';
    }

    return e.target.value;
  };

  const covMinFrom = () => {
    if (+data.fico_range_from < 500) {
      setData((prev: ficoProps) => ({
        ...prev,
        fico_range_from: '500',
      }));
    }
  };

  const covMinTo = () => {
    if (+data.fico_range_to < 500) {
      setData((prev: ficoProps) => ({
        ...prev,
        fico_range_to: '500',
      }));
    }
  };

  return (
    <Modal
      destroyOnClose
      className={`add-item-modal`}
      centered
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button className="gray-btn" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
          <Button
            className="blue-btn"
            htmlType="submit"
            onClick={addFico}
            disabled={
              !data.fico_range_from || !data.fico_range_to || !data.apr || err
                ? true
                : false
            }
          >
            {editFico || editFico === 0 ? 'Save' : 'Add'}
          </Button>
        </div>
      }
      closeIcon={<img src={close} />}
      width={344}
    >
      <div className="fico-modal__wrapper">
        <div className="fico-modal__title">
          {editFico || editFico === 0 ? 'Edit' : 'Add'} Row
        </div>
        <div className="custom-field__title">FICO Buckets (reference)</div>
        <div className="fico-modal__row">
          <div className="item">
            <CustomInput
              placeholder="From"
              className="input-white-small"
              value={data.fico_range_from}
              onChange={(e) =>
                setData((prev: ficoProps) => ({
                  ...prev,
                  fico_range_from: e.target.value,
                }))
              }
              onInput={currencyMaskTo}
              onBlur={covMinFrom}
            />
          </div>
          <div className="item">
            <CustomInput
              placeholder="To"
              className="input-white-small"
              value={data.fico_range_to}
              onChange={(e) =>
                setData((prev: ficoProps) => ({
                  ...prev,
                  fico_range_to: e.target.value,
                }))
              }
              onInput={currencyMaskTo}
              onBlur={covMinTo}
            />
          </div>
        </div>
        {err ? (
          <div className="error-text" style={{ margin: '-18px 0 5px 0' }}>
            <b>FICO ranges to</b> must be greater than <b>FICO ranges from</b>
          </div>
        ) : (
          ''
        )}
        {id !== 3 && (
          <div className="fico-modal__row">
            <div style={{ width: '100%' }}>
              <CustomInput
                className="input-white-small"
                placeholder="Indicate APR"
                name="APR"
                value={data.apr}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setData((prev: ficoProps) => ({
                    ...prev,
                    apr: e.target.value,
                  }))
                }
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  currencyMaskProcent(e)
                }
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  onBlurHandler(e)
                }
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
