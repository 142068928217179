import { Col, Form, Row } from 'antd';
import { LocationSearchInput } from './location-search';

const SomeFunctionalComponent = ({ setAddress, address }: any) => {
  const clearAddress = () => {
    // Очистить адрес с помощью this.props.form.setFieldsValue();
  };

  const handleAddressChange = () => {
    // Делать что-то с адресом
  };

  const handleAddressSelect = (address: any) => {
    setAddress(address);
  };

  return (
    <Form>
      <Form.Item
        label={<span className="Form__label">Address</span>}
        name="property_status"
        labelCol={{ span: 24 }}
      >
        <LocationSearchInput
          address={address}
          clearAddress={clearAddress}
          onChange={handleAddressChange}
          onAddressSelect={handleAddressSelect}
        />
      </Form.Item>
    </Form>
  );
};

export default SomeFunctionalComponent;
