import './index.scss';
import sync from '../../assets/synchronize.svg'
import {Button} from "antd";
import React, {useEffect, useState} from "react";
import {LendersFunnelTable} from "../Tables/LendersFunnelTable/LendersFunnelTable";
import {useLazyGetLendersFunnelQuery, useSynkLenderFunnelMutation} from "../../api/lendersFunnel";
import {Spinner} from "../Spinner/Spinner";

interface LendersFunnelI {
    model_id: string
    model_type: string
}

export const LendersFunnel = ({model_id, model_type}: LendersFunnelI) => {
    const INSTALLMENT = '1'
    const REVOLVING = '2'
    const LEASE_TO_OWN = '3'
    const PROMOTIONAL = '4'
    const PERSONAL = 'personal'
    const COMMERCIAL = 'commercial'
    const [loanTypeTab, setLoanTypeTab] = useState<string>(INSTALLMENT)
    const [proposalTypeTab, setProposalTypeTab] = useState<string>(PERSONAL)
    const [getLenderFunnels, {data, isLoading}] = useLazyGetLendersFunnelQuery()
    const [synkLenders, {isLoading: isSynkLoading}] = useSynkLenderFunnelMutation()
    const handleLoanTypeTab = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoanTypeTab(e.currentTarget.name);
    };
    const handleProposalTypeTab = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setProposalTypeTab(e.currentTarget.name);
    };
    useEffect(() => {
        getLenderFunnels({
            personal_commercial: proposalTypeTab,
            loan_type_id: loanTypeTab,
            model_id,
            model_type
        })
    }, [proposalTypeTab, loanTypeTab, getLenderFunnels, model_id, model_type])

    return (
        <div className="lenders-funnel">
            {isLoading && <Spinner size="large"/>}
            <div className="tabs-wrapper">
                <div>
                    <Button name={INSTALLMENT}
                            onClick={handleLoanTypeTab}
                            className={`tab-button ${loanTypeTab === INSTALLMENT && 'active'}`}>
                        Installment
                    </Button>
                    <Button name={REVOLVING}
                            onClick={handleLoanTypeTab}
                            className={`tab-button ${loanTypeTab === REVOLVING && 'active'}`}>
                        Revolving
                    </Button>
                    <Button name={LEASE_TO_OWN}
                            onClick={handleLoanTypeTab}
                            className={`tab-button ${loanTypeTab === LEASE_TO_OWN && 'active'}`}>
                        Lease To Own
                    </Button>
                    <Button name={PROMOTIONAL}
                            onClick={handleLoanTypeTab}
                            className={`tab-button ${loanTypeTab === PROMOTIONAL && 'active'}`}>
                        Promotional
                    </Button>
                </div>
                <div className="round-tab-container">
                    <Button name={PERSONAL}
                            onClick={handleProposalTypeTab}
                            className={`tab-button-round ${proposalTypeTab === PERSONAL && 'active'}`}>
                        Personal
                    </Button>
                    <Button name={COMMERCIAL}
                            onClick={handleProposalTypeTab}
                            className={`tab-button-round ${proposalTypeTab === COMMERCIAL && 'active'}`}>
                        Commercial
                    </Button>
                </div>
                {model_type === "main_account"
                    ? <Button className="synk-button"
                              onClick={() => synkLenders({model_id: +model_id, model_type})}
                              disabled={isSynkLoading}
                    ><img src={sync} alt="synk"/><span>Sync Partners</span></Button>
                    : <div>&nbsp;</div>
                }
            </div>
            <div className="table-container">
                <div className="table-container_item">
                    <div className="table-container_item__title">{data?.data[0]?.name || '-'}</div>
                    <LendersFunnelTable
                        banks={data?.data[0]?.banks}
                        id={data?.data[0]?.id}
                        model_id={model_id}
                        model_type={model_type}
                        personal_commercial={proposalTypeTab}
                        loan_type_id={loanTypeTab}
                    />
                </div>
                <div className="table-container_item">
                    <div className="table-container_item__title">{data?.data[1]?.name || '-'}</div>
                    <LendersFunnelTable
                        banks={data?.data[1]?.banks}
                        id={data?.data[1]?.id}
                        model_id={model_id}
                        model_type={model_type}
                        personal_commercial={proposalTypeTab}
                        loan_type_id={loanTypeTab}
                    />
                </div>
            </div>
            <hr className="lenders-funnel-line"/>
            <div className="table-container">
                <div className="table-container_item">
                    <div className="table-container_item__title">{data?.data[2]?.name || '-'}</div>
                    <LendersFunnelTable
                        banks={data?.data[2]?.banks}
                        id={data?.data[2]?.id}
                        model_id={model_id}
                        model_type={model_type}
                        personal_commercial={proposalTypeTab}
                        loan_type_id={loanTypeTab}
                    />
                </div>
                <div className="table-container_item">
                    <div className="table-container_item__title">{data?.data[3]?.name || '-'}</div>
                    <LendersFunnelTable
                        banks={data?.data[3]?.banks}
                        id={data?.data[3]?.id}
                        model_id={model_id}
                        model_type={model_type}
                        personal_commercial={proposalTypeTab}
                        loan_type_id={loanTypeTab}
                    />
                </div>
            </div>
            <hr className="lenders-funnel-line"/>
            <div className="table-container">
                <div className="table-container_item">
                    <div className="table-container_item__title">{data?.data[4]?.name || '-'}</div>
                    <LendersFunnelTable
                        banks={data?.data[4]?.banks}
                        id={data?.data[4]?.id}
                        model_id={model_id}
                        model_type={model_type}
                        personal_commercial={proposalTypeTab}
                        loan_type_id={loanTypeTab}
                    />
                </div>
                <div className="table-container_item">
                    <div className="table-container_item__title">{data?.data[5]?.name || '-'}</div>
                    <LendersFunnelTable
                        banks={data?.data[5]?.banks}
                        id={data?.data[5]?.id}
                        model_id={model_id}
                        model_type={model_type}
                        personal_commercial={proposalTypeTab}
                        loan_type_id={loanTypeTab}
                    />
                </div>
            </div>
            <hr className="lenders-funnel-line"/>
            <div className="table-container">
                <div className="table-container_item">
                    <div className="table-container_item__title">{data?.data[6]?.name || '-'}</div>
                    <LendersFunnelTable
                        banks={data?.data[6]?.banks}
                        id={data?.data[6]?.id}
                        model_id={model_id}
                        model_type={model_type}
                        personal_commercial={proposalTypeTab}
                        loan_type_id={loanTypeTab}
                    />
                </div>
                <div className="table-container_item">
                    <div className="table-container_item__title">{data?.data[7]?.name || '-'}</div>
                    <LendersFunnelTable
                        banks={data?.data[7]?.banks}
                        id={data?.data[7]?.id}
                        model_id={model_id}
                        model_type={model_type}
                        personal_commercial={proposalTypeTab}
                        loan_type_id={loanTypeTab}
                    />
                </div>
            </div>
        </div>
    )
}