import axios from 'axios';
import moment from 'moment';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const downloadFileFunc = (data: any, reportType: string) => {
  const accountTypeIdsString = data.account_type_ids.join(',');

  return axios({
    method: 'get',
    url: `${baseUrl}/reports/${reportType}/export`,
    params: {
      date_from: moment(data.date_from).format('YYYY-MM-DD HH:mm:ss'),
      date_to: moment(data.date_to).format('YYYY-MM-DD HH:mm:ss'),
      order: data.order,
      order_by: data.order_by,
      search: data.search,
      account_type_ids: data.account_type_ids.map((elem: any) => +elem),
      bank_ids: data?.bank_ids?.map((elem: any) => +elem),
      partners: data?.partners?.map((elem: any) => +elem),
      loan_products: data?.loan_products?.map((elem: any) => +elem),
      main_accounts: data?.main_accounts?.map((elem: any) => +elem),
      application_statuses: data.application_statuses.map((elem: any) => elem),
      transaction_statuses: data.transaction_statuses.map((elem: any) => elem),
    },
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    },
  });
};
