import { FC } from 'react';
import check from '../assets/Check.svg';
import { LoanProducts } from '../api/types/partners';
import './index.scss';

interface LoanProductI {
  options: LoanProducts[];
  onChange: React.Dispatch<React.SetStateAction<number[]>>;
  values: number[];
  error: boolean;
  title?: string | React.ReactNode;
  type?: string;
}

export const LoanProduct: FC<LoanProductI> = ({
  options,
  onChange,
  values,
  error,
  title,
  type,
}) => {
  const handleCheck = (id: number) => {
    if (type === 'loan_types') {
      if (values?.includes(id)) {
        const newValues = [...values];
        const array = newValues.filter((item: any) => item !== id);
        onChange(array);
      } else {
        const newValues = [...values];

        newValues.push(id);
        onChange(newValues);
      }
    } else {
      if (values?.includes(id)) {
        onChange((prev: number[]) => prev.filter((item) => item !== id));
      } else {
        onChange((prev: number[]) => [...prev, id]);
      }
    }
  };

  return (
    <div className={`loan ${error ? 'error' : ''}`}>
      <div className="loan__title">{title}</div>
      <div className="loan__row">
        {options?.map((elem: LoanProducts) => {
          return (
            <div
              key={elem.id}
              className={`loan__item ${
                values?.includes(elem.id) ? 'active' : ''
              }`}
              onClick={() => {
                handleCheck(elem.id);
              }}
            >
              <img
                src={check}
                className={`loan__item-image ${
                  values?.includes(elem.id) ? 'active' : ''
                }`}
              />

              {elem.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};
