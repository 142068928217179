import { Button, Table, Tooltip, message, Dropdown } from 'antd';
import { useState, useEffect, useRef } from 'react';
import arrow from '../../assets/arrow-down.svg';
import Property from '../../assets/Property.svg';
import PropertyHover from '../../assets/PropertyHover.svg';
import noData from '../../assets/noData.svg';
import NoUser from '../../assets/NoUser.svg';
import view from '../../assets/view.svg';
import email from '../../assets/email.svg';
import activate from '../../assets/activate.svg';
import pause from '../../assets/Stopwatch Pause.svg';
import type { ColumnsType } from 'antd/es/table';
import { TableParams, TableResponse } from '../../api/types/partners';
import { Pagination } from './Pagination';
import { useClickOutside } from '../../hooks/useClickOutSide';
import { ClockIcon } from '../icons/ClockIcon';
import { useNavigate } from 'react-router-dom';
import {
  useChangeStatusMutation,
  useResetInvitationMutation,
} from '../../api/partners';
import { TablePartnersDataI, idsI } from '../../pages/Partners/Partners';
import './index.scss';
import { useAppSelector } from '../../hooks';

interface PartnersTableProps {
  data: TablePartnersDataI[];
  setTableParams: React.Dispatch<React.SetStateAction<TableParams>>;
  metaData: TableResponse | undefined;
  tableParams: TableParams;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAddNewPartnerModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PartnersTable = ({
  data,
  setTableParams,
  metaData,
  tableParams,
  setTrigger,
  setOpenAddNewPartnerModal,
}: PartnersTableProps) => {
  const ref = useRef(null);
  const [openActionId, setOpenActionId] = useState<number | null>(null);
  useClickOutside(ref, () => setOpenActionId(null));
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const user = useAppSelector((state) => state.profile);

  const success = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const sorting = (name: string) => {
    setTableParams((prev: TableParams) => ({
      ...prev,
      order_by: name,
      order:
        prev.order_by === name
          ? prev.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'asc',
    }));
  };

  const [changeStatus, { isSuccess }] = useChangeStatusMutation();
  const [resetInvitation, { isSuccess: isSuccessResetInv }] =
    useResetInvitationMutation();

  useEffect(() => {
    setTrigger((prev: boolean) => !prev);
    if (isSuccessResetInv) {
      success('Link resent successfully');
    }
    if (isSuccess) {
      success('Status changed successfully');
    }
  }, [isSuccess, isSuccessResetInv]);

  const changeStatusFunc = (id: number, status: number) => {
    if (status === 2 || status === 1) {
      const ids: idsI = {
        id: id,
        key: { status_key: status },
      };
      changeStatus(ids);
    }
    if (status === 0) {
      resetInvitation(id);
    }
  };

  const columns: ColumnsType<TablePartnersDataI> = [
    {
      title: (
        <div className="header-item">
          ID{' '}
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'item_id' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('item_id')}
          />
        </div>
      ),
      width: 70,
      dataIndex: 'item_id',
      key: 'item_id',
      render: (text) => (
        <div className="table-item">
          <div className="table-text blue">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Company Name
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'company_name' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('company_name')}
          />
        </div>
      ),
      width: 160,
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Main Account{' '}
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'main_account' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('main_account')}
          />
        </div>
      ),
      width: 160,
      dataIndex: 'main_account',
      key: 'main_account',
      render: (text, record) => (
        <div className="table-item">
          <Tooltip title={record?.main_account?.company_name}>
            <div className="table-text">
              {record?.main_account?.company_name
                ? record?.main_account?.company_name
                : '-'}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: <div className="header-item">Account Type </div>,
      dataIndex: 'account_type_id',
      key: 'account_type_id',
      width: 160,
      render: (text, record) => (
        <Tooltip
          title={record.account_types?.map((elem: any) => elem.name).join(', ')}
        >
          <div className="table-text-acc-types">
            {record.account_types?.length
              ? record.account_types?.map((elem: any) => elem.name).join(', ')
              : '-'}
          </div>
        </Tooltip>
      ),
    },
    {
      title: (
        <div className="header-item">
          State
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'state' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('state')}
          />
        </div>
      ),
      width: 90,
      dataIndex: 'state',
      key: 'state',
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" style={{ width: '50px' }}>
          City
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'city' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('city')}
          />
        </div>
      ),
      width: 120,
      dataIndex: 'city',
      key: 'city',
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },

    {
      title: <div className="header-item">Phone</div>,
      dataIndex: 'phone',
      key: 'phone',
      width: 120,
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Status
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'status' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('status')}
          />
        </div>
      ),
      width: 130,
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        const items = [
          {
            key: '1',
            label:
              text === 'Active' ? (
                <span className="table-dropdown-text">Pause</span>
              ) : text === 'Paused' ? (
                <span className="table-dropdown-text">Activate</span>
              ) : (
                <span className="table-dropdown-text">
                  Resend Invitation email
                </span>
              ),
            icon:
              text === 'Active' ? (
                <img src={pause} alt="pause" />
              ) : text === 'Paused' ? (
                <img src={activate} alt="activate" />
              ) : (
                <img src={email} alt="email" />
              ),
          },
          {
            key: '2',
            label: <span className="table-dropdown-text">View</span>,
            icon: <img src={view} alt="view" />,
          },
        ];
        const handleDropdownClick = (
          key: string,
          e:
            | React.MouseEvent<HTMLElement, MouseEvent>
            | React.KeyboardEvent<HTMLElement>
        ) => {
          e.stopPropagation();
          if (key === '1') {
            text === 'Active' && changeStatusFunc(record.id, 1);
            text === 'Paused' && changeStatusFunc(record.id, 2);
            text === 'Pending' && changeStatusFunc(record.id, 0);
          } else if (key === '2') {
            navigate(`/partners/${record.id}`);
          }
        };
        return (
          <div className="table-item table-status">
            <div
              className={
                text === 'Active'
                  ? 'table-text status Approved'
                  : text === 'Paused'
                  ? 'table-text status noOffer'
                  : text === 'Pending'
                  ? 'table-text status Pending'
                  : ''
              }
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {text === 'Pending' && <ClockIcon fill="#7C24C2" />}
                {text}
              </div>
            </div>
            <Dropdown
              placement="bottomRight"
              menu={{
                items,
                onClick: (info) => handleDropdownClick(info.key, info.domEvent),
              }}
              onOpenChange={(open) => {
                open && setOpenActionId(record.id);
                !open && setOpenActionId(null);
              }}
            >
              <img
                src={record.id === openActionId ? PropertyHover : Property}
                style={{ cursor: 'pointer', margin: '0 0 0 15px' }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                alt="dots"
              />
            </Dropdown>
          </div>
        );
      },
    },
  ];

  if (user.role.key === 'main_account') {
    const roleColumnIndex = columns.findIndex(
      (column) => column.key === 'main_account'
    );
    if (roleColumnIndex !== -1) {
      columns.splice(roleColumnIndex, 1);
    }
    const roleColumnIndex1 = columns.findIndex(
      (column) => column.key === 'account_type_id'
    );
    if (roleColumnIndex1 !== -1) {
      columns.splice(roleColumnIndex1, 1);
    }
  }

  const customNoDataText = {
    emptyText: (
      <>
        {!tableParams.account_type_id &&
          !tableParams.status_key &&
          !tableParams.search && (
            <div className="no-data-found">
              <img src={NoUser} />
              <div className="no-data-found__text">No users yet</div>
              <div className="no-data-found__subtext">
                Add new partners to start work with them
              </div>
              {user?.role?.key === 'main_account' ? (
                <></>
              ) : (
                <Button
                  className="blue-btn"
                  style={{ margin: '24px 0 0 0' }}
                  onClick={() => {
                    if (user.role.key === 'super_admin') {
                      setOpenAddNewPartnerModal(true);
                    }
                    if (user.role.key === 'main_account') {
                      navigate('/new-partner/partner');
                    }
                  }}
                >
                  + Add partner
                </Button>
              )}
            </div>
          )}
        {(tableParams.account_type_id ||
          tableParams.status_key ||
          tableParams.search) && (
          <div className="no-data-found">
            <img src={noData} />
            <div className="no-data-found__text">No results found</div>
            <div className="no-data-found__subtext">
              Try adjusting your search to find what you are looking for
            </div>
          </div>
        )}
      </>
    ),
  };

  return (
    <>
      <div>
        {contextHolder}
        <Table
          ref={ref}
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={customNoDataText}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => navigate(`/partners/${record.id}`),
            };
          }}
        />
      </div>
      {data?.length > 0 && (
        <Pagination
          setTableParams={setTableParams}
          metaData={metaData}
          tableParams={tableParams}
        />
      )}
    </>
  );
};
