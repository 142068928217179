import { iconI } from './types';

export const SettingsIcon = ({ fill = 'white', style }: iconI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 22 22"
      fill="none"
      style={style}
    >
      <path
        d="M15.3771 6.62358C17.1473 8.3938 17.6769 11.0561 16.7188 13.369C15.7608 15.6819 13.5038 17.19 11.0004 17.19C8.49689 17.19 6.23992 15.6819 5.28189 13.369C4.32385 11.0561 4.85341 8.3938 6.62363 6.62358C7.78437 5.46272 9.35875 4.81055 11.0004 4.81055C12.642 4.81055 14.2164 5.46272 15.3771 6.62358"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5038 10.9998H17.1904"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.81044 10.9998H3.49707"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9998 18.5039V17.1904"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9998 3.49707V4.81044"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.9325 8.12934L16.7188 8.63207"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.2821 13.3686L4.06836 13.8713"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.12932 4.06836L8.6321 5.28216"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8714 17.9325L13.3686 16.7188"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.06836 8.12934L5.28215 8.63207"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.7188 13.3686L17.9325 13.8713"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.12932 17.9325L8.6321 16.7188"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8714 4.06836L13.3686 5.28216"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.3053 16.3053L12.5156 12.5156"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.48401 9.48401L5.69434 5.69434"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.3053 5.69434L12.5156 9.48401"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.48401 12.5156L5.69434 16.3053"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.516 9.48436C13.1291 10.0975 13.3126 11.0195 12.9807 11.8206C12.6489 12.6216 11.8673 13.1439 11.0002 13.1439C10.1332 13.1439 9.35149 12.6216 9.01969 11.8206C8.68788 11.0195 8.87129 10.0975 9.48439 9.48436C9.8864 9.08232 10.4317 8.85645 11.0002 8.85645C11.5688 8.85645 12.114 9.08232 12.516 9.48436"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
