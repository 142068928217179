import { Modal } from 'antd';
import { FC } from 'react';
import mail from '../../assets/mail.svg';
import './index.scss';

interface ForgotPasswordProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SuccessPopup: FC<ForgotPasswordProps> = ({
  openModal,
  setOpenModal,
}) => {
  return (
    <Modal
      destroyOnClose
      className="forgot-password-modal"
      centered
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={<></>}
      width={344}
    >
      <div className="forgot-password-modal__container-success">
        <img src={mail} className="forgot-password-modal__image" />
        <div className="forgot-password-modal__title">Check your email</div>
        <div className="forgot-password-modal__subtitle-success">
          If such email address exists in our system, we will send an email with
          instructions on how to reset your password
        </div>
      </div>
    </Modal>
  );
};
