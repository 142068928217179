import React, { useEffect } from 'react';

const Timer = ({
  time,
  setTime,
}: {
  time: number;
  setTime: React.Dispatch<React.SetStateAction<number>>;
}) => {
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      } else {
        clearInterval(timerInterval);
        // You can perform some actions here when the timer expires.
      }
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [time]);

  return <></>;
};

export default Timer;
