import { ChangeEvent, FC, useCallback } from 'react';
import InputMask from 'react-input-mask';
import { CustomInput } from './CustomInput';
import './index.scss';

interface PhoneNumberInputProps {
  value?: string;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  onChange?: any;
  hasError: () => boolean;
  name: string;
  mask: string;
  disable?: boolean;
  onBlur?: () => void;
  required?: boolean;
}

export const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  value,
  placeholder,
  className,
  maxLength,
  onChange,
  hasError,
  name,
  mask,
  required = false,
  disable = false,
  onBlur = () => {},
}) => {
  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }
    },
    [onChange]
  );

  const renderInput = (inputProps: any) => (
    <CustomInput
      placeholder={placeholder}
      style={{ width: '100%' }}
      className={`${className} ${
        hasError() && (value?.includes('_') || !value) ? 'error' : ''
      } `}
      type="tel"
      maxLength={maxLength}
      name={name}
      disabled={disable}
      required={required}
      {...inputProps}
    />
  );

  return (
    <InputMask
      mask={mask}
      value={value}
      onChange={handleInputChange}
      onBlur={onBlur}
      disabled={disable}
    >
      {/* @ts-ignore */}
      {renderInput}
    </InputMask>
  );
};
