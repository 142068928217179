import { useEffect, useState, useRef } from 'react';
import dots from '../../../assets/Actions menu.svg';
import deleteIcon from '../../../assets/TrashBinMinimalistic.svg';
import Pen from '../../../assets/Pen.svg';
import plus from '../../../assets/plusicon.svg';
import { ficoProps } from './LoanTypeTab';
import './index.scss';
import { useClickOutside } from '../../../hooks/useClickOutSide';

interface Props {
  id: number;
  setIsFicoModal?: React.Dispatch<React.SetStateAction<boolean>>;
  ficoData: ficoProps[];
  setFicoData?: any;
  setEditFico?: React.Dispatch<React.SetStateAction<any>>;
  setIsEditFico?: React.Dispatch<React.SetStateAction<boolean>>;
  error?: boolean;
  required?: boolean;
}

export const Ficotable = ({
  id,
  setIsFicoModal,
  ficoData,
  setFicoData,
  setEditFico,
  setIsEditFico,
  error,
  required,
}: Props) => {
  const [openAction, setOpenAction] = useState<number | null>(null);

  const deleteFunc = (index: number) => {
    const newData = [...ficoData];
    newData.splice(index, 1);
    setFicoData && setFicoData(newData);
    setOpenAction(null);
  };

  const ref: any = useRef();

  useClickOutside(ref, () => setOpenAction(null));

  return (
    <>
      <div className="fico">
        <div className="fico__title">
          {setFicoData ? 'FICO Buckets (reference)' : ''}
          {required && (
            <span className="error-text" style={{ margin: '0 0 0 3px' }}>
              *
            </span>
          )}
        </div>
        <div className="fico__table" ref={ref}>
          <div className="fico__header">
            <div
              className="fico__header-item"
              style={id === 3 ? { width: '50%' } : { width: '33%' }}
            >
              From
            </div>
            <div
              className="fico__header-item"
              style={id === 3 ? { width: '50%' } : { width: '33%' }}
            >
              To{' '}
            </div>
            {id !== 3 && (
              <div className="fico__header-item" style={{ width: '33%' }}>
                APR
              </div>
            )}
          </div>
          {ficoData.map((elem: ficoProps, index: number) => {
            return (
              <div className="fico__action-wrapper">
                <div className="fico__wrapper">
                  <div
                    className="fico__item"
                    style={id === 3 ? { width: '50%' } : { width: '33%' }}
                  >
                    {elem.fico_range_from}
                  </div>
                  <div
                    className="fico__item"
                    style={id === 3 ? { width: '50%' } : { width: '33%' }}
                  >
                    {elem.fico_range_to}
                    {setFicoData && id === 3 ? (
                      <img
                        src={dots}
                        className="fico__icon"
                        onClick={() =>
                          setOpenAction((prev: number | null) => {
                            if (prev === index) {
                              return null;
                            } else {
                              return index;
                            }
                          })
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  {id !== 3 && (
                    <div className="fico__item" style={{ width: '33%' }}>
                      {elem.apr}%
                      {setFicoData ? (
                        <img
                          src={dots}
                          className="fico__icon"
                          onClick={() =>
                            setOpenAction((prev: number | null) => {
                              if (prev === index) {
                                return null;
                              } else {
                                return index;
                              }
                            })
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
                {openAction === index && (
                  <div className="fico__action">
                    <div
                      className="fico__action-row"
                      onClick={() => {
                        setEditFico && setEditFico(index);
                        setIsEditFico && setIsEditFico(true);
                      }}
                    >
                      <img src={Pen} className="fico__action-pen" />
                      Edit
                    </div>
                    <div
                      className="fico__action-row"
                      onClick={() => deleteFunc(index)}
                    >
                      <img src={deleteIcon} className="fico__action-delete" />
                      Delete
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {error ? (
            <div className="error-text" style={{ margin: '20px 0 0 0' }}>
              Add at least one FICO range
            </div>
          ) : (
            ''
          )}
        </div>
        {setFicoData ? (
          <div className="fico__addrow">
            <div
              onClick={() => setIsFicoModal && setIsFicoModal(true)}
              style={{ display: 'flex' }}
            >
              <img src={plus} /> Add Row
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
