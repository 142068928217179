import {Table, Tooltip, Dropdown} from 'antd';
import {useState, useRef} from 'react';
import Property from '../../assets/Property.svg';
import PropertyHover from '../../assets/PropertyHover.svg';
import Bank from '../../assets/Bank.svg';
import trash from '../../assets/TrashBinMinimalistic.svg'
import pen from '../../assets/Pen.svg'
import alert from '../../assets/alert-circle.svg';
import pause from '../../assets/Stopwatch Pause.svg';
import activate from '../../assets/activate.svg'
import type {ColumnsType} from 'antd/es/table';
import {useClickOutside} from '../../hooks/useClickOutSide';
import {ActivateIcon} from '../icons/ActivateIcon';
import './index.scss';

import {BankTableItem} from "../../api/types/common";
import {ConfirmationModal} from "../Modals/ConfirmationModal";

interface BanksTableProps {
    banks: BankTableItem[] | undefined;
    setBanks?: React.Dispatch<React.SetStateAction<BankTableItem[]>>
    setEditBank?: React.Dispatch<React.SetStateAction<BankTableItem | null>>
    setAddBankModal?: React.Dispatch<React.SetStateAction<boolean>>
    view?: boolean
}

export const AvailableBanksTable = ({
                                        banks,
                                        setBanks,
                                        setEditBank,
                                        setAddBankModal,
                                        view
                                    }: BanksTableProps) => {
    const ref = useRef(null);
    const [openActionId, setOpenActionId] = useState<{ bank_id: number, loan_type_id: number } | null>(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [bankToDelete, setBankToDelete] = useState<BankTableItem | null>(null)
    useClickOutside(ref, () => setOpenActionId(null));
    const changeStatus = () => {
        setBanks && setBanks((prevState) => {
            return prevState.map(bank => {
                if (bank.bank_id === openActionId?.bank_id &&
                    bank.loan_type_id === openActionId?.loan_type_id) {
                    return {...bank, active: bank.active ? 0 : 1}
                } else return bank
            })
        })
    }
    const deleteBank = () => {
        setBanks && setBanks((prevState) => {
            return prevState.filter(bank => bank.bank_id !== bankToDelete?.bank_id ||
                bank.loan_type_id !== bankToDelete?.loan_type_id)
        })
        setOpenDeleteModal(false)
        setBankToDelete(null)
    }
    const cancelDelete = () => {
        setOpenDeleteModal(false)
        setBankToDelete(null)
    }


    const columns: ColumnsType<BankTableItem> = [
        {
            title: (
                <div className="header-item">
                    Bank Name
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div
                    className={`table-item`}>
                    <Tooltip title={text}>
                        <div className="table-text">{text}</div>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: (
                <div className="header-item">
                    Loan Type
                </div>
            ),
            dataIndex: 'loan_type_name',
            key: 'loan_type_name',
            render: (text, record) => (
                <div
                    className={`table-item`}>
                    <Tooltip title={text}>
                        <div className="table-text">{text}</div>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: <div className="header-item AccTypes">FICO</div>,
            dataIndex: 'fico_ranges',
            key: 'fico_ranges',
            render: (range, record) => (
                <div
                    className={`table-item`}>
                    <Tooltip
                        title={range?.map((fico: any) => fico.name)?.join(", ")}
                    >
                        <div className="table-text">
                            {range?.map((fico: any) => fico.name)?.join(", ")}
                        </div>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: (
                <div className="header-item">
                    Status
                </div>
            ),
            width: 150,
            dataIndex: 'active',
            key: 'active',
            render: (active, record) => {
                const hoveredItem = openActionId?.bank_id === record.bank_id &&
                    openActionId?.loan_type_id === record.loan_type_id
                const items = [
                    {
                        key: '1',
                        label: active
                            ? <span className="table-dropdown-text">Pause</span>
                            : <span className="table-dropdown-text">Activate</span>,
                        icon: active ? <img src={pause} alt="pause"/> : <img src={activate} alt="activate"/>
                    },
                    {
                        key: '2',
                        label: <span className="table-dropdown-text">Edit</span>,
                        icon: <img src={pen} alt="edit"/>

                    },
                    {
                        key: '3',
                        label: <span className="table-dropdown-text">Delete</span>,
                        icon: <img src={trash} alt="delete"/>

                    },
                ];
                const handleDropdownClick = (key:string) => {
                    key === '1' && changeStatus()
                    if (key === '2' && setEditBank && setAddBankModal) {
                        setEditBank(record)
                        setAddBankModal(true)
                    } else if (key === '3') {
                        setBankToDelete(record)
                        setOpenDeleteModal(true)
                    }
                }
                return (
                    <div className={`table-item table-status`}>
                        <div
                            className={
                                active
                                    ? 'table-text status Approved'
                                    : 'table-text status noOffer'
                            }
                        >
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {active ? 'Active' : 'Pause'}
                                </div>
                        </div>
                        {!view &&
                            <Dropdown placement="bottomRight" menu={{items, onClick: (info) => handleDropdownClick(info.key)}}
                                      onOpenChange={(open) => {
                                          open && setOpenActionId({bank_id: record.bank_id, loan_type_id: record.loan_type_id})
                                          !open && setOpenActionId(null)
                                      }}
                            >
                                <img
                                    src={hoveredItem ? PropertyHover : Property}
                                    style={{cursor: 'pointer', margin: '0 0 0 15px'}}
                                    alt="dots"/>
                            </Dropdown>
                        }
                    </div>
                )
            },
        },
    ];

    const customNoDataText = {
        emptyText: (
            <div className="no-data-found">
                <div><img src={Bank} alt="bank"/></div>
                <div className="no-data-found__text">No banks available</div>
                <div className="no-data-found__subtext">Please edit your profile to add banks</div>
            </div>
        ),
    };

    return (
        <div className={`${view && 'no-pointer'}`}>
            <Table
                ref={ref}
                columns={columns}
                dataSource={banks}
                pagination={false}
                locale={customNoDataText}
            />
            <ConfirmationModal
                openModal={openDeleteModal}
                setOpenModal={setOpenDeleteModal}
                title={`Are you sure you want
                    to remove ${bankToDelete?.name} bank?`}
                subtitle=""
                cancelText="No"
                confirmText="Yes"
                confirm={() => {
                    deleteBank()
                }}
                cancel={cancelDelete}
                closeIcon={false}
                maskClosable={false}
            />
        </div>
    );
};
