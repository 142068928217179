import { Button, Table, Tooltip } from 'antd';
import { useRef } from 'react';
import arrow from '../../assets/arrow-down.svg';

import noData from '../../assets/noData.svg';
import NoUser from '../../assets/NoUser.svg';
import type { ColumnsType } from 'antd/es/table';
import { Pagination } from './Pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.scss';
import { useAppSelector } from '../../hooks';
import moment from 'moment';
import { ApplicationItem, TableParamsApp } from '../../api/types/applicants';

interface PartnersTableProps {
  data: ApplicationItem[];
  setTableParams: React.Dispatch<React.SetStateAction<TableParamsApp>>;
  metaData: any;
  tableParams: TableParamsApp;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  isCard?: boolean;
}

export const ApplicationsTable = ({
  data,
  setTableParams,
  metaData,
  tableParams,
  isCard,
}: PartnersTableProps) => {
  const ref = useRef(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.profile);
  const appTooltip = (record: any) => {
    if (record.application_status === 'Draft' && !record.transaction_status) {
      return 'The application needs to be completed';
    }
    if (
      record.application_status === 'No Offer' &&
      record.transaction_status === 'Withdrawn'
    ) {
      return 'The System could not present any offers for this applicant';
    }
    if (
      record.application_status === 'Offer Made' &&
      record.transaction_status === 'In Progress'
    ) {
      return 'The offer needs to be selected to finish the application process';
    }
    if (
      record.application_status === 'Offer Made' &&
      record.transaction_status === 'Timed Out'
    ) {
      return 'The application expired';
    }
    if (
      record.application_status === 'Offer Made' &&
      record.transaction_status === 'Withdrawn'
    ) {
      return 'The application has been withdrawn';
    }
    if (
      record.application_status === 'Awaiting Signature' &&
      !record.additional_application_status &&
      record.transaction_status === 'In Progress'
    ) {
      return 'The documents need to be signed  to finish the application process';
    }
    if (
      record.application_status === 'Awaiting Signature' &&
      !!record.additional_application_status &&
      record.transaction_status === 'Timed Out'
    ) {
      return 'The application expired';
    }
    if (
      record.application_status === 'Approved' &&
      record.transaction_status === 'Approved'
    ) {
      return 'The application has been successfully approved';
    }
    if (
      record.application_status === 'Approved' &&
      record.transaction_status === 'In Progress'
    ) {
      return 'The funds should be released to Partner to finish the application process';
    }
    if (
      record.application_status === 'Awaiting Signature' &&
      record.additional_application_status === 'Awaiting Approval' &&
      record.transaction_status === 'In Progress'
    ) {
      return 'Delivery of products needs to be confirmed to finish the application process';
    }
    if (
      record.application_status === 'Awaiting Signature' &&
      record.additional_application_status === 'Awaiting Approval' &&
      record.transaction_status === 'Timed Out'
    ) {
      return 'The application expired';
    }
    if (
      !record.application_status &&
      record.transaction_status === 'Timed Out'
    ) {
      return 'The application expired';
    }
    if (
      !record.application_status &&
      record.transaction_status === 'Withdrawn'
    ) {
      return 'The application has been withdrawn';
    }
    if (
      record.application_status === 'Awaiting Down Payment' &&
      record.transaction_status === 'In Progress'
    ) {
      return 'Down payment needs to be made to finish the application process';
    }
    if (
      record.application_status === 'Awaiting Down Payment' &&
      record.transaction_status === 'Timed Out'
    ) {
      return 'The application expired';
    }
    return '';
  };
  const sorting = (name: string) => {
    setTableParams((prev: TableParamsApp) => ({
      ...prev,
      order_by: name,
      order:
        prev.order_by === name
          ? tableParams.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'asc',
    }));
  };

  const columns: ColumnsType<ApplicationItem> = [
    {
      title: (
        <div className="header-item">
          ID
          <img
            src={arrow}
            alt="arrow"
            className={`header-image ${
              tableParams.order_by === 'public_id' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('public_id')}
          />
        </div>
      ),
      width: 70,
      dataIndex: 'item_id',
      key: 'item_id',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text blue">{record.public_id}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" style={{ minWidth: '100px' }}>
          Name
          <img
            src={arrow}
            alt="arrow"
            className={`header-image ${
              tableParams.order_by === 'name' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('name')}
          />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">
            {record.first_name} {record.last_name}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Product
          <img
            src={arrow}
            alt="arrow"
            className={`header-image ${
              tableParams.order_by === 'product' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('product')}
          />
        </div>
      ),
      dataIndex: 'contact_number',
      key: 'contact_number',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">
            {record.financing_type?.name ? record.financing_type?.name : '-'}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Loan Amount
          <img
            src={arrow}
            alt="arrow"
            className={`header-image ${
              tableParams.order_by === 'loan_amount' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('loan_amount')}
          />
        </div>
      ),
      width: 100,
      dataIndex: 'contact_email',
      key: 'contact_email',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">
            {record.loan_amount
              ? `$${record.loan_amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
              : '-'}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Date
          <img
            src={arrow}
            alt="arrow"
            className={`header-image ${
              tableParams.order_by === 'date' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('date')}
          />
        </div>
      ),
      width: 160,
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">
            {moment(record.created_at).format('MM/DD/YYYY hh:mm A')}
          </div>
        </div>
      ),
    },
  ];
  const customNoDataText = {
    emptyText: (
      <>
        {(!tableParams.status_key && !tableParams.search) ||
          !tableParams.loan_product_id ||
          !tableParams.application_status ||
          (!tableParams.application_date_from &&
            !tableParams.application_date_to) ||
          (!tableParams.transaction_status && (
            <div className="no-data-found">
              <img src={NoUser} alt="no user" />
              <div className="no-data-found__text">No applications yet</div>
              {user.role.key === 'partner' && (
                <div className="no-data-found__subtext">
                  Add new application to start work with them
                </div>
              )}
              {user.role.key === 'partner' && (
                <Button
                  className="blue-btn"
                  style={{ margin: '24px 0 0 0' }}
                  onClick={() => navigate('/new-application')}
                >
                  + Add application
                </Button>
              )}
            </div>
          ))}
        {(tableParams.status_key ||
          tableParams.search ||
          tableParams.loan_product_id ||
          tableParams.application_status ||
          (tableParams.application_date_from &&
            tableParams.application_date_to) ||
          tableParams.transaction_status) && (
          <div className="no-data-found">
            <img src={noData} alt="no data" />
            <div className="no-data-found__text">No results found</div>
            <div className="no-data-found__subtext">
              Try adjusting your search to find what you are looking for
            </div>
          </div>
        )}
      </>
    ),
  };

  if (!isCard) {
    columns.splice(-3, 0, {
      title: (
        <div className="header-item" style={{ minWidth: '100px' }}>
          Partner
          <img
            src={arrow}
            alt="arrow"
            className={`header-image ${
              tableParams.order_by === 'partner' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('partner')}
          />
        </div>
      ),
      dataIndex: 'partner',
      key: 'partner',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">{record.partner.company_name}</div>
        </div>
      ),
    });
  }
  if (
    (user.role.key === 'super_admin' || user.role.key === 'admin') &&
    pathname === '/applications'
  ) {
    columns.splice(-3, 0, {
      title: (
        <div className="header-item" style={{ minWidth: '100px' }}>
          Main Account
          <img
            src={arrow}
            alt="arrow"
            className={`header-image ${
              tableParams.order_by === 'main_account' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('main_account')}
          />
        </div>
      ),
      dataIndex: 'main_account',
      key: 'main_account',
      render: (text, record) => (
        <div className="table-item">
          <Tooltip title={record.partner?.main_account?.company_name}>
            <div className="table-text">
              {record.partner?.main_account?.company_name
                ? record.partner?.main_account?.company_name
                : '-'}
            </div>
          </Tooltip>
        </div>
      ),
    });
  }
  if (!isCard) {
    columns.splice(-1, 0, {
      title: (
        <div className="header-item">
          App Status
          <img
            src={arrow}
            alt="arrow"
            className={`header-image ${
              tableParams.order_by === 'application_status' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('application_status')}
          />
        </div>
      ),
      width: 120,
      dataIndex: 'application_status',
      key: 'application_status',
      render: (text, record) => (
        <div className="table-item">
          <Tooltip title={appTooltip(record)}>
            {text === 'Offer Made' && (
              <div className="table-text status offerMade">{text}</div>
            )}
            {text === 'No Offer' && (
              <div className="table-text status noOffer">{text}</div>
            )}
            {text === 'Awaiting Signature' && (
              <div className="table-text status Awaiting">
                {record.additional_application_status
                  ? record.additional_application_status
                  : text}
              </div>
            )}
            {text === 'Awaiting Down Payment' && (
              <div className="table-text status Awaiting">
                Await. Down Payment
              </div>
            )}
            {text === 'Approved' && (
              <div className="table-text status Approved">{text}</div>
            )}
            {text === 'Draft' && (
              <div className="table-text status Draft">{text}</div>
            )}
          </Tooltip>
        </div>
      ),
    });
  }
  if (!isCard) {
    columns.splice(-1, 0, {
      title: (
        <div className="header-item">
          Trans Status
          <img
            src={arrow}
            alt="arrow"
            className={`header-image ${
              tableParams.order_by === 'transaction_status' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('transaction_status')}
          />
        </div>
      ),
      width: 120,
      dataIndex: 'transaction_status',
      key: 'transaction_status',
      render: (text, record) => (
        <div className="table-item">
          <Tooltip title={appTooltip(record)}>
            {text === 'In Progress' && (
              <div className="table-text status offerMade">{text}</div>
            )}
            {text === 'Timed Out' && (
              <div className="table-text status noOffer">{text}</div>
            )}
            {text === 'Withdrawn' && (
              <div className="table-text status Withdrawn">{text}</div>
            )}
            {text === 'Approved' && (
              <div className="table-text status Approved">{text}</div>
            )}
          </Tooltip>
        </div>
      ),
    });
  }

  if (user.role.key === 'partner') {
    const roleColumnIndex = columns.findIndex(
      (column) => column.key === 'partner'
    );
    if (roleColumnIndex !== -1) {
      columns.splice(roleColumnIndex, 1);
    }
  }

  return (
    <>
      <div className={isCard ? 'table-wrapper-card' : ''}>
        <Table
          ref={ref}
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={customNoDataText}
          onRow={(record) => {
            return {
              onClick: () => navigate(`/applications/edit/${record.id}`),
            };
          }}
        />
      </div>
      {data?.length > 0 && (
        <Pagination
          setTableParams={setTableParams}
          metaData={metaData}
          tableParams={tableParams}
        />
      )}
    </>
  );
};
