import { iconI } from './types';

export const ArrowIcon = ({ fill, style }: iconI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={style}
    >
      <path
        d="M9 18L15 12L9 6"
        stroke={fill ? fill : '#B3B3B7'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
