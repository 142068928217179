import React, { useCallback } from 'react';

export const usePreventSpaceTyping = () => {
  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
    []
  );

  return { handleKeyPress };
};
