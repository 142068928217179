import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import { NotifListI } from './types/notif';

export const notifApi = createApi({
  reducerPath: 'notifApi',
  baseQuery,
  endpoints: (builder) => ({
    getNotifList: builder.query<NotifListI, any>({
      query: (read: number) => ({
        url: `notifications?read=${read}`,
        method: 'GET',
      }),
    }),
    markAll: builder.mutation<void, void>({
      query: (data) => ({
        url: `/notifications`,
        method: 'POST',
        body: data,
      }),
    }),
    getNotif: builder.query<NotifListI, any>({
      query: (data) => ({
        url: `notifications/${data?.id}`,
        method: 'GET',
        params: data?.data,
      }),
    }),
  }),
});

export const {
  useLazyGetNotifListQuery,
  useMarkAllMutation,
  useLazyGetNotifQuery,
} = notifApi;
