import {Button, Form, Modal} from 'antd';
import {FC, useEffect, useState} from 'react';
import close from '../../../assets/x-close.svg';
import check from '../../../assets/CheckGreen.svg'
import alert from '../../../assets/alert-circle-small.svg'
import './index.scss';
import {Spinner} from '../../Spinner/Spinner';
import {CustomInput} from "../../../customFields/CustomInput";
import {CustomSelect} from "../../../customFields/CustomSelect";
import {CodeInput} from "./CodeInput";
import {useAppSelector} from "../../../hooks";
import {useSendVarificationCodeMutation} from "../../../api/applicants";
import {useNewCustomerPurchaseMutation} from "../../../api/customers";
import {useParams} from "react-router-dom";

interface ModalI {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSuccessPurchase: React.Dispatch<React.SetStateAction<boolean | string>>
    className?: string;
    phone: string | undefined
    email: string | undefined
    availableAmount: number
}

interface FormData {
    amount: string
    product: string
}


export const SubsequentPurchaseModal: FC<ModalI> = ({
                                                        openModal,
                                                        setOpenModal,
                                                        setSuccessPurchase,
                                                        className,
                                                        phone,
                                                        email,
                                                        availableAmount
                                                    }) => {
    const { id } = useParams();
    const [form] = Form.useForm<FormData>();
    const user = useAppSelector((state) => state.profile);
    const [code, setCode] = useState('')
    const [
        varificationPhone,
        {
            isSuccess: isSuccessPhone,
            isError: isErrorPhone,
            isLoading: isLoadingPhone,
            reset: resetPhone
        },
    ] = useSendVarificationCodeMutation();
    const [
        varificationEmail,
        {
            isSuccess: isSuccessEmail,
            isError: isErrorEmail,
            isLoading: isLoadingEmail,
            reset: resetEmail
        },
    ] = useSendVarificationCodeMutation();
    const [newPurchase,
        {
            data: purchaseData,
            isError: isErrorPurchase,
            isLoading: isLoadingPurchase,
            isSuccess: isPurchaseSuccess,
            reset: resetPurchase
        }] = useNewCustomerPurchaseMutation()
    const [sendByEmail, setSendByEmail] = useState(false)
    const isCodeLoading = isLoadingPhone || isLoadingEmail
    const isCodeSuccess = isSuccessPhone || isSuccessEmail
    const isCodeError = isErrorPhone || isErrorEmail
    const codeSanded = isCodeLoading || isCodeSuccess || isCodeError
    const isVerifyError = isErrorPurchase
    const isVerifyLoading = isLoadingPurchase
    const isVerifySuccess = isPurchaseSuccess
    const products = user?.model.loan_products?.map(item => ({label: item.id, value: item.name}))
    const validateFormOnBlur = (name: string) => {
        form.validateFields([name]);
    };

    const handleClose = () => {
        form.resetFields()
        resetPhone()
        resetEmail()
        resetPurchase()
        setSendByEmail(false)
        setOpenModal(false)
    }

    const currencyMaskAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        value = value.replace(/^0/, '');
        value = value.replace(/[^\d.]/g, '');
        value = value.replace(/\.{2,}/g, '.');
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        e.target.value = value;
        const number = value.replace(/,/g, '');
        if (+number > 300000) {
            e.target.value = '300,000';
        } else {
            const parts = number?.split('.');
            if (parts.length === 2 && parts[1].length > 2) {
                e.target.value = `${parts[0]}.${parts[1].substring(0, 2)}`;
            }
        }
        return e.target.value;
    };

    const handleSendCode = () => {
        if (!isVerifyLoading) {
            resetPurchase()
            resetPhone()
            resetEmail()
            if (sendByEmail) {
                varificationEmail({email: email, type: 'email'})
            } else {
                varificationPhone({phone: phone, type: 'phone'})
            }
        }
    }

    const handlePurchase = () => {
        resetPurchase()
        const data: any = {
            id,
            payload: {
            code:code,
            amount: form.getFieldValue('amount'),
            loan_product_id: form.getFieldValue('product')
        }}
        if (sendByEmail) {
            data.payload.email = email
            newPurchase(data)
        } else {
            data.payload.phone = phone
            newPurchase(data)
        }

    }
    const handleSendByEmail = () => {
        if (!isVerifyLoading) {
            setSendByEmail(prev => !prev)
            resetEmail()
            resetPhone()
            resetPurchase()
        }
    }

    const handleSubmit = () => {
        form.validateFields().then(() => {
            handlePurchase()
        }).catch((e) => {
            console.log(e)
        })
    }


    useEffect(() => {
        if (isVerifySuccess) {
            setSuccessPurchase(purchaseData?.item_id)
            handleClose()
        }
    },[isVerifySuccess])


    return (
        <Modal
            destroyOnClose={true}
            className={`purchase-modal ${className}`}
            centered
            open={openModal}
            onCancel={handleClose}
            footer={<></>}
            width={600}
            closeIcon={<img src={close} alt="close"/>}
            maskClosable={!isCodeLoading && !isVerifyLoading}
        >
            <p className="purchase-modal__title">Make Purchase</p>
            <Form
                form={form}
            >
                <Form.Item
                    className="input-wrapper"
                    name="amount"
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                        () => ({
                            validator(_, value) {
                                if (parseFloat(value) > availableAmount) {
                                    return Promise.reject('Purchase amount cannot exceed available balance');
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <CustomInput
                        name="Enter Desired Amount"
                        className="input"
                        placeholder="00,000.00"
                        maxLength={256}
                        prefix={<div className="procent">$</div>}
                        onBlur={() => validateFormOnBlur('amount')}
                        onInput={currencyMaskAmount}
                        onChange={() => {
                        }}
                        hasError={() => false}
                        disabled={isVerifyLoading}
                    />
                </Form.Item>
                <Form.Item
                    className="input-wrapper"
                    name="product"
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <CustomSelect
                        name="Select  Product"
                        options={products || []}
                        placeholder="Select  Product"
                        className="input input-white-small"
                        onChange={()=>{}}
                        disabled={isVerifyLoading}
                    />
                </Form.Item>
            </Form>
            <div className="purchase-modal__trans-confirm">
                <p className="purchase-modal__trans-confirm__title">Transaction Confirmation</p>
                {isCodeLoading && <p className="d-flex align-center">
                    <Spinner color={'black'}/>
                    <span className="ml-5">Awaiting code</span>
                </p>}
                {isCodeSuccess && !isVerifyError && <p className="d-flex align-center">
                    <img src={check} alt="check" className="mr-2"/>
                    <span className="">Code sent</span>
                </p>}
                {isCodeError && <p className="d-flex align-center">
                    <img src={alert} alt="check" className="mr-2"/>
                    <span className="purchase-modal__code-error">Could not send. Please try again</span>
                </p>}
                {isVerifyError && <p className="d-flex align-center">
                    <img src={alert} alt="check" className="mr-2"/>
                    <span className="purchase-modal__code-error">Invalid code</span>
                </p>}
            </div>
            <div className="purchase-modal__trans-confirm-footer">
                <div className="purchase-modal__trans-confirm-footer__wrapper">
                    {!sendByEmail && <div className="flex-1">
                        <p className="purchase-modal__contact-title">Customer Contact Number</p>
                        <p className="purchase-modal__contact-content">{phone}</p>
                    </div>}
                    {sendByEmail && <div className="flex-1">
                        <p className="purchase-modal__contact-title">Customer Contact Email</p>
                        <p className="purchase-modal__contact-content">{email}</p>
                    </div>}
                    {!codeSanded && <div className="flex-1 d-flex align-end">
                        <Button className="violet-btn h-32" onClick={handleSendCode}
                        >Send Code
                        </Button>
                    </div>}
                    {codeSanded && isCodeSuccess && <div className="flex-1">
                        <p className="purchase-modal__contact-title">
                            {!sendByEmail ? '5-Digit Code Received in SMS': '5-Digit Code Received in Email'}
                        </p>
                        <CodeInput
                            setCode={setCode}
                            isVerifyError={isVerifyError}
                            isVerifyLoading={isVerifyLoading}
                            resetPurchase={resetPurchase}
                        />
                    </div>}
                    {codeSanded && isCodeError && <div className="purchase-modal__resend-text  flex-1">
                        Issue with code?<br/>
                        <span className="purchase-modal__resend-text-blue" onClick={handleSendCode}>
                    Resend
                </span> or <span
                        className="purchase-modal__resend-text-blue"
                        onClick={handleSendByEmail}>
                        {sendByEmail ? 'Send via SMS' : 'Send by Email'}
                    </span>
                    </div>}
                </div>
                {codeSanded && isCodeSuccess && <div className="purchase-modal__resend-text mt-12">
                    Issue with code?&nbsp;
                    <span className="purchase-modal__resend-text-blue" onClick={handleSendCode}>
                    Resend
                </span> or <span
                    className="purchase-modal__resend-text-blue"
                    onClick={handleSendByEmail}>
                    {sendByEmail ? 'Send via SMS' : 'Send by Email'}
                </span>
                </div>}
            </div>

            <div className="purchase-modal__btns">
                <Button className="gray-btn" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    className='blue-btn'
                    disabled={code.length < 5 || isVerifyLoading}
                    onClick={handleSubmit}
                >
                    Transact
                </Button>
            </div>
        </Modal>
    );
};
