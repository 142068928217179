import { useAppSelector } from "../../hooks";

export default function useProfileController() {
  const user = useAppSelector((state) => state.profile);
  const isAdmin = user.role.key === "admin";
  const isParnter = user.role.key === "partner";
  const isMainAcc = user.role.key === "main_account";

  return {
    isAdmin,
    isParnter,
    isMainAcc
  };
}
