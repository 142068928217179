import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import {
  PartnerResponse,
  StatesResponse,
  TableParams,
  TableResponse,
} from './types/partners';

export const partnersApi = createApi({
  reducerPath: 'partnersApi',
  baseQuery,
  tagTypes: ['Partner'],
  endpoints: (builder) => ({
    createPartner: builder.mutation({
      query: (arg) => ({
        url: '/partners',
        method: 'POST',
        body: arg,
      }),
    }),
    addLogo: builder.mutation({
      query: (arg) => ({
        url: '/files',
        method: 'POST',
        formData: true,
        body: arg,
      }),
    }),
    addContract: builder.mutation({
      query: (arg) => ({
        url: '/files',
        method: 'POST',
        formData: true,
        body: arg,
      }),
    }),
    changeStatus: builder.mutation({
      query: (data) => ({
        url: `/partners/${data.id}/status`,
        method: 'PUT',
        body: data.key,
      }),
      invalidatesTags: ['Partner'],

    }),
    resetInvitation: builder.mutation({
      query: (id) => ({
        url: `/partners/${id}/invitation`,
        method: 'PUT',
      }),
    }),
    editPartner: builder.mutation({
      query: (data) => ({
        url: `/partners/${data.id}`,
        method: 'PUT',
        body: data.data,
      }),
    }),
    getStates: builder.query<StatesResponse, { name: string }>({
      query: (params) => ({
        url: '/states?per_page=999',
        method: 'GET',
        params,
      }),
    }),
    getAllStates: builder.query<StatesResponse, { name: string }>({
      query: (params) => ({
        url: '/states?per_page=all',
        method: 'GET',
        params,
      }),
    }),
    getPartnersList: builder.query<TableResponse, TableParams>({
      query: (params) => ({
        url: '/partners/',
        method: 'GET',
        params: params,
      }),
    }),
    getPartner: builder.query<PartnerResponse, number | string>({
      query: (id) => ({
        url: `/partners/${id}`,
        method: 'GET',
      }),
      providesTags: ['Partner'],
    }),
    regenerateCode: builder.query<PartnerResponse, number | string>({
      query: (id) => ({
        url: `/partners/${id}/code`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useCreatePartnerMutation,
  useAddLogoMutation,
  useAddContractMutation,
  useLazyGetPartnersListQuery,
  useGetStatesQuery,
  useGetAllStatesQuery,
  useLazyGetPartnerQuery,
  useChangeStatusMutation,
  useResetInvitationMutation,
  useEditPartnerMutation,
  useLazyRegenerateCodeQuery,
} = partnersApi;
