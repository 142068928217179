import logo from '../../assets/logo.svg';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon } from '../icons/HomeIcon';
import { PartnersIcon } from '../icons/PartnersIcon';
import { CustomersIcon } from '../icons/CustomersIcon';
import { ApplicationsIcon } from '../icons/ApplicationsIcon';
import { MainAccountIcon } from '../icons/MainAccountIcon';
import { useAppSelector } from '../../hooks';
import { ReportsIcon } from '../icons/ReportsIcon';
import { LendersIcon } from '../icons/LendersIcon';
import './index.scss';

export const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.profile);

  useEffect(() => {
    if (!location) return;
    if (location.pathname === '/dashboard') {
    }
  }, [location]);
  return (
    <div className="navigation">
      <img
        src={!!user.model.white_labeled_account ? user.application_logo : logo}
        className="navigation__logo"
        alt="logo"
      />
      <div className="navigation__main">
        <div
          className={`navigation__item ${
            location.pathname === '/dashboard' ? 'active' : ''
          }`}
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          <HomeIcon
            fill={location.pathname === '/dashboard' ? 'white' : '#808086'}
            style={{ margin: '0 5px 0 0' }}
          />
          Dashboard
        </div>
        {(user.role.key === 'super_admin' || user.role.key === 'admin') && (
          <div
            className={`navigation__item ${
              location.pathname.includes('main_accounts') ||
              location.pathname === '/new-main-accounts' ||
              location.pathname === '/new-main-account'
                ? 'active'
                : ''
            }`}
            onClick={() => {
              navigate('/main_accounts');
            }}
          >
            <MainAccountIcon
              fill={
                location.pathname.includes('main_accounts') ||
                location.pathname === '/new-main-accounts' ||
                location.pathname === '/new-main-account'
                  ? 'white'
                  : '#808086'
              }
              style={{ margin: '0 5px 0 0' }}
            />
            Main accounts
          </div>
        )}
        {user.role.key === 'partner' ? (
          <></>
        ) : (
          <div
            className={`navigation__item ${
              location.pathname.includes('partners') ||
              location.pathname === '/new-partner'
                ? 'active'
                : ''
            }`}
            onClick={() => {
              navigate('/partners');
            }}
          >
            <PartnersIcon
              fill={
                location.pathname.includes('partners') ||
                location.pathname === '/new-partner'
                  ? 'white'
                  : '#808086'
              }
              style={{ margin: '0 5px 0 0' }}
            />
            Partners
          </div>
        )}
        <div
          className={`navigation__item ${
            location.pathname === '/applications' ? 'active' : ''
          }`}
          onClick={() => {
            navigate('/applications');
          }}
        >
          <ApplicationsIcon
            fill={location.pathname === '/applications' ? 'white' : '#808086'}
            style={{ margin: '0 5px 0 0' }}
          />
          Applications
        </div>

        <div
          className={`navigation__item ${
            location.pathname === '/customers' ? 'active' : ''
          }`}
          onClick={() => {
            navigate('/customers');
          }}
        >
          <CustomersIcon
            fill={location.pathname === '/customers' ? 'white' : '#808086'}
            style={{ margin: '0 5px 0 0' }}
          />
          Customers
        </div>
        {(user.role.key === 'admin' || user.role.key === 'super_admin') && (
          <div
            className={`navigation__item ${
              location.pathname.includes('lenders') ? 'active' : ''
            }`}
            onClick={() => {
              navigate('/lenders');
            }}
          >
            <LendersIcon
              fill={location.pathname.includes('lenders') ? 'white' : '#808086'}
              style={{ margin: '0 5px 0 0' }}
            />
            Lenders
          </div>
        )}
        <div
          className={`navigation__item ${
            location.pathname === '/reports' ? 'active' : ''
          }`}
          onClick={() => {
            navigate('/reports');
          }}
        >
          <ReportsIcon
            fill={location.pathname === '/reports' ? 'white' : '#808086'}
            style={{ margin: '0 5px 0 0' }}
          />
          Reports
        </div>
      </div>
    </div>
  );
};
