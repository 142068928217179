import { useState, useEffect } from 'react';
import { YourChartComponent } from '../../components/Charts/Chart';
import PercentageBar from '../../components/Charts/LinerChart';
import { DotIcon } from '../../components/icons/Dot';
import { CustomSelect } from '../../customFields/CustomSelect';
import doneicon from '../../assets/doneicon.svg';
import copyLinkIcon from '../../assets/ci_copy.svg';
import Illustration from '../../assets/Illustration.svg';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { Button, DatePicker, message } from 'antd';
import { SelectOption } from '../../types.ts/commonTypes';
import {
  useGetFirstQuery,
  useLazyGetFeesQuery,
  useLazyGetFoundingQuery,
  useLazyGetStatusesQuery,
  useLazyGetTopsQuery,
  useLazyGetTotalsQuery,
} from '../../api/dashboard';
import {
  StatusI,
  TableParamsGetTotals,
  TopItem,
} from '../../api/types/dashboard';
import './Dashboard.scss';
import {
  formatNumberToCurrency,
  calcPercent,
  filtersI,
  updateFilters,
  setComparedPeriodParams,
  setValuesFromTo,
  updateCustomFilters,
} from './features';
import { useAppSelector } from '../../hooks';
import { useLazyRegenerateCodeQuery } from '../../api/partners';
import { useLazyGetMeQuery } from '../../api/common';
import { Spinner } from '../../components/Spinner/Spinner';
import { isApiError } from '../../utils/general';
const { RangePicker } = DatePicker;

interface BarChartI {
  percent: number;
  color: string;
}

export const Dashboard = () => {
  const user = useAppSelector((state) => state.profile);

  const today = dayjs();
  const [messageApi, contextHolder] = message.useMessage();
  const [dateRange, setDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([today.startOf('year'), today.endOf('day')]);

  const [filters, setFilters] = useState<filtersI>({
    period: 'Year to date',
    comparedPeriod: 'Previous period',
    breakdown: 'Daily',
  });

  const [paramsCurrent, setParamsCurrent] = useState<TableParamsGetTotals>({
    date_from: dayjs(dateRange[0]).toDate(),
    date_to: dayjs(dateRange[1]).toDate(),
    breakdown: 'day',
  });

  const previousYearStart = dayjs(dateRange[0]).subtract(1, 'year');
  const previousYearEnd = dayjs(dateRange[1]).subtract(1, 'year');
  const [paramsCompared, setParamsCompared] = useState<TableParamsGetTotals>({
    date_from: previousYearStart.toDate(),
    date_to: previousYearEnd.toDate(),
    breakdown: 'day',
  });

  const [getCurrentTotals, { data: dataCurrentTotals }] =
    useLazyGetTotalsQuery();
  const [getComparedTotals, { data: dataComparedTotals }] =
    useLazyGetTotalsQuery();

  const [getCurrentFounding, { data: dataCurrentFounding }] =
    useLazyGetFoundingQuery();
  const [getComparedFounding, { data: dataComparedFounding }] =
    useLazyGetFoundingQuery();

  const [getCurrentFees, { data: dataCurrentFees }] = useLazyGetFeesQuery();
  const [getComparedFees, { data: dataComparedFees }] = useLazyGetFeesQuery();

  const [getTops, { data: dataTops }] = useLazyGetTopsQuery();
  const [getStatuses, { data: dataStatuses }] = useLazyGetStatusesQuery();

  const [isLoading, setLoading] = useState(false);

  const [regenerateCode] = useLazyRegenerateCodeQuery();
  const [getMe] = useLazyGetMeQuery();

  const [copiedCode, setCopiedCode] = useState<boolean>(false);
  const [copiedLink, setCopiedLink] = useState<boolean>(false);

  //@ts-ignore
  const { data: dataFirst } = useGetFirstQuery();

  const [noCompApplied, setNoCompApplied] = useState(false);

  const [dataFounding, setDataFounding] = useState([]);
  const [dataFees, setDataFees] = useState([]);
  const [fromToFounding, setfromToFounding] = useState<string[]>([]);

  const [prevComp, setPrevCopm] = useState('');

  const [comparedOptions, setComparedOptions] = useState<SelectOption[]>([
    { value: 'Previous period', label: 'Previous period' },
    { value: 'Previous month', label: 'Previous month' },
    {
      value: 'Previous year',
      label: 'Previous year',
    },
    { value: 'No comparison', label: 'No comparison' },
  ]);

  const [breakdownOptions, setBreakdownOptions] = useState([
    { value: 'Hourly', label: 'Hourly' },
    { value: 'Daily', label: 'Daily' },
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Monthly', label: 'Monthly' },
  ]);

  const [barDataApplicationStatuses, setBarDataApplicationStatuses] = useState<
    BarChartI[]
  >([]);
  const [barDataTransactionStatuses, setBarDataTransactionStatuses] = useState<
    BarChartI[]
  >([]);

  const [noApplicationStatuses, setNoApplicationStatuses] = useState(false);
  const [noTransactionStatuses, setNoTransactionStatuses] = useState(false);

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    setComparedPeriodParams(filters, dateRange, setParamsCompared);
  }, [filters.comparedPeriod, dateRange]);

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      const dateString0 = dateStrings[0];
      const dateString1 = dateStrings[1];
      let date0 = dayjs(dateString0);
      let date1 = dayjs(dateString1);
      const startOfDay = date0.startOf('day');
      const endOfDay = date1.endOf('day');
      setDateRange([startOfDay, endOfDay]);
      setFilters((prev: filtersI) => ({ ...prev, period: 'Custom' }));
    }
  };

  useEffect(() => {
    if (filters.period !== 'Custom') {
      updateFilters(
        filters,
        setFilters,
        setBreakdownOptions,
        setComparedOptions,
        setDateRange,
        dateRange,
        setParamsCurrent,
        dataFirst?.data
      );
    }
  }, [filters.period, filters.breakdown]);

  useEffect(() => {
    if (filters.period === 'Custom') {
      updateCustomFilters(
        filters,
        setFilters,
        setBreakdownOptions,
        setComparedOptions,
        dateRange,
        setParamsCurrent
      );
    }
  }, [filters.breakdown, dateRange]);

  useEffect(() => {
    if (dataCurrentFounding?.data && dataComparedFounding?.data) {
      let current: any = [];
      dataCurrentFounding?.data?.forEach((elem: any) => {
        current.push({
          CurValue: elem.value,
          CurDate: elem.date,
          ComValue: '',
          ComDate: '',
        });
      });

      dataComparedFounding?.data?.forEach((elem: any, index: number) => {
        if (current[index]) {
          if (noCompApplied) {
            current[index].ComValue = null;
          } else {
            current[index].ComValue = elem.value;
            current[index].ComDate = elem.date;
          }
        }
      });

      setDataFounding(current);
    }
  }, [dataCurrentFounding, dataComparedFounding, noCompApplied]);

  useEffect(() => {
    if (dataCurrentFees?.data && dataComparedFounding?.data) {
      let current: any = [];
      dataCurrentFees?.data?.forEach((elem: any) => {
        current.push({
          CurValue: elem.value,
          CurDate: elem.date,
          ComValue: '',
          ComDate: '',
        });
      });

      dataComparedFees?.data?.forEach((elem: any, index: number) => {
        if (current[index]) {
          if (noCompApplied) {
            current[index].ComValue = null;
          } else {
            current[index].ComValue = elem.value;
            current[index].ComDate = elem.date;
          }
        }
      });

      setDataFees(current);
    }
  }, [dataCurrentFees, dataComparedFees, noCompApplied]);

  const ApplyFilters = async () => {
    try {
      setLoading(true);
      await Promise.all([
        getComparedTotals(paramsCompared),
        getComparedFounding(paramsCompared),
        getComparedFees(paramsCompared),

        getCurrentTotals(paramsCurrent),
        getCurrentFounding(paramsCurrent),
        getCurrentFees(paramsCurrent),
        getTops(paramsCurrent),
        getStatuses(paramsCurrent),
      ]);

      setLoading(false);

      setValuesFromTo(filters, fromToFounding, dateRange, setfromToFounding);

      if (filters.comparedPeriod === 'No comparison') {
        setNoCompApplied(true);
      } else {
        setNoCompApplied(false);
      }
    } catch (error) {
      errorMessage('Apply filters failed');
    } finally {
    }
  };

  useEffect(() => {
    if (!dataStatuses) return;
    let noApp = true;
    let noTra = true;
    const result: BarChartI[] = dataStatuses?.data?.application_statuses?.map(
      (elem: StatusI) => {
        if (elem.percent) {
          noApp = false;
        }
        return {
          percent: elem.percent,
          color:
            elem.name === 'Approved'
              ? '#09BF55'
              : elem.name === 'Offer Made' && !elem.timed
              ? '#1F44DD'
              : elem.name === 'Offer Made' && elem.timed
              ? '#ADE5EA'
              : elem.name === 'Awaiting Signature' && !elem.timed
              ? '#D3A9F5'
              : elem.name === 'Awaiting Signature' && elem.timed
              ? '#FFCB44'
              : elem.name === 'No Offer'
              ? '#F97B81'
              : '',
        };
      }
    );

    const result2: BarChartI[] = dataStatuses?.data?.transaction_statuses?.map(
      (elem: StatusI) => {
        if (elem.percent) {
          noTra = false;
        }
        return {
          percent: elem.percent,
          color:
            elem.name === 'Approved'
              ? '#09BF55'
              : elem.name === 'In Progress'
              ? '#1F44DD'
              : elem.name === 'Withdrawn'
              ? '#F97B81'
              : elem.name === 'Timed Out'
              ? '#FFCB44'
              : '',
        };
      }
    );

    setNoApplicationStatuses(noApp);
    setNoTransactionStatuses(noTra);

    setBarDataApplicationStatuses(result);
    setBarDataTransactionStatuses(result2);
  }, [dataStatuses]);

  useEffect(() => {
    if (!user?.role?.key) return;
    if (user?.role?.key === 'main_account' || user?.role?.key === 'partner') {
      getComparedTotals(paramsCompared);
      getComparedFounding(paramsCompared);

      getCurrentTotals(paramsCurrent);
      getCurrentFounding(paramsCurrent);
      getTops(paramsCurrent);
      getStatuses(paramsCurrent);

      setValuesFromTo(filters, fromToFounding, dateRange, setfromToFounding);
    } else {
      getComparedTotals(paramsCompared);
      getComparedFounding(paramsCompared);
      getComparedFees(paramsCompared);

      getCurrentTotals(paramsCurrent);
      getCurrentFounding(paramsCurrent);
      getCurrentFees(paramsCurrent);
      getTops(paramsCurrent);
      getStatuses(paramsCurrent);

      setValuesFromTo(filters, fromToFounding, dateRange, setfromToFounding);
    }
  }, [user?.role?.key]);

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs();
  };

  const RegenerateCodeFunc = async () => {
    const id: number | null = user.model.id;
    if (!id) return;
    try {
      await regenerateCode(id);
      await getMe();
      await setCopiedCode(false);
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Regenerate code failed');
    }
  };

  const handleCopyCode = () => {
    navigator.clipboard
      .writeText(user?.model?.referral_code + '' || '')
      .then(() => {
        setCopiedCode(true);
        setCopiedLink(false);
      })
      .catch((err) => {
        console.error('Failed to copy text');
      });
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(user?.model?.application_link || '')
      .then(() => {
        setCopiedLink(true);
        setCopiedCode(false);
      })
      .catch((err) => {
        console.error('Failed to copy text');
      });
  };
  return (
    <>
      {contextHolder}
      <div className="container">
        <div className="dashboard">
          {user?.role?.key === 'partner' && (
            <div className="dashboard__row">
              {user?.model?.code_required && (
                <div className="dashboard__code code" style={{ width: '49%' }}>
                  <div className="code__item">
                    <div className="code__item-title">Referral Code</div>
                    <div className="code__item-value">
                      {user?.model?.referral_code}
                    </div>
                  </div>
                  <Button
                    className="violet-btn code__btn-link"
                    onClick={() => RegenerateCodeFunc()}
                  >
                    Regenerate code
                  </Button>
                  <Button
                    className="blue-btn code__btn-link"
                    onClick={handleCopyCode}
                  >
                    {copiedCode && (
                      <img src={doneicon} className="code__btn-image" alt="done" />
                    )}
                    {!copiedCode && (
                      <img src={copyLinkIcon} className="code__btn-image" alt="copy"/>
                    )}
                    {copiedCode && 'Copied'}
                    {!copiedCode && 'Copy code'}
                  </Button>
                </div>
              )}
              <div className="dashboard__code code" style={{ width: '49%' }}>
                <div className="code__item">
                  <div className="code__item-title">Application Link</div>
                  <div className="code__item-value link">
                    {user?.model?.application_link}
                  </div>
                </div>
                <Button
                  className="blue-btn code__btn-link"
                  onClick={handleCopyLink}
                >
                  {copiedLink && (
                    <img src={doneicon} className="code__btn-image" alt="done" />
                  )}
                  {!copiedLink && (
                    <img src={copyLinkIcon} className="code__btn-image" alt="copy" />
                  )}
                  {copiedLink && 'Copied'}
                  {!copiedLink && 'Copy link'}
                </Button>
              </div>
            </div>
          )}

          <div className="dashboard__title">Dashboard</div>

          <div className="dashboard__filters">
            <div className="dashboard__filters-container">
              <div style={{ width: '170px', margin: '0 0 -18px 0' }}>
                <CustomSelect
                  name=""
                  className="input-white-small"
                  placeholder="Show All"
                  value={filters.period}
                  options={[
                    { value: 'Today', label: 'Today' },
                    { value: 'Last 7 days', label: 'Last 7 days' },
                    {
                      value: 'Last 4 weeks',
                      label: 'Last 4 weeks',
                    },
                    { value: 'Last 3 months', label: 'Last 3 months' },
                    { value: 'Last 12 months', label: 'Last 12 months' },
                    { value: 'Month to date', label: 'Month to date' },
                    { value: 'Quarter to date', label: 'Quarter to date' },
                    { value: 'Year to date', label: 'Year to date' },
                    { value: 'All time', label: 'All time' },
                  ]}
                  onChange={(value: any) => {
                    if (value === 'All time') {
                      setPrevCopm(filters.comparedPeriod);
                    }
                    setFilters((prev: filtersI) => ({
                      ...prev,
                      period: value,
                    }));
                  }}
                  isSearchSelect={false}
                />
              </div>

              <div>
                <RangePicker
                  value={dateRange}
                  inputReadOnly
                  disabledDate={disabledDate}
                  onChange={onRangeChange}
                  className="input-white-small"
                  allowClear={false}
                  format="MM-DD-YYYY"
                />
              </div>
              <div
                style={{
                  // width: '170px',
                  color: '#838388',
                  fontSize: '16px',
                  textAlign: 'center',
                }}
              >
                Compared to
              </div>
              <div style={{ width: '170px', margin: '0px 0 -15px 0' }}>
                <CustomSelect
                  name=""
                  className="input-white-small"
                  placeholder="Show All"
                  value={filters.comparedPeriod}
                  options={comparedOptions}
                  onChange={(value: any) => {
                    if (value === 'No comparison') {
                      setPrevCopm(filters.comparedPeriod);
                    }
                    setFilters((prev: filtersI) => ({
                      ...prev,
                      comparedPeriod: value,
                    }));
                  }}
                  isSearchSelect={false}
                  allowClear={false}
                  suffixIcon={false}
                />
              </div>

              <div style={{ width: '170px', margin: '0px 0 -15px 0' }}>
                <CustomSelect
                  name=""
                  className="input-white-small"
                  placeholder="Show All"
                  value={filters.breakdown}
                  options={breakdownOptions}
                  onChange={(value: any) => {
                    setFilters((prev: filtersI) => ({
                      ...prev,
                      breakdown: value,
                    }));
                  }}
                  isSearchSelect={false}
                  allowClear={false}
                  suffixIcon={false}
                />
              </div>
            </div>
            <div className="dashboard__filters-btn">
              <Button
                className="blue-btn"
                style={{ width: '170px', height: '32px' }}
                onClick={ApplyFilters}
              >
                Apply
              </Button>
            </div>
          </div>

          <div className="dashboard__row">
            <div
              className={`dashboard__card card ${
                user?.role?.key === 'partner' ||
                user?.role?.key === 'main_account'
                  ? 'partner'
                  : ''
              }`}
            >
              <div className="card__column first">
                <div className="card__title">Customers</div>
                <div className="card__number">
                  {dataCurrentTotals?.data?.customers}
                </div>
              </div>
              {!noCompApplied && (
                <div className="card__column">
                  <div className="card__value">
                    {calcPercent(
                      dataCurrentTotals?.data?.customers ?? 0,
                      dataComparedTotals?.data?.customers ?? 0
                    )}
                  </div>
                  <div className="card__period">
                    {dataComparedTotals?.data?.customers} <br />
                    {!noCompApplied &&
                    filters.comparedPeriod !== 'No comparison'
                      ? filters.comparedPeriod === 'Previous period'
                        ? 'Prev. Period'
                        : filters.comparedPeriod === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                      : ''}
                    {!noCompApplied &&
                    filters.comparedPeriod === 'No comparison'
                      ? prevComp === 'Previous period'
                        ? 'Prev. Period'
                        : prevComp === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                      : ''}
                    {noCompApplied
                      ? prevComp === 'Previous period'
                        ? 'Prev. Period'
                        : prevComp === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                      : ''}
                  </div>
                </div>
              )}
            </div>
            <div
              className={`dashboard__card card ${
                user?.role?.key === 'partner' ||
                user?.role?.key === 'main_account'
                  ? 'partner'
                  : ''
              }`}
            >
              <div className="card__column first">
                <div className="card__title">Applications</div>
                <div className="card__number">
                  {dataCurrentTotals?.data?.applications}
                </div>
              </div>
              {!noCompApplied && (
                <div className="card__column">
                  <div className="card__value">
                    {calcPercent(
                      dataCurrentTotals?.data?.applications ?? 0,
                      dataComparedTotals?.data?.applications ?? 0
                    )}
                  </div>
                  <div className="card__period">
                    {' '}
                    {dataComparedTotals?.data?.applications} <br />
                    {!noCompApplied &&
                    filters.comparedPeriod !== 'No comparison'
                      ? filters.comparedPeriod === 'Previous period'
                        ? 'Prev. Period'
                        : filters.comparedPeriod === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                      : ''}
                    {!noCompApplied &&
                    filters.comparedPeriod === 'No comparison'
                      ? prevComp === 'Previous period'
                        ? 'Prev. Period'
                        : prevComp === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                      : ''}
                    {noCompApplied
                      ? prevComp === 'Previous period'
                        ? 'Prev. Period'
                        : prevComp === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                      : ''}
                  </div>
                </div>
              )}
            </div>
            <div
              className={`dashboard__card card ${
                user?.role?.key === 'partner' ||
                user?.role?.key === 'main_account'
                  ? 'partner'
                  : ''
              }`}
            >
              <div className="card__column first">
                <div className="card__title">Funding</div>
                <div className="card__number">
                  {' '}
                  {formatNumberToCurrency(
                    dataCurrentTotals?.data?.funding
                      ? dataCurrentTotals?.data?.funding
                      : 0
                  )}
                </div>
              </div>
              {!noCompApplied && (
                <div className="card__column">
                  <div className="card__value">
                    {calcPercent(
                      dataCurrentTotals?.data?.funding ?? 0,
                      dataComparedTotals?.data?.funding ?? 0
                    )}
                  </div>
                  <div className="card__period">
                    {' '}
                    {formatNumberToCurrency(
                      dataComparedTotals?.data?.funding
                        ? dataComparedTotals?.data?.funding
                        : 0
                    )}{' '}
                    <br />
                    {!noCompApplied &&
                    filters.comparedPeriod !== 'No comparison'
                      ? filters.comparedPeriod === 'Previous period'
                        ? 'Prev. Period'
                        : filters.comparedPeriod === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                      : ''}
                    {!noCompApplied &&
                    filters.comparedPeriod === 'No comparison'
                      ? prevComp === 'Previous period'
                        ? 'Prev. Period'
                        : prevComp === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                      : ''}
                    {noCompApplied
                      ? prevComp === 'Previous period'
                        ? 'Prev. Period'
                        : prevComp === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                      : ''}
                  </div>
                </div>
              )}
            </div>
            {(user?.role?.key === 'admin' ||
              user?.role?.key === 'super_admin') && (
              <div className={`dashboard__card card`}>
                <div className="card__column first">
                  <div className="card__title">Processing Fees</div>
                  <div className="card__number">
                    {' '}
                    {formatNumberToCurrency(
                      dataCurrentTotals?.data?.fees
                        ? dataCurrentTotals?.data?.fees
                        : 0
                    )}
                  </div>
                </div>
                {!noCompApplied && (
                  <div className="card__column">
                    <div className="card__value">
                      {calcPercent(
                        dataCurrentTotals?.data?.fees ?? 0,
                        dataComparedTotals?.data?.fees ?? 0
                      )}
                    </div>
                    <div className="card__period">
                      {' '}
                      {formatNumberToCurrency(
                        dataComparedTotals?.data?.fees
                          ? dataComparedTotals?.data?.fees
                          : 0
                      )}
                      <br />
                      {!noCompApplied &&
                      filters.comparedPeriod !== 'No comparison'
                        ? filters.comparedPeriod === 'Previous period'
                          ? 'Prev. Period'
                          : filters.comparedPeriod === 'Previous year'
                          ? 'Prev. Year'
                          : ''
                        : ''}
                      {!noCompApplied &&
                      filters.comparedPeriod === 'No comparison'
                        ? prevComp === 'Previous period'
                          ? 'Prev. Period'
                          : prevComp === 'Previous year'
                          ? 'Prev. Year'
                          : ''
                        : ''}
                      {noCompApplied
                        ? prevComp === 'Previous period'
                          ? 'Prev. Period'
                          : prevComp === 'Previous year'
                          ? 'Prev. Year'
                          : ''
                        : ''}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="dashboard__row">
            <div
              className="dashboard__graphs graphs"
              style={
                user?.role?.key === 'main_account' ? { width: '100%' } : {}
              }
            >
              <div className="graphs__values">
                <div className="graphs__wrapper">
                  <div className="graphs__title">Approved Funding</div>
                  <div className="graphs__value">
                    {!noCompApplied &&
                      calcPercent(
                        dataCurrentFounding?.totals ?? 0,
                        dataComparedFounding?.totals ?? 0
                      )}
                  </div>
                </div>
                <div className="graphs__wrapper">
                  <div className="graphs__number">
                    {formatNumberToCurrency(
                      dataCurrentFounding?.totals
                        ? dataCurrentFounding?.totals
                        : 0
                    )}
                  </div>
                  <div className="graphs__period">
                    {!noCompApplied &&
                      formatNumberToCurrency(
                        dataComparedFounding?.totals
                          ? dataComparedFounding?.totals
                          : 0
                      )}{' '}
                    {!noCompApplied &&
                    filters.comparedPeriod !== 'No comparison'
                      ? filters.comparedPeriod === 'Previous period'
                        ? 'Prev. Period'
                        : filters.comparedPeriod === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                      : ''}
                    {!noCompApplied &&
                    filters.comparedPeriod === 'No comparison'
                      ? prevComp === 'Previous period'
                        ? 'Prev. Period'
                        : prevComp === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                      : ''}
                  </div>
                </div>
              </div>
              <div style={{ width: '90%', height: '100%' }}>
                <YourChartComponent
                  data={dataFounding}
                  values={fromToFounding}
                  breakdown={filters.breakdown}
                  noCompApplied={noCompApplied}
                  type={'Approved Funding'}
                />
              </div>
            </div>
            {user?.role?.key === 'admin' ||
            user?.role?.key === 'super_admin' ? (
              <div className="dashboard__graphs graphs">
                <div className="graphs__values">
                  <div className="graphs__wrapper">
                    <div className="graphs__title">Processing Fees</div>
                    <div className="graphs__value">
                      {!noCompApplied &&
                        calcPercent(
                          dataCurrentFees?.totals ?? 0,
                          dataComparedFees?.totals ?? 0
                        )}
                    </div>
                  </div>{' '}
                  <div className="graphs__wrapper">
                    <div className="graphs__number">
                      {formatNumberToCurrency(
                        dataComparedFees?.totals ? dataComparedFees?.totals : 0
                      )}{' '}
                    </div>
                    <div className="graphs__period">
                      {!noCompApplied && (
                        <>
                          {formatNumberToCurrency(
                            dataComparedFees?.totals
                              ? dataComparedFees?.totals
                              : 0
                          )}
                          {filters.comparedPeriod !== 'No comparison'
                            ? filters.comparedPeriod === 'Previous period'
                              ? 'Prev. Period'
                              : filters.comparedPeriod === 'Previous year'
                              ? 'Prev. Year'
                              : ''
                            : prevComp === 'Previous period'
                            ? 'Prev. Period'
                            : prevComp === 'Previous year'
                            ? 'Prev. Year'
                            : ''}{' '}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ width: '90%', height: '100%' }}>
                  <YourChartComponent
                    data={dataFees}
                    values={fromToFounding}
                    breakdown={filters.breakdown}
                    noCompApplied={noCompApplied}
                    type={'Processing Fees'}
                  />
                </div>
              </div>
            ) : (
              user?.role?.key === 'partner' && (
                <div className="dashboard__table table">
                  <div className="table__title">Top Products by Funding</div>
                  <div className="table__header header">
                    <div className="header__item title">Product</div>
                    <div className="header__item">Applications</div>
                    <div className="header__item">Total $</div>
                  </div>
                  {dataTops?.data?.products.length ? (
                    dataTops?.data?.products?.map((elem: TopItem) => {
                      return (
                        <div className="table__row row">
                          <div className="row__item title">{elem.name}</div>
                          <div className="row__item">{elem.applicants}</div>
                          <div className="row__item">
                            $
                            {elem.value
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="table__nodata">
                      <img src={Illustration} alt="no data" />
                      No data to display
                    </div>
                  )}
                </div>
              )
            )}
          </div>
          {user?.role?.key !== 'partner' && (
            <>
              <div className="dashboard__row">
                {user?.role?.key !== 'main_account' && (
                  <div className="dashboard__table table">
                    <div className="table__title">Top Main Accounts</div>
                    <div className="table__header header">
                      <div className="header__item title">Account name</div>
                      <div className="header__item">Applications</div>
                      <div className="header__item">Total $</div>
                    </div>
                    {dataTops?.data?.main_accounts.length ? (
                      dataTops?.data?.main_accounts?.map((elem: TopItem) => {
                        return (
                          <div className="table__row row">
                            <div className="row__item title">
                              {elem.company_name}
                            </div>
                            <div className="row__item">{elem.applicants}</div>
                            <div className="row__item">
                              $
                              {elem.value
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="table__nodata">
                        <img src={Illustration} alt="no data"/>
                        No data to display
                      </div>
                    )}
                  </div>
                )}
                <div className="dashboard__table table">
                  <div className="table__title">
                    {user?.role?.key === 'admin' ||
                    user?.role?.key === 'super_admin'
                      ? 'Top Partner Accounts'
                      : 'Top Partner Accounts by Funding'}
                  </div>
                  <div className="table__header header">
                    <div className="header__item title">Account name</div>
                    <div className="header__item">Applications</div>
                    <div className="header__item">Total $</div>
                  </div>
                  {dataTops?.data?.partners.length ? (
                    dataTops?.data?.partners?.map((elem: TopItem) => {
                      return (
                        <div className="table__row row">
                          <div className="row__item title">
                            {elem.company_name}
                          </div>
                          <div className="row__item">{elem.applicants}</div>
                          <div className="row__item">
                            $
                            {elem.value
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="table__nodata">
                      <img src={Illustration} alt="no data"/>
                      No data to display
                    </div>
                  )}
                </div>
                {user?.role?.key === 'main_account' && (
                  <div className="dashboard__table table">
                    <div className="table__title">
                      Top Loan Products by Funding
                    </div>
                    <div className="table__header header">
                      <div className="header__item title">Product</div>
                      <div className="header__item">Applications</div>
                      <div className="header__item">Total $</div>
                    </div>
                    {dataTops?.data?.products.length ? (
                      dataTops?.data?.products?.map((elem: TopItem) => {
                        return (
                          <div className="table__row row">
                            <div className="row__item title">{elem.name}</div>
                            <div className="row__item">{elem.applicants}</div>
                            <div className="row__item">
                              $
                              {elem.value
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="table__nodata">
                        <img src={Illustration} alt="no data"/>
                        No data to display
                      </div>
                    )}
                  </div>
                )}
              </div>
              {user?.role?.key !== 'main_account' && (
                <div className="dashboard__row">
                  <div className="dashboard__table table">
                    <div className="table__title">Top Loan Products</div>
                    <div className="table__header header">
                      <div className="header__item title">Product</div>
                      <div className="header__item">Applications</div>
                      <div className="header__item">Total $</div>
                    </div>
                    {dataTops?.data?.products.length ? (
                      dataTops?.data?.products?.map((elem: TopItem) => {
                        return (
                          <div className="table__row row">
                            <div className="row__item title">{elem.name}</div>
                            <div className="row__item">{elem.applicants}</div>
                            <div className="row__item">
                              $
                              {elem.value
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="table__nodata">
                        <img src={Illustration} alt="no data"/>
                        No data to display
                      </div>
                    )}
                  </div>
                  <div className="dashboard__table table">
                    <div className="table__title">Top Banks</div>
                    <div className="table__header header">
                      <div className="header__item title">Bank</div>
                      <div className="header__item">Applications</div>
                      <div className="header__item">Total $</div>
                    </div>
                    {dataTops?.data?.banks.length ? (
                      dataTops?.data?.banks?.map((elem: TopItem) => {
                        return (
                          <div className="table__row row">
                            <div className="row__item title">{elem.name}</div>
                            <div className="row__item">{elem.applicants}</div>
                            <div className="row__item">
                              $
                              {elem.value
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="table__nodata">
                        <img src={Illustration} alt="no data"/>
                        No data to display
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          <div className="dashboard__row">
            <div className="dashboard__progress progress">
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Spinner color="grey" size="medium" />
                </div>
              ) : (
                <>
                  <div className="progress__title">
                    Application statuses analytics
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {noApplicationStatuses ? (
                      <div className="progress__noData">
                        <img
                          src={Illustration}
                          style={{ width: '20px', margin: '0 10px 0 0' }}
                          alt="no data"
                        />
                        No data to display
                      </div>
                    ) : (
                      ''
                    )}
                    <PercentageBar barsData={barDataApplicationStatuses} />
                  </div>
                  <div className="progress-table">
                    <div className="progress-table__header">
                      <div className="progress-table__header-item main">
                        Status
                      </div>
                      <div className="progress-table__header-item">
                        Applications
                      </div>
                      <div className="progress-table__header-item">%</div>
                    </div>
                    {dataStatuses?.data?.application_statuses.length ? (
                      dataStatuses?.data?.application_statuses?.map(
                        (elem: StatusI) => {
                          return (
                            <div className="progress-table__row">
                              <div className="progress-table__row-item main">
                                <DotIcon
                                  fill={
                                    elem.name === 'Approved'
                                      ? '#09BF55'
                                      : elem.name === 'Offer Made'
                                      ? '#1F44DD'
                                      : elem.name === 'Offer Made/Timed Out'
                                      ? '#ADE5EA'
                                      : elem.name === 'Awaiting Signature'
                                      ? '#D3A9F5'
                                      : elem.name === 'Awaiting Sign./Timed Out'
                                      ? '#FFCB44'
                                      : elem.name === 'No Offer'
                                      ? '#F97B81'
                                      : ''
                                  }
                                  style={{ margin: '0 5px 0 0' }}
                                />{' '}
                                {elem.name}
                              </div>
                              <div className="progress-table__row-item">
                                {elem.value ? elem.value : '--'}
                              </div>
                              <div className="progress-table__row-item">
                                {elem.percent ? `${elem.percent}%` : '--'}
                              </div>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <div className="table__nodata">
                        <img src={Illustration} alt="no data"/>
                        No data to display
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="dashboard__progress progress">
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Spinner color="grey" size="medium" />
                </div>
              ) : (
                <>
                  <div className="progress__title">
                    Transaction statuses analytics
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {noTransactionStatuses ? (
                      <div className="progress__noData">
                        <img
                          src={Illustration}
                          style={{ width: '20px', margin: '0 10px 0 0' }}
                          alt="no data"
                        />
                        No data to display
                      </div>
                    ) : (
                      ''
                    )}
                    <PercentageBar barsData={barDataTransactionStatuses} />
                  </div>
                  <div className="progress-table">
                    <div className="progress-table__header">
                      <div className="progress-table__header-item main">
                        Status
                      </div>
                      <div className="progress-table__header-item">
                        Applications
                      </div>
                      <div className="progress-table__header-item">%</div>
                    </div>
                    {dataStatuses?.data?.transaction_statuses.length ? (
                      dataStatuses?.data?.transaction_statuses?.map(
                        (elem: StatusI) => {
                          return (
                            <div className="progress-table__row">
                              <div className="progress-table__row-item main">
                                <DotIcon
                                  fill={
                                    elem.name === 'Approved'
                                      ? '#09BF55'
                                      : elem.name === 'In Progress'
                                      ? '#1F44DD'
                                      : elem.name === 'Withdrawn'
                                      ? '#F97B81'
                                      : elem.name === 'Timed Out'
                                      ? '#FFCB44'
                                      : ''
                                  }
                                  style={{ margin: '0 5px 0 0' }}
                                />{' '}
                                {elem.name}
                              </div>
                              <div className="progress-table__row-item">
                                {elem.value ? elem.value : '--'}
                              </div>
                              <div className="progress-table__row-item">
                                {elem.percent ? `${elem.percent}%` : '--'}
                              </div>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <div className="table__nodata">
                        <img src={Illustration} alt="no data"/>
                        No data to display
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
