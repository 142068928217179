import React, { FC } from 'react';

interface PropsI {
  openItem: number | null | undefined;
  offer: any;
  selectOption: number | null;
  item: any;
  elem: any;
  index: number;
  setSelectOption: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OkinusItem: FC<PropsI> = (props) => {
  const { openItem, offer, selectOption, item, elem, index, setSelectOption } =
    props;

  return openItem === offer.id && selectOption === item.id ? (
    <div className="OfferItem__option-selected">
      {elem.offers.length > 1 && (
        <div className="OfferItem__row-title">Option {index + 1}</div>
      )}
      <div className="OfferItem__row">
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Loan Amount</p>
          <p className="OfferItem__value bold">
            ${' '}
            {item.offer_value
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
        </div>
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Down Payment</p>
          <p className="OfferItem__value bold">$ {item.data.due_today}</p>
        </div>
      </div>
      <div className="OfferItem__row">
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Monthly payment</p>
          <p className="OfferItem__value">$ {item.monthly_payment}</p>
        </div>
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Term</p>
          <p className="OfferItem__value">{item.term}</p>
        </div>
      </div>

      <div className="OfferItem__line"></div>
    </div>
  ) : (
    <div className="OfferItem__option" onClick={() => setSelectOption(item.id)}>
      {elem.offers.length > 1 && (
        <div className="OfferItem__row-title">Option {index + 1}</div>
      )}
      <div className="OfferItem__row">
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Loan Amount</p>
          <p className="OfferItem__value bold">
            ${' '}
            {item.offer_value
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
        </div>
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Down Payment</p>
          <p className="OfferItem__value bold">$ {item.data.due_today}</p>
        </div>
      </div>
      <div className="OfferItem__line"></div>
    </div>
  );
};
