import {Button, Form, Modal, Tooltip} from 'antd';
import {Store} from 'antd/lib/form/interface';
import {FC, useEffect, useState} from 'react';
import close from '../../assets/x-close.svg';
import alert from '../../assets/alert-circle-small.svg'
import success from '../../assets/success-small.svg'
import './index.scss';
import {yupSync} from "../../utils";
import {BankI, BankTableItem, LoanType} from "../../api/types/common";
import {CustomSelect} from "../../customFields/CustomSelect";
import * as Yup from "yup";
import {MultiSelect} from "../../customFields/MultiSelect";
import {ConfirmationModal} from "./ConfirmationModal";
import {useGetFicoQuery,
    useValidateCredentialsMutation
} from "../../api/lenders";
import {FicoRanges} from "../../api/types/lenders";
import {OKINUS, PARTNER, UMWSB} from "../../helpers/variables";
import {CustomInput} from "../../customFields/CustomInput";
import {Spinner} from "../Spinner/Spinner";
import {useLocation} from "react-router-dom";

const validationSchema = Yup.object().shape({
    select_bank: Yup.string()
        .required('This field is required'),
    select_loan_type: Yup.string()
        .required('This field is required'),
    fico_range: Yup.array()
        .of(Yup.string())
        .min(1, 'At least one FICO range is required')
        .required('This field is required'),
    store_id: Yup.string()
        .required('This field is required'),
    api_key: Yup.string()
        .required('This field is required'),
    partner_key: Yup.string()
        .required('This field is required'),
});

interface FormData {
    fico_ranges: any,
    bank_id: string,
    loan_type_id: string,
    active: number,
    name: string,
    loan_type_name: string,
    loan_type_account_types: any,
    api_key: string,
    store_id: string,
    partner_key: string
}

interface ModalI {
    banks: BankI[]
    openModal: boolean;
    isClose?: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    maskClosable?: boolean;
    className?: string;
    tableBanks: BankTableItem[]
    setBanks: React.Dispatch<React.SetStateAction<any>>
    editBank: null | BankTableItem
    setEditBank: React.Dispatch<React.SetStateAction<BankTableItem | null>>
}

export const AddBankModal: FC<ModalI> = ({
                                             banks,
                                             openModal,
                                             setOpenModal,
                                             maskClosable = true,
                                             className,
                                             tableBanks,
                                             setBanks,
                                             editBank,
                                             setEditBank
                                         }) => {
    const [validateCredentials,
        {isLoading, isError, isSuccess, reset}] = useValidateCredentialsMutation()
    const {pathname} = useLocation()
    const isPartnerEdit = pathname?.includes('partner')
    const [form] = Form.useForm<FormData>();
    const {data: ficoRanges} = useGetFicoQuery()
    const [openCancelationModal, setOpenCancelationModal] = useState(false)
    const [selectedBank, setSelectedBank] = useState<string | number | undefined>(undefined)
    const [selectedLoanType, setSelectedLoanType] = useState<string | number | undefined>(undefined)
    const [selectedFicoRanges, setSelectedFicoRanges] = useState<string[]>([])
    const [apiKey, setApiKey] = useState('')
    const [partnerKey, setPartnerKey] = useState('')
    // const [storeId, setStoreId] = useState('')
    //array to filter options by added banks
    const addedBanks = tableBanks.reduce((acc: any, current) => {
        const existingBank: any = acc.find((item: BankTableItem) => item.bank_id === current.bank_id);
        if (existingBank) {
            existingBank.loan_type_ids.push(current.loan_type_id);
        } else {
            acc.push({
                bank_id: current.bank_id,
                loan_type_ids: [current.loan_type_id],
            });
        }
        return acc;
    }, []);
    //options filtration to don't show banks with no loan type options left
    const banksOptions = banks
        .filter(bank => {
            return !(
                bank.loan_types.map(type => type.id)
                    .every((id: number) => addedBanks
                        ?.find((elem: any) => elem.bank_id === bank.id)
                        ?.loan_type_ids.includes(id))
            );

        })
        .map(bank => ({label: bank.id, value: bank.name}))
    const tableBankLoanTypes = selectedBank
        ? tableBanks.filter(bank => bank.bank_id === +selectedBank)
            ?.map(bank => bank.loan_type_id)
        : null
    //filtering loan types that is not selected
    const loanTypes = selectedBank && tableBankLoanTypes
        ? banks?.find(bank => bank.id === +selectedBank)?.loan_types
            .filter((type: LoanType) => !tableBankLoanTypes.includes(type.id))
            .map((type: LoanType) =>
                ({label: type.id, value: type.name}))
        : []
    const resultBank = !!selectedBank
        ? banks?.find(bank => bank.id === +selectedBank) : null
    const resultLoanType = !!selectedBank && !!selectedLoanType
        ? banks?.find(bank => bank.id === +selectedBank)?.loan_types
            .find(type => type.id === +selectedLoanType) : null
    const bankLoanType = !!selectedBank && !!selectedLoanType
        ? banks?.find(bank => bank.id === +selectedBank)?.loan_types
            .find(type => type.id === +selectedLoanType)?.name : null

    const ficoRangesOptions = ficoRanges?.data
        .filter((range: any) => {
            if (resultBank?.fico_ranges) return resultBank?.fico_ranges.includes(range.id)
            return true
        })
        .map((item: FicoRanges) => ({value: item.name, label: item.id}))

    const selectedUMWSB = resultBank?.key === 'umwsb'
    const selectedConcora = resultBank?.key === 'genesis'


    const validateFormOnBlur = (name: string) => {
        form.validateFields([name]);
    };
    const closeModal = () => {
        setOpenModal(false)
        setEditBank(null)
    }
    const closeCancelModal = () => {
        setOpenCancelationModal(false)
    }
    const handleValidateCredentials = () => {
        selectedBank && validateCredentials(
            {
                bank_id: +selectedBank,
                api: {
                    api_key: apiKey,
                }
            })
    }

    const handleValidatePartnerKey = () => {
        selectedBank && validateCredentials(
            {
                bank_id: +selectedBank,
                api: {
                    partner_id: partnerKey,
                }
            })
    }
    const handleSubmit = () => {
        form.validateFields().then((values) => {
                !editBank && setBanks((prev: BankTableItem[]) => {
                    const newBank: any  = {
                        fico_ranges: ficoRanges?.data.filter((range: FicoRanges) => selectedFicoRanges.includes(range.id.toString()))
                            ?.map((range: FicoRanges) => ({name: range.name, id: range.id})),
                        bank_id: selectedBank && +selectedBank,
                        loan_type_id: selectedLoanType && +selectedLoanType,
                        active: 1,
                        name: resultBank?.name,
                        loan_type_name: bankLoanType,
                        loan_type_account_types: resultLoanType?.account_types?.map(type => type.id),
                    }
                    if (isPartnerEdit) {
                        if (selectedUMWSB) {
                            newBank.details = {
                                umwsb: {
                                    api_key: values.api_key,
                                }
                            }
                        }
                        if (selectedConcora) {
                            newBank.details = {
                                genesis: {
                                    partner_id: values.partner_key,
                                }
                            }
                        }
                    }
                    return [...prev, newBank]
                })
                editBank && setBanks((prev: BankTableItem[]) =>
                    prev.map(bank => {
                        if (selectedBank &&
                            bank.bank_id === +selectedBank
                            && selectedLoanType
                            && bank.loan_type_id === +selectedLoanType) {
                            const editedBank: any = {
                                ...bank, ...{
                                    fico_ranges: ficoRanges?.data.filter((range: FicoRanges) => selectedFicoRanges.includes(range.id.toString()))
                                        ?.map((range: FicoRanges) => ({name: range.name, id: range.id})),
                                }
                            }
                            if (isPartnerEdit) {
                                if (selectedUMWSB) {
                                    editedBank.details = {
                                        umwsb: {
                                            api_key: values.api_key,
                                        }
                                    }
                                }
                                if (selectedConcora) {
                                    editedBank.details = {
                                        genesis: {
                                            partner_id: values.partner_key,
                                        }
                                    }
                                }
                            }
                            return editedBank
                        } else return bank
                    }))
                setOpenModal(false)
                setEditBank(null)
            }
        ).catch((error) => {
                console.log(error)
            }
        )
    }

    useEffect(() => {
        if (!editBank) {
            setSelectedLoanType(undefined)
            const valuesToUpdate: Store = {loan_type_id: undefined};
            form.setFieldsValue(valuesToUpdate)
        }
    }, [selectedBank, editBank, form])

    useEffect(() => {
        if (!editBank) {
            setSelectedFicoRanges([])
            const valuesToUpdate: Store = {fico_ranges: []};
            form.setFieldsValue(valuesToUpdate)
        }
    }, [selectedBank, selectedLoanType, form, editBank])

    useEffect(() => {
        if (editBank) {
            setSelectedBank(editBank?.bank_id)
            setSelectedLoanType(editBank.loan_type_id)
        }
    }, [editBank])

    useEffect(() => {
        const fico: string[] = editBank?.fico_ranges.filter((range: any) => {
            if (resultBank?.fico_ranges) return resultBank?.fico_ranges.includes(range.id)
            return true
        }).map((range: any) => range.id?.toString()) || []
        editBank && setSelectedFicoRanges(fico)
        const valuesToUpdate: Store = {fico_ranges: fico};
        editBank && form.setFieldsValue(valuesToUpdate)
        editBank && selectedUMWSB && form.setFieldsValue({api_key:editBank?.details?.umwsb?.api_key })
        editBank && selectedConcora && form.setFieldsValue({partner_key:editBank?.details?.genesis?.partner_id })
    }, [editBank, resultBank, form])

    return (
        <div>
            <Modal
                destroyOnClose
                className={`add-bank-modal ${className}`}
                centered
                open={openModal}
                onCancel={closeModal}
                footer={<></>}
                closeIcon={<img src={close} alt="close"/>}
                width={600}
                maskClosable={maskClosable}
            >
                <div className="add-bank-modal__title">{editBank ? "Edit Bank" : "Add Bank"}</div>

                <Form
                    form={form}
                >
                    {editBank && <div>
                        <div>
                            <div className="add-bank-modal__subtitle">
                                Bank Name
                            </div>
                            <div className="add-bank-modal__text">
                                {editBank.name}
                            </div>
                        </div>
                        <div>
                            <div className="add-bank-modal__subtitle">
                                Loan Type
                            </div>
                            <div className="add-bank-modal__text">
                                {editBank.loan_type_name}
                            </div>
                        </div>
                    </div>}
                    {!editBank && <Form.Item
                        name="bank_id"
                        rules={yupSync(
                            'select_bank',
                            validationSchema,
                            true
                        )}
                    >
                        <CustomSelect
                            name="Select bank"
                            options={banksOptions}
                            placeholder="Please select bank"
                            className="input"
                            onChange={(value) => {
                                setSelectedBank(value)
                            }}
                        />
                    </Form.Item>}
                        {isPartnerEdit && selectedUMWSB &&
                            <div className="add-bank-modal__vetification-container">
                                {/*<Form.Item*/}
                                {/*    name="store_id"*/}
                                {/*    rules={yupSync(*/}
                                {/*        'store_id',*/}
                                {/*        validationSchema,*/}
                                {/*        true*/}
                                {/*    )}*/}
                                {/*>*/}
                                {/*    <CustomInput*/}
                                {/*        name="Store ID"*/}
                                {/*        className="input "*/}
                                {/*        placeholder="Please enter store ID"*/}
                                {/*        onBlur={() => validateFormOnBlur('store_id')}*/}
                                {/*        error={isError ? 'err' : ''}*/}
                                {/*        onChange={(e) => {*/}
                                {/*            reset()*/}
                                {/*            setStoreId(e.target.value)*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</Form.Item>*/}
                                <Form.Item
                                    name="api_key"
                                    rules={yupSync(
                                        'api_key',
                                        validationSchema,
                                        true
                                    )}
                                >
                                    <CustomInput
                                        name="API Key"
                                        className="input"
                                        max={256}
                                        placeholder="Please enter API key"
                                        onBlur={() => validateFormOnBlur('api_key')}
                                        error={isError ? 'err' : ''}
                                        onChange={(e) => {
                                            reset()
                                            setApiKey(e.target.value)
                                        }}
                                    />
                                </Form.Item>
                                <div className="add-bank-modal__verificaton-footer">
                                    <Button
                                        className={`add-bank-modal__verificaton-button ${isSuccess && 'd-none'}`}
                                        onClick={handleValidateCredentials}
                                        disabled={
                                            isLoading
                                            || isError
                                            || !apiKey
                                            // || !storeId
                                    }
                                    >
                                        Verify
                                    </Button>
                                    {!isLoading && !isError && !isSuccess && <div className="d-flex">
                                        <span>Awaiting verification</span>
                                    </div>}
                                    {isLoading && <div className="d-flex">
                                        <Spinner color={'#1A1A26'}/>
                                        <span style={{marginLeft:'8px'}}>Verification in progress</span>
                                    </div>}
                                    {isSuccess && <div className="d-flex">
                                        <img className="mr-2" src={success} alt="success"/>
                                        <span>Successfully verified</span>
                                    </div>}
                                    {isError && <div className="error-text d-flex">
                                        <img className="mr-2" src={alert} alt="alert"/>
                                        <span>Invalid API Key. Please double-check.</span>
                                    </div>}
                                </div>
                            </div>}
                    {isPartnerEdit && selectedConcora && <div className="add-bank-modal__vetification-container">
                        <Form.Item
                            name="partner_key"
                            rules={yupSync(
                                'partner_key',
                                validationSchema,
                                true
                            )}
                        >
                            <CustomInput
                                name="Please enter Partner key"
                                className="input"
                                max={256}
                                placeholder="Partner key"
                                onBlur={() => validateFormOnBlur('partner_key')}
                                error={isError ? 'err' : ''}
                                onChange={(e) => {
                                    reset()
                                    setPartnerKey(e.target.value)
                                }}
                            />
                        </Form.Item>
                        <div className="add-bank-modal__verificaton-footer">
                            <Button
                                className={`add-bank-modal__verificaton-button ${isSuccess && 'd-none'}`}
                                onClick={handleValidatePartnerKey}
                                disabled={
                                    isLoading
                                    || isError
                                    || !partnerKey
                                }
                            >
                                Verify
                            </Button>
                            {!isLoading && !isError && !isSuccess && <div className="d-flex">
                                <span>Awaiting verification</span>
                            </div>}
                            {isLoading && <div className="d-flex">
                                <Spinner color={'#1A1A26'}/>
                                <span style={{marginLeft:'8px'}}>Verification in progress</span>
                            </div>}
                            {isSuccess && <div className="d-flex">
                                <img className="mr-2" src={success} alt="success"/>
                                <span>Successfully verified</span>
                            </div>}
                            {isError && <div className="error-text d-flex">
                                <img className="mr-2" src={alert} alt="alert"/>
                                <span>Invalid Partner Key. Please double-check.</span>
                            </div>}
                        </div>
                    </div>}
                    {!editBank && <Form.Item
                            name="loan_type_id"
                            rules={yupSync(
                                'select_loan_type',
                                validationSchema,
                                true
                            )}
                        >
                            <CustomSelect
                                name="Loan type"
                                options={loanTypes}
                                placeholder="Please select loan type"
                                className="input"
                                onChange={(value) => {
                                    setSelectedLoanType(value)
                                }}
                            />
                        </Form.Item>}
                    {selectedBank && selectedLoanType && <Tooltip
                        zIndex={1000}
                        title={<div className="add-bank-modal__tooltip-container">
                            <div className="add-bank-modal__tooltip-title">
                                {`${resultBank?.name} recommended FICO:`}
                            </div>
                            {resultLoanType?.fico_ranges.map((range) =>
                                <div className="add-bank-modal__tooltip-list-item">
                                    {range.fico_range_from}-{range.fico_range_to} {resultLoanType?.id !== 3 && `with ${range.apr}% APR`}
                                </div>
                            )}
                        </div>}
                        placement="top"
                        color="#1F44DD"
                    >
                        <div
                            className={`add-bank-modal__tooltip-triger ${!!editBank && 'edit'}`}
                        >View reference
                        </div>
                    </Tooltip>}
                    <Form.Item
                        className="input-wrapper"
                        name="fico_ranges"
                        rules={yupSync(
                            'fico_range',
                            validationSchema,
                            true
                        )}
                    >
                        <MultiSelect
                            name="Select FICO Range"
                            className="input input-white-small"
                            placeholder="Please select FICO range"
                            value={selectedFicoRanges}
                            options={selectedBank && !!selectedLoanType ? ficoRangesOptions : []}
                            onChange={(value) => setSelectedFicoRanges(value)}
                            isSearchSelect={false}
                        />
                    </Form.Item>
                    <div className="add-bank-modal__btns">
                        <Button className="gray-btn" onClick={() => setOpenCancelationModal(true)}>
                            Cancel
                        </Button>
                        <Button
                            className="blue-btn"
                            onClick={handleSubmit}
                            disabled={!editBank && (selectedUMWSB || selectedConcora) && !isSuccess}
                        >
                            {editBank ? "Save" : "Add Bank"}
                        </Button>
                    </div>
                </Form>
            </Modal>
            <ConfirmationModal
                openModal={openCancelationModal}
                setOpenModal={setOpenCancelationModal}
                title={editBank
                    ? "Are you sure you want to discard the Bank Editing?"
                    : "Are you sure you want to discard the Bank Adding?"}
                subtitle=""
                cancelText="No"
                confirmText="Yes"
                confirm={() => {
                    closeModal()
                }}
                cancel={closeCancelModal}
                closeIcon={false}
                maskClosable={false}
            />
        </div>
    );
};
