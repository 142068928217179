import {Input, Select, Divider, Space, Form} from 'antd';
import Alert from '../assets/alert-circle.svg';
import searchIcon from '../assets/searchIcon.svg';
import arrow from '../assets/arrow.svg';
import {FC, ReactNode} from 'react';
import {SelectOption} from '../types.ts/commonTypes';
import './index.scss';

interface FloatInputProps {
    name?: string;
    customLabel?: ReactNode;
    error?: string;
    options: SelectOption[] | undefined;
    onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isWithLogo?: boolean;
    defaultValue?: { value: string; label: string };
    suffixIcon?: React.ReactNode;
    allowClear?: boolean;
    placeholder: string;
    className: string;
    onChange: (value: number | string | undefined) => void;
    value?: any;
    isSearchSelect?: boolean;
    disabled?: boolean;
    style?: any;
    searchValue?: string;
}

export const CustomSelect: FC<FloatInputProps> = ({
                                                      placeholder,
                                                      className,
                                                      name,
                                                      customLabel,
                                                      options,
                                                      error,
                                                      onChange,
                                                      onSearch,
                                                      defaultValue,
                                                      suffixIcon,
                                                      allowClear,
                                                      value,
                                                      isWithLogo = false,
                                                      isSearchSelect,
                                                      disabled = false,
                                                      style = {width: '100%'},
                                                      searchValue,

                                                  }) => {
    return (
        <div className="custom-field">
            <div className="custom-field__title">{customLabel ?? name}</div>
            <Select
                disabled={disabled}
                allowClear={allowClear ? {clearIcon: suffixIcon} : false}
                filterOption={false}
                style={style}
                placeholder={placeholder}
                onChange={(value: any) => {
                    onChange && onChange(value);
                }}
                value={value}
                className={className}
                defaultValue={defaultValue}
                suffixIcon={
                    suffixIcon ? (
                        <></>
                    ) : (
                        <img
                            src={arrow}
                            style={className === 'input-white-small' ? {width: '10px'} : {}}
                            alt="arrow"/>
                    )
                }
                onClick={(e) => {
                    e.stopPropagation();
                }}
                dropdownRender={(menu) => {
                    return (
                        <>
                            {isSearchSelect ? (
                                <>
                                    <Space style={{padding: '0 8px 4px', width: '100%'}}>
                                        <Form.Item name={name} className="wrapper-search-input">
                                            <Input
                                                allowClear
                                                className="search-input"
                                                prefix={<img src={searchIcon} alt="search"/>}
                                                placeholder="Search by name"
                                                value={searchValue ? searchValue : name}
                                                onChange={onSearch}
                                                maxLength={100}
                                            />
                                        </Form.Item>
                                    </Space>
                                    <Divider style={{margin: '8px 0'}}/>
                                    {menu}
                                </>
                            ) : (
                                <>{menu}</>
                            )}
                        </>
                    );
                }}
            >
                {options?.map((option: SelectOption) => (
                    <Select.Option key={option.label?.toString() || option.id?.toString()}>
                        <div className="option-select">
                            {isWithLogo && (
                                <img src={option.logo} className="option-select__image" alt="logo"/>
                            )}
                            <div>
                                <div className={isWithLogo ? `option-select__value` : ''}>
                                    {option?.value}
                                </div>
                                {option.item_id && (
                                    <div className="option-select__id">(ID:{option.item_id})</div>
                                )}
                            </div>
                        </div>
                    </Select.Option>
                ))}
            </Select>
            {error && <img src={Alert} className="alert" alt="alert"/>}
        </div>
    );
};
