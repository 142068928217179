import { Button, Table, Tooltip, message } from 'antd';
import { useState, useEffect, useRef } from 'react';
import arrow from '../../assets/arrow-down.svg';
import Property from '../../assets/Property.svg';
import PropertyHover from '../../assets/PropertyHover.svg';
import noData from '../../assets/noData.svg';
import NoUser from '../../assets/NoUser.svg';
import type { ColumnsType } from 'antd/es/table';
import { Pagination } from './Pagination';
import { PausedIcon } from '../icons/PausedIcon';
import { ViewIcon } from '../icons/ViewIcon';
import { useClickOutside } from '../../hooks/useClickOutSide';
import { ClockIcon } from '../icons/ClockIcon';
import { ActivateIcon } from '../icons/ActivateIcon';
import { EmailIcon } from '../icons/EmailIcon';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import {
  AdminItem,
  AdminsTableResponse,
  TableParamsAdmin,
} from '../../api/types/admins';
import { DeleteIcon } from '../icons/DeleteIcon';
import {
  useDeleteAdminMutation,
  useResendInvitationMutation,
} from '../../api/admins';
import { ConfirmationModal } from '../Modals/ConfirmationModal';
import { useAppDispatch } from '../../hooks';
import { setMessage } from '../../reducers/common/commonSlice';
import { isApiError } from '../../utils/general';

interface PartnersTableProps {
  data: AdminItem[];
  setTableParams: React.Dispatch<React.SetStateAction<TableParamsAdmin>>;
  metaData: AdminsTableResponse | undefined;
  tableParams: TableParamsAdmin;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdminsTable = ({
  data,
  setTableParams,
  metaData,
  tableParams,
  setTrigger,
}: PartnersTableProps) => {
  const ref = useRef(null);
  const [openActionId, setOpenActionId] = useState<number | null>(null);
  useClickOutside(ref, () => setOpenActionId(null));
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<string>('');
  const dispatch = useAppDispatch();

  const success = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const sorting = (name: string) => {
    setTableParams((prev: TableParamsAdmin) => ({
      ...prev,
      order_by: name,
      order:
        prev.order_by === name
          ? tableParams.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'asc',
    }));
  };

  const [resetInvitation, { isSuccess: isSuccessResetInv }] =
    useResendInvitationMutation();
  const [deleteAdmin, {}] = useDeleteAdminMutation();

  useEffect(() => {
    setTrigger((prev: boolean) => !prev);
    if (isSuccessResetInv) {
      success('Link resent successfully');
    }
  }, [isSuccessResetInv]);

  const deleteAdminFunc = async () => {
    try {
      await deleteAdmin(idToDelete).unwrap();
      await setConfirmDeleteModal(false);
      if (data.length === 1) {
        setTableParams((prev: TableParamsAdmin) => ({
          ...prev,
          page: tableParams.page > 1 ? tableParams.page - 1 : 1,
        }));
      }
      await setTrigger((prev: boolean) => !prev);
      await dispatch(setMessage('Admin was successfully deleted'));
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Delete admin failed');
    }
  };

  const changeStatusFunc = (id: number, status: number) => {
    if (status === 0) {
      resetInvitation(id + '');
    }
  };

  const columns: ColumnsType<AdminItem> = [
    {
      title: (
        <div className="header-item" style={{ minWidth: '200px' }}>
          First Name
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'first_name' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('first_name')}
          />
        </div>
      ),
      dataIndex: 'first_name',
      key: 'first_name',
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" style={{ minWidth: '200px' }}>
          Last Name
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'last_name' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('last_name')}
          />
        </div>
      ),
      dataIndex: 'last_name',
      key: 'last_name',
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          ID{' '}
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'item_id' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('item_id')}
          />
        </div>
      ),
      width: 70,
      dataIndex: 'item_id',
      key: 'item_id',
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" style={{ minWidth: '200px' }}>
          Contact Email
        </div>
      ),
      dataIndex: 'contact_email',
      key: 'contact_email',
      render: (text) => (
        <div className="table-item">
          <div className="table-text">
            <Tooltip title={text}>{text}</Tooltip>
          </div>
        </div>
      ),
    },
    {
      title: <div className="header-item">Phone</div>,
      dataIndex: 'contact_number',
      key: 'contact_number',
      width: 120,
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Status
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'status' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('status')}
          />
        </div>
      ),
      width: 150,
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div className="table-item table-status">
          <div
            className={
              text === 'Active'
                ? 'table-text status Approved'
                : text === 'Paused'
                ? 'table-text status noOffer'
                : text === 'Pending'
                ? 'table-text status offerMade'
                : ''
            }
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {text === 'Pending' && <ClockIcon fill="#7C24C2" />}
              {text}
            </div>
          </div>
          {openActionId === record.id ? (
            <img
              src={PropertyHover}
              style={{ cursor: 'pointer', margin: '0 0 0 15px' }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenActionId(null);
              }}
            />
          ) : (
            <img
              src={Property}
              style={{ cursor: 'pointer', margin: '0 0 0 15px' }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenActionId(record.id);
              }}
            />
          )}
          {openActionId === record.id && (
            <div className="action">
              {text === 'Pending' && (
                <div
                  className="action__item"
                  onClick={(e) => {
                    e.stopPropagation();
                    changeStatusFunc(record.id, 0);
                  }}
                >
                  <EmailIcon fill={'#808086'} />
                  Resend Invitation email
                </div>
              )}
              <div
                className="action__item"
                onClick={() => navigate(`/partners/${record.id}`)}
              >
                <div style={{ padding: '0 0 0 5px', width: '23px' }}>
                  <ViewIcon fill="#808086" />
                </div>{' '}
                View Profile
              </div>
              <div
                className="action__item"
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmDeleteModal(true);
                  setIdToDelete(record.id + '');
                }}
              >
                <div style={{ padding: '0' }}>
                  <DeleteIcon fill="#808086" />
                </div>{' '}
                Delete
              </div>
            </div>
          )}
        </div>
      ),
    },
  ];

  const customNoDataText = {
    emptyText: (
      <>
        {!tableParams.status_key && !tableParams.search && (
          <div className="no-data-found">
            <img src={NoUser} />
            <div className="no-data-found__text">No users yet</div>
            <div className="no-data-found__subtext">
              Add new partners to start work with them
            </div>
            <Button
              className="blue-btn"
              style={{ margin: '24px 0 0 0' }}
              onClick={() => navigate('/new-partner')}
            >
              + Add partner
            </Button>
          </div>
        )}
        {(tableParams.status_key || tableParams.search) && (
          <div className="no-data-found">
            <img src={noData} />
            <div className="no-data-found__text">No results found</div>
            <div className="no-data-found__subtext">
              Try adjusting your search to find what you are looking for
            </div>
          </div>
        )}
      </>
    ),
  };

  return (
    <>
      <div>
        {contextHolder}
        <Table
          ref={ref}
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={customNoDataText}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => navigate(`/admins/${record.id}`),
            };
          }}
        />
      </div>
      {data?.length > 0 && (
        <Pagination
          setTableParams={setTableParams}
          metaData={metaData}
          tableParams={tableParams}
        />
      )}
      {confirmDeleteModal && (
        <ConfirmationModal
          openModal={confirmDeleteModal}
          setOpenModal={setConfirmDeleteModal}
          type="warning"
          title="Are you sure you want to delete the Admin?"
          subtitle={``}
          cancelText="No"
          confirmText="Yes"
          confirm={() => {
            deleteAdminFunc();
          }}
          cancel={() => setConfirmDeleteModal(false)}
          closeIcon={false}
          maskClosable={false}
        />
      )}
    </>
  );
};
