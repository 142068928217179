import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import {
  MainAccData,
  MainAccTableResponse,
  TableParamsMainAcc,
} from './types/mainAcc';

export const mainAccountsApi = createApi({
  reducerPath: 'mainAccountsApi',
  baseQuery,
  tagTypes: ['MainAccount'],
  endpoints: (builder) => ({
    getMainAccountsList: builder.query<
      MainAccTableResponse,
      TableParamsMainAcc
    >({
      query: (params) => ({
        url: '/main-accounts/',
        method: 'GET',
        params: params,
      }),
    }),
    resendInvitation: builder.mutation<number, string>({
      query: (id) => ({
        url: `/main-accounts/${id}/invitation`,
        method: 'PUT',
      }),
    }),
    changeStatus: builder.mutation({
      query: (data) => ({
        url: `/main-accounts/${data.id}/status`,
        method: 'PUT',
        body: data.key,
      }),
      invalidatesTags: ['MainAccount'],
    }),
    createMainAccount: builder.mutation({
      query: (arg) => ({
        url: '/main-accounts',
        method: 'POST',
        body: arg,
      }),
    }),
    getMainAccount: builder.query<MainAccData, number | string>({
      query: (id) => ({
        url: `/main-accounts/${id}`,
        method: 'GET',
      }),
      providesTags: ['MainAccount'],
    }),
    editMainAccount: builder.mutation({
      query: (data) => ({
        url: `/main-accounts/${data.id}`,
        method: 'PUT',
        body: data.data,
      }),
    }),
  }),
});

export const {
  useLazyGetMainAccountsListQuery,
  useCreateMainAccountMutation,
  useChangeStatusMutation,
  useResendInvitationMutation,
  useLazyGetMainAccountQuery,
  useEditMainAccountMutation,
} = mainAccountsApi;
