export const distributionTooltipLayout = (
    <div>
        <div className="distribution-tooltip-item">
        <strong>· Model 1</strong>
<div className="pl-2">Bank sends funds separately to Magwitch and to the corresponding Partner.</div>
</div>
<div className="distribution-tooltip-item">
    <strong>· Model 2</strong>
<div className="pl-2">Bank sends all funds directly to Magwitch, and Magwitch is obligated to release funds to the Partner.</div>
</div>
</div>
)