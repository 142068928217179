import { iconI } from './types';

export const EmailIcon = ({ fill, style }: iconI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M14.0702 12.8098L14.7997 10.6213C15.4369 8.70953 15.7556 7.75362 15.251 7.24903C14.7464 6.74444 13.7905 7.06308 11.8787 7.70035L9.69019 8.42984C8.14717 8.94418 7.37567 9.20135 7.15642 9.57847C6.94786 9.93723 6.94786 10.3803 7.15642 10.7391C7.37567 11.1162 8.14717 11.3734 9.69019 11.8877C9.93795 11.9703 10.0618 12.0116 10.1654 12.0809C10.2657 12.1481 10.3519 12.2343 10.4191 12.3346C10.4884 12.4382 10.5297 12.5621 10.6123 12.8098C11.1266 14.3528 11.3838 15.1243 11.7609 15.3436C12.1197 15.5521 12.5628 15.5521 12.9215 15.3436C13.2987 15.1243 13.5558 14.3528 14.0702 12.8098Z"
        stroke={fill}
      />
      <path
        d="M13.1679 10.0396C13.3642 9.84539 13.366 9.52881 13.1718 9.33247C12.9776 9.13613 12.6611 9.13437 12.4647 9.32855L13.1679 10.0396ZM10.8096 12.3719L13.1679 10.0396L12.4647 9.32855L10.1064 11.6609L10.8096 12.3719Z"
        fill={fill}
      />
    </svg>
  );
};
