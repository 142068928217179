import { useState, useEffect } from 'react';
import { CustomInput } from '../../customFields/CustomInput';
import searchIcon from '../../assets/searchIcon.svg';
import cancel from '../../assets/x-close.svg';
import { Spinner } from '../../components/Spinner/Spinner';
import { useAppSelector } from '../../hooks';
import exportImage from '../../assets/export.svg';
import type { TimeRangePickerProps } from 'antd';
import './index.scss';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import useDebounce from '../../hooks/useDebounce';
import { DatePicker, Button, message } from 'antd';
import { ReportsAccountTable } from '../../components/Tables/ReportsAccountTable';
import {
  useLazyGetReportsAccountQuery,
  useLazyGetReportsAccountTotalsQuery,
  useLazyGetReportsAccountTypesQuery,
  useLazyGetReportsProductsQuery,
  useLazyGetReportsLendersQuery,
  useLazyGetReportsApplicationsQuery,
  useLazyGetPartnersListQuery,
} from '../../api/reports';
import {
  TableItemReportAccount,
  TableItemReportAccountType,
  TableItemReportApplications,
  TableParamsReports,
} from '../../api/types/reports';
import { useGetFirstQuery } from '../../api/dashboard';
import { downloadFileFunc } from '../../api/axiosCrud';
import { ReportsAccTypesTable } from '../../components/Tables/ReportsAccTypesTable';
import { MultiSelect } from '../../customFields/MultiSelect';
import {
  useGetAccountTypesQuery,
  useGetBanksQuery,
  useGetMainAccountsQuery,
} from '../../api/common';
import { AccountTypesItem, BankI } from '../../api/types/common';
import { ReportsProductsTable } from '../../components/Tables/ReportsProductsTable';
import { ReportsLenders } from '../../components/Tables/ReportsLenders';
import { ReportApplications } from '../../components/Tables/ReportApplications';

export const Reports = () => {
  const { RangePicker } = DatePicker;
  const [reportType, setReportType] = useState('');
  const today = dayjs();
  //@ts-ignore
  const { data: dataFirst } = useGetFirstQuery();
  const user = useAppSelector((state) => state.profile);
  const [messageApi, contextHolder] = message.useMessage();

  const [tableParams, setTableParams] = useState<TableParamsReports>({
    per_page: 50,
    search: '',
    order_by: '',
    order: 'desc',
    page: 1,
    date_from: today.startOf('year').toDate(),
    date_to: today.endOf('day').toDate(),
    account_type_ids: [],
  });

  const [tableData, setTableData] = useState<TableItemReportAccount[]>([]);
  const [tableDataAccTypes, setTableDataAccTypes] = useState<
    TableItemReportAccountType[]
  >([]);
  const [tableDataProducts, setTableDataProducts] = useState<
    TableItemReportAccountType[]
  >([]);
  const [tableDataLenders, setTableDataLenders] = useState<
    TableItemReportAccountType[]
  >([]);
  const [tableDataApplications, setTableDataApplications] = useState<
    TableItemReportApplications[]
  >([]);
  const [trigger, setTrigger] = useState<boolean>(false);

  //get lists
  const [
    getListAccount,
    { data: dataAccount, isLoading, isError: isGetListAccountError },
  ] = useLazyGetReportsAccountQuery();
  const [
    getListAccTypes,
    {
      data: dataAccountTypes,
      isLoading: isLoadingAccTypes,
      isError: isGetListAccTypesError,
    },
  ] = useLazyGetReportsAccountTypesQuery();
  const [
    getListProducts,
    {
      data: dataProducts,
      isLoading: isLoadingProducts,
      isError: isGetListProductError,
    },
  ] = useLazyGetReportsProductsQuery();
  const [
    getListLenders,
    {
      data: dataLenders,
      isLoading: isLoadingLenders,
      isError: isGetListLendersError,
    },
  ] = useLazyGetReportsLendersQuery();
  const [
    getListApplications,
    {
      data: dataApplications,
      isLoading: isLoadingApplications,
      isError: isGetListApplicationsError,
    },
  ] = useLazyGetReportsApplicationsQuery();

  const [getTotals, { data: dataTotals }] =
    useLazyGetReportsAccountTotalsQuery();

  //banks
  const [searchBanks, setSearchBanks] = useState('');
  const debouncedSearchBanks = useDebounce(searchBanks);
  const { data: dataBanks } = useGetBanksQuery({ name: debouncedSearchBanks });
  const [availableBanks, setAvailableBanks] = useState<string[]>([]);

  //partners
  const [searchPartners, setSearchPartners] = useState('');
  const debouncedSearchPartners = useDebounce(searchPartners);
  const [
    getListPartners,
    { data: dataPartnerList, isError: isGetListPartnersError },
  ] = useLazyGetPartnersListQuery();

  //set order by
  useEffect(() => {
    if (reportType === 'Lenders') {
      setTableParams((prev: TableParamsReports) => ({
        ...prev,
        order_by: 'revenue',
      }));
    }
    if (reportType === 'Applications & Transactions') {
      setTableParams((prev: TableParamsReports) => ({
        ...prev,
        order_by: 'id',
      }));
      getListPartners({
        search: debouncedSearchPartners,
      });
    }
    if (
      reportType === 'Accounts' ||
      reportType === 'Account Type' ||
      reportType === 'Products'
    ) {
      setTableParams((prev: TableParamsReports) => ({
        ...prev,
        order_by: 'applications',
      }));
    }
  }, [reportType, debouncedSearchPartners, getListPartners]);

  //main_accounts
  const [mainAccounts, setMainAccounts] = useState<string[]>([]);
  const [searchMainAcc, setSearchMainAcc] = useState('');
  const debouncedSearchMainAcc = useDebounce(searchMainAcc);
  const { data: mainAccountsList } = useGetMainAccountsQuery({
    search: debouncedSearchMainAcc,
  });

  //search reports
  const [searchReports, setSearchReports] = useState('');
  const debouncedSearchReports = useDebounce(searchReports);

  //account type
  const { data: accountTypesData } = useGetAccountTypesQuery();
  const [accountType, setAccountType] = useState<string[]>([]);

  //partners
  const [partners, setPartners] = useState<string[]>([]);
  const [partnersOptions, setPartnersOptions] = useState([]);

  //products
  const [products, setProducts] = useState<string[]>([]);
  const [productsOptions, setProductsOptions] = useState([]);

  //app status
  const [appStatus, setAppStatus] = useState<string[]>([]);

  //trans status
  const [transStatus, setTransStatus] = useState<string[]>([]);

  useEffect(() => {
    if (user?.role?.key === 'partner') {
      setReportType('Products');
    } else {
      setReportType('Accounts');
    }
  }, [user?.role?.key]);

  useEffect(() => {
    const errorMessage = (text: string) => {
      messageApi.open({
        type: 'error',
        content: text,
        style: {
          marginTop: '90vh',
        },
      });
    };
    if (isGetListAccTypesError) {
      errorMessage('Fetch list of acc types failed');
    }
    if (isGetListAccountError) {
      errorMessage('Fetch list of account failed');
    }
    if (isGetListApplicationsError) {
      errorMessage('Fetch applications failed');
    }
    if (isGetListLendersError) {
      errorMessage('Fetch lenders failed');
    }
    if (isGetListPartnersError) {
      errorMessage('Fetch partners failed');
    }
    if (isGetListProductError) {
      errorMessage('Fetch list of products failed');
    }
  }, [
    isGetListAccTypesError,
    isGetListAccountError,
    isGetListApplicationsError,
    isGetListLendersError,
    isGetListPartnersError,
    isGetListProductError,
    messageApi,
  ]);

  useEffect(() => {
    setTableParams((prev: TableParamsReports) => ({
      ...prev,
      bank_ids: availableBanks.map((elem) => +elem),
      account_type_ids: accountType,
      main_accounts: mainAccounts,
      partners: partners,
      loan_products: products,
      application_statuses: appStatus,
      transaction_statuses: transStatus,
    }));
  }, [
    accountType,
    mainAccounts,
    debouncedSearchPartners,
    partners,
    availableBanks,
    products,
    appStatus,
    transStatus,
  ]);

  useEffect(() => {
    setPartners([]);
    if (mainAccounts.length) {
      const filteredData: any = [];
      dataPartnerList?.data.forEach((item: any) => {
        if (mainAccounts.includes(item.main_account_id + '')) {
          filteredData.push(item);
        }
      });
      setPartnersOptions(filteredData);
    } else {
      setPartnersOptions(dataPartnerList?.data);
    }
  }, [dataPartnerList, mainAccounts]);

  useEffect(() => {
    setProducts([]);
    const filteredData: any = [];

    if (accountType.length === 4 || !accountType.length) {
      accountTypesData?.data?.forEach((elem: any) => {
        elem.loan_products.forEach((item: any) => {
          filteredData.push(item);
        });
      });
    } else {
      accountTypesData?.data?.forEach((elem: any) => {
        if (accountType.includes(elem.id + '')) {
          elem.loan_products.forEach((item: any) => {
            filteredData.push(item);
          });
        }
      });
    }

    setProductsOptions(filteredData);
  }, [accountTypesData, accountType]);

  const [period, setPeriod] = useState<string | number | undefined>(
    'Year to date'
  );

  const [dateRange, setDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([today.startOf('year'), today.endOf('day')]);

  useEffect(() => {
    setTableParams((prev: TableParamsReports) => ({
      ...prev,
      search: debouncedSearchReports,
    }));
  }, [debouncedSearchReports]);

  useEffect(() => {
    setTableParams((prev: TableParamsReports) => ({ ...prev, page: 1 }));
  }, [tableParams.search]);

  useEffect(() => {
    if (!reportType) return;
    if (reportType === 'Accounts') {
      getListAccount(tableParams);
    }
    if (reportType === 'Account Type') {
      getListAccTypes(tableParams);
    }
    if (reportType === 'Products') {
      getListProducts(tableParams);
    }
    if (reportType === 'Applications & Transactions') {
      getListApplications(tableParams);
    }
    if (reportType === 'Lenders') {
      getListLenders(tableParams);
    }
  }, [
    tableParams,
    trigger,
    reportType,
    getListAccount,
    getListAccTypes,
    getListProducts,
    getListApplications,
    getListLenders,
  ]);

  useEffect(() => {
    if (!reportType || reportType === 'Applications & Transactions') return;
    let account = '';
    if (reportType === 'Accounts') {
      account = 'accounts';
    }
    if (reportType === 'Account Type') {
      account = 'account_types';
    }
    if (reportType === 'Products') {
      account = 'loan_products';
    }

    if (reportType === 'Lenders') {
      account = 'banks';
    }

    let data = {
      data: tableParams,
      type: account,
    };

    getTotals(data);
  }, [tableParams, period, getTotals]);

  const clearFilters = () => {
    setSearchReports('');
    setAccountType([]);
    setDateRange([today.startOf('year'), today.endOf('day')]);
    setMainAccounts([]);
    setPartners([]);
    setAppStatus([]);
    setTransStatus([]);
    setAvailableBanks([]);
    setTableParams({
      per_page: 50,
      search: '',
      order_by: '',
      order: 'desc',
      page: 1,
      date_from: today.startOf('year').toDate(),
      date_to: today.endOf('day').toDate(),
      account_type_ids: [],
    });
  };

  useEffect(() => {
    if (reportType === 'Accounts') {
      if (!dataAccount?.data) return;
      setTableData(dataAccount?.data);
    }
    if (reportType === 'Account Type') {
      if (!dataAccountTypes?.data) return;
      setTableDataAccTypes(dataAccountTypes?.data);
    }
    if (reportType === 'Products') {
      if (!dataProducts?.data) return;
      setTableDataProducts(dataProducts?.data);
    }
    if (reportType === 'Applications & Transactions') {
      if (!dataApplications?.data) return;
      setTableDataApplications(dataApplications?.data);
    }
    if (reportType === 'Lenders') {
      if (!dataLenders?.data) return;
      setTableDataLenders(dataLenders?.data);
    }
  }, [
    dataAccount?.data,
    dataAccountTypes?.data,
    dataProducts?.data,
    dataLenders?.data,
    dataApplications?.data,
    reportType,
  ]);

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      const dateString0 = dateStrings[0];
      const dateString1 = dateStrings[1];
      let date0 = dayjs(dateString0);
      let date1 = dayjs(dateString1);
      const startOfDay = date0.startOf('day');
      const endOfDay = date1.endOf('day');

      setDateRange([startOfDay, endOfDay]);
      setPeriod('Custom');
      setTableParams((prev: TableParamsReports) => ({
        ...prev,
        date_from: dayjs(startOfDay).toDate(),
        date_to: dayjs(endOfDay).toDate(),
        page: 1,
      }));
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs();
  };

  const exportFunc = () => {
    let account = '';
    if (reportType === 'Accounts') {
      account = 'accounts';
    }
    if (reportType === 'Account Type') {
      account = 'account_types';
    }
    if (reportType === 'Products') {
      account = 'loan_products';
    }
    if (reportType === 'Applications & Transactions') {
      account = 'applicants';
    }
    if (reportType === 'Lenders') {
      account = 'banks';
    }

    downloadFileFunc(tableParams, account).then((res: any) => {
      const csvBlob = new Blob([res.data], { type: 'text/csv' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(csvBlob);
      link.download = reportType;
      link.target = '_blank';

      const el = document.body;
      el.appendChild(link);

      link.click();
    });
  };

  const currentQuarter = Math.floor((today.month() + 3) / 3);

  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: 'Today',
      value: [today.startOf('day'), today.endOf('day')],
    },
    {
      label: 'Last 7 days',
      value: [today.subtract(6, 'day').startOf('day'), today.endOf('day')],
    },
    {
      label: 'Last 4 weeks',
      value: [today.subtract(3, 'week').startOf('day'), today.endOf('day')],
    },
    {
      label: 'Last 3 months',
      value: [today.subtract(3, 'month').startOf('day'), today.endOf('day')],
    },
    {
      label: 'Last 12 months',
      value: [today.subtract(1, 'year').startOf('day'), today.endOf('day')],
    },
    {
      label: 'Month to date',
      value: [today.startOf('month'), today.endOf('day')],
    },
    {
      label: 'Quarter to date',
      value: [
        today.startOf('year').add(currentQuarter * 3 - 3, 'month'),
        today.endOf('day'),
      ],
    },
    {
      label: 'Year to date',
      value: [today.startOf('year'), today.endOf('day')],
    },
    {
      label: 'All time',
      value: [dayjs(dataFirst?.data).startOf('day'), today.endOf('day')],
    },
  ];

  return (
    <div className="container">
      {contextHolder}
      <div className="members">
        <div className="members__row">
          <div className="members__title">Reports</div>
        </div>
        <div className="members__row" style={{ justifyContent: 'flex-start' }}>
          {(user?.role?.key === 'super_admin' ||
            user?.role?.key === 'admin' ||
            user?.role?.key === 'main_account') && (
            <Button
              onClick={() => setReportType('Accounts')}
              className={`gray-btn-with-border ${
                reportType === 'Accounts' ? 'active' : ''
              }`}
            >
              Accounts
            </Button>
          )}
          {(user?.role?.key === 'super_admin' ||
            user?.role?.key === 'admin') && (
            <Button
              onClick={() => setReportType('Account Type')}
              className={`gray-btn-with-border ${
                reportType === 'Account Type' ? 'active' : ''
              }`}
            >
              Account Type
            </Button>
          )}
          <Button
            onClick={() => setReportType('Products')}
            className={`gray-btn-with-border ${
              reportType === 'Products' ? 'active' : ''
            }`}
          >
            Products
          </Button>
          <Button
            onClick={() => setReportType('Applications & Transactions')}
            className={`gray-btn-with-border ${
              reportType === 'Applications & Transactions' ? 'active' : ''
            }`}
          >
            Applications & Transactions
          </Button>
          {(user?.role?.key === 'super_admin' ||
            user?.role?.key === 'admin') && (
            <Button
              onClick={() => setReportType('Lenders')}
              className={`gray-btn-with-border ${
                reportType === 'Lenders' ? 'active' : ''
              }`}
            >
              Lenders
            </Button>
          )}
        </div>

        <div className="members__table">
          <div
            className="members__newfilters"
            style={reportType === 'Products' ? { flexDirection: 'column' } : {}}
          >
            {reportType === 'Accounts' && (
              <div style={{ width: '33%', margin: '0 15px 0 0' }}>
                <CustomInput
                  className="input-white-small"
                  name=""
                  placeholder={
                    user?.role?.key === 'admin' ||
                    user?.role?.key === 'super_admin'
                      ? 'Search by Main Account, Partner or ID'
                      : user?.role?.key === 'main_account'
                      ? 'Search by Partner or ID'
                      : ''
                  }
                  value={searchReports}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchReports(e.target.value)
                  }
                  prefix={<img src={searchIcon} alt="search" />}
                  suffix={
                    searchReports ? (
                      <img
                        className="cancel-btn"
                        style={{ margin: '0 0 0 0' }}
                        src={cancel}
                        onClick={() => setSearchReports('')}
                        alt="cancel"
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              </div>
            )}
            {reportType === 'Account Type' && (
              <div style={{ width: '33%', margin: '0 15px 0 0' }}>
                <MultiSelect
                  value={accountType}
                  name=""
                  className="input-white-small"
                  placeholder="Select Account Type"
                  options={accountTypesData?.data?.map(
                    (elem: AccountTypesItem) => {
                      return { value: elem.name, label: elem.id };
                    }
                  )}
                  onChange={setAccountType}
                  isSearchSelect={false}
                />{' '}
              </div>
            )}
            {reportType === 'Products' && (
              <>
                <div
                  style={{
                    width: '100%',
                    margin: '0 15px 0px 0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div style={{ width: '25%', marginRight: '15px' }}>
                    <CustomInput
                      className="input-white-small"
                      name=""
                      placeholder={'Search by Loan Products'}
                      value={searchReports}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchReports(e.target.value)
                      }
                      prefix={<img src={searchIcon} alt="search" />}
                      suffix={
                        searchReports ? (
                          <img
                            className="cancel-btn"
                            style={{ cursor: 'pointer', margin: '0 0 -2px 0' }}
                            src={cancel}
                            onClick={() => setSearchReports('')}
                            alt="cancel"
                          />
                        ) : (
                          <></>
                        )
                      }
                    />
                  </div>
                  <div style={{ width: '25%', margin: '0 16px 0 0 ' }}>
                    <MultiSelect
                      value={accountType}
                      name=""
                      className="input-white-small"
                      placeholder="Select Account Type"
                      options={accountTypesData?.data?.map(
                        (elem: AccountTypesItem) => {
                          return { value: elem.name, label: elem.id };
                        }
                      )}
                      onChange={setAccountType}
                      isSearchSelect={false}
                    />
                  </div>

                  <div
                    className="topfilters__item"
                    style={{ width: '25%', margin: '0 0 16px 0' }}
                  >
                    <RangePicker
                      value={dateRange}
                      style={{ margin: '0 0 3px 0' }}
                      inputReadOnly
                      presets={rangePresets}
                      onChange={onRangeChange}
                      className="input-white-small"
                      disabledDate={disabledDate}
                      allowClear={false}
                    />
                  </div>
                  <Button
                    className="blue-btn"
                    style={{ margin: '0 0 19px 16px' }}
                    onClick={clearFilters}
                  >
                    Clear filters
                  </Button>
                </div>
              </>
            )}
            {reportType === 'Lenders' && (
              <div style={{ width: '33%', margin: '0 15px 0 0' }}>
                <MultiSelect
                  allowClear={true}
                  value={availableBanks}
                  name=""
                  className="input-white-small"
                  placeholder="Please select banks"
                  options={dataBanks?.data?.map((elem: BankI) => {
                    return { value: elem.name, label: elem.id };
                  })}
                  onChange={setAvailableBanks}
                  onSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchBanks(e.target.value)
                  }
                  isSearchSelect={true}
                />
              </div>
            )}
            {(reportType === 'Accounts' ||
              reportType === 'Account Type' ||
              reportType === 'Lenders') && (
              <>
                <div
                  className="topfilters__item"
                  style={{ width: '32%', margin: '0 0 4px 0' }}
                >
                  <RangePicker
                    value={dateRange}
                    style={{ margin: '0 0 15px 0' }}
                    inputReadOnly
                    presets={rangePresets}
                    onChange={onRangeChange}
                    className="input-white-small"
                    disabledDate={disabledDate}
                    allowClear={false}
                  />
                </div>
                <Button
                  className="blue-btn"
                  style={{ margin: '0 0 19px 16px' }}
                  onClick={clearFilters}
                >
                  Clear filters
                </Button>
              </>
            )}
            {reportType === 'Applications & Transactions' && (
              <div className="members__filters">
                <div
                  className="members__row"
                  style={{ flexDirection: 'column' }}
                >
                  <div className="members__toprow">
                    <div style={{ width: '100%' }}>
                      <div
                        style={{
                          width: '75%',

                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <div
                          className="topfilters__item"
                          style={{ width: '32%', margin: '0 16px 16px 0' }}
                        >
                          <RangePicker
                            value={dateRange}
                            style={{ margin: '0 16px 15px 0' }}
                            inputReadOnly
                            presets={rangePresets}
                            onChange={onRangeChange}
                            className="input-white-small"
                            disabledDate={disabledDate}
                            allowClear={false}
                          />
                        </div>
                        <Button
                          className="blue-btn"
                          style={{ margin: '0 0 30px 16px' }}
                          onClick={clearFilters}
                        >
                          Clear filters
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className={`members__topfilters topfilters`}>
                    <div className="topfilters__container">
                      {(user?.role?.key === 'admin' ||
                        user?.role?.key === 'super_admin') && (
                        <div style={{ width: '15%' }}>
                          <MultiSelect
                            isSearchSelect={true}
                            value={mainAccounts}
                            name="Main Account"
                            className="input-white-small"
                            placeholder="Select"
                            options={mainAccountsList?.data?.map(
                              (elem: any) => {
                                return {
                                  value: elem.name,
                                  label: elem.id,
                                };
                              }
                            )}
                            onChange={setMainAccounts}
                            onSearch={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchMainAcc(e.target.value)}
                          />
                        </div>
                      )}
                      {user?.role?.key !== 'partner' && (
                        <div
                          style={
                            user?.role.key === 'main_account'
                              ? { width: '18%' }
                              : user?.role?.key === 'partner'
                              ? { width: '24%' }
                              : { width: '15%' }
                          }
                        >
                          <MultiSelect
                            isSearchSelect={true}
                            value={partners}
                            name="Partners"
                            className="input-white-small"
                            placeholder="Select"
                            options={partnersOptions?.map((elem: any) => {
                              return {
                                value: elem.company_name,
                                label: elem.id,
                              };
                            })}
                            onChange={setPartners}
                            onSearch={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchPartners(e.target.value)}
                          />
                        </div>
                      )}
                      <div
                        style={
                          user?.role.key === 'main_account'
                            ? { width: '18%' }
                            : user?.role?.key === 'partner'
                            ? { width: '24%' }
                            : { width: '15%' }
                        }
                      >
                        <MultiSelect
                          value={accountType}
                          name="Account Type"
                          className="input-white-small"
                          placeholder="Select"
                          options={accountTypesData?.data?.map(
                            (elem: AccountTypesItem) => {
                              return { value: elem.name, label: elem.id };
                            }
                          )}
                          onChange={setAccountType}
                          isSearchSelect={false}
                        />
                      </div>{' '}
                      <div
                        style={
                          user?.role.key === 'main_account'
                            ? { width: '18%' }
                            : user?.role?.key === 'partner'
                            ? { width: '24%' }
                            : { width: '15%' }
                        }
                      >
                        <MultiSelect
                          value={products}
                          name="Product"
                          className="input-white-small"
                          placeholder="Select"
                          options={productsOptions?.map((elem: any) => {
                            return {
                              value: elem.name,
                              label: elem.id,
                            };
                          })}
                          onChange={setProducts}
                          isSearchSelect={true}
                        />
                      </div>{' '}
                      <div
                        style={
                          user?.role.key === 'main_account'
                            ? { width: '18%' }
                            : user?.role?.key === 'partner'
                            ? { width: '24%' }
                            : { width: '15%' }
                        }
                      >
                        <MultiSelect
                          value={appStatus}
                          name="App Status"
                          className="input-white-small"
                          placeholder="Select"
                          options={[
                            { value: 'Offer Made', label: 'Offer Made' },
                            { value: 'No Offer', label: 'No Offer' },
                            {
                              value: 'Awaiting Signature',
                              label: 'Awaiting Signature',
                            },
                            { value: 'Approved', label: 'Approved' },
                          ]}
                          onChange={setAppStatus}
                          isSearchSelect={false}
                        />
                      </div>{' '}
                      <div
                        style={
                          user?.role.key === 'main_account'
                            ? { width: '18%' }
                            : user?.role?.key === 'partner'
                            ? { width: '24%' }
                            : { width: '15%' }
                        }
                      >
                        <MultiSelect
                          value={transStatus}
                          name="Trans Status"
                          className="input-white-small"
                          placeholder="Select"
                          options={[
                            { value: 'In Progress', label: 'In Progress' },
                            { value: 'Timed Out', label: 'Timed Out' },
                            {
                              value: 'Withdrawn',
                              label: 'Withdrawn',
                            },
                            { value: 'Approved', label: 'Approved' },
                          ]}
                          onChange={setTransStatus}
                          isSearchSelect={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="export-btn" onClick={exportFunc}>
              <img
                src={exportImage}
                className="export-btn__image"
                alt="export"
              />
              Export
            </div>
          </div>
          {isLoading ||
          isLoadingAccTypes ||
          isLoadingApplications ||
          isLoadingLenders ||
          isLoadingProducts ? (
            <Spinner size="large" />
          ) : (
            <>
              {reportType === 'Accounts' && (
                <ReportsAccountTable
                  data={tableData}
                  setTableParams={setTableParams}
                  tableParams={tableParams}
                  metaData={dataAccount}
                  setTrigger={setTrigger}
                  dataTotals={dataTotals?.data}
                />
              )}
              {reportType === 'Account Type' && (
                <ReportsAccTypesTable
                  data={tableDataAccTypes}
                  setTableParams={setTableParams}
                  tableParams={tableParams}
                  metaData={dataAccountTypes}
                  setTrigger={setTrigger}
                  dataTotals={dataTotals?.data}
                />
              )}
              {reportType === 'Products' && (
                <ReportsProductsTable
                  data={tableDataProducts}
                  setTableParams={setTableParams}
                  tableParams={tableParams}
                  metaData={dataProducts}
                  setTrigger={setTrigger}
                  dataTotals={dataTotals?.data}
                />
              )}
              {reportType === 'Applications & Transactions' && (
                <ReportApplications
                  data={tableDataApplications}
                  setTableParams={setTableParams}
                  tableParams={tableParams}
                  metaData={dataApplications}
                  setTrigger={setTrigger}
                  dataTotals={dataTotals?.data}
                />
              )}
              {reportType === 'Lenders' && (
                <ReportsLenders
                  data={tableDataLenders}
                  setTableParams={setTableParams}
                  tableParams={tableParams}
                  metaData={dataLenders}
                  setTrigger={setTrigger}
                  dataTotals={dataTotals?.data}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
