
interface VinCodeBadgeI {
    data :Data
}
interface Data {
    success: boolean
    error: string
    vin: string
    year: string
    make: string
    model: string
    trim: string
}
export const VinCodeBadge:React.FC<VinCodeBadgeI> = ({data}) => {
    return (
        <div className="vin-info-container">
        <div className="vin-info-container__row">
            <div className="vin-info-container__row-item">
                <div className="vin-info-container__title">
                    Make
                </div>
                <div className="vin-info-container__text">
                    {data.make}
                </div>
            </div>
            <div className="vin-info-container__row-item">
                <div className="vin-info-container__title">
                    Model
                </div>
                <div className="vin-info-container__text">
                    {data.model}
                </div>
            </div>
        </div>
        <div className="vin-info-container__row">
            <div className="vin-info-container__row-item">
                <div className="vin-info-container__title">
                    Trim
                </div>
                <div className="vin-info-container__text">
                    {data.trim}
                </div>
            </div>
            <div className="vin-info-container__row-item">
                <div className="vin-info-container__title">
                    Year
                </div>
                <div className="vin-info-container__text">
                    {data.year}
                </div>
            </div>
        </div>
    </div>)
}