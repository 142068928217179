import { iconI } from './types';

export const ViewIcon = ({ fill }: iconI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
    >
      <path
        d="M6 9.18182C3.72727 9.18182 1 6.90909 1 5.09091C1 3.27273 3.72727 1 6 1C8.27273 1 11 3.27273 11 5.09091C11 6.90909 8.27273 9.18182 6 9.18182ZM6 2.81818C5.39724 2.81818 4.81916 3.05763 4.39294 3.48385C3.96672 3.91007 3.72727 4.48814 3.72727 5.09091C3.72727 5.69367 3.96672 6.27175 4.39294 6.69797C4.81916 7.12419 5.39724 7.36364 6 7.36364C6.60276 7.36364 7.18084 7.12419 7.60706 6.69797C8.03328 6.27175 8.27273 5.69367 8.27273 5.09091C8.27273 4.48814 8.03328 3.91007 7.60706 3.48385C7.18084 3.05763 6.60276 2.81818 6 2.81818Z"
        stroke={fill}
        strokeWidth="1.09921"
      />
    </svg>
  );
};
