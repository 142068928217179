import { iconI } from './types';

export const ClockIcon = ({ fill }: iconI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9 6V9L11 10M14 9C14 11.7614 11.7614 14 9 14C6.23857 14 4 11.7614 4 9C4 6.23857 6.23857 4 9 4C11.7614 4 14 6.23857 14 9Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
