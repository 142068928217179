import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./general";
import { IProfileRes } from "./types/profile";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery,
  endpoints: (builder) => ({
    getProfileInfo: builder.query<IProfileRes, void>({
      query: () => ({
        url: "/my-profile",
        method: "GET",
      }),
    }),
    editProfileInfo: builder.mutation({
      query: (data) => ({
        url: `/my-profile`,
        method: "PUT",
        body: data.data,
      }),
    }),
  }),
});

export const { useLazyGetProfileInfoQuery, useEditProfileInfoMutation } = profileApi;
