import { ViewMainAccount } from "../MainAccounts/ViewMainAccount/ViewMainAccount";
import { ViewAdmin } from "../ManageAdmins/ViewAdmin/ViewAdmin";
import { ViewPartner } from "../Partners/ViewPartner.tsx/ViewPartner";
import useProfileController from "./profile.controller";

export const ProfilePage = () => {
  const { isAdmin, isParnter, isMainAcc } = useProfileController();

  if (isAdmin) {
    return <ViewAdmin isProfile />;
  }

  if (isParnter) {
    return <ViewPartner isProfile />;
  }

  if (isMainAcc) {
    return <ViewMainAccount isProfile />;
  }

  return null;
};
