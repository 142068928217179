import { Button, Form, message } from 'antd';
import { useEffect, useState } from 'react';
import {
  useGetAccountTypesQuery,
  useLazyGetBanksQuery,
  useLazyGetMeQuery,
} from '../../../api/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../components/icons/HomeIcon';
import { ArrowIcon } from '../../../components/icons/ArrowIcon';
import { PartnerInfoForm } from '../../../components/PartnerInfoForm/PartnerInfoForm';
import { BankingInfoForm } from '../../../components/PartnerInfoForm/BankingInfoForm';
import * as Yup from 'yup';
import { ConfirmationModal } from '../../../components/Modals/ConfirmationModal';
import {
  useCreatePartnerMutation,
  useEditPartnerMutation,
  useLazyGetPartnerQuery,
} from '../../../api/partners';
import { Spinner } from '../../../components/Spinner/Spinner';
import { useAppSelector } from '../../../hooks';
import {
  AccountTypesItem,
  BankI,
  BankTableItem,
} from '../../../api/types/common';
import { useDispatch } from 'react-redux';
import {
  setIsDirtyAction,
  setMessage,
} from '../../../reducers/common/commonSlice';
import { UserI } from '../../../reducers/profile/profileSlice';
import { CustomErrorType } from '../../../types.ts/commonTypes';
import './index.scss';
import {
  useEditProfileInfoMutation,
  useLazyGetProfileInfoQuery,
} from '../../../api/profile';
import { isApiError } from '../../../utils/general';
import { AddBankModal } from '../../../components/Modals/AddBankModal';
import { unstable_usePrompt } from 'react-router-dom';
import { useLazyGetMainAccountQuery } from '../../../api/mainAccounts';

export const validationSchema = Yup.object().shape({
  company_name: Yup.string()
    .required('This field is required')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  website_address: Yup.string()
    .required('This field is required')
    .url('Link is not valid'),
  first_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  last_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  contact_number: Yup.string()
    .required('This field is required')
    .matches(/^[^_]*$/, 'This field is required'),
  contact_email: Yup.string()
    .email('Email is invalid')
    .required('This field is required'),
  company_address: Yup.string()
    .required('This field is required')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  city: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  zip_code: Yup.string()
    .required('This field is required')
    .matches(/^[^_]*$/, 'This field is required'),
  state: Yup.string().required('This field is required'),

  account_types: Yup.array().min(1, 'This field is required'),
  bank_name: Yup.string()
    .required('This field is required')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  legal_business_name: Yup.string()
    .required('This field is required')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  accounting_number: Yup.string()
    .required('This field is required')
    .min(8, 'Accounting Number is not valid'),
  account_holder_name: Yup.string()
    .required('This field is required')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  routing_number: Yup.string()
    .required('This field is required')
    .matches(/^[0-9]+$/, 'Only integers are allowed in this field'),
  main_account_id: Yup.string().required('This field is required'),
  available_banks: Yup.array().min(1, 'This field is required'),
});

export interface FormDataPartner {
  logo: string | number | undefined;
  contract: string | number | undefined;
  company_name: string;
  website_address: string;
  first_name: string;
  last_name: string;
  contact_number: string;
  contact_email: string;
  company_address: string;
  city: string;
  state: string;
  zip_code: string;
  account_types: AccountTypesItem[];
  bank_name: string;
  legal_business_name: string;
  accounting_number: number;
  account_holder_name: string;
  routing_number: number;
  associated_main_account?: boolean;
  main_account_id: number | string;
  additional_information: string | null;
  available_banks: string[];
  code_required: boolean;
}

export interface selectOption {
  value: string;
  label?: string;
  id?: string;
}

interface CreatePartnerProps {
  isProfile?: boolean;
}

export const CreatePartner = ({ isProfile }: CreatePartnerProps) => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const {
    data: accountTypesData,
    isLoading: getAccTypeLoading,
    isError: isAccountTypesDataError,
  } = useGetAccountTypesQuery();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormDataPartner>();
  const [logo, setLogo] = useState<any[]>([]);
  const [contract, setContract] = useState<any[]>([]);
  const [logoErr, setLogoErr] = useState<string | null>(null);
  const [contractErr, setContractErr] = useState<string | null>(null);
  const [ContractNumber, setContractNumber] = useState<string>('');
  const [accountType, setAccountType] = useState<string[]>([]);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const user = useAppSelector((state: { profile: UserI }) => state.profile);
  const [IdLogo, setIdLogo] = useState<number | null>(null);
  const [IdContract, setIdContract] = useState<number | null>(null);
  const [companyError, setCompanyError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [isClickOnHome, setIsClickOnHome] = useState<boolean>(false);
  const location = useLocation();
  const [isDirty, setIsDirty] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [is5digitCode, setIs5DigitCode] = useState(false);
  const [tableBanks, setTableBanks] = useState<BankTableItem[]>([]);
  const [addBankModal, setAddBankModal] = useState<boolean>(false);
  const [editBank, setEditBank] = useState<BankTableItem | null>(null);
  const [getMe, { isError: isGetMeError }] = useLazyGetMeQuery();
  const [getPartner, { data: PartnerData, isError: isGetPartnerError }] =
    useLazyGetPartnerQuery();
  const [
    getProfileInfo,
    {
      data: profileInfo,
      isLoading: isProfileInfoLoading,
      isError: isGetProfileInfoError,
    },
  ] = useLazyGetProfileInfoQuery();
  const [mainAccIdCteate, setMainAccId] = useState<string | number | undefined>(
    ''
  );
  const [getMainAccount, { data: MainAccData, isSuccess: isSuccessMainData }] =
    useLazyGetMainAccountQuery();
  const mainAccId = PartnerData?.data.main_account_id;
  const [getBanks, { data: dataBanks }] = useLazyGetBanksQuery();
  const [id, setId] = useState<null | number>(null);
  const [createPartner, { data, isSuccess, isError, error }] =
    useCreatePartnerMutation();
  const [
    editPartner,
    {
      isSuccess: isSuccessEditPartner,
      isError: isErrorEditPartner,
      error: errorEditPartner,
    },
  ] = useEditPartnerMutation();
  const [
    editProfile,
    {
      isSuccess: isSuccessEditProfile,
      isError: isErrorEditProfile,
      error: errorEditProfile,
    },
  ] = useEditProfileInfoMutation();
  const [checkedLoanProducts, setCheckedLoanProducts] = useState<number[]>([]);
  const [loanErr, setLoanErr] = useState<any>({});
  const [clickOnSave, setClickOnSave] = useState(false);
  const [typeOfPartner, setTypeOfPartner] = useState('');

  console.log('typeOfPartner', typeOfPartner);

  const responseData = isProfile
    ? profileInfo?.data?.resource
    : PartnerData?.data;

  const [LoanProdErr, setLoanProdErr] = useState<boolean>(false);

  unstable_usePrompt({
    message: 'You have unsaved changes. Do you want to leave this page?',
    //@ts-ignore
    when: ({ currentLocation, nextLocation }) =>
      isDirty &&
      currentLocation !== nextLocation &&
      nextLocation.pathname !== '/login' &&
      !clickOnSave,
  });

  const beforeUnLoad = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    //@ts-ignore
    e.returnValue = '';
  };

  useEffect(() => {
    getBanks({
      main_account_id: mainAccId || '',
    });
  }, [mainAccId]);

  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', beforeUnLoad);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnLoad);
    };
  }, [isDirty]);

  useEffect(() => {
    const hasError = Object.values(loanErr).some((value) => value);

    if (hasError) {
      setLoanProdErr(true);
    } else {
      setLoanProdErr(false);
    }
  }, [loanErr]);

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (!location) return;
    if (location.pathname.includes('partner')) {
      setTypeOfPartner('partner');
    }
    if (location.pathname.includes('related_partner')) {
      setTypeOfPartner('related_partner');
    }
    if (location.pathname.includes('external_partner')) {
      setTypeOfPartner('external_partner');
    }
  }, [location]);

  useEffect(() => {
    if (isAccountTypesDataError) {
      errorMessage('Fetch account type failed');
    }

    if (isGetMeError) {
      errorMessage('Fetch me failed');
    }

    if (isGetPartnerError) {
      errorMessage('Fetch partner info failed');
    }

    if (isGetProfileInfoError) {
      errorMessage('Fetch profile info failed');
    }
  }, [
    isAccountTypesDataError,
    isGetMeError,
    isGetPartnerError,
    isGetProfileInfoError,
  ]);

  useEffect(() => {
    if (!PartnerData) return;
    form.setFieldsValue({
      logo: PartnerData?.data?.logo,
      contract: PartnerData?.data?.contract,
      company_name: PartnerData?.data?.company_name,
      website_address: PartnerData?.data?.website_address,
      first_name: PartnerData?.data?.first_name,
      last_name: PartnerData?.data?.last_name,
      contact_number: PartnerData?.data?.contact_number,
      contact_email: PartnerData?.data?.contact_email,
      company_address: PartnerData?.data?.company_address,
      city: PartnerData?.data?.city,
      state: PartnerData?.data?.state?.id + '',
      zip_code: PartnerData?.data?.zip_code,
      account_types: PartnerData?.data?.account_types?.map(
        (elem: AccountTypesItem) => elem.id + ''
      ),
      additional_information: PartnerData?.data?.additional_information,
      bank_name: PartnerData?.data?.bank_details?.bank_name,
      legal_business_name: PartnerData?.data?.bank_details?.legal_business_name,
      accounting_number: PartnerData?.data?.bank_details?.accounting_number,
      account_holder_name: PartnerData?.data?.bank_details?.account_holder_name,
      routing_number: PartnerData?.data?.bank_details?.routing_number,
      main_account_id: PartnerData?.data?.main_account_id
        ? PartnerData?.data?.main_account_id + ''
        : undefined,
    });
    setId(PartnerData?.data?.item_id);
    setIs5DigitCode(PartnerData?.data?.code_required);
    setLogoUrl(
      typeof PartnerData?.data?.logo === 'string' ? PartnerData?.data?.logo : ''
    );
    setCheckedLoanProducts(
      PartnerData?.data?.loan_products.map((elem: any) => elem.id)
    );
    setLogo([PartnerData?.data?.logo]);
    setContract([PartnerData?.data?.contract]);
    setTableBanks(PartnerData?.data?.banks);
    setAccountType(
      PartnerData?.data?.account_types?.map(
        (elem: AccountTypesItem) => elem.id + ''
      )
    );
  }, [PartnerData]);

  useEffect(() => {
    if (!profileInfo) return;
    form.setFieldsValue({
      logo: profileInfo?.data?.resource?.logo,
      contract: profileInfo?.data?.resource?.contract,
      company_name: profileInfo?.data?.resource?.company_name,
      website_address: profileInfo?.data?.resource?.website_address,
      first_name: profileInfo?.data?.resource?.first_name,
      last_name: profileInfo?.data?.resource?.last_name,
      contact_number: profileInfo?.data?.resource?.contact_number,
      contact_email: profileInfo?.data?.resource?.contact_email,
      company_address: profileInfo?.data?.resource?.company_address,
      city: profileInfo?.data?.resource?.city,
      state: profileInfo?.data?.resource?.state?.id + '',
      zip_code: profileInfo?.data?.resource?.zip_code,
      account_types: profileInfo?.data?.resource?.account_types?.map(
        (elem: AccountTypesItem) => elem.id + ''
      ),
      bank_name: profileInfo?.data?.resource?.bank_details?.bank_name,
      additional_information:
        profileInfo?.data?.resource?.additional_information,
      legal_business_name:
        profileInfo?.data?.resource?.bank_details?.legal_business_name,
      accounting_number:
        profileInfo?.data?.resource?.bank_details?.accounting_number,
      account_holder_name:
        profileInfo?.data?.resource?.bank_details?.account_holder_name,
      routing_number: profileInfo?.data?.resource?.bank_details?.routing_number,
      main_account_id: profileInfo?.data?.resource?.main_account_id
        ? profileInfo?.data?.resource?.main_account_id + ''
        : undefined,
      available_banks: profileInfo?.data?.resource?.banks.map(
        (elem: BankI) => elem.id + ''
      ),
    });
    setId(profileInfo?.data?.resource?.item_id);
    setIs5DigitCode(responseData?.code_required);
    setLogoUrl(
      typeof profileInfo?.data?.resource?.logo === 'string'
        ? profileInfo?.data?.resource?.logo
        : ''
    );
    setLogo([profileInfo?.data?.resource?.logo]);
    setContract([profileInfo?.data?.resource?.contract]);
    setTableBanks(profileInfo?.data?.resource?.banks);
    setAccountType(
      profileInfo?.data?.resource?.account_types?.map(
        (elem: AccountTypesItem) => elem.id + ''
      )
    );
  }, [profileInfo]);

  useEffect(() => {
    if (!location) return;
    if (isProfile) {
      getProfileInfo();
    } else {
      if (location.pathname.includes('edit')) {
        getPartner(location.pathname.split('/')[4]);
      }
      if (
        location.pathname.includes('new-partner/related_partner') &&
        mainAccIdCteate
      ) {
        getMainAccount(mainAccIdCteate);
      }
    }
  }, [location, mainAccIdCteate]);

  useEffect(() => {
    MainAccData &&
      setAccountType(
        MainAccData?.data?.account_types?.map(
          (elem: AccountTypesItem) => elem.id + ''
        )
      );
  }, [MainAccData]);

  useEffect(() => {
    if (!user.email) return;
    if (
      (user.role.key === 'partner' || user.role.key === 'main_account') &&
      !isProfile
    ) {
      navigate('/403');
    }
  }, [user.email, isProfile]);

  const initialValues = {
    account_holder_name: undefined,
    account_types: [],
    available_banks: [],
    accounting_number: undefined,
    additional_information: undefined,
    bank_name: undefined,
    city: undefined,
    company_address: undefined,
    company_name: undefined,
    contact_email: undefined,
    contact_number: undefined,
    contract: undefined,
    first_name: undefined,
    last_name: undefined,
    legal_business_name: undefined,
    logo: undefined,
    main_account_id: undefined,
    routing_number: undefined,
    state: undefined,
    website_address: undefined,
    zip_code: undefined,
  };

  useEffect(() => {
    if (isSuccessEditPartner) {
      dispatch(setMessage('Changes have been successfully saved'));
      navigate(`/partners/${PartnerData?.data?.id}`);
    }
  }, [isSuccessEditPartner]);

  useEffect(() => {
    if (isSuccessEditProfile) {
      dispatch(setMessage('Changes have been successfully saved'));
      navigate('/profile');
    }
  }, [isSuccessEditProfile]);

  useEffect(() => {
    if (isError) {
      for (let prop in (error as CustomErrorType)?.data?.errors) {
        if (
          (error as CustomErrorType)?.data?.errors[prop].includes(
            'A Partner with such Company Name already exists'
          )
        ) {
          setCompanyError(true);
          scrollToElementById('company_name');
        }
        if (
          (error as CustomErrorType)?.data?.errors[prop].includes(
            'A user with such Email already exists'
          )
        ) {
          setEmailError(true);
          scrollToElementById('contact_email');
        }
      }
    }

    if (isSuccess) {
      setSuccessPopup(true);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    for (let prop in (errorEditPartner as CustomErrorType)?.data?.errors) {
      if (
        (errorEditPartner as CustomErrorType)?.data?.errors[prop].includes(
          'A Partner with such Company Name already exists'
        )
      ) {
        setCompanyError(true);
        scrollToElementById('company_name');
      }
      if (
        (errorEditPartner as CustomErrorType)?.data?.errors[prop].includes(
          'A user with such Email already exists'
        )
      ) {
        setEmailError(true);
        scrollToElementById('contact_email');
      }
    }
  }, [isErrorEditPartner]);

  useEffect(() => {
    for (let prop in (errorEditProfile as CustomErrorType)?.data?.errors) {
      if (
        (errorEditProfile as CustomErrorType)?.data?.errors[prop].includes(
          'A Partner with such Company Name already exists'
        )
      ) {
        setCompanyError(true);
        scrollToElementById('company_name');
      }
      if (
        (errorEditProfile as CustomErrorType)?.data?.errors[prop].includes(
          'A user with such Email already exists'
        )
      ) {
        setEmailError(true);
        scrollToElementById('contact_email');
      }
    }
  }, [isErrorEditProfile]);

  const editPartnerFunc = async (params: FormDataPartner) => {
    const data: any = {
      id: PartnerData?.data?.id,
      logo: IdLogo,
      contract: IdContract,
      additional_information: params.additional_information
        ? params.additional_information
        : '',
      company_name: params.company_name?.trim() ?? responseData.company_name,
      website_address: params.website_address ?? responseData.website_address,
      first_name: params.first_name.trim(),
      last_name: params.last_name.trim(),
      contact_number: params.contact_number,
      contact_email: params.contact_email,
      company_address: params.company_address.trim(),
      city: params.city.trim(),
      state_id: params.state,
      zip_code: params.zip_code,

      bank_name: isProfile
        ? profileInfo?.data.resource?.bank_details.bank_name
        : params.bank_name,
      legal_business_name: isProfile
        ? profileInfo?.data.resource?.bank_details.legal_business_name
        : params.legal_business_name,
      accounting_number: isProfile
        ? profileInfo?.data.resource?.bank_details.accounting_number
        : params.accounting_number,
      account_holder_name: isProfile
        ? profileInfo?.data.resource?.bank_details.account_holder_name
        : params.account_holder_name,
      routing_number: isProfile
        ? profileInfo?.data.resource?.bank_details.routing_number
        : params.routing_number,
      associated_main_account:
        typeOfPartner === 'related_partner' ? true : false,
      code_required: is5digitCode,
      banks: tableBanks,
    };
    if (typeOfPartner === 'related_partner') {
      data.main_account_id = +params.main_account_id;
    }
    if (typeOfPartner !== 'related_partner') {
      data.account_type_ids = accountType;
      data.loan_products = checkedLoanProducts;
    }
    if (typeOfPartner === 'external_partner') {
      data.external = true;
    }
    if (!data.logo) {
      data.logo_delete = 1;
    }
    try {
      isProfile
        ? await editProfile({ data: data }).unwrap()
        : await editPartner({ id: PartnerData?.data?.id, data: data }).unwrap();
      isProfile && getMe();
    } catch {}
  };

  const creactePartnerFunc = async (params: FormDataPartner) => {
    const data: any = {
      logo: IdLogo,
      contract: IdContract,
      additional_information: params.additional_information
        ? params.additional_information
        : '',
      company_name: params.company_name,
      website_address: params.website_address,
      first_name: params.first_name.trim(),
      last_name: params.last_name.trim(),
      contact_number: params.contact_number,
      contact_email: params.contact_email,
      company_address: params.company_address,
      city: params.city.trim(),
      state_id: params.state,
      zip_code: params.zip_code,
      bank_name: params.bank_name,
      legal_business_name: params.legal_business_name,
      accounting_number: params.accounting_number,
      account_holder_name: params.account_holder_name,
      routing_number: params.routing_number,
      associated_main_account:
        typeOfPartner === 'related_partner' ? true : false,
      code_required: is5digitCode,
      banks: tableBanks,
    };
    if (typeOfPartner === 'related_partner') {
      data.main_account_id = +params.main_account_id;
    }
    if (typeOfPartner !== 'related_partner') {
      data.account_type_ids = accountType;
      data.loan_products = checkedLoanProducts;
    }
    if (typeOfPartner === 'external_partner') {
      data.external = true;
    }

    try {
      await createPartner(data).unwrap();
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Create partner failed');
    }
  };

  const handleFormSubmit = () => {
    let isValid = true;

    if (!contract.length) {
      setContractErr('This field is required');
      scrollToElementById('contract');
      isValid = false;
    }
    if (LoanProdErr || emailError || companyError) {
      isValid = false;
      if (LoanProdErr) {
        scrollToElementById('loan_product');
      }
      if (emailError) {
        scrollToElementById('contact_email');
      }
      if (companyError) {
        scrollToElementById('company_name');
      }
    }
    form
      .validateFields()
      .then((values: FormDataPartner) => {
        if (isValid) {
          if (location.pathname.includes('edit')) {
            editPartnerFunc(values);
          } else {
            creactePartnerFunc(values);
          }
        }
      })
      .catch((error: { errorFields: [{ name: string[] }] }) => {
        if (!logo.length || !contract.length) {
          scrollToElementById('logo');
        } else {
          const name = error.errorFields[0].name[0];
          scrollToElementById(name);
        }
      });
  };

  const scrollToElementById = (elementId: string) => {
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const onValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <>
      {getAccTypeLoading || isProfileInfoLoading ? (
        <Spinner size="large" />
      ) : (
        <div className="container">
          {contextHolder}
          <div className="new-member">
            {!isProfile && (
              <div className="new-member__breadscrumbs breadscrumbs">
                <div
                  className="breadscrumbs__title"
                  onClick={() => {
                    setClickOnSave(true);
                    if (location.pathname.includes('edit')) {
                      if (isDirty) {
                        setIsClickOnHome(true);
                        setConfirmationModal(true);
                      } else {
                        navigate('/dashboard');
                      }
                    } else {
                      if (form.isFieldsTouched()) {
                        setIsClickOnHome(true);
                        setConfirmationModal(true);
                      } else {
                        navigate('/dashboard');
                      }
                    }
                  }}
                >
                  <HomeIcon fill="" />
                </div>
                <div className="breadscrumbs__separatop">
                  <ArrowIcon fill="" />
                </div>
                <div
                  className="breadscrumbs__title"
                  onClick={() => {
                    setClickOnSave(true);
                    if (location.pathname.includes('edit')) {
                      if (isDirty) {
                        setIsClickOnHome(false);
                        setConfirmationModal(true);
                      } else {
                        navigate('/partners');
                      }
                    } else {
                      if (form.isFieldsTouched()) {
                        setIsClickOnHome(false);
                        setConfirmationModal(true);
                      } else {
                        navigate('/partners');
                      }
                    }
                  }}
                >
                  Partners
                </div>
                <div className="breadscrumbs__separatop">
                  <ArrowIcon fill="#4D4D56" />
                </div>
                <div className="breadscrumbs__title breadscrumbs__title-active">
                  {location.pathname.includes('edit') ? 'Edit' : 'Create'}{' '}
                  Partner
                </div>
              </div>
            )}
            <div className="new-member__main main">
              <Form
                form={form}
                initialValues={initialValues}
                onSubmitCapture={handleFormSubmit}
                // scrollToFirstError={true}
                onValuesChange={onValuesChange}
              >
                <div className="main__container">
                  <div className="main__title-block">
                    <div className="main__title">
                      {isProfile ? 'Edit profile information' : 'Partner info'}
                    </div>
                    <div className="main__subtitle">
                      {isProfile
                        ? 'Edit logo and details'
                        : 'Create Partner logo and details'}
                    </div>
                  </div>
                  <div className="main__form-block">
                    <PartnerInfoForm
                      setLogo={setLogo}
                      logo={logo}
                      setContract={setContract}
                      contract={contract}
                      form={form}
                      setLogoErr={setLogoErr}
                      logoErr={logoErr}
                      setContractErr={setContractErr}
                      contractErr={contractErr}
                      setContractNumber={setContractNumber}
                      ContractNumber={ContractNumber}
                      accountType={accountType}
                      setAccountType={setAccountType}
                      setCheckedLoanProducts={setCheckedLoanProducts}
                      checkedLoanProducts={checkedLoanProducts}
                      setIdLogo={setIdLogo}
                      setIdContract={setIdContract}
                      setCompanyError={setCompanyError}
                      companyError={companyError}
                      setEmailError={setEmailError}
                      emailError={emailError}
                      loanErr={loanErr}
                      accountTypesData={accountTypesData?.data}
                      setLogoUrl={setLogoUrl}
                      logoUrl={logoUrl}
                      id={id}
                      setLoanErr={setLoanErr}
                      typeOfPartner={typeOfPartner}
                      isProfile={isProfile}
                      setIs5DigitCode={setIs5DigitCode}
                      is5digitCode={is5digitCode}
                      banks={tableBanks}
                      setBanks={setTableBanks}
                      setEditBank={setEditBank}
                      setAddBankModal={setAddBankModal}
                      setMainAccId={setMainAccId}
                    />
                  </div>
                </div>
                {(user?.role?.key === 'main_account' &&
                  location.pathname.includes('edit')) ||
                user?.role?.key === 'partner' ? (
                  ''
                ) : (
                  <div
                    className="main__container"
                    style={{ marginTop: ' 24px' }}
                  >
                    <div className="main__title-block">
                      <div className="main__title">Banking Information</div>
                      <div className="main__subtitle">
                        Update Partner billing details
                      </div>
                    </div>
                    <div className="main__form-block">
                      <BankingInfoForm form={form} />
                    </div>
                  </div>
                )}

                <div className="main__btns">
                  <Button
                    className="gray-btn"
                    style={{ margin: '0 16px 0 0' }}
                    onClick={() => {
                      setClickOnSave(true);
                      if (location.pathname.includes('edit')) {
                        if (isDirty) {
                          setIsClickOnHome(false);
                          setConfirmationModal(true);
                        } else {
                          isProfile
                            ? navigate('/dashboard')
                            : navigate('/partners');
                        }
                      } else {
                        if (form.isFieldsTouched()) {
                          setIsClickOnHome(false);
                          setConfirmationModal(true);
                        } else {
                          isProfile
                            ? navigate('/dashboard')
                            : navigate('/partners');
                        }
                      }
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="blue-btn"
                    htmlType="submit"
                    onClick={() => setClickOnSave(true)}
                  >
                    {location.pathname.includes('edit')
                      ? 'Save Changes'
                      : 'Create'}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
      {confirmationModal && (
        <ConfirmationModal
          openModal={confirmationModal}
          setOpenModal={setConfirmationModal}
          type="warning"
          title={
            location.pathname.includes('edit') && !isProfile
              ? 'Are you sure you want to discard the Partner Editing?'
              : location.pathname.includes('edit') && isProfile
              ? 'Are you sure you want to discard the profile editing?'
              : !location.pathname.includes('edit') && !isProfile
              ? 'Are you sure you want to discard the Partner Creation?'
              : ''
          }
          cancelText="No"
          confirmText="Yes"
          cancel={() => {
            setClickOnSave(false);
            setConfirmationModal(false);
          }}
          confirm={() => {
            if (isClickOnHome) {
              navigate('/dashboard');
            } else {
              isProfile ? navigate('/dashboard') : navigate('/partners');
            }
          }}
          closeIcon={true}
        />
      )}
      {successPopup && data?.data && (
        <ConfirmationModal
          openModal={successPopup}
          setOpenModal={setSuccessPopup}
          type="success"
          title="Partner has been successfully created"
          subtitle={`Partner ID: ${data?.data?.item_id}`}
          cancelText=""
          confirmText="Okay"
          confirm={() => {
            navigate('/partners');
          }}
          closeIcon={false}
          maskClosable={false}
        />
      )}
      {addBankModal && (
        <AddBankModal
          banks={dataBanks?.data || []}
          openModal={addBankModal}
          setOpenModal={setAddBankModal}
          setBanks={setTableBanks}
          tableBanks={tableBanks}
          editBank={editBank}
          setEditBank={setEditBank}
        />
      )}
    </>
  );
};
