import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';

import { useAppDispatch } from '../../../hooks';
import { ApplicantI } from '../../../api/types/applicants';

import {
  IOffer,
  ISelectOfferReq,
  ISendOfferTypeReq,
  ISendWithDrawReq,
} from '../../../api/types/offer';
import {
  useSelectOfferMutation,
  useSendOffersByTypeMutation,
  useSendWithDrawMutation,
} from '../../../api/offer';
import { setMessage } from '../../../reducers/common/commonSlice';
import { useLazyGetApplicationQuery } from '../../../api/applicants';
import { isApiError } from '../../../utils/general';

import './index.scss';

interface Props {
  applicationData?: ApplicantI;
}

export default function useViewApplicationController({
  applicationData,
}: Props) {
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedOffer, setSelectedOffer] = useState<number | null>();
  const [selectedValue, setSelectedValue] = useState<
    string | number | undefined
  >('email');
  const [isOfferSelected, setIsOfferSelected] = useState<boolean>(false);
  const location = useLocation();

  const [openItem, setOpenItem] = useState<number | null>(null);
  const [selectOption, setSelectOption] = useState<number | null>(null);

  const [getApplication, { isError, error }] = useLazyGetApplicationQuery();

  const [sendOffersByType] = useSendOffersByTypeMutation();
  const [
    sendSelectedOffer,
    {
      data: selectedOfferData,
      isLoading: isSelectedOfferLoading,
      isSuccess: isSelectedOfferSuccess,
    },
  ] = useSelectOfferMutation();
  const navigate = useNavigate();
  const [isWithDrawModal, setIsWithDrawModal] = useState<boolean>(false);

  const [sendWithDraw, { isSuccess: isSendWithDrawSuccess }] =
    useSendWithDrawMutation();

  const handleSendWithDraw = async (applicantId: number) => {
    try {
      const data: ISendWithDrawReq = {
        applicant_id: applicantId,
      };
      await sendWithDraw(data);
      navigate('/applications');
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Send offer with draw failed');
      setIsWithDrawModal(false);
    }
  };

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const options = [
    { value: 'By Phone', label: 'phone' },
    { value: 'By Email', label: 'email' },
  ];

  const handleChange = (value: string | number | undefined) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    if (isSelectedOfferSuccess) {
      setIsOfferSelected(true);
    }
  }, [isSelectedOfferSuccess]);

  const getStatus = (status: string) => {
    switch (status) {
      case 'No Offer':
        return (
          <p className="ViewApplication__status-label ViewApplication__red-status-label">
            {status}
          </p>
        );

      case 'In Progress':
        return (
          <p className="ViewApplication__status-label ViewApplication__purpure-status-label">
            {status}
          </p>
        );

      case 'Awaiting Signature':
        return (
          <p className="ViewApplication__status-label ViewApplication__purpure-status-label">
            {status}
          </p>
        );

      case 'Withdrawn':
        return (
          <p className="ViewApplication__status-label ViewApplication__red-status-label">
            {status}
          </p>
        );

      case 'Timed Out':
        return (
          <p className="ViewApplication__status-label ViewApplication__red-status-label">
            {status}
          </p>
        );

      default:
        return (
          <p className="ViewApplication__status-label ViewApplication__green-status-label">
            {status}
          </p>
        );
    }
  };

  const getOffersLabel = (
    applicationStatus: string,
    transactionStatus: string
  ) => {
    if (
      applicationStatus === 'Offer Made' &&
      transactionStatus === 'In Progress'
    ) {
      return 'Available offers';
    } else if (
      (applicationStatus === 'Offer Made' &&
        transactionStatus === 'Withdrawn') ||
      transactionStatus.includes('Withdrawn')
    ) {
      return 'Withdrawn offers';
    } else if (
      applicationStatus === 'Offer Made' &&
      transactionStatus === 'Timed Out'
    ) {
      return 'Withdrawn offers';
    } else {
      return 'Offer information';
    }
  };

  const offersLabel =
    applicationData?.data.application_status &&
    getOffersLabel(
      applicationData?.data.application_status,
      applicationData?.data.transaction_status
    );

  const isAvailableOffers = offersLabel === 'Available offers';

  // const isDisabledOffers =
  //   applicationData?.data.application_status === 'Offer Made' &&
  //   applicationData?.data.transaction_status === 'In Progress';

  const handleSendOffersByType = async (applicantId: number) => {
    try {
      const data: ISendOfferTypeReq = {
        applicant_id: applicantId,
        type: {
          type: selectedValue,
        },
      };
      await sendOffersByType(data);
      dispatch(setMessage('Offers list has been successfully sent!'));
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Send offer failed');
    }
  };

  const handleOfferSend = async (applicantId: number, offerId: number) => {
    try {
      const data: ISelectOfferReq = {
        applicant_id: applicantId,
        offer: {
          offer: {
            id: offerId,
          },
        },
      };
      await sendSelectedOffer(data);
      await getApplication(location.pathname.split('/')[3]);
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Selected offer failed');
    }
  };

  return {
    selectedValue,
    navigate,
    options,
    handleChange,
    getStatus,
    handleSendOffersByType,
    selectedOffer,
    handleOfferSend,
    offersLabel,
    isAvailableOffers,
    selectedOfferData,
    isSelectedOfferLoading,
    isSelectedOfferSuccess,
    isOfferSelected,
    setSelectedOffer,
    setIsOfferSelected,
    isError,
    error,
    contextHolder,
    openItem,
    selectOption,
    setOpenItem,
    setSelectOption,
    isWithDrawModal,
    setIsWithDrawModal,
    handleSendWithDraw,
  };
}
