import { Button, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import close from '../../../assets/x-close.svg';
import check from '../../../assets/CheckGreen.svg';
import alert from '../../../assets/alert-circle-small.svg';
import './index.scss';
import { Spinner } from '../../Spinner/Spinner';
import { CodeInput } from './CodeInput';
// import {useAppSelector} from "../../../hooks";
import {
  useLazyGetBillCodeQuery,
  useLazyGetBillPhoneQuery,
  useStoreBillCodeMutation,
} from '../../../api/applicants';

interface ModalI {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setVerifySuccess: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}

export const TwoFAuthModal: FC<ModalI> = ({
  openModal,
  setOpenModal,
  setVerifySuccess,
  className,
}) => {
  const [getPhone, { data: phoneData }] = useLazyGetBillPhoneQuery();
  const [
    getCode,
    {
      isLoading: isCodeLoading,
      isError: isCodeError,
      isSuccess: isCodeSuccess,
    },
  ] = useLazyGetBillCodeQuery();
  const [
    verifyCode,
    {
      isError: isVerifyError,
      isLoading: isVerifyLoading,
      isSuccess: isVerifySuccess,
      reset: resetVerify,
    },
  ] = useStoreBillCodeMutation();
  // const user = useAppSelector((state) => state.profile);
  const [code, setCode] = useState('');
  const [isResendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setResendDisabled(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const codeSanded = isCodeLoading || isCodeSuccess || isCodeError;

  function formatPhoneNumber(phoneNumber: string | undefined) {
    if (phoneNumber) {
      const cleaned = phoneNumber.replace(/\D/g, '');
      const masked = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '(***) *** $3');
      return masked;
    }
  }

  const handleClose = () => {
    setOpenModal(false);
    resetVerify();
  };

  const handleSendCode = () => {
    resetVerify();
    getCode({});
    setResendDisabled(true);
    setTimer(30);
  };

  const handleVerify = () => {
    verifyCode({ code: code });
  };

  useEffect(() => {
    if (openModal) {
      getPhone({});
      handleSendCode();
    }
  }, [openModal]);

  useEffect(() => {
    if (isVerifySuccess) {
      setOpenModal(false);
      setVerifySuccess(true);
      resetVerify();
    }
  }, [isVerifySuccess]);

  return (
    <Modal
      destroyOnClose={true}
      className={`purchase-modal ${className}`}
      centered
      open={openModal}
      onCancel={handleClose}
      footer={<></>}
      width={600}
      closeIcon={<img src={close} alt="close" />}
      maskClosable={!isCodeLoading && !isVerifyLoading}
    >
      <p className="purchase-modal__title">Two-factor Authentication</p>
      <div className="purchase-modal__trans-confirm">
        <p className="purchase-modal__trans-confirm__title">SMS Code</p>
        {isCodeLoading && (
          <p className="d-flex align-center">
            <Spinner color={'black'} />
            <span className="ml-5">Awaiting code</span>
          </p>
        )}
        {isCodeSuccess && !isVerifyError && (
          <p className="d-flex align-center">
            <img src={check} alt="check" className="mr-2" />
            <span className="">Code sent</span>
          </p>
        )}
        {isCodeError && (
          <p className="d-flex align-center">
            <img src={alert} alt="check" className="mr-2" />
            <span className="purchase-modal__code-error">
              Could not send. Please try again
            </span>
          </p>
        )}
        {isVerifyError && (
          <p className="d-flex align-center">
            <img src={alert} alt="check" className="mr-2" />
            <span className="purchase-modal__code-error">Invalid code</span>
          </p>
        )}
      </div>
      <div className="purchase-modal__trans-confirm-footer">
        <div className="purchase-modal__trans-confirm-footer__wrapper">
          <div className="flex-1">
            <p className="purchase-modal__contact-title">
              Customer Contact Number
            </p>
            <p className="purchase-modal__contact-content">
              {formatPhoneNumber(phoneData?.phone)}
            </p>
          </div>
          {codeSanded && isCodeSuccess && (
            <div className="flex-1">
              <p className="purchase-modal__contact-title">
                5-Digit Code Received in SMS
              </p>
              <CodeInput
                setCode={setCode}
                isVerifyError={isVerifyError}
                isVerifyLoading={isVerifyLoading}
                resetPurchase={resetVerify}
              />
            </div>
          )}
          {codeSanded && isCodeError && (
            <div className="purchase-modal__resend-text  flex-1">
              Issue with code?
              <br />
              <span
                className="purchase-modal__resend-text-blue"
                onClick={() => !isResendDisabled && handleSendCode()}
              >
                Resend
              </span>
              {!!timer && <p>You can resend the code in: {timer}</p>}
            </div>
          )}
        </div>
        {codeSanded && isCodeSuccess && (
          <div className="purchase-modal__resend-text mt-12">
            Issue with code?&nbsp;
            <span
              className="purchase-modal__resend-text-blue"
              onClick={() => !isResendDisabled && handleSendCode()}
            >
              Resend
            </span>
            {!!timer && <p>You can resend the code in: {timer}</p>}
          </div>
        )}
      </div>

      <div className="purchase-modal__btns">
        <Button className="gray-btn" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="blue-btn"
          disabled={code.length < 6 || isVerifyLoading}
          onClick={handleVerify}
        >
          Pass
        </Button>
      </div>
    </Modal>
  );
};
