import {Button} from "antd";
import doneicon from "../../assets/doneicon.svg";
import copyLinkIcon from "../../assets/ci_copy.svg";
import {useState} from "react";

interface CopyLinkPropI {
    title: string
    link: string
}

export const CopyLink = ({title,link}: CopyLinkPropI) => {
    const [copiedLink, setCopiedLink] = useState<boolean>(false);

    const handleCopyLink = () => {
        navigator.clipboard
            .writeText(link)
            .then(() => {
                setCopiedLink(true);
            })
            .catch(() => {
                console.error('Failed to copy text');
            });
    };

    return (
        <div className="block__row">
            <div className="block__item" style={{ width: '66%' }}>
                <div className="block__item-title">
                    {title}
                </div>
                <div className="block__item-value block__application-link">
                    {link}
                </div>
            </div>
            <Button
                className="blue-btn block__btn"
                onClick={handleCopyLink}
            >
                {copiedLink && (
                    <img
                        src={doneicon}
                        className="block__btn-image"
                        alt="done"
                    />
                )}
                {!copiedLink && (
                    <img
                        src={copyLinkIcon}
                        className="block__btn-image"
                        alt="copy"
                    />
                )}
                {copiedLink && 'Copied'}
                {!copiedLink && 'Copy link'}
            </Button>
        </div>
    )
}