import moment from 'moment';

import { Button, Tooltip } from 'antd';

import { ArrowIcon } from '../../../components/icons/ArrowIcon';
import { HomeIcon } from '../../../components/icons/HomeIcon';
import { TrashIcon } from '../../../components/icons/TrashIcon';
import { OfferItem } from '../Offers/components/OfferItem/OfferItem';
import { ApplicantI } from '../../../api/types/applicants';
import { ISelectOfferRes } from '../../../api/types/offer';
import { CustomSelect } from '../../../customFields/CustomSelect';
import { SelectedOffer } from '../Offers/components/SelectedOffer';

import useViewApplicationController from './view-application.controller';

import './index.scss';
import { useEffect } from 'react';
import { Offer } from '../Offers/components/Offer/Offer';
import { Spinner } from '../../../components/Spinner/Spinner';
import { ConfirmationModal } from '../../../components/Modals/ConfirmationModal';
import { distributionTooltipLayout } from '../../Lenders/tooltips';
import info from '../../../assets/info-circle-blue.svg';
import { TitleRecepientInfo } from '../../../components/LayoutBlocks/TitleRecepientInfo';
import { CopyLink } from '../../../components/LayoutBlocks/CopyLink';

interface ViewApplicationProps {
  applicationData?: ApplicantI;
  trigger: boolean;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

export const ViewApplication = ({
  applicationData,
  setTrigger,
  isLoading,
}: ViewApplicationProps) => {
  const {
    selectedValue,
    navigate,
    options,
    handleChange,
    getStatus,
    handleSendOffersByType,
    selectedOffer,
    handleOfferSend,
    offersLabel,
    isAvailableOffers,
    selectedOfferData,
    isSelectedOfferLoading,
    isOfferSelected,
    setSelectedOffer,
    setIsOfferSelected,
    isError,
    error,
    contextHolder,
    openItem,
    selectOption,
    setOpenItem,
    setSelectOption,
    isWithDrawModal,
    setIsWithDrawModal,
    handleSendWithDraw,
  } = useViewApplicationController({ applicationData });

  useEffect(() => {
    if (isError) {
    }
  }, [isError, error]);

  if (isOfferSelected) {
    const goToList = () => {
      setIsOfferSelected(false);
      setSelectedOffer(null);
      navigate(`/applications/edit/${applicationData?.data.id}`);
    };

    return (
      <SelectedOffer
        selectedOfferData={selectedOfferData as ISelectOfferRes}
        isSelectedOfferLoading={isSelectedOfferLoading}
        isViewApplication
        goTo={goToList}
        buttonText="View Application"
        contextHolder={contextHolder}
      />
    );
  }

  return (
    <>
      {isLoading ? (
        <Spinner size="large" />
      ) : (
        <>
          {contextHolder}
          <div className="container">
            <div className="new-member">
              <div className="new-member__breadscrumbs breadscrumbs">
                <div
                  className="breadscrumbs__title"
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                >
                  <HomeIcon fill="" />
                </div>
                <div className="breadscrumbs__separatop">
                  <ArrowIcon fill="" />
                </div>
                <div
                  className="breadscrumbs__title"
                  onClick={() => {
                    navigate('/applications');
                  }}
                >
                  Applications
                </div>
                <div className="breadscrumbs__separatop">
                  <ArrowIcon fill="#4D4D56" />
                </div>
                <div className="breadscrumbs__title breadscrumbs__title-active">
                  View Application
                </div>
              </div>
              <div className="view-member">
                <div className="ViewApplication">
                  <div className="ViewApplication__content">
                    <div className="ViewApplication__sub-block ViewApplication__left-side">
                      <div className="ViewApplication__user-info-container">
                        <div className="ViewApplication__user-info">
                          <p className="ViewApplication__user-name">
                            {applicationData?.data?.first_name}{' '}
                            {applicationData?.data?.last_name}
                          </p>
                          <p className="ViewApplication__user-id-container">
                            <p className="ViewApplication__user-id-label">
                              ID#
                            </p>
                            <p className="ViewApplication__user-id">
                              {applicationData?.data?.public_id}
                            </p>
                          </p>
                          <div className="ViewApplication__partner-company-container">
                            <p className="ViewApplication__partner-company-label">
                              Partner’s Company Name
                            </p>
                            <p className="ViewApplication__partner-company-name">
                              {applicationData?.data?.partner.company_name}
                            </p>
                          </div>
                        </div>
                        <div className="ViewApplication__user-additional-info">
                          <div className="ViewApplication__user-additional-info-item">
                            <p className="ViewApplication__label">Address</p>
                            <p className="ViewApplication__additional-info">
                              {applicationData?.data?.street_address}
                            </p>
                          </div>
                          <div className="ViewApplication__info-item">
                            <div className="ViewApplication__user-info-item-wrapper">
                              <p className="ViewApplication__label">
                                Date of Birth
                              </p>
                              <p className="ViewApplication__additional-info">
                                {moment(
                                  applicationData?.data?.date_of_birth
                                ).format('MM/DD/YYYY')}
                              </p>
                            </div>
                            <div className="ViewApplication__user-city">
                              <p className="ViewApplication__label">City</p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data?.city}
                              </p>
                            </div>
                          </div>
                          <div className="ViewApplication__info-item">
                            <div className="ViewApplication__user-info-item-wrapper">
                              <p className="ViewApplication__label">State</p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data.state?.name ?? ''}
                              </p>
                            </div>
                            <div className="ViewApplication__user-city">
                              <p className="ViewApplication__label">Zip Code</p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data?.zip_code}
                              </p>
                            </div>
                          </div>
                          <div className="ViewApplication__info-item">
                            <div className="ViewApplication__user-info-item-wrapper">
                              <p className="ViewApplication__label">
                                Property Status
                              </p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data?.property_status}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="ViewApplication__product-info">
                          <p className="ViewApplication__header-label">
                            Product information
                          </p>
                          <div className="ViewApplication__info-item">
                            <div className="ViewApplication__user-info-item-wrapper">
                              <p className="ViewApplication__label">
                                Financing Amount
                              </p>
                              <p className="ViewApplication__additional-info">
                                ${' '}
                                {applicationData?.data?.financing_amount
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </p>
                            </div>
                            <div className="ViewApplication__user-city">
                              <p className="ViewApplication__label">
                                Financing Type
                              </p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data?.financing_type?.name ??
                                  ''}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="ViewApplication__income-info">
                          <p className="ViewApplication__header-label">
                            Income information
                          </p>
                          <div className="ViewApplication__info-item">
                            <div className="ViewApplication__user-info-item-wrapper">
                              <p className="ViewApplication__label">
                                Annual Income
                              </p>
                              <p className="ViewApplication__additional-info">
                                ${' '}
                                {applicationData?.data?.annual_income
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </p>
                            </div>
                            <div className="ViewApplication__user-city">
                              <p className="ViewApplication__label">
                                Employment Status
                              </p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data?.employment_status}
                              </p>
                            </div>
                          </div>
                          <div className="ViewApplication__info-item">
                            <div className="ViewApplication__user-info-item-wrapper">
                              <p className="ViewApplication__label">
                                Company Name / Source of Income
                              </p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data?.company_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ViewApplication__sub-block ViewApplication__right-side">
                      <div className="ViewApplication__application-status-information">
                        <p className="ViewApplication__header-label">
                          Application status information
                        </p>
                        <div className="ViewApplication__authorization-info-item ViewApplication__margin">
                          <div className="ViewApplication__status-information-item">
                            <p className="ViewApplication__status-information-item-label">
                              Application Status:
                            </p>
                            <>
                              {applicationData?.data
                                ?.additional_application_status ===
                              'Awaiting Approval' ? (
                                <p className="ViewApplication__status-label ViewApplication__purpure-status-label">
                                  {
                                    applicationData?.data
                                      ?.additional_application_status
                                  }
                                </p>
                              ) : (
                                getStatus(
                                  applicationData?.data
                                    ?.application_status as string
                                )
                              )}
                            </>
                          </div>
                          <div className="ViewApplication__status-information-item">
                            <p className="ViewApplication__status-information-item-label">
                              Transaction Status:
                            </p>
                            <>
                              {getStatus(
                                applicationData?.data
                                  ?.transaction_status as string
                              )}
                            </>
                          </div>
                          {applicationData?.data?.bank
                            ?.fund_distribution_model && (
                            <div className="ViewApplication__status-information-item">
                              <p className="ViewApplication__status-information-item-label d-flex">
                                <span className="mr-2">
                                  Lending Funds Distribution
                                </span>
                                <Tooltip title={distributionTooltipLayout}>
                                  <img src={info} alt="info" />
                                </Tooltip>
                              </p>
                              <p className="ViewApplication__status-label">
                                {applicationData?.data
                                  ?.fund_distribution_model === 1
                                  ? 'Model 1'
                                  : 'Model 2'}
                              </p>
                            </div>
                          )}
                          <div className="ViewApplication__status-information-item">
                            <p className="ViewApplication__status-information-item-label">
                              Date Created:
                            </p>
                            <p className="ViewApplication__status-label">
                              {moment(applicationData?.data?.created_at).format(
                                'LL'
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="ViewApplication__auth-info">
                        <p className="ViewApplication__header-label">
                          Authorization information
                        </p>
                        <div className="ViewApplication__info-item">
                          <div className="ViewApplication__user-info-item-wrapper">
                            <p className="ViewApplication__label">
                              Phone Number
                            </p>
                            <p className="ViewApplication__additional-info">
                              {applicationData?.data?.phone}
                            </p>
                          </div>
                          <div className="ViewApplication__user-city">
                            <p className="ViewApplication__label">
                              Email Address
                            </p>
                            <p className="ViewApplication__additional-info">
                              {applicationData?.data?.email}
                            </p>
                          </div>
                        </div>
                      </div>

                      {applicationData?.data?.application_status ===
                        'Approved' &&
                        !!applicationData?.data?.secured && (
                          <TitleRecepientInfo
                            bank={applicationData?.data?.bank}
                          />
                        )}

                      {applicationData?.data?.application_status ===
                        'Approved' &&
                        applicationData?.data?.fund_distribution_model ===
                          2 && (
                          <CopyLink
                            title={'Bill.com Invoice Link'}
                            link={applicationData?.data.invoice_url}
                          />
                        )}

                      {applicationData?.data?.additional_data?.genesis && (
                        <div className="ViewApplication__auth-info-concora">
                          <div className="OfferItem__head">
                            <div className="OfferItem__title ViewApplication__header-label">
                              <img
                                src={
                                  applicationData?.data?.additional_data
                                    ?.genesis.bank_logo
                                }
                                className="OfferItem__logo"
                                alt="bank logo"
                              />
                              {
                                applicationData?.data?.additional_data?.genesis
                                  .bank_name
                              }
                            </div>
                          </div>
                          <div>
                            <div className="OfferItem__row">
                              <div className="OfferItem__wrapper">
                                <p className="OfferItem__name">
                                  Approved Balance
                                </p>
                                <p className="OfferItem__value bold">
                                  $
                                  {applicationData?.data?.additional_data?.genesis.creditLine
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </p>
                              </div>
                              <div className="OfferItem__wrapper">
                                <p className="OfferItem__name">
                                  Avaialble Balance
                                </p>
                                <p className="OfferItem__value bold">
                                  $
                                  {applicationData?.data?.additional_data?.genesis.availableCredit
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </p>
                              </div>
                              <div className="OfferItem__wrapper">
                                <p className="OfferItem__name">
                                  Transacted Amount
                                </p>
                                <p className="OfferItem__value bold">
                                  $
                                  {applicationData?.data?.additional_data?.genesis.transactionAmount
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {!applicationData?.data?.additional_data?.genesis && (
                        <div className="ViewApplication__application-status-offers">
                          <p className="ViewApplication__header-label">
                            {offersLabel}
                          </p>
                          {isAvailableOffers && (
                            <div className="ViewApplication__application-offers-available">
                              <div className="ViewApplication__available-send-offer-wrapper">
                                <div className="ViewApplication__application-available-send-offer">
                                  <p className="Offers__available-for-label">
                                    Send Offers To Applicant:
                                  </p>
                                  <CustomSelect
                                    className="input"
                                    placeholder="Select"
                                    value={selectedValue}
                                    options={options}
                                    onChange={(value) => handleChange(value)}
                                  />
                                </div>
                                <div className="Offers__available-actions">
                                  <p className="Offers__available-for-label"></p>
                                  <Button
                                    className="Offers__button"
                                    onClick={() =>
                                      handleSendOffersByType(
                                        applicationData?.data?.id as number
                                      )
                                    }
                                  >
                                    <span>Send</span>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}

                          {!applicationData?.data?.offers?.length ? (
                            <div className="ViewApplication__no-offers-available">
                              <div className="Offers__list-no-data">
                                <div className="Offers__list-no-data-icon">
                                  <TrashIcon />
                                </div>
                                <div className="Offers__list-no-data-description">
                                  <p className="Offers__list-no-data-title">
                                    No offers available
                                  </p>
                                  <p className="Offers__list-no-data-sub-title">
                                    This applicant is not eligible for any
                                    offers
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="ViewApplication__list">
                              {applicationData.data.offers.map((offer) => (
                                <>
                                  {applicationData?.data?.application_status ===
                                    'Awaiting Signature' ||
                                  applicationData?.data?.application_status ===
                                    'Approved' ? (
                                    <Offer
                                      key={offer.id}
                                      offer={offer}
                                      selectedOffer={selectedOffer}
                                      setSelectedOffer={setSelectedOffer}
                                      ApproveBtn={
                                        applicationData?.data
                                          ?.additional_application_status ===
                                          'Awaiting Approval' &&
                                        offer.name === 'Lease-to-own'
                                      }
                                      ApprovedBtn={
                                        offer.name === 'Lease-to-own' &&
                                        applicationData?.data
                                          ?.application_status === 'Approved' &&
                                        !applicationData?.data
                                          ?.additional_application_status
                                      }
                                      setTrigger={setTrigger}
                                    />
                                  ) : applicationData?.data
                                      ?.application_status ===
                                      'Awaiting Down Payment' ||
                                    applicationData?.data
                                      ?.application_status === 'Approved' ? (
                                    <Offer
                                      key={offer.id}
                                      offer={offer}
                                      selectedOffer={selectedOffer}
                                      setSelectedOffer={setSelectedOffer}
                                      ConfirmDPBtn={true}
                                      ConfirmedDPBtn={
                                        applicationData?.data
                                          ?.application_status === 'Approved'
                                          ? true
                                          : false
                                      }
                                      setTrigger={setTrigger}
                                    />
                                  ) : (
                                    <OfferItem
                                      key={offer.id}
                                      offer={offer}
                                      openItem={openItem}
                                      selectOption={selectOption}
                                      setOpenItem={setOpenItem}
                                      setSelectOption={setSelectOption}
                                      isWithdrawn={
                                        applicationData?.data
                                          ?.transaction_status === 'Withdrawn'
                                      }
                                    />
                                  )}
                                </>
                              ))}

                              {isAvailableOffers && (
                                <div className="ViewApplication__list-actions">
                                  {applicationData?.data?.application_status ===
                                    'Offer Made' && (
                                    <Button
                                      type="text"
                                      onClick={() => setIsWithDrawModal(true)}
                                      style={{ margin: '0 10px 0 0' }}
                                    >
                                      <span className="Offers__with-draw">
                                        Withdraw
                                      </span>
                                    </Button>
                                  )}
                                  <Button
                                    className="blue-btn"
                                    style={{
                                      maxWidth: '136px',
                                    }}
                                    disabled={!selectOption}
                                    onClick={() => {
                                      if (selectOption) {
                                        handleOfferSend(
                                          applicationData.data.id,
                                          selectOption
                                        );
                                      }
                                    }}
                                  >
                                    Select This Offer
                                  </Button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isWithDrawModal && (
        <ConfirmationModal
          openModal={isWithDrawModal}
          setOpenModal={setIsWithDrawModal}
          type="success"
          title="Are you sure you’d like to exit and permanently withdraw from this application?"
          cancelText="NO"
          confirmText="YES"
          confirm={() =>
            handleSendWithDraw(
              applicationData?.data?.id ? applicationData?.data?.id : NaN
            )
          }
          cancel={() => setIsWithDrawModal(false)}
          closeIcon={false}
          maskClosable={false}
        />
      )}
    </>
  );
};
