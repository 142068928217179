import { useNavigate } from 'react-router-dom';
import plus from '../../assets/plus.svg';
import { useState, useEffect } from 'react';
import { CustomInput } from '../../customFields/CustomInput';
import { CustomSelect } from '../../customFields/CustomSelect';
import searchIcon from '../../assets/searchIcon.svg';
import cancel from '../../assets/x-close.svg';
import { Spinner } from '../../components/Spinner/Spinner';
import { useAppSelector } from '../../hooks';
import { useLazyGetAdminsListQuery } from '../../api/admins';
import { AdminsTable } from '../../components/Tables/AdminsTable';
import { AdminItem, TableParamsAdmin } from '../../api/types/admins';
import { TableParams } from '../../api/types/partners';
import './index.scss';
import useDebounce from '../../hooks/useDebounce';

export interface idsI {
  id: number;
  key: { status_key: number };
}

export interface TableAdminsDataI {
  length?: number;
  id: number;
  company_name: string;
  city: string;
  state: string;
  phone: string;
  status: string;
  logo: string;
}

export const ManageAdmins = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<AdminItem[]>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [searchAdmins, setSearchAdmins] = useState('');
  const debouncedSearchMainAcc = useDebounce(searchAdmins);

  useEffect(() => {
    setTableParams((prev: TableParams | TableParamsAdmin) => ({
      ...prev,
      search: debouncedSearchMainAcc,
    }));
  }, [debouncedSearchMainAcc]);

  const [tableParams, setTableParams] = useState<
    TableParamsAdmin | TableParams
  >({
    per_page: 50,
    search: '',
    order_by: '',
    order: 'desc',
    status_key: '',
    page: 1,
  });
  const [getList, { data, isLoading }] = useLazyGetAdminsListQuery();
  const user = useAppSelector((state) => state.profile);

  useEffect(() => {
    if (!user.email) return;
    if (user.role.key !== 'super_admin') {
      navigate('/403');
    }
  }, [user.email]);

  useEffect(() => {
    setTableParams((prev: TableParamsAdmin) => ({ ...prev, page: 1 }));
  }, [tableParams.search, tableParams.status_key]);

  useEffect(() => {
    getList(tableParams);
  }, [tableParams, trigger]);

  useEffect(() => {
    if (!data?.data) return;

    setTableData(data?.data);
  }, [data?.data]);

  useEffect(() => {
    if (!user.email) return;
    if (user.role.key !== 'super_admin') {
      navigate('/403');
    }
  }, [user.email]);

  return (
    <div className="container">
      {isLoading && <Spinner size="large" />}
      <div className="members">
        <div className="members__row">
          <div className="members__title">Admins</div>

          <div className="members__add" onClick={() => navigate('/new-admin')}>
            <img src={plus} className="members__add-image" /> Add new
          </div>
        </div>

        <div className="members__table">
          <div className="members__filters" style={{ margin: '0 0 10px 0' }}>
            <CustomInput
              style={{ width: '350px', margin: '0 15px 0 0' }}
              className="input-white-small"
              name="Search"
              placeholder="Search by First/Last name, Contact number, Email or ID"
              value={searchAdmins}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchAdmins(e.target.value)
              }
              prefix={<img src={searchIcon} />}
              suffix={
                searchAdmins ? (
                  <img
                    className="cancel-btn"
                    src={cancel}
                    style={{ margin: '0 0 0 0' }}
                    onClick={() => setSearchAdmins('')}
                  />
                ) : (
                  <></>
                )
              }
            />
            <div className="members__select" style={{ width: '170px' }}>
              <CustomSelect
                allowClear
                options={[
                  { value: 'Active', label: '2' },
                  { value: 'Pending', label: '0' },
                ]}
                name="Status"
                className="input-white-small"
                placeholder="Show All"
                onChange={(value: any) =>
                  setTableParams((prev: TableParamsAdmin) => ({
                    ...prev,
                    status_key: value,
                  }))
                }
                suffixIcon={
                  tableParams.status_key ? (
                    <img
                      src={cancel}
                      onClick={(e) => {
                        e.stopPropagation();
                        setTableParams((prev: TableParamsAdmin) => ({
                          ...prev,
                          status_key: '',
                        }));
                      }}
                    />
                  ) : (
                    false
                  )
                }
              />
            </div>
            <div style={{ width: '22%' }}></div>
            <div style={{ width: '22%' }}></div>
          </div>
          <AdminsTable
            data={tableData}
            setTableParams={setTableParams}
            tableParams={tableParams}
            metaData={data}
            setTrigger={setTrigger}
          />
        </div>
      </div>
    </div>
  );
};
