import { Button, Modal } from 'antd';
import { FC, useState } from 'react';
import close from '../../../assets/x-close.svg';
import { CustomSelect } from '../../../customFields/CustomSelect';
import { LoanType } from '../../../api/types/lenders';
import './index.scss';
import { LoanProps } from './LoanTypeTab';

interface ModalI {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectLoanTypes: number[];
  setSelectLoanTypes: React.Dispatch<React.SetStateAction<number[]>>;
  loanTypes: any;
  setLoanData: any;
}

export const AddLoanModal: FC<ModalI> = ({
  openModal,
  setOpenModal,
  selectLoanTypes,
  setSelectLoanTypes,
  loanTypes,
  setLoanData,
}) => {
  const [data, setData] = useState();

  const addLoanTypes = () => {
    if (data) {
      setSelectLoanTypes((prev: number[]) => [...prev, +data]);

      const object: LoanProps = {
        loan_type_id: data,
        personal: true,
        commercial: true,
        secured: false,
        coverage_amount_max: '',
        coverage_amount_min: '',
        processing_fees: [],
        fico_ranges: [],
        id: data,
      };

      setLoanData((prev: any) => [...prev, object]);
      setOpenModal(false);
    }
  };

  return (
    <Modal
      destroyOnClose
      className={`add-item-modal`}
      centered
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button className="gray-btn" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
          <Button
            className="blue-btn"
            htmlType="submit"
            onClick={addLoanTypes}
            disabled={data ? false : true}
          >
            Add
          </Button>
        </div>
      }
      closeIcon={<img src={close} />}
      width={344}
    >
      <div className="fico-modal__wrapper">
        <div className="fico-modal__title">Add Loan Type</div>

        <div
          className="members__select"
          style={{ width: '100%', margin: '0 16px 0 0' }}
        >
          <CustomSelect
            allowClear={false}
            options={loanTypes?.data?.map((elem: LoanType) => {
              return { value: elem.name, label: elem.id };
            })}
            name="Loan type"
            className="input-white-small loantype"
            placeholder="Show All"
            onChange={(value: any) => setData(value)}
          />
        </div>
      </div>
    </Modal>
  );
};
