import { Button, Form, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import close from '../../../assets/x-close.svg';
import { CustomInput } from '../../../customFields/CustomInput';
import { yupSync } from '../../../utils';
import * as Yup from 'yup';
import './index.scss';
import { CustomSelect } from '../../../customFields/CustomSelect';
import { ficoProps } from './LoanTypeTab';
import { useLazyGetFicoQuery } from '../../../api/lenders';
import { AccountTypesItem, LoanProduct } from '../../../api/types/common';
import { ArrowIcon } from '../../../components/icons/ArrowIcon';
import arrow from '../../../assets/arrow.svg';
import { ConfirmationModal } from '../../../components/Modals/ConfirmationModal';

interface ModalI {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  editFee: FeesI | null;
  accountTypesData: AccountTypesItem[] | undefined;
  feeData: FeesI[];
  setFeesData: React.Dispatch<React.SetStateAction<FeesI[]>>;
  existedFees: string[];
}

export interface FeesI {
  fee: string;
  loan_products: number[];
  account_types: number[];
}

interface ErrorsI {
  fee: string;
  loan_products: string;
}

export const AddFeesModal: FC<ModalI> = ({
  openModal,
  setOpenModal,
  editFee,
  accountTypesData,
  feeData,
  setFeesData,
  existedFees,
}) => {
  const [data, setData] = useState<FeesI>({
    fee: '',
    loan_products: [],
    account_types: [],
  });
  const [openItem, setOpenItem] = useState<string | null>(null);
  const [errors, setErrors] = useState<ErrorsI>({
    fee: '',
    loan_products: '',
  });

  const [iDirty, setIsDirty] = useState(false);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  useEffect(() => {
    if (editFee) {
      setData(editFee);
    }
  }, [editFee]);

  function onBlurHandler(e: React.FocusEvent<HTMLInputElement>) {
    const inputValue = e.target.value;
    if (inputValue.endsWith('.')) {
      e.target.value = inputValue + '00';
    }
    if (inputValue.endsWith('.0')) {
      e.target.value = inputValue + '0';
    }

    let newValue = e.target.value;

    if (!e.target.value.includes('.')) {
      newValue = e.target.value + '.00';
    }

    if (existedFees?.includes(newValue)) {
      setErrors((prev: ErrorsI) => ({
        ...prev,
        fee: 'Discount fee has already been added',
      }));
    }
    setData((prev: FeesI) => ({
      ...prev,
      fee: e.target.value,
    }));
  }

  const currencyMaskFee = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setErrors((prev: ErrorsI) => ({
      ...prev,
      fee: '',
    }));
    let value = e.target.value;
    const number = value.replace(/,/g, '');
    if (+number > 20) {
      e.target.value = '20.00';
    } else {
      const parts = number.split('.');
      if (parts.length === 2 && parts[1].length > 2) {
        e.target.value = `${parts[0]}.${parts[1].substring(0, 2)}`;
      }
    }

    return e.target.value;
  };

  const openItemFunc = (name: string) => {
    if (openItem === name) {
      setOpenItem(null);
    } else {
      setOpenItem(name);
    }
  };

  const clickOnProduct = (id: number) => {
    setIsDirty(true);
    setErrors((prev: ErrorsI) => ({
      ...prev,
      loan_products: '',
    }));
    let array = [...data.loan_products];
    if (data.loan_products?.includes(id)) {
      array = array.filter((elem: number) => elem !== id);
    } else {
      array.push(id);
    }
    setData((prev: FeesI) => ({ ...prev, loan_products: array }));
  };

  useEffect(() => {
    let accountTypes: number[] = [];
    accountTypesData?.forEach((elem: any) => {
      const isExist = elem.loan_products.some((item: any) =>
        data.loan_products?.includes(item.id)
      );
      if (isExist) {
        accountTypes.push(elem.id);
      }
    });
    setData((prev: FeesI) => ({ ...prev, account_types: accountTypes }));
  }, [data.loan_products]);

  const addFee = () => {
    let isValid = true;
    if (!data.fee) {
      isValid = false;
      setErrors((prev: ErrorsI) => ({
        ...prev,
        fee: 'This field is required',
      }));
    }
    if (!data.loan_products.length) {
      isValid = false;

      setErrors((prev: ErrorsI) => ({
        ...prev,
        loan_products: 'Add at least one loan product to continue',
      }));
    }
    if (errors.fee || errors.loan_products) {
      isValid = false;
    }
    if (!isValid) return;

    if (editFee) {
      if (feeData) {
        const index = feeData.findIndex(
          (elem: FeesI) => elem.fee === editFee.fee
        );
        const newData: FeesI[] = [...feeData];
        if (index !== -1) {
          newData.splice(index, 1, data);
        }
        setFeesData(newData);
        setOpenModal(false);
      }
    } else {
      const newFees: FeesI[] = feeData;
      newFees.push(data);
      setFeesData(newFees);
      setOpenModal(false);
    }
  };

  return (
    <>
      <Modal
        destroyOnClose
        className={`add-item-modal add-fees-modal`}
        centered
        open={openModal}
        onCancel={() => {
          if (iDirty) {
            setOpenConfirmationModal(true);
          } else {
            setOpenModal(false);
          }
        }}
        footer={
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              className="gray-btn"
              onClick={() => {
                if (iDirty) {
                  setOpenConfirmationModal(true);
                } else {
                  setOpenModal(false);
                }
              }}
            >
              Cancel
            </Button>
            <Button className="blue-btn" htmlType="submit" onClick={addFee}>
              Save
            </Button>
          </div>
        }
        closeIcon={<img src={close} />}
        width={344}
      >
        <div className="add-item-modal__wrapper">
          <div className="add-item-modal__title">
            {editFee ? 'Edit' : 'Add'} Discount Fee
          </div>
          <div className="custom-field__title">Fee</div>
          <CustomInput
            placeholder="Indicate discount fee"
            className={`input-white-small ${errors.fee ? 'error' : ''}`}
            value={data.fee}
            onChange={(e) =>
              setData((prev: FeesI) => ({
                ...prev,
                fee: e.target.value,
              }))
            }
            onInput={currencyMaskFee}
            onBlur={onBlurHandler}
            maxLength={5}
            style={{ width: '200px' }}
          />
          <div className="error-text" style={{ margin: '-19px 0 0 0' }}>
            {errors.fee}
          </div>
          <div className="custom-field__title">Loan Products</div>
          <div className="add-item-modal__list list">
            {accountTypesData?.map((elem: any) => {
              return (
                <>
                  <div
                    className="list__item"
                    onClick={() => openItemFunc(elem.name)}
                  >
                    <div>{elem.name}</div>
                    <div>
                      <img
                        src={arrow}
                        className={`list__arrow ${
                          openItem === elem.name ? 'active' : ''
                        }`}
                      />
                    </div>
                  </div>

                  <div
                    className={`list__item-loan ${
                      openItem === elem.name ? 'active' : ''
                    }`}
                  >
                    {elem.loan_products.length ? (
                      <div className="list__item-container">
                        {elem.loan_products.map((item: any) => {
                          return (
                            <div
                              className={`list__item-product ${
                                data.loan_products?.includes(item.id)
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => clickOnProduct(item.id)}
                            >
                              {item.name}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="list__nodata">
                        No loan products left. You assigned them to other
                        discount fee values.
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
          <div className="error-text" style={{ margin: '0 0 0 0' }}>
            {errors.loan_products}
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        openModal={openConfirmationModal}
        setOpenModal={setOpenConfirmationModal}
        title={`Are you sure you want to discard the Discount Fee ${
          editFee ? 'Editing' : 'Adding'
        }?`}
        subtitle=""
        cancelText="No"
        confirmText="Yes"
        confirm={() => {
          setOpenModal(false);
        }}
        cancel={() => setOpenConfirmationModal(false)}
        closeIcon={false}
        maskClosable={false}
      />
    </>
  );
};
