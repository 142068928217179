import { Button, Modal } from 'antd';
import { FC } from 'react';
import attention from '../../assets/attention.svg';
import success from '../../assets/success.svg';
import close from '../../assets/x-close.svg';
import './index.scss';
import { Spinner } from '../Spinner/Spinner';

interface ModalI {
  openModal: boolean;
  isClose?: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  type?: string;
  title: string;
  subtitle?: string | React.ReactNode;
  cancelText: string;
  confirmText: string;
  cancel?: () => void;
  confirm?: () => void;
  closeIcon: boolean;
  maskClosable?: boolean;
  className?: string;
}

export const ConfirmationModal: FC<ModalI> = ({
  openModal,
  isClose,
  setOpenModal,
  type,
  title,
  subtitle,
  cancelText,
  confirmText,
  cancel,
  confirm,
  closeIcon,
  maskClosable = true,
  className,
}) => {
  return (
    <Modal
      destroyOnClose
      className={`confirmation-modal ${className}`}
      centered
      open={openModal}
      onCancel={() => !isClose ? setOpenModal(false) : {}}
      footer={<></>}
      closeIcon={closeIcon ? <img src={close}  alt="close"/> : <></>}
      width={344}
      maskClosable={maskClosable}
    >
      <div className="confirmation-modal__container">
        {type === 'waiting' && <Spinner size="medium" color="grey" />}
        {type === 'warning' && (
          <img src={attention} className="confirmation-modal__image"  alt="attention"/>
        )}
        {type === 'success' && (
          <img src={success} className="confirmation-modal__image"  alt="success"/>
        )}
        <div className="confirmation-modal__title">{title}</div>
        <div className="confirmation-modal__subtitle">{subtitle}</div>
        <div className="confirmation-modal__btns">
          {cancelText && (
            <Button className="gray-btn" onClick={cancel}>
              {cancelText}
            </Button>
          )}
          {confirmText && (
            <Button
              className={type === 'warning' ? 'red-btn' : 'blue-btn'}
              onClick={confirm}
            >
              {confirmText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
