import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
} from 'recharts';
import dayjs from 'dayjs';
import {
  formatNumberToCurrency,
  formatNumberToCurrencyWithoutDollar,
} from '../../pages/Dashboard/features';
import moment from 'moment';

interface dataI {
  data: { name: string; uv: number; pv: number }[];
  values: string[];
  breakdown: string;
  noCompApplied: boolean;
  type: string;
}

export const YourChartComponent: React.FC<dataI> = ({
  data,
  values,
  breakdown,
  noCompApplied,
  type,
}) => {
  const Week = (date: any) => {
    const [year, week] = date.split(' ');

    let first = moment()
      .isoWeekYear(Number(year))
      .isoWeek(Number(week))
      .startOf('isoWeek')
      .format('MM/DD/YYYY');
    let last = moment()
      .isoWeekYear(Number(year))
      .isoWeek(Number(week))
      .endOf('isoWeek')
      .format('MM/DD/YYYY');

    return `${first} - ${last}`;
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={
            breakdown === 'Weekly' || breakdown === 'Hourly'
              ? { width: '280px' }
              : {}
          }
        >
          <div className="custom-tooltip__title">{type}</div>

          <div className="custom-tooltip__row">
            <div className="custom-tooltip__graytext">
              <svg
                stroke-width="2"
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="9"
                viewBox="0 0 8 9"
                fill="none"
              >
                <circle cx="4" cy="4.5" r="4" fill="#1F44DD" />
              </svg>{' '}
              {breakdown === 'Hourly' &&
                dayjs(payload[0]?.payload?.CurDate)
                  .locale('en')
                  .format('MM DD YYYY hh:mm A')}
              {breakdown === 'Daily' &&
                dayjs(payload[0]?.payload?.CurDate)
                  .locale('en')
                  .format('DD MMMM YYYY')}
              {breakdown === 'Weekly' && Week(payload[0]?.payload?.CurDate)}
              {breakdown === 'Monthly' &&
                dayjs(payload[0]?.payload?.CurDate)
                  .locale('en')
                  .format('MMMM YYYY')}
            </div>
            <div className="custom-tooltip__blacktext">
              {formatNumberToCurrency(
                payload[0]?.payload.CurValue ? payload[0]?.payload.CurValue : 0
              )}
            </div>
          </div>

          {!noCompApplied && (
            <div className="custom-tooltip__row">
              <div className="custom-tooltip__graytext">
                <svg
                  stroke-width="2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="9"
                  viewBox="0 0 8 9"
                  fill="none"
                >
                  <circle cx="4" cy="4.5" r="4" fill="#9EACCE" />
                </svg>{' '}
                {breakdown === 'Hourly' &&
                  dayjs(payload[1]?.payload?.ComDate)
                    .locale('en')
                    .format('DD MMMM YYYY hh:mm A')}
                {breakdown === 'Daily' &&
                  dayjs(payload[1]?.payload?.ComDate)
                    .locale('en')
                    .format('DD MMMM YYYY')}
                {breakdown === 'Weekly' && Week(payload[0]?.payload?.ComDate)}
                {breakdown === 'Monthly' &&
                  dayjs(payload[1]?.payload?.ComDate)
                    .locale('en')
                    .format('MMMM YYYY')}
              </div>
              <div className="custom-tooltip__blacktext">
                {formatNumberToCurrency(
                  payload[0]?.payload.ComValue
                    ? payload[0]?.payload.ComValue
                    : 0
                )}
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 30,
            right: 0,
            left: 10,
            bottom: 50,
          }}
          //@ts-ignore
          xAxis={null}
        >
          <CartesianGrid stroke="#DBEAFE" horizontal={true} vertical={false} />

          <XAxis dataKey="CurData" />
          <YAxis
            tickCount={3}
            tick={(props: any) => (
              <text x={props.x} y={props.y} textAnchor="end" fill="#666666">
                {formatNumberToCurrencyWithoutDollar(props.payload.value)}
              </text>
            )}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line
            type="monotone"
            dataKey="ComValue"
            stroke="#9EACCE"
            activeDot={{ r: 5 }}
          />
          <Line type="monotone" dataKey="CurValue" stroke="#1F44DD" />
        </LineChart>
      </ResponsiveContainer>
      <div className="values">
        <div>{values[0]}</div>
        <div>{values[1]}</div>
      </div>
    </>
  );
};
