import { Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import arrow from '../../assets/arrow-down.svg';
import noData from '../../assets/noData.svg';
import NoUser from '../../assets/NoUser.svg';
import type { ColumnsType } from 'antd/es/table';
import { Pagination } from './Pagination';
import { useAppSelector } from '../../hooks';
import {
  TableParamsReports,
  TableItemReportAccountType,
  Totals,
} from '../../api/types/reports';
import './index.scss';

interface PartnersTableProps {
  data: TableItemReportAccountType[];
  setTableParams: React.Dispatch<React.SetStateAction<TableParamsReports>>;
  metaData: any;
  tableParams: TableParamsReports;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  isCard?: boolean;
  dataTotals?: Totals;
}

export const ReportsAccTypesTable = ({
  data,
  setTableParams,
  metaData,
  tableParams,
  dataTotals,
}: PartnersTableProps) => {
  const ref = useRef(null);
  const user = useAppSelector((state) => state.profile);

  const sorting = (name: string) => {
    setTableParams((prev: TableParamsReports) => ({
      ...prev,
      order_by: name,
      order:
        prev.order_by === name
          ? prev.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'asc',
    }));
  };

  const [tableData, setTableData] = useState<TableItemReportAccountType[]>([]);

  useEffect(() => {
    if (!data) return;
    const newTable: any = [...data];
    !!data.length && newTable.push(dataTotals);
    setTableData(newTable);
  }, [data, dataTotals]);

  const columns: ColumnsType<TableItemReportAccountType> = [
    {
      title: (
        <div className="header-item" onClick={() => sorting('account_type')}>
          Account type
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'account_type' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
           alt="arrow"/>
        </div>
      ),
      dataIndex: 'account_type',
      key: 'account_type',
      render: (text, _, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">{text}</div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('applications')}>
          Applications
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'applications' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
           alt="arrow"/>
        </div>
      ),
      width: 100,
      dataIndex: 'applications',
      key: 'applications',
      render: (text, _, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                {' '}
                {text
                  ? text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : '-'}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('no_offers')}>
          No Offer
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'no_offers' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
           alt="arrow"/>
        </div>
      ),
      width: 90,
      dataIndex: 'no_offers',
      key: 'no_offers',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                {' '}
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('offers')}>
          Offer Made
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'offers' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
           alt="arrow"/>
        </div>
      ),
      width: 90,
      dataIndex: 'offers',
      key: 'offers',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                {' '}
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('approved')}>
          Approved{' '}
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'approved' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
           alt="arrow"/>
        </div>
      ),
      width: 90,
      dataIndex: 'approved',
      key: 'approved',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                {' '}
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('a_r')}>
          A/R{' '}
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'a_r' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
           alt="arrow"/>
        </div>
      ),
      width: 80,
      dataIndex: 'a_r',
      key: 'a_r',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                {' '}
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('ala')}>
          ALA{' '}
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'ala' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
           alt="arrow"/>
        </div>
      ),
      width: 80,
      dataIndex: 'ala',
      key: 'ala',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                $ {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                $
                {Math.round(text)
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('loan_amount')}>
          Funding
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'loan_amount' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
           alt="arrow"/>
        </div>
      ),
      width: 80,
      dataIndex: 'loan_amount',
      key: 'loan_amount',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                $ {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                $
                {Math.round(text)
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('apf')}>
          APF{' '}
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'apf' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
           alt="arrow"/>
        </div>
      ),
      width: 80,
      dataIndex: 'apf',
      key: 'apf',
      render: (text, record, index) => (
        <>
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                {' '}
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('revenue')}>
          Revenue{' '}
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'revenue' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
           alt="arrow"/>
        </div>
      ),
      width: 90,
      dataIndex: 'revenue',
      key: 'revenue',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                ${text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                $
                {Math.round(text)
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },
  ];

  const customNoDataText = {
    emptyText: (
      <>
        {!tableParams.date_from && !tableParams.search && (
          <div className="no-data-found">
            <img src={NoUser}  alt="no-user"/>
            <div className="no-data-found__text">No reports yet</div>
          </div>
        )}
        {(tableParams.date_from || tableParams.search) && (
          <div className="no-data-found">
            <img src={noData}  alt="no-data"/>
            <div className="no-data-found__text">No results found</div>
            <div className="no-data-found__subtext">
              Try adjusting your search to find what you are looking for
            </div>
          </div>
        )}
      </>
    ),
  };

  if (user.role.key === 'main_account') {
    const roleColumnIndex = columns.findIndex(
      (column) => column.key === 'main_account'
    );
    if (roleColumnIndex !== -1) {
      columns.splice(roleColumnIndex, 1);
    }
  }

  return (
    <>
      <div>
        <Table
          ref={ref}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          locale={customNoDataText}
        />
      </div>

      {data?.length > 0 && (
        <Pagination
          setTableParams={setTableParams}
          metaData={metaData}
          tableParams={tableParams}
        />
      )}
    </>
  );
};
