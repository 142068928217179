import { SerializedError } from "@reduxjs/toolkit";
import { CustomErrorType } from "../types.ts/commonTypes";

export const isApiError = (
	error: CustomErrorType | SerializedError | undefined | unknown
): error is CustomErrorType => {
	if (!error) return false;
	if (error && typeof error === 'object') return true;
	return false;
};
