import { Divider, Segmented } from 'antd';
import { FC, useEffect, useState } from 'react';
import './index.scss';
import {
  useLazyGetNotifListQuery,
  useLazyGetNotifQuery,
  useMarkAllMutation,
} from '../../api/notif';
import { notifI } from '../../api/types/notif';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { TwoFAuthModal } from '../Modals/TwoFAuthModal/TwoFAuthModal';

interface Props {
  refNotif: any;
  openNotif: boolean;
  setOpenNotif: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setVerifySuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Notifications: FC<Props> = ({
  refNotif,
  openNotif,
  setOpenNotif,
  openModal,
  setOpenModal,
  setVerifySuccess,
}) => {
  const [type, setType] = useState<string | number>('Show unread');
  const [getList, { data }] = useLazyGetNotifListQuery();
  const [getNotif] = useLazyGetNotifQuery();
  const [markAll] = useMarkAllMutation();
  const [today, setToday] = useState<notifI[]>([]);
  const [yesterday, setYesterday] = useState<notifI[]>([]);
  const [later, setLater] = useState<notifI[]>([]);
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.profile);

  const MarkAllFunc = async () => {
    let prop = 0;
    if (type === 'Show read') {
      prop = 1;
    }
    const data: any = {};
    if (type === 'Show read') {
      data.unread = 1;
    }
    try {
      await markAll(data).unwrap();
      await getList(prop);
    } catch {}
  };

  useEffect(() => {
    const categorizeNotifications = () => {
      const current = new Date().toDateString();
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const yesterdayString = yesterday.toDateString();

      const todayNotifications: notifI[] = [];
      const yesterdayNotifications: notifI[] = [];
      const laterNotifications: notifI[] = [];

      data?.data.forEach((notification) => {
        const notificationDate = new Date(
          notification.created_at
        ).toDateString();
        if (notificationDate === current) {
          todayNotifications.push(notification);
        } else if (notificationDate === yesterdayString) {
          yesterdayNotifications.push(notification);
        } else {
          laterNotifications.push(notification);
        }
      });

      setToday(todayNotifications);
      setYesterday(yesterdayNotifications);
      setLater(laterNotifications);
    };

    categorizeNotifications();
  }, [data?.data]);

  const getNotifFunc = async (id: string) => {
    let prop = 0;
    if (type === 'Show read') {
      prop = 1;
    }
    const data: any = {
      id: id,
    };
    if (type === 'Show read') {
      data.data = { unread: 1 };
    } else {
      data.data = {};
    }

    try {
      await getNotif(data);
      await getList(prop);
    } catch {}
  };

  useEffect(() => {
    let prop = 0;
    if (type === 'Show read') {
      prop = 1;
    }
    getList(prop);
  }, [type]);

  const navigateTo = (role: string, id: number) => {
    if (role === 'applicant') {
      navigate(`/applications/edit/${id}`);
    } else {
      navigate(`/${role}s/${id}`);
    }
    setOpenNotif(false);
  };

  return (
    <div ref={refNotif}>
      <div className={`notif__container ${openNotif ? 'open' : ''}`}>
        <div className="notif__rhombus"></div>
        <div className="notif__main">
          <div className="notif__header">
            <div className="notif__title">Notifications</div>
            <div className="notif__mark">
              <Segmented
                options={['Show unread', 'Show read']}
                value={type}
                onChange={setType}
              />
            </div>
          </div>
          {user.need_bill_2fa && (
            <div className="notif__bill">
              <div className="notif__bill-text">
                Your transactions have been suspended. 2FA for Bill.com is
                required.
              </div>
              <div
                className="notif__bill-btn violet-btn"
                onClick={() => setOpenModal(true)}
              >
                Pass 2FA
              </div>
            </div>
          )}
          <div className="notif__list">
            {!today.length && !yesterday.length && !later.length && (
              <div>No data</div>
            )}
            {today.length ? (
              <div>
                <div className="notif__subtitle">Today</div>
                {today.map((elem: notifI) => {
                  return (
                    <div
                      className="notif__item"
                      onClick={() =>
                        navigateTo(elem.data.item_role, elem.data.item_id)
                      }
                    >
                      <div className="notif__wrapper">
                        <div className="notif__text">{elem.data.text}</div>
                        <div className="notif__time">
                          {moment(elem.created_at).format('hh:MM A')}
                        </div>
                      </div>
                      <div
                        className="notif__btn-wrapper"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.stopPropagation();
                          getNotifFunc(elem.id);
                        }}
                      >
                        {type === 'Show unread' ? (
                          <div className="notif__btn"></div>
                        ) : (
                          <div className="notif__btn-read"></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
            {yesterday.length ? (
              <div>
                <div className="notif__subtitle">Yesterday</div>
                {yesterday.map((elem: notifI) => {
                  return (
                    <div
                      className="notif__item"
                      onClick={() =>
                        navigateTo(elem.data.item_role, elem.data.item_id)
                      }
                    >
                      <div className="notif__wrapper">
                        <div className="notif__text">{elem.data.text}</div>
                        <div className="notif__time">
                          {moment(elem.created_at).format('hh:MM A')}
                        </div>
                      </div>
                      <div
                        className="notif__btn-wrapper"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.stopPropagation();
                          getNotifFunc(elem.id);
                        }}
                      >
                        {type === 'Show unread' ? (
                          <div className="notif__btn"></div>
                        ) : (
                          <div className="notif__btn-read"></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
            {later.length ? (
              <div>
                <div className="notif__subtitle">Later</div>
                {later.map((elem: notifI) => {
                  return (
                    <div
                      className="notif__item"
                      onClick={() =>
                        navigateTo(elem.data.item_role, elem.data.item_id)
                      }
                    >
                      <div className="notif__wrapper">
                        <div className="notif__text">{elem.data.text}</div>
                        <div className="notif__time">
                          {moment(elem.created_at).format('hh:MM A')}
                        </div>
                      </div>
                      <div
                        className="notif__btn-wrapper"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.stopPropagation();
                          getNotifFunc(elem.id);
                        }}
                      >
                        {type === 'Show unread' ? (
                          <div className="notif__btn"></div>
                        ) : (
                          <div className="notif__btn-read"></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>

          {!today.length && !yesterday.length && !later.length ? (
            <></>
          ) : (
            <>
              {' '}
              <Divider />
              <div className="notif__mark-btn" onClick={() => MarkAllFunc()}>
                {type === 'Show unread'
                  ? 'Mark all as read'
                  : 'Mark all as unread'}
              </div>
            </>
          )}
        </div>
      </div>
      <TwoFAuthModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setVerifySuccess={setVerifySuccess}
      />
    </div>
  );
};
