import { Select } from 'antd';
import arrowPagination from '../../assets/arrow.svg';
import { TableParams, TableResponse } from '../../api/types/partners';
import { FC } from 'react';
import arrow from '../../assets/arrow.svg';
import { TableParamsAdmin } from '../../api/types/admins';

interface PaginationParams {
  setTableParams: any;
  metaData: any;
  tableParams: any;
}

export const Pagination: FC<PaginationParams> = ({
  setTableParams,
  metaData,
  tableParams,
}) => {
  return (
    <div className="pagination">
      <div className="pagination__block">
        <Select
          suffixIcon={<img src={arrow} />}
          defaultValue={50}
          className="pagination__select"
          options={[
            { value: 10, label: 10 },
            { value: 20, label: 20 },
            { value: 50, label: 50 },
            { value: 'all', label: 'All' },
          ]}
          onChange={(e: number) => {
            setTableParams((prev: TableParams) => ({
              ...prev,
              per_page: e,
              page: 1,
            }));
          }}
        />
        <div className="pagination__text">Items per page</div>
        <div className="pagination__text">
          {metaData?.meta?.total} {metaData?.meta?.total && 'items'}
        </div>
      </div>
      {tableParams.per_page !== 'all' && (
        <div className="pagination__block">
          <div
            className="pagination__btn"
            onClick={() => {
              setTableParams((prev: TableParams) => ({
                ...prev,
                page: 1,
              }));
            }}
          >
            First
          </div>
          <div
            onClick={() => {
              if (tableParams.page === 1) {
                return;
              } else {
                setTableParams((prev: TableParams) => ({
                  ...prev,
                  page: tableParams.page - 1,
                }));
              }
            }}
          >
            <img
              src={arrowPagination}
              className={`pagination__arrow-left ${
                tableParams.page === 1 ? 'disable' : ''
              }`}
            />
          </div>
          <div style={{ fontSize: '12px' }}>
            Page {metaData?.meta?.current_page} of {metaData?.meta?.last_page}
          </div>
          <div
            onClick={() => {
              if (tableParams.page === metaData?.meta?.last_page) {
                return;
              } else {
                setTableParams((prev: TableParams) => ({
                  ...prev,
                  page: tableParams.page + 1,
                }));
              }
            }}
          >
            <img
              src={arrowPagination}
              className={`pagination__arrow-right ${
                tableParams.page === metaData?.meta?.last_page ? 'disable' : ''
              }`}
            />
          </div>
          <div
            className="pagination__btn"
            onClick={() => {
              setTableParams((prev: TableParams) => ({
                ...prev,
                page: metaData?.meta?.last_page || 1,
              }));
            }}
          >
            Last
          </div>
        </div>
      )}
    </div>
  );
};
