import { Table, Tooltip, Dropdown, Button } from 'antd';
import { useState, useRef, useEffect, SetStateAction } from 'react';
import Property from '../../assets/Property.svg';
import PropertyHover from '../../assets/PropertyHover.svg';
import FeeIcon from '../../assets/FeeIcon.svg';
import trash from '../../assets/TrashBinMinimalistic.svg';
import plus from '../../assets/plusicon.svg';

import pen from '../../assets/Pen.svg';
import alert from '../../assets/alert-circle.svg';
import pause from '../../assets/Stopwatch Pause.svg';
import activate from '../../assets/activate.svg';
import type { ColumnsType } from 'antd/es/table';
import { useClickOutside } from '../../hooks/useClickOutSide';
import { ActivateIcon } from '../icons/ActivateIcon';
import './index.scss';

import {
  AccountTypesItem,
  BankTableItem,
  LoanProduct,
} from '../../api/types/common';
import { ConfirmationModal } from '../Modals/ConfirmationModal';
import { FeesI } from '../../pages/Lenders/EditLenders/AddFeesModal';

interface DiscountTableProps {
  fees: FeesI[] | undefined;
  setOpenFeesModal?: React.Dispatch<React.SetStateAction<boolean>>;
  accountTypesData: AccountTypesItem[] | undefined;
  error: boolean;
  setEditFee?: React.Dispatch<React.SetStateAction<FeesI | null>>;
  setFeesData?: React.Dispatch<React.SetStateAction<FeesI[] | null | any>>;
}

export const DiscountFeeTable = ({
  fees,
  setOpenFeesModal,
  accountTypesData,
  error,
  setEditFee,
  setFeesData,
}: DiscountTableProps) => {
  const ref = useRef(null);
  const [openActionId, setOpenActionId] = useState<number | null>(null);
  useClickOutside(ref, () => setOpenActionId(null));
  const [loanArray, setLoanArray] = useState<LoanProduct[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const deleteFunc = (index: number) => {
    if (!fees) return;
    const newData = [...fees];
    newData.splice(index, 1);
    setFeesData && setFeesData(newData);
  };

  useEffect(() => {
    if (!accountTypesData) return;
    let data: LoanProduct[] = [];
    accountTypesData.forEach((item: AccountTypesItem) =>
      item.loan_products.forEach((elem: any) => data.push(elem))
    );
    setLoanArray(data);
  }, [accountTypesData]);

  const columns: ColumnsType<FeesI> = [
    {
      title: <div className="header-item">Fee</div>,
      dataIndex: 'fee',
      key: 'fee',
      render: (text, record) => (
        <div className={`table-item`}>
          <Tooltip title={text + '%'}>
            <div className="table-text">{text}%</div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: <div className="header-item">Loan Products</div>,
      dataIndex: 'loan_products',
      key: 'loan_products',
      render: (text, record, index) => {
        const items = [
          {
            key: '2',
            label: <span className="table-dropdown-text">Edit</span>,
            icon: <img src={pen} alt="edit" />,
          },
          {
            key: '3',
            label: <span className="table-dropdown-text">Delete</span>,
            icon: <img src={trash} alt="delete" />,
          },
        ];

        const hoveredItem = openActionId === index ? true : false;

        const handleDropdownClick = (key: string) => {
          if (key === '2' && setEditFee && setOpenFeesModal) {
            setEditFee(record);
            setOpenFeesModal(true);
          } else if (key === '3') {
            setDeleteModal(true);
            setDeleteIndex(index);
          }
        };

        return (
          <div className={`table-item table-status`}>
            <Tooltip
              title={text
                .map(
                  (elem: number) =>
                    loanArray.find((item: LoanProduct) => item.id === elem)
                      ?.name
                )
                .join(', ')}
            >
              <div className="table-text">
                {text
                  .map(
                    (elem: number) =>
                      loanArray.find((item: LoanProduct) => item.id === elem)
                        ?.name
                  )
                  .join(', ')}
              </div>
            </Tooltip>
            {setEditFee && (
              <Dropdown
                placement="bottomRight"
                menu={{
                  items,
                  onClick: (info) => handleDropdownClick(info.key),
                }}
                onOpenChange={(open) => {
                  open && setOpenActionId(index);
                  !open && setOpenActionId(null);
                }}
              >
                <img
                  src={hoveredItem ? PropertyHover : Property}
                  style={{ cursor: 'pointer', margin: '0 0 0 15px' }}
                  alt="dots"
                />
              </Dropdown>
            )}
          </div>
        );
      },
    },
  ];

  const customNoDataText = {
    emptyText: (
      <div className="no-data-found">
        <div>
          <img src={FeeIcon} alt="bank" />
        </div>
        <div className="no-data-found__text">No Discount Fees </div>
        {setEditFee && (
          <div
            className="no-data-found__subtext"
            style={{ margin: '0 0 15px 0' }}
          >
            Please add them to the table
          </div>
        )}
        {setEditFee && (
          <Button
            className="blue-btn"
            onClick={() => {
              setEditFee && setEditFee(null);
              setOpenFeesModal && setOpenFeesModal(true);
            }}
          >
            + Add
          </Button>
        )}
      </div>
    ),
  };

  return (
    <>
      <div
        className={`${false && 'no-pointer'} ${
          error ? 'DiscountError' : ''
        } DiscountFeeTable`}
      >
        <div className="custom-field__title" style={{ padding: '0 0 15px 0' }}>
          Discount fee
        </div>
        <Table
          ref={ref}
          columns={columns}
          dataSource={fees}
          pagination={false}
          locale={customNoDataText}
        />
        {setFeesData && fees?.length ? (
          <div className="fico__addrow">
            <div
              onClick={() => {
                setEditFee && setEditFee(null);
                setOpenFeesModal && setOpenFeesModal(true);
              }}
              style={{ display: 'flex' }}
            >
              <img src={plus} /> Add Row
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {deleteModal && (
        <ConfirmationModal
          openModal={deleteModal}
          setOpenModal={setDeleteModal}
          title="Are you sure you want to delete the Discount Fee record?"
          cancelText={'No'}
          confirmText={'Yes'}
          closeIcon={false}
          cancel={() => setDeleteModal(false)}
          confirm={() => {
            if (deleteIndex || deleteIndex === 0) {
              deleteFunc(deleteIndex);
              setDeleteModal(false);
              setDeleteIndex(null);
            }
          }}
        />
      )}
    </>
  );
};
