import { InputProps, Input } from 'antd';
import Alert from '../assets/alert-circle.svg';
import './index.scss';

interface FloatInputProps extends InputProps {
  name?: string;
  error?: string;
  onBlur?: (e: any) => void;
  maxLength?: number;
  validation?: string;
  prefix?: boolean | React.ReactNode;
  suffix?: boolean | React.ReactNode;
  suffixIcon?: React.ReactNode;
  onFocus?: () => void;
  handlePaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  hasError?: () => boolean;
  required?: boolean;
}

export const CustomInput = (props: FloatInputProps) => {
  let {
    placeholder,
    className,
    disabled,
    maxLength,
    name,
    error,
    onBlur,
    validation = '',
    onKeyPress,
    prefix,
    suffix,
    suffixIcon,
    onFocus,
    handlePaste,
    hasError,
    required,
    ...restProps
  } = props;

  const handleKeyPressNoNumbers = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode === 32 ||
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122) ||
      [
        '-',
        '`',
        '!',
        '№',
        '%',
        ';',
        ':',
        '?',
        '*',
        '(',
        ')',
        ',',
        '.',
        '/',
        '|',
      ].includes(String.fromCharCode(charCode))
    ) {
    } else {
      e.preventDefault();
    }
  };

  const handleKeyPressNumbersOnly = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const charCode = e.which || e.keyCode;
    if (charCode >= 48 && charCode <= 57) {
    } else {
      e.preventDefault();
    }
  };

  return (
    <div className="custom-field">
      <div className="custom-field__title">
        {name}
        {required && (
          <span className="error-text" style={{ margin: '0 0 0 3px' }}>
            *
          </span>
        )}
      </div>
      {props.type === 'password' ? (
        <Input.Password
          {...restProps}
          className={`${className} ${error ? 'error' : ''} `}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      ) : (
        <Input
          autoComplete="off"
          onFocus={onFocus}
          onPaste={handlePaste}
          prefix={prefix ? prefix : false}
          suffix={suffix ? suffix : false}
          {...restProps}
          className={`${className} ${disabled ? 'disable' : ''} ${
            error ? 'error' : ''
          } ${hasError && hasError() ? 'error' : ''} input`}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={onBlur}
          maxLength={maxLength}
          onKeyPress={
            validation === 'noNumbers'
              ? handleKeyPressNoNumbers
              : validation === 'onlyNumbers'
              ? handleKeyPressNumbersOnly
              : onKeyPress
          }
        />
      )}
      {hasError && hasError() ? (
        <div className="error-text">This field is required</div>
      ) : (
        ''
      )}
      {error && props.type !== 'password' && (
        <img src={Alert} className="alert" />
      )}
    </div>
  );
};
