import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./general";

import {
  CustomersTableResponse,
  GetApplicationHistoryReq,
  GetApplicationHistoryRes,
  GetCustomerByIdRes,
  TableParamsCustomer,
} from "./types/customers";

export const customersApi = createApi({
  reducerPath: "customersApi",
  baseQuery,
  endpoints: (builder) => ({
    getCustomersList: builder.query<
      CustomersTableResponse,
      TableParamsCustomer
    >({
      query: (params) => ({
        url: "/customers/",
        method: "GET",
        params: params,
      }),
    }),
    getCustomerById: builder.query<GetCustomerByIdRes, number>({
      query: (id) => ({
        url: `/customers/${id}`,
        method: "GET",
      }),
    }),
    getCustomerConcoraBalance: builder.query<any, number>({
      query: (id) => ({
        url: `/customers/${id}/banks/genesis`,
        method: "GET",
      }),
    }),
    newCustomerPurchase: builder.mutation<any, any>({
      query: (data) => ({
        url: `/customers/${data.id}/banks/genesis`,
        method: "POST",
        body: data.payload
      }),
    }),
    getApplicationHistory: builder.query<GetApplicationHistoryRes, GetApplicationHistoryReq>({
      query: (params) => ({
        url: `/customers/${params.id}/applicants`,
        method: "GET",
        params: params.params,
      }),
    }),

    // deleteAdmin: builder.mutation<number, string>({
    //   query: (id) => ({
    //     url: `/admins/${id}`,
    //     method: 'DELETE',
    //   }),
    // }),
    // resendInvitation: builder.mutation<number, string>({
    //   query: (id) => ({
    //     url: `/admins/${id}/invitation`,
    //     method: 'PUT',
    //   }),
    // }),
    // createAdmin: builder.mutation({
    //   query: (arg) => ({
    //     url: '/admins',
    //     method: 'POST',
    //     body: arg,
    //   }),
    // }),
    // getAdmin: builder.query<AdminResponse, number | string>({
    //   query: (id) => ({
    //     url: `/admins/${id}`,
    //     method: 'GET',
    //   }),
    // }),
    // editAdmin: builder.mutation({
    //   query: (data) => ({
    //     url: `/admins/${data.id}`,
    //     method: 'PUT',
    //     body: data.data,
    //   }),
    // }),
  }),
});

export const {
  useLazyGetCustomersListQuery,
  useLazyGetCustomerByIdQuery,
  useLazyGetApplicationHistoryQuery,
    useLazyGetCustomerConcoraBalanceQuery,
    useNewCustomerPurchaseMutation,
} = customersApi;
