import { iconI } from './types';

export const MainAccountIcon = ({ fill, style }: iconI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 24 24"
      fill="none"
      style={style}
    >
      <g clipPath="url(#clip0_2216_1922)">
        <path
          d="M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5036 1.19293C19.9469 0.397644 20.1686 0 20.5 0C20.8314 0 21.0531 0.397644 21.4964 1.19293L21.6111 1.39868C21.7371 1.62468 21.8001 1.73768 21.8983 1.81223C21.9965 1.88679 22.1188 1.91447 22.3634 1.96982L22.5862 2.02021C23.447 2.21499 23.8775 2.31239 23.9799 2.64171C24.0823 2.97103 23.7889 3.31417 23.202 4.00047L23.0501 4.17803C22.8834 4.37305 22.8 4.47056 22.7624 4.5912C22.7249 4.71184 22.7375 4.84194 22.7628 5.10214L22.7857 5.33904C22.8744 6.25471 22.9188 6.71255 22.6507 6.91608C22.3826 7.11961 21.9796 6.93404 21.1735 6.56291L20.965 6.46689C20.7359 6.36143 20.6214 6.3087 20.5 6.3087C20.3786 6.3087 20.2641 6.36143 20.035 6.46689L19.8265 6.56291C19.0204 6.93404 18.6174 7.11961 18.3493 6.91608C18.0812 6.71255 18.1256 6.25471 18.2143 5.33904L18.2372 5.10214C18.2625 4.84194 18.2751 4.71184 18.2376 4.5912C18.2 4.47056 18.1166 4.37305 17.9499 4.17803L17.798 4.00047C17.2111 3.31417 16.9177 2.97103 17.0201 2.64171C17.1225 2.31239 17.5529 2.21499 18.4138 2.02021L18.6366 1.96982C18.8812 1.91447 19.0035 1.88679 19.1017 1.81223C19.1999 1.73768 19.2629 1.62468 19.3889 1.39868L19.5036 1.19293Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2216_1922">
          <rect width="24" height="24" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};
