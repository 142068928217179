import './index.scss'
import noData from '../../../assets/NoLenders.svg'
import React, {useEffect, useState} from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {useEditLenderFunnelMutation} from "../../../api/lendersFunnel";
import {DragDropIcon} from "../../icons/Drag&DropIcon";
interface TableRow {
    bank_id: number;
    bank_name: string;
    bank_secured: number;
    coverage_amount_min: number;
    coverage_amount_max: number;
    order: number;
}

interface LendersFunnelTableI {
    banks: any
    id: string
    model_id: string
    model_type: string
    personal_commercial:string
    loan_type_id: string
}
export const LendersFunnelTable = ({banks = [], id, loan_type_id, personal_commercial, model_type, model_id}:LendersFunnelTableI) => {
    const TableHeader = () => (
        <div className={`lenders-funnel-table-header ${banks.length>6 && 'scroll'}`}>
            <div className="lenders-funnel-table-header__column">Lenders</div>
            <div className="lenders-funnel-table-header__column">S/U</div>
            <div className="lenders-funnel-table-header__column">Coverage Amount</div>
        </div>
    );
    const [editLendersFunnel] = useEditLenderFunnelMutation()
    const [data, setData] = useState<TableRow[]>([]);
    useEffect(() => {
        !!banks && !!banks.length &&
        setData([...banks].sort((a:any, b:any) => a.order - b.order))
        !!banks && !banks.length && setData([])
    },[banks])

    const onDragEnd = (result: DropResult) => {
        const { destination, source } = result;

        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        const newData = [...data];
        const [movedItem] = newData.splice(source.index, 1);
        newData.splice(destination.index, 0, movedItem);
        const newDataOrdered = newData.map((row, index) => ({...row, order: index + 1}))
        setData(newDataOrdered);
        editLendersFunnel({params:
                {loan_type_id,
                    personal_commercial
                }, data: {
                model_type,
                model_id,
                id,
                banks: newDataOrdered.map(bank => ({
                    bank_id: bank.bank_id,
                    order: bank.order
                }))
            }})
    };
    if (data.length === 0) {
        return <div>
            <TableHeader />
            <div className="lenders-funnel-table-container">
                <div className="no-landers">
                    <img src={noData} alt="no data"/>
                    <div className="no-landers__title">No lenders here</div>
                    <div className="no-landers__text">Please edit this profile to add lenders</div>
                </div>
            </div>
        </div>
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="table" direction="vertical">
                {(provided) => (
                    <>
                        <TableHeader />
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="lenders-funnel-table-container"
                    >
                        {data.map((row, index) => (
                            <Draggable key={row.bank_id} draggableId={row.bank_id.toString()} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        className={`lenders-funnel-table-row ${banks.length>6 && 'scroll'}`}
                                        style={{
                                            backgroundColor: snapshot.isDragging ? '#F4FBFF' : 'white',
                                            // borderBottom: snapshot.draggingOver ? '1px solid blue' : 'none',
                                            ...provided.draggableProps.style,
                                        }}
                                    >
                                        <div className="lenders-funnel-table-row__item">{row.bank_name}</div>
                                        <div className="lenders-funnel-table-row__item">
                                            {!!row.bank_secured ? "Secured" : "Unsecured"}</div>
                                        <div className="lenders-funnel-table-row__item">
                                            <span>
                                                $ {row.coverage_amount_min} - $ {row.coverage_amount_max}
                                            </span>
                                            <DragDropIcon fill={snapshot.isDragging ? 'blue' : '#808086'}/>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                    </>
                )}
            </Droppable>
        </DragDropContext>
    );
};