import { Button, DatePicker, Form, message } from 'antd';
import { useEffect, useState } from 'react';
import { unstable_usePrompt, useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../components/icons/HomeIcon';
import { ArrowIcon } from '../../../components/icons/ArrowIcon';
import * as Yup from 'yup';
import { ConfirmationModal } from '../../../components/Modals/ConfirmationModal';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { yupSync } from '../../../utils';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { CustomInput } from '../../../customFields/CustomInput';
import { usePreventSpaceTyping } from '../../../hooks/usePreventSpaceTyping';
import { PhoneNumberInput } from '../../../customFields/PhoneNumberInput';
import { CustomSelect } from '../../../customFields/CustomSelect';
import {
  setIsDirtyAction,
  setMessage,
} from '../../../reducers/common/commonSlice';
import { SelectOption } from '../../../types.ts/commonTypes';
import { useGetStatesQuery } from '../../../api/partners';
import useDebounce from '../../../hooks/useDebounce';
import { LoanProducts, StatesItem } from '../../../api/types/partners';
import checkcircle from '../../../assets/check-circle.svg';
import {
  useCheckRoutingNumberMutation,
  useCheckVinCodeMutation,
  useCreateApplicationMutation,
  useEditApplicantsMutation,
  useRemoveApplicantMutation,
  useSendVarificationCodeMutation,
} from '../../../api/applicants';
import Timer from './Times';
import moment, { Moment } from 'moment';
import { CodeModal } from './codeModal';
import { ApplicantI, varificationBody } from '../../../api/types/applicants';
import { Offers } from '../Offers';
import {
  convertStringToFloat,
  formatFloatToString,
} from '../../../utils/formatAmountNumber';
import { isApiError } from '../../../utils/general';
import SomeFunctionalComponent from '../../../customFields/GoogleMpasAutocomplete/sameComponent';
import { VinCodeBadge } from '../../../components/VinCodeBadge/VinCodeBadge';
import { Spinner } from '../../../components/Spinner/Spinner';

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  document_number: Yup.string().required('This field is required'),
  vin: Yup.string()
    .required('This field is required')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  vinConfirm: Yup.string()
    .required('This field is required')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  last_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  date_of_birth: Yup.date()
    .required('This field is required')
    .test(
      'is-at-least-18',
      'Please, enter a valid birth date',
      function (value) {
        const eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
        return value <= eighteenYearsAgo;
      }
    ),
  document_issuence_date: Yup.date().required('This field is required'),
  document_expiration_date: Yup.date().required('This field is required'),
  document_issuence_state: Yup.string().required('This field is required'),
  street_address: Yup.string()
    .required('This field is required')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  city: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  zip_code: Yup.string()
    .required('This field is required')
    .matches(/^[^_]*$/, 'This field is required'),
  state_id: Yup.string().required('This field is required'),
  property_status: Yup.string().required('This field is required'),
  phone: Yup.string()
    .required('This field is required')
    .matches(/^[^_]*$/, 'This field is required'),
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email is invalid')
    .required('This field is required'),
  ssn: Yup.string()
    .required('This field is required')
    .matches(/^[^_]*$/, 'This field is required'),
  employment_status: Yup.string().required('This field is required'),
  annual_income: Yup.string().required('This field is required'),
  financing_amount: Yup.string().required('This field is required'),
  confirm_financing_amount: Yup.string().required('This field is required'),
  application_status: Yup.string().required('This field is required'),
  loan_product_id: Yup.string().required('This field is required'),
  company_name: Yup.string()
    .required('This field is required')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  transaction_status: Yup.string()
    .required('This field is required')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  pay_frequency: Yup.string().required('This field is required'),
});

export interface FormData {
  public_id?: number;
  id?: number;
  first_name: string | undefined;
  last_name: string | undefined;
  date_of_birth: Moment | string | undefined;
  street_address: string | undefined;
  city: string | undefined;
  zip_code: string | undefined;
  state_id: string | number | undefined;
  property_status: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  ssn: string | undefined;
  employment_status: string | undefined;
  annual_income: string | number | undefined;
  confirm_financing_amount: string | number | undefined;
  financing_amount: string | number | undefined;
  application_status: string | undefined;
  company_name: string | undefined;
  loan_product_id: string | undefined;
  transaction_status: string | undefined;
  vin: null | string;
  vinConfirm: null | string;
  document_number: string | undefined;
  document_issuence_date: string | undefined | Moment;
  document_issuence_state: string | undefined;
  document_expiration_date: string | undefined | Moment;
  accounting_number?: string | undefined;
  confirm_accounting_number?: string | undefined;
  routing_number?: string | undefined;
  pay_frequency?: string | undefined;
  first_pay_date?: string | undefined;
  second_pay_date?: string | undefined;
  last_pay_date?: any;
}

interface CreateApplicationsProps {
  ApplicationData?: ApplicantI;
}

const initialValues = {
  first_name: undefined,
  last_name: undefined,
  date_of_birth: undefined,
  last_pay_date: undefined,
  street_address: undefined,
  city: undefined,
  zip_code: undefined,
  state_id: undefined,
  property_status: 'Rent',
  phone: undefined,
  email: undefined,
  ssn: undefined,
  employment_status: undefined,
  annual_income: undefined,
  confirm_financing_amount: undefined,
  financing_amount: undefined,
  application_status: undefined,
  company_name: undefined,
  loan_product_id: undefined,
  transaction_status: undefined,
  accounting_number: undefined,
  confirm_accounting_number: undefined,
  routing_number: undefined,
  document_number: undefined,
  document_issuence_date: undefined,
  document_issuence_state: undefined,
  document_expiration_date: undefined,
  pay_frequency: undefined,
};

export const CreateApplications = ({
  ApplicationData,
}: CreateApplicationsProps) => {
  const [
    varificationPhone,
    { isSuccess: isSuccessPhone, isError: isErrorPhone },
  ] = useSendVarificationCodeMutation();
  const [
    varificationEmail,
    { isSuccess: isSuccessEmail, isError: isErrorEmail },
  ] = useSendVarificationCodeMutation();
  const [
    createApplication,
    { data, isSuccess, isError: isErrorCreateApplication },
  ] = useCreateApplicationMutation();
  const [
    editApplicants,
    { data: editApplicantsData, isSuccess: isEditApplicantsSuccess },
  ] = useEditApplicantsMutation();
  const [removeApplicant] = useRemoveApplicantMutation();
  const [
    checkVinCode,
    {
      data: vinResponse,
      isError: vinCheckError,
      isSuccess: vinCheckSuccess,
      isLoading: vinCheckLoading,
      reset: vinCheckReset,
    },
  ] = useCheckVinCodeMutation();
  const [
    checkRoutingNumber,
    {
      data: routingData,
      isLoading,
      isSuccess: isSuccessRouting,
      isError,
      reset,
    },
  ] = useCheckRoutingNumberMutation();

  const [searchState, setSearchState] = useState('');
  const debouncedSearchState = useDebounce(searchState);
  const { data: statesData } = useGetStatesQuery({
    name: debouncedSearchState,
  });

  const [form] = Form.useForm<FormData>();

  const { handleKeyPress } = usePreventSpaceTyping();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.profile);
  const location = useLocation();
  const dispatch = useAppDispatch();

  //states for modal
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [confirmationSaveDraftModal, setConfirmationSaveDraftModal] =
    useState(false);
  const [confirmationRemoveModal, setConfirmationRemoveModal] = useState(false);

  const [isClickOnHome, setIsClickOnHome] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState(false);

  const [id, setId] = useState<null | number>(null);
  const [applicantId, setApplicantId] = useState<null | number>(null);
  const [productOption, setProductOption] = useState<SelectOption[]>([]);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [ContractNumber, setContractNumber] = useState<string>('');

  //state for varification and timer
  const [varificationError, setVarificationError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [loan, setLoan] = useState<string | number | undefined>();
  const [isVinCodes, setIsVinCodes] = useState(false);
  const [needCheckVin, setNeedCheckVin] = useState(false);
  const [vinValue, setVinValue] = useState('');
  const [acceptAnyway, setAcceptAnyway] = useState(false);

  //state for varification and timer
  const [timePhone, setTimePhone] = useState(30);
  const [timeEmail, setTimeEmail] = useState(30);
  const [countPhoneRequest, setCountPhoneRequest] = useState(0);
  const [countEmailRequest, setCountEmailRequest] = useState(0);
  const [isCodeModal, setIsCodeModal] = useState(false);
  const [verifyType, setVerifyType] = useState('');
  const [isVerifiedPhone, setIsVerifiedPhone] = useState(false);
  const [isVerifiedEmail, setIsVerifiedEmail] = useState(false);
  const [clickOnSendPhone, setClickOnSendPhone] = useState(false);
  const [clickOnSendEmail, setClickOnSendEmail] = useState(false);
  const [isDisableSendPhone, setIsDisableSendPhone] = useState(false);
  const [isDisableSendEmail, setIsDisableSendEmail] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [address, setAddress] = useState<string>('');
  const [clickOnSave, setClickOnSave] = useState(false);

  const [payFrequency, setPayFrequency] = useState<string | number | undefined>(
    'Monthly'
  );

  const firstPayDateOptions = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
    { label: 13, value: 13 },
    { label: 14, value: 14 },
  ];
  const secondPayDateOptions = [
    { label: 15, value: 15 },
    { label: 16, value: 16 },
    { label: 17, value: 17 },
    { label: 18, value: 18 },
    { label: 19, value: 19 },
    { label: 20, value: 20 },
    { label: 21, value: 21 },
    { label: 22, value: 22 },
    { label: 23, value: 23 },
    { label: 24, value: 24 },
    { label: 25, value: 25 },
    { label: 26, value: 26 },
    { label: 27, value: 27 },
    { label: 28, value: 'End of month' },
  ];

  const disabledLastPayDates = (current: Dayjs) => {
    const today = dayjs();
    const minDate = today.subtract(18, 'day');
    return current && (current > today || current < minDate);
  };

  const disabledDateAfterToday = (current: Dayjs) => {
    return current && current > dayjs();
  };
  const disabledDateBeforeToday = (current: Dayjs) => {
    return current && current < dayjs();
  };

  unstable_usePrompt({
    message: 'You have unsaved changes. Do you want to leave this page?',
    //@ts-ignore
    when: ({ currentLocation, nextLocation }) =>
      isDirty &&
      currentLocation !== nextLocation &&
      nextLocation.pathname !== '/login' &&
      !clickOnSave,
  });
  const beforeUnLoad = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    //@ts-ignore
    e.returnValue = '';
  };
  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', beforeUnLoad);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnLoad);
    };
  }, [isDirty]);

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const handleCheckVin = () =>
    checkVinCode({ vin_code: form.getFieldValue('vin') });

  useEffect(() => {
    if (isErrorCreateApplication) {
      messageApi.open({
        type: 'error',
        content: 'Please check all fields again',
        style: {
          marginTop: '90vh',
        },
      });
    }
  }, [isErrorCreateApplication]);

  useEffect(() => {
    if (clickOnSendPhone) {
      setIsDisableSendPhone(true);
      if (isSuccessPhone) {
        setIsDisableSendPhone(true);
        setPhoneError(false);
      }
      if (isErrorPhone) {
        setIsDisableSendPhone(false);
        setPhoneError(true);
      }
    }
    if (timePhone === 0) {
      setIsDisableSendPhone(false);
    }
  }, [isSuccessPhone, clickOnSendPhone, timePhone, isErrorPhone]);

  useEffect(() => {
    if (clickOnSendEmail) {
      setIsDisableSendEmail(true);
      if (isSuccessEmail) {
        setIsDisableSendEmail(true);
        setEmailError(false);
      }
      if (isErrorEmail) {
        setIsDisableSendEmail(false);
        setEmailError(true);
      }
    }
    if (timeEmail === 0) {
      setIsDisableSendEmail(false);
    }
  }, [isSuccessEmail, clickOnSendEmail, timeEmail, isErrorEmail]);

  useEffect(() => {
    if (!address) return;
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=AIzaSyA8iTVgEXVeBLdvzw0LsVGIr0loR10oVeg`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results.length > 0) {
          const zipCode = data.results[0].address_components.find(
            (component: any) => component.types.includes('postal_code')
          );
          const state = data.results[0].address_components.find(
            (elem: any) =>
              elem.types.includes('administrative_area_level_1') &&
              elem.types.includes('political')
          );

          const element = statesData?.data?.find(
            (elem: any) => elem?.code === state.short_name
          )?.name;

          if (element) {
            form.setFieldsValue({
              state_id: element,
            });
          }

          if (zipCode?.short_name) {
            form.setFieldsValue({
              zip_code: zipCode.short_name,
            });
          } else {
            form.setFieldsValue({
              zip_code: undefined,
            });
          }
        }
      })
      .catch((error) => console.error('Ошибка при получении ZIP-кода:', error));
  }, [address]);

  useEffect(() => {
    const element: any = productOption.find(
      (elem: any) => elem.label + '' === loan
    );
    if (!element) return;
    setIsVinCodes(element.vin);
    setNeedCheckVin(element.check_vin);
    if (!element.vin) {
      form.setFieldsValue({
        vin: null,
      });
    }
  }, [loan, productOption]);

  useEffect(() => {
    if (!user) return;
    if (user.model.main_account) {
      const data: SelectOption[] =
        user?.model?.main_account?.loan_products?.map((elem: SelectOption) => {
          return {
            value: elem.name,
            label: elem.id,
            vin: elem.vin,
            check_vin: elem.check_vin,
          };
        });
      setProductOption(data);
    } else {
      const data: SelectOption[] = user?.model?.loan_products?.map(
        (elem: LoanProducts) => {
          return {
            value: elem.name,
            label: elem.id,
            vin: elem.vin,
            check_vin: elem.check_vin,
          };
        }
      );
      setProductOption(data);
    }
  }, [user]);
  useEffect(() => {
    if (!ApplicationData || !statesData) return;
    form.setFieldsValue({
      first_name: ApplicationData?.data?.first_name
        ? ApplicationData?.data?.first_name
        : undefined,
      last_name: ApplicationData?.data?.last_name
        ? ApplicationData?.data?.last_name
        : undefined,
      date_of_birth: ApplicationData?.data?.date_of_birth
        ? moment(new Date(ApplicationData?.data?.date_of_birth).toString())
        : undefined,

      zip_code: ApplicationData?.data?.zip_code
        ? ApplicationData?.data?.zip_code
        : undefined,
      property_status: ApplicationData?.data?.property_status ? 'Rent' : 'Own',
      phone: ApplicationData?.data?.phone
        ? ApplicationData?.data?.phone
        : undefined,
      email: ApplicationData?.data?.email
        ? ApplicationData?.data?.email
        : undefined,
      ssn: ApplicationData?.data?.ssn ? ApplicationData?.data?.ssn : undefined,
      employment_status: ApplicationData?.data?.employment_status
        ? ApplicationData?.data?.employment_status
        : undefined,
      annual_income: ApplicationData?.data?.annual_income
        ? formatFloatToString(Number(ApplicationData?.data?.annual_income))
        : undefined,
      confirm_financing_amount: ApplicationData?.data?.confirm_financing_amount
        ? formatFloatToString(
            Number(ApplicationData?.data?.confirm_financing_amount)
          )
        : undefined,
      financing_amount: ApplicationData?.data?.financing_amount
        ? formatFloatToString(Number(ApplicationData?.data?.financing_amount))
        : undefined,
      application_status: ApplicationData?.data?.application_status
        ? ApplicationData?.data?.application_status
        : undefined,
      company_name: ApplicationData?.data?.company_name
        ? ApplicationData?.data?.company_name
        : undefined,
      loan_product_id: ApplicationData?.data?.loan_product_id
        ? ApplicationData?.data?.loan_product_id + ''
        : undefined,
      transaction_status: ApplicationData?.data?.transaction_status
        ? ApplicationData?.data?.transaction_status
        : undefined,
      document_expiration_date: ApplicationData?.data?.document_expiration_date
        ? moment(ApplicationData?.data?.document_expiration_date)
        : undefined,
      document_issuence_date: ApplicationData?.data?.document_issuence_date
        ? moment(ApplicationData?.data?.document_issuence_date)
        : undefined,
      document_issuence_state: ApplicationData?.data?.document_issuence_state
        ? ApplicationData?.data?.document_issuence_state
        : undefined,
      document_number: ApplicationData?.data?.document_number
        ? ApplicationData?.data?.document_number
        : undefined,
      accounting_number: ApplicationData?.data?.accounting_number
        ? ApplicationData?.data?.accounting_number
        : undefined,
      confirm_accounting_number: ApplicationData?.data?.accounting_number
        ? ApplicationData?.data?.accounting_number
        : undefined,
      routing_number: ApplicationData?.data?.routing_number
        ? ApplicationData?.data?.routing_number
        : undefined,
    });
    ApplicationData?.data?.pay_frequency &&
      form.setFieldsValue({
        pay_frequency: ApplicationData?.data?.pay_frequency,
      });
    ApplicationData?.data?.first_pay_date &&
      form.setFieldsValue({
        first_pay_date: ApplicationData?.data?.first_pay_date,
      });
    ApplicationData?.data?.second_pay_date &&
      form.setFieldsValue({
        second_pay_date: ApplicationData?.data?.second_pay_date,
      });
    ApplicationData?.data?.last_pay_date &&
      form.setFieldsValue({
        last_pay_date: dayjs(
          ApplicationData?.data?.last_pay_date,
          'YYYY-MM-DD'
        ),
      });

    let AddressString = '';

    if (ApplicationData?.data?.street_address) {
      AddressString = `${ApplicationData?.data?.street_address}, ${ApplicationData?.data?.city}, ${ApplicationData?.data?.state?.code}, USA`;
    }

    if (ApplicationData?.data?.financing_type?.vin) {
      setIsVinCodes(true);
    }
    setContractNumber(ApplicationData?.data?.phone);
    setEmail(ApplicationData?.data?.email);
    setId(ApplicationData?.data?.public_id);
    setApplicantId(ApplicationData?.data?.id);
    setAddress(AddressString);
    ApplicationData?.data?.loan_product_id &&
      setLoan(ApplicationData?.data?.loan_product_id + '');
    ApplicationData?.data?.pay_frequency &&
      setPayFrequency(ApplicationData?.data?.pay_frequency);
  }, [ApplicationData, statesData]);

  useEffect(() => {
    if (!user.email) return;
    if (user.role.key !== 'partner') {
      navigate('/403');
    }
  }, [user.email]);

  useEffect(() => {
    if (isSuccess) {
      setConfirmationSaveDraftModal(false);
      setSuccessPopup(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    dispatch(setIsDirtyAction(isDirty));
  }, [isDirty]);

  useEffect(() => {
    if (vinCheckError) {
      form.validateFields(['vin']);
    }
  }, [vinCheckError, acceptAnyway, form]);

  const [routingValue, setRoutingValue] = useState('');

  useEffect(() => {
    if (isError) {
      form.validateFields(['routing_number']);
    }
  }, [isError, form]);

  const editApplicantsFunc = async (isToast: boolean = true) => {
    const formData = form.getFieldsValue();

    const copyAddress = address;

    const parts = copyAddress?.split(',').map((part: string) => part.trim());
    const Address = parts[0];
    const City = parts[1];
    const State = parts[2]?.split(' ')[0];
    const stateId = statesData?.data?.find(
      (elem: any) => elem.code === State
    )?.id;

    let body: FormData = {
      ...formData,
      street_address: Address ? Address : undefined,
      city: City ? City : undefined,
      zip_code: formData.zip_code ? formData.zip_code : undefined,
      state_id: stateId ? stateId : undefined,
      annual_income: formData.annual_income
        ? convertStringToFloat(formData.annual_income)
        : undefined,
      confirm_financing_amount: formData.confirm_financing_amount
        ? convertStringToFloat(formData.confirm_financing_amount)
        : undefined,
      financing_amount: formData.financing_amount
        ? convertStringToFloat(formData.financing_amount)
        : undefined,
      document_number: formData.document_number,
      document_issuence_state: formData.document_issuence_state,
      document_expiration_date: formData.document_expiration_date
        ? moment(formData.document_expiration_date).format('YYYY-MM-DD')
        : undefined,
      document_issuence_date: formData.document_issuence_date
        ? moment(formData.document_issuence_date).format('YYYY-MM-DD')
        : undefined,
    };

    if (formData?.loan_product_id && +formData?.loan_product_id === 15) {
      body = {
        ...body,
        accounting_number: formData.accounting_number,
        routing_number: formData.routing_number,
        pay_frequency: formData.pay_frequency?.toLowerCase(),
        first_pay_date: formData.first_pay_date,
        second_pay_date: formData.second_pay_date,
        last_pay_date: moment(
          new Date(formData.last_pay_date).toString()
        ).format('YYYY-MM-DD'),
      };
    }

    if (body.date_of_birth) {
      body.date_of_birth = moment(
        new Date(body.date_of_birth as any).toString()
      ).format('YYYY-MM-DD');
    }

    try {
      await editApplicants({
        data: body,
        id: location.pathname?.split('/')[3],
      }).unwrap();

      if (isToast) {
        dispatch(setMessage('Changes have been successfully saved'));
      }
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Edit applicantion failed');
    }
  };

  const creacteApplicationFunc = async () => {
    setClickOnSave(true);

    let Address = '';
    let City = '';
    let stateId = undefined;

    if (address) {
      const copyAddress = address;

      const parts = copyAddress?.split(',').map((part: string) => part.trim());
      Address = parts[0];
      City = parts[1];
      const State = parts[2]?.split(' ')[0];
      stateId = statesData?.data?.find((elem: any) => elem?.code === State)?.id;
    }

    const formData = form.getFieldsValue();

    let body: FormData = {
      first_name: formData.first_name ? formData.first_name : undefined,
      last_name: formData.last_name ? formData.last_name : undefined,
      date_of_birth: formData.date_of_birth
        ? new Date(formData.date_of_birth as any).toString()
        : undefined,
      street_address: Address ? Address : undefined,
      city: City ? City : undefined,
      zip_code: formData.zip_code ? formData.zip_code : undefined,
      state_id: stateId ? stateId : undefined,
      property_status: formData.property_status ? 'Rent' : 'Own',
      phone: formData.phone ? formData.phone : undefined,
      email: formData.email ? formData.email : undefined,
      ssn: formData.ssn,
      employment_status: formData.employment_status,
      annual_income: formData.annual_income
        ? convertStringToFloat(formData.annual_income)
        : undefined,
      confirm_financing_amount: formData.confirm_financing_amount
        ? convertStringToFloat(formData.confirm_financing_amount)
        : undefined,
      financing_amount: formData.financing_amount
        ? convertStringToFloat(formData.financing_amount)
        : undefined,
      application_status: formData.application_status,
      company_name: formData.company_name,
      loan_product_id: formData.loan_product_id,
      transaction_status: formData.transaction_status,
      vin: formData.vin,
      vinConfirm: null,
      document_number: formData.document_number,
      document_issuence_state: formData.document_issuence_state,
      document_expiration_date: formData.document_expiration_date
        ? moment(formData.document_expiration_date).format('YYYY-MM-DD')
        : undefined,
      document_issuence_date: formData.document_issuence_date
        ? moment(formData.document_issuence_date).format('YYYY-MM-DD')
        : undefined,
    };

    if (formData?.loan_product_id && +formData?.loan_product_id === 15) {
      body = {
        ...body,
        accounting_number: formData.accounting_number,
        routing_number: formData.routing_number,
        pay_frequency: formData.pay_frequency?.toLowerCase(),
        first_pay_date: formData.first_pay_date,
        second_pay_date: formData.second_pay_date,
        last_pay_date: moment(
          new Date(formData.last_pay_date).toString()
        ).format('YYYY-MM-DD'),
      };
    }

    if (body.date_of_birth) {
      body.date_of_birth = moment(body.date_of_birth).format('YYYY-MM-DD');
    }

    if (
      formData?.loan_product_id &&
      +formData?.loan_product_id === 15 &&
      !isSuccessRouting
    ) {
      dispatch(setMessage('Verify the rooting number first'));
      return;
    }

    try {
      await createApplication(body).unwrap();
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Create applicantion failed');
    }
  };

  const handleFormSubmit = () => {
    let isValid = true;
    setIsFormValid(true);
    if (!isVerifiedEmail || !isVerifiedPhone) {
      isValid = false;
      setIsFormValid(false);
      setVarificationError(true);
      dispatch(setMessage('Please verify Phone Number and Email Address'));
    }
    form
      .validateFields()
      .then((values: FormData) => {
        if (id && isValid) {
          editApplicantsFunc(false);
        } else if (!id && isValid) {
          creacteApplicationFunc();
        }
      })
      .catch((error: { errorFields: [{ name: string[] }] }) => {
        if (!isVerifiedEmail || !isVerifiedPhone) {
          scrollToElementById('phone');
          const name = error.errorFields[0].name[0];
          scrollToElementById(name);
        }
      });
  };

  const scrollToElementById = (elementId: string) => {
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    if (form.getFieldValue('loan_product_id') !== 15) {
      form.setFieldsValue({
        accounting_number: undefined,
        confirm_accounting_number: undefined,
        routing_number: undefined,
      });
    }
  }, [form.getFieldValue('loan_product_id')]);

  const onValuesChange = () => {
    setIsDirty(true);
  };

  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
  };

  const currencyMaskFinancingAmount = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = e.target.value;
    value = value.replace(/^0/, '');
    value = value.replace(/[^\d.]/g, '');
    value = value.replace(/\.{2,}/g, '.');
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    e.target.value = value;
    const number = value.replace(/,/g, '');
    if (+number > 300000) {
      e.target.value = '300,000';
    } else {
      const parts = number?.split('.');
      if (parts.length === 2 && parts[1].length > 2) {
        e.target.value = `${parts[0]}.${parts[1].substring(0, 2)}`;
      }
    }
    return e.target.value;
  };

  const currencyMaskDocumentNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let cleanedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    cleanedValue = cleanedValue.toUpperCase();
    e.target.value = cleanedValue;
  };

  const currencyMaskAnnualIncome = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/^0/, '');
    value = value.replace(/[^\d.]/g, '');
    value = value.replace(/\.{2,}/g, '.');
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    e.target.value = value;
    const number = value.replace(/,/g, '');
    if (+number > 1000000) {
      e.target.value = '1,000,000';
    } else {
      const parts = number?.split('.');
      if (parts.length === 2 && parts[1].length > 2) {
        e.target.value = `${parts[0]}.${parts[1].substring(0, 2)}`;
      }
    }
    return e.target.value;
  };

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
  };

  const varificationFunc = async (name: string) => {
    const values = form.getFieldsValue();
    const data: varificationBody = {
      type: name,
    };

    if (name === 'phone') {
      setTimePhone(30);
      data.phone = values.phone;
      varificationPhone(data);
      setCountPhoneRequest((prev: number) => prev + 1);
    }
    if (name === 'email') {
      setTimeEmail(30);
      data.email = values.email;
      varificationEmail(data);
      setCountEmailRequest((prev: number) => prev + 1);
    }
  };

  const removeApplicationData = () => {
    setConfirmationRemoveModal(true);
  };

  const removeApplicationDataReq = async () => {
    try {
      await removeApplicant(applicantId as number);
      setConfirmationRemoveModal(false);
      navigate('/applications');
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Remove applicantion failed');
    }
  };

  const handleVinInput = (e: any) => {
    e.target.value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
  };

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const handleRoutingInput = (e: any) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };

  function onBlurHandler(e: React.FocusEvent<HTMLInputElement>, name: string) {
    const inputValue = e.target.value;
    if (inputValue.endsWith('.')) {
      e.target.value = inputValue + '00';
    }
    if (inputValue.endsWith('.0')) {
      e.target.value = inputValue + '0';
    }
    form.setFieldsValue({
      [name]: e.target.value,
    });
  }
  if ((isEditApplicantsSuccess && isFormValid) || (isSuccess && isFormValid)) {
    return (
      <Offers
        customerInfo={editApplicantsData ?? data ?? ApplicationData}
        isDirty={isDirty}
        setIsClickOnHome={setIsClickOnHome}
        setConfirmationModal={setConfirmationModal}
        form={form}
      />
    );
  }

  return (
    <>
      {contextHolder}
      {isSuccessPhone && countPhoneRequest >= 1 && (
        <Timer time={timePhone} setTime={setTimePhone} />
      )}
      {isSuccessEmail && countEmailRequest >= 1 && (
        <Timer time={timeEmail} setTime={setTimeEmail} />
      )}

      <div className="container">
        <div className="new-member">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className="new-member__breadscrumbs breadscrumbs">
              <div
                className="breadscrumbs__title"
                onClick={() => {
                  setClickOnSave(true);
                  if (location.pathname.includes('edit')) {
                    if (isDirty) {
                      setIsClickOnHome(true);
                      setConfirmationModal(true);
                    } else {
                      navigate('/dashboard');
                    }
                  } else {
                    if (form.isFieldsTouched()) {
                      setIsClickOnHome(true);
                      setConfirmationModal(true);
                    } else {
                      navigate('/dashboard');
                    }
                  }
                }}
              >
                <HomeIcon fill="" />
              </div>
              <div className="breadscrumbs__separatop">
                <ArrowIcon fill="" />
              </div>
              <div
                className="breadscrumbs__title"
                onClick={() => {
                  setClickOnSave(true);
                  if (location.pathname.includes('edit')) {
                    if (isDirty) {
                      setIsClickOnHome(false);
                      setConfirmationModal(true);
                    } else {
                      navigate('/applications');
                    }
                  } else {
                    if (form.isFieldsTouched()) {
                      setIsClickOnHome(false);
                      setConfirmationModal(true);
                    } else {
                      navigate('/applications');
                    }
                  }
                }}
              >
                Applications
              </div>
              <div className="breadscrumbs__separatop">
                <ArrowIcon fill="#4D4D56" />
              </div>
              <div className="breadscrumbs__title breadscrumbs__title-active">
                {location.pathname.includes('edit') ? 'Edit' : 'Create'}{' '}
                Application
              </div>
            </div>
            <div
              className="main__btns"
              style={{ padding: '8px 0 35px 0', margin: '0' }}
            >
              <Button
                className="gray-btn"
                style={{ margin: '0 16px 0 0' }}
                onClick={() => {
                  setClickOnSave(true);

                  if (location.pathname.includes('edit')) {
                    if (isDirty) {
                      setIsClickOnHome(false);
                      setConfirmationModal(true);
                    } else {
                      navigate('/applications');
                    }
                  } else {
                    if (form.isFieldsTouched()) {
                      setIsClickOnHome(false);
                      setConfirmationModal(true);
                    } else {
                      navigate('/applications');
                    }
                  }
                }}
              >
                Cancel
              </Button>

              <Button
                className="blue-btn"
                style={{ margin: '0 16px 0 0' }}
                onClick={() => {
                  setClickOnSave(true);
                  if (location.pathname.includes('edit')) {
                    editApplicantsFunc();
                  } else {
                    setConfirmationSaveDraftModal(true);
                  }
                }}
              >
                Save Draft
              </Button>

              {id && ApplicationData?.data.application_status === 'Draft' && (
                <Button className="red-btn" onClick={removeApplicationData}>
                  Delete
                </Button>
              )}
            </div>
          </div>

          <Form
            form={form}
            initialValues={initialValues}
            onSubmitCapture={handleFormSubmit}
            onValuesChange={onValuesChange}
          >
            <div className="new-member__main main" style={{ margin: '20px 0' }}>
              <div className="main__container">
                <div className="main__title-block">
                  <div className="main__title">Customer Information</div>
                </div>
                <div className="main__form-block">
                  <div className="partner-info">
                    {id && (
                      <div className="partner-info__id">
                        Application ID:{' '}
                        <span style={{ color: '#67666E' }}>{id}</span>
                      </div>
                    )}

                    <div
                      className="partner-info__row"
                      style={{ margin: '0 0 -12px 0' }}
                    >
                      <div className="item" id="first_name">
                        <Form.Item
                          className="input-wrapper"
                          name="first_name"
                          rules={yupSync('first_name', validationSchema, true)}
                        >
                          <CustomInput
                            name="First Name"
                            className="input"
                            maxLength={100}
                            placeholder="First name"
                            onBlur={() => validateFormOnBlur('first_name')}
                            validation={'noNumbers'}
                          />
                        </Form.Item>
                      </div>
                      <div className="item" id="last_name">
                        <Form.Item
                          className="input-wrapper"
                          name="last_name"
                          rules={yupSync('last_name', validationSchema, true)}
                        >
                          <CustomInput
                            name="Last Name"
                            className="input"
                            maxLength={100}
                            placeholder="Last name"
                            onBlur={() => validateFormOnBlur('last_name')}
                            validation={'noNumbers'}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div
                      className="partner-info__row"
                      style={{ margin: '0 0 -20px 0' }}
                    >
                      <div className="item" id="date_of_birth">
                        <div
                          className="custom-field__title"
                          style={{ margin: '15px 0 0 0' }}
                        >
                          Date of Birth
                        </div>
                        <Form.Item
                          name="date_of_birth"
                          rules={yupSync(
                            'date_of_birth',
                            validationSchema,
                            true
                          )}
                        >
                          <DatePicker
                            className="input"
                            style={{ width: '100%' }}
                            placeholder="xx/xx/xxxx"
                            format="MM/DD/YYYY"
                            inputReadOnly
                            allowClear
                            onBlur={() => {
                              form
                                .validateFields(['date_of_birth'])
                                .catch(() => {});
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="item" id="property_status">
                        <Form.Item
                          className="input-wrapper"
                          name="property_status"
                          rules={yupSync(
                            'property_status',
                            validationSchema,
                            true
                          )}
                        >
                          <CustomSelect
                            name="Property status"
                            className="input"
                            placeholder="Property status"
                            options={[
                              { value: 'Rent', label: 'Rent' },
                              { value: 'Own', label: 'Own' },
                            ]}
                            onChange={() => {}}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="partner-info__row">
                      <div className="item" id="street_address">
                        <SomeFunctionalComponent
                          setAddress={setAddress}
                          address={address}
                        />
                      </div>
                      <div className="item" id="zip_code">
                        <Form.Item
                          className="input-wrapper"
                          name="zip_code"
                          rules={yupSync('zip_code', validationSchema, true)}
                        >
                          <PhoneNumberInput
                            mask="99999"
                            name="ZIP code"
                            onChange={() => {}}
                            className="input"
                            value={undefined}
                            onBlur={() => validateFormOnBlur('zip_code')}
                            placeholder={'ZIP code'}
                            hasError={() =>
                              !!form.getFieldError('zip_code').length
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="partner-info__row"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="new-member__main main" style={{ margin: '20px 0' }}>
              <div className="main__container">
                <div className="main__title-block">
                  <div className="main__title">Driver License Information</div>
                </div>
                <div className="main__form-block">
                  <div className="partner-info">
                    <div className="partner-info__row">
                      <div className="item" id="document_number">
                        <Form.Item
                          className="input-wrapper"
                          name="document_number"
                          rules={[
                            ...yupSync(
                              'document_number',
                              validationSchema,
                              true
                            ),
                          ]}
                        >
                          <CustomInput
                            name="Document Number"
                            className="input"
                            placeholder="Enter document number"
                            onBlur={() => validateFormOnBlur('document_number')}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                              currencyMaskDocumentNumber(e)
                            }
                            maxLength={14}
                          />
                        </Form.Item>
                      </div>
                      <div className="item" id="document_issuence_state">
                        <Form.Item
                          className="input-wrapper"
                          name="document_issuence_state"
                          rules={yupSync(
                            'document_issuence_state',
                            validationSchema,
                            true
                          )}
                        >
                          <CustomSelect
                            name="State of Issuance"
                            className="input"
                            placeholder="Select State"
                            options={statesData?.data?.map(
                              (elem: StatesItem) => {
                                return { value: elem.name, label: elem.code };
                              }
                            )}
                            onChange={() => {}}
                            onSearch={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchState(e.target.value)}
                            isSearchSelect={true}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="partner-info__row">
                      <div className="item" id="document_issuence_date">
                        <div
                          className="custom-field__title"
                          style={{ margin: '15px 0 0 0' }}
                        >
                          Issuance Date
                        </div>
                        <Form.Item
                          name="document_issuence_date"
                          rules={yupSync(
                            'document_issuence_date',
                            validationSchema,
                            true
                          )}
                        >
                          <DatePicker
                            className="input"
                            style={{ width: '100%' }}
                            placeholder="xx/xx/xxxx"
                            format="MM/DD/YYYY"
                            inputReadOnly
                            allowClear
                            onBlur={() => {
                              form
                                .validateFields(['document_issuence_date'])
                                .catch(() => {});
                            }}
                            disabledDate={disabledDateAfterToday}
                          />
                        </Form.Item>
                      </div>
                      <div className="item" id="document_expiration_date">
                        <div
                          className="custom-field__title"
                          style={{ margin: '15px 0 0 0' }}
                        >
                          Expiration Date
                        </div>
                        <Form.Item
                          name="document_expiration_date"
                          rules={yupSync(
                            'document_expiration_date',
                            validationSchema,
                            true
                          )}
                        >
                          <DatePicker
                            className="input"
                            style={{ width: '100%' }}
                            placeholder="xx/xx/xxxx"
                            format="MM/DD/YYYY"
                            inputReadOnly
                            allowClear
                            onBlur={() => {
                              form
                                .validateFields(['document_expiration_date'])
                                .catch(() => {});
                            }}
                            disabledDate={disabledDateBeforeToday}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="new-member__main main" style={{ margin: '20px 0' }}>
              <div className="main__container">
                <div className="main__title-block">
                  <div className="main__title">Product Information</div>
                </div>
                <div className="main__form-block">
                  <div className="partner-info">
                    <div className="partner-info__row">
                      <div className="item" id="financing_amount">
                        <Form.Item
                          className="input-wrapper"
                          name="financing_amount"
                          rules={[
                            ...yupSync(
                              'financing_amount',
                              validationSchema,
                              true
                            ),
                          ]}
                        >
                          <CustomInput
                            name="Financing Amount"
                            className="input"
                            placeholder="1,000.00"
                            onBlur={(e) => {
                              validateFormOnBlur('financing_amount');
                              onBlurHandler(e, 'financing_amount');
                            }}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                              currencyMaskFinancingAmount(e)
                            }
                          />
                        </Form.Item>
                      </div>
                      <div className="item" id="confirm_financing_amount">
                        <Form.Item
                          className="input-wrapper"
                          name="confirm_financing_amount"
                          rules={[
                            ...yupSync(
                              'confirm_financing_amount',
                              validationSchema
                            ),
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue('financing_amount') === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error('Financing amount should match')
                                );
                              },
                            }),
                          ]}
                          dependencies={['financing_amount']}
                        >
                          <CustomInput
                            name="Confirm Financing Amount"
                            className="input"
                            maxLength={100}
                            onKeyPress={handleKeyPress}
                            placeholder="1,000.00"
                            onBlur={(e) => {
                              validateFormOnBlur('confirm_financing_amount');
                              onBlurHandler(e, 'confirm_financing_amount');
                            }}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                              currencyMaskFinancingAmount(e)
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="partner-info__row">
                      <Form.Item
                        className="input-wrapper"
                        name="loan_product_id"
                        rules={yupSync(
                          'loan_product_id',
                          validationSchema,
                          true
                        )}
                      >
                        <CustomSelect
                          name="Financing Type"
                          className="input"
                          placeholder="Financing Type"
                          options={productOption}
                          onChange={(value: any) => {
                            setLoan(value);
                            setAcceptAnyway(false);
                            vinCheckReset();
                            setVinValue('');
                            form.setFieldsValue({ vin: '', vinConfirm: '' });
                          }}
                          onSearch={() => {}}
                          isSearchSelect={false}
                        />
                      </Form.Item>
                    </div>
                    {isVinCodes && (
                      <>
                        <div className="partner-info__row">
                          <div className="item">
                            <Form.Item
                              className="input-wrapper"
                              name="vin"
                              rules={[
                                ...yupSync('vin', validationSchema, true),
                                {
                                  validator: () => {
                                    if (vinCheckError && acceptAnyway) {
                                      return Promise.resolve();
                                    }
                                    if (vinCheckError) {
                                      return Promise.reject(
                                        'No match found, please verify VIN Number and accept manually'
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <CustomInput
                                value={vinValue}
                                name="VIN Number"
                                className="input"
                                placeholder="Enter VIN Number"
                                maxLength={20}
                                onInput={(e) => {
                                  handleVinInput(e);
                                }}
                                onChange={(e) => {
                                  vinCheckReset();
                                  setVinValue(e.target.value);
                                  setAcceptAnyway(false);
                                }}
                                onBlur={() => {
                                  form.validateFields(['vin']);
                                }}
                              />
                            </Form.Item>
                          </div>
                          {needCheckVin && (
                            <div className="item d-flex mb-3">
                              <Button
                                className={`new-member__varification-btn violet-btn ${
                                  (vinCheckSuccess || acceptAnyway) &&
                                  'accepted'
                                }`}
                                onClick={() => handleCheckVin()}
                                disabled={
                                  !vinValue ||
                                  vinCheckLoading ||
                                  vinCheckError ||
                                  vinCheckSuccess
                                }
                              >
                                {vinCheckSuccess || acceptAnyway
                                  ? 'Accepted'
                                  : 'Check'}
                              </Button>
                              {vinCheckError && !acceptAnyway && (
                                <span
                                  className="vin-accept-text"
                                  onClick={() => {
                                    setAcceptAnyway(true);
                                  }}
                                >
                                  Accept VIN Number
                                </span>
                              )}
                            </div>
                          )}
                          {!needCheckVin && (
                            <div className="item">
                              <Form.Item
                                className="input-wrapper"
                                name="vinConfirm"
                                rules={[
                                  ...yupSync('vinConfirm', validationSchema),
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        !value ||
                                        getFieldValue('vin') === value
                                      ) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        new Error('VIN do not match. Try again')
                                      );
                                    },
                                  }),
                                ]}
                                dependencies={['vin']}
                              >
                                <CustomInput
                                  name="Confirm VIN Number"
                                  className="input"
                                  placeholder="Confirm VIN Number"
                                  maxLength={20}
                                  onInput={(e) => {
                                    handleVinInput(e);
                                  }}
                                />
                              </Form.Item>
                            </div>
                          )}
                        </div>
                        {vinCheckSuccess && (
                          <div className="partner-info__row">
                            <VinCodeBadge data={vinResponse.data} />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="new-member__main main" style={{ margin: '20px 0' }}>
              <div className="main__container">
                <div className="main__title-block">
                  <div className="main__title">Income Information</div>
                </div>
                <div className="main__form-block">
                  <div className="partner-info">
                    <div className="partner-info__row">
                      <div className="item" id="annual_income">
                        <Form.Item
                          className="input-wrapper"
                          name="annual_income"
                          rules={[
                            ...yupSync('annual_income', validationSchema, true),
                          ]}
                        >
                          <CustomInput
                            name="Annual Income"
                            className="input"
                            maxLength={100}
                            placeholder="Annual income"
                            onBlur={(e) => {
                              validateFormOnBlur('annual_income');
                              onBlurHandler(e, 'annual_income');
                            }}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                              currencyMaskAnnualIncome(e)
                            }
                          />
                        </Form.Item>
                      </div>
                      <div className="item" id="employment_status">
                        <Form.Item
                          className="input-wrapper"
                          name="employment_status"
                          rules={yupSync(
                            'employment_status',
                            validationSchema,
                            true
                          )}
                        >
                          <CustomSelect
                            name="Employment Status"
                            className="input"
                            placeholder="Select Employement status"
                            options={[
                              { value: 'Employed', label: 'Employed' },
                              { value: 'Military', label: 'Military' },
                              {
                                value: 'Not Employed',
                                label: 'Not Employed',
                              },
                              { value: 'Retired', label: 'Retired' },
                              {
                                value: 'Self Employed',
                                label: 'Self Employed',
                              },
                              { value: 'Other', label: 'Other' },
                            ]}
                            onChange={() => {}}
                            onSearch={() => {}}
                            isSearchSelect={false}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="partner-info__row">
                      <div className={loan === '15' ? 'item' : 'w-100'}>
                        <Form.Item
                          className="input-wrapper"
                          name="company_name"
                          rules={yupSync(
                            'company_name',
                            validationSchema,
                            true
                          )}
                        >
                          <CustomInput
                            name="Company name/ Source of income"
                            className="input"
                            maxLength={255}
                            placeholder="Company name"
                            onBlur={() => validateFormOnBlur('company_name')}
                          />
                        </Form.Item>
                      </div>
                      {loan === '15' && (
                        <div className="item">
                          <Form.Item
                            className="input-wrapper"
                            name="pay_frequency"
                            initialValue="Monthly"
                          >
                            <CustomSelect
                              name="Pay Frequency"
                              className="input"
                              placeholder="Select Pay Frequency"
                              options={[
                                { label: 'weekly', value: 'Weekly' },
                                { label: 'bi-weekly', value: 'Bi-weekly' },
                                {
                                  label: 'semi-monthly',
                                  value: 'Semi-monthly',
                                },
                                { label: 'monthly', value: 'Monthly' },
                              ]}
                              onChange={(value) => {
                                setPayFrequency(value);
                              }}
                              onSearch={() => {}}
                              isSearchSelect={false}
                            />
                          </Form.Item>
                        </div>
                      )}
                    </div>
                    {loan === '15' && (
                      <>
                        <div className="partner-info__row">
                          {payFrequency === 'semi-monthly' && (
                            <>
                              <div className="item">
                                <Form.Item
                                  className="input-wrapper"
                                  name="first_pay_date"
                                  initialValue={1}
                                >
                                  <CustomSelect
                                    name="First Pay Date"
                                    className="input"
                                    placeholder="Select First Pay Date"
                                    options={firstPayDateOptions}
                                    onChange={() => {}}
                                    onSearch={() => {}}
                                    isSearchSelect={false}
                                  />
                                </Form.Item>
                              </div>
                              <div className="item">
                                <Form.Item
                                  className="input-wrapper"
                                  name="second_pay_date"
                                  initialValue={15}
                                >
                                  <CustomSelect
                                    name="Second Pay Date"
                                    className="input"
                                    placeholder="Select Second Pay Date"
                                    options={secondPayDateOptions}
                                    onChange={() => {}}
                                    onSearch={() => {}}
                                    isSearchSelect={false}
                                  />
                                </Form.Item>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="partner-info__row">
                          <div className="w-100">
                            <div
                              className="custom-field__title"
                              style={{ margin: '15px 0 0 0' }}
                            >
                              Last Pay Date
                            </div>
                            <Form.Item
                              name="last_pay_date"
                              initialValue={dayjs()}
                            >
                              <DatePicker
                                className="input"
                                style={{ width: '100%' }}
                                placeholder="Select Last Pay Date"
                                format="MM/DD/YYYY"
                                inputReadOnly
                                allowClear={false}
                                disabledDate={disabledLastPayDates}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {+form.getFieldValue('loan_product_id') === 15 && (
              <div
                className="new-member__main main"
                style={{ margin: '20px 0' }}
              >
                <div className="main__container">
                  <div className="main__title-block">
                    <div className="main__title">Banking Information</div>
                  </div>
                  <div className="main__form-block">
                    <div className="partner-info">
                      <div className="partner-info__row">
                        <div className="item">
                          <Form.Item
                            name="accounting_number"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                              {
                                min: 5,
                                message:
                                  'Account Number must be at least 5 characters long',
                              },
                            ]}
                          >
                            <CustomInput
                              name="Account Number"
                              className="input"
                              autoComplete="off"
                              placeholder="Enter Account Number"
                              maxLength={17}
                              onInput={handleRoutingInput}
                            />
                          </Form.Item>
                        </div>{' '}
                        <div className="item">
                          <Form.Item
                            name="confirm_accounting_number"
                            dependencies={['accounting_number']}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue('accounting_number') === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    'Account Numbers do not match'
                                  );
                                },
                              }),
                            ]}
                          >
                            <CustomInput
                              name="Confirm Account Number"
                              className="input"
                              autoComplete="off"
                              placeholder="Enter Account Number"
                              maxLength={17}
                              onInput={handleRoutingInput}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="partner-info__row">
                        <div className="item routing">
                          <Form.Item
                            name="routing_number"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                              {
                                validator: () => {
                                  if (isError) {
                                    return Promise.reject(
                                      'Enter valid Routing Number'
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <CustomInput
                              name="Routing Number"
                              value={routingValue}
                              className="input"
                              placeholder="Enter Routing Number"
                              maxLength={9}
                              onInput={handleRoutingInput}
                              onChange={(e) => {
                                reset();
                                setRoutingValue(e.target.value);
                              }}
                            />
                          </Form.Item>
                        </div>

                        <Button
                          className={`violet-btn ${
                            !isSuccessRouting ? '' : 'accepted'
                          }`}
                          style={{ height: '32px' }}
                          disabled={
                            isLoading ||
                            isError ||
                            !routingValue ||
                            !!isSuccessRouting
                          }
                          onClick={() => {
                            checkRoutingNumber({
                              routing_number:
                                form.getFieldValue('routing_number'),
                            });
                          }}
                        >
                          {isLoading ? (
                            <Spinner color="#808086" size="small" />
                          ) : isSuccessRouting ? (
                            'Verified'
                          ) : (
                            'Verify'
                          )}
                        </Button>
                      </div>
                      {isSuccessRouting && routingData.bank_name && (
                        <div className="vin-info-container">
                          <div className="vin-info-container__row-item">
                            <div className="vin-info-container__title">
                              Bank Name
                            </div>
                            <div className="vin-info-container__text">
                              {routingData.bank_name}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="new-member__main main" style={{ margin: '20px 0' }}>
              <div className="main__container">
                <div className="main__title-block">
                  <div className="main__title">Authorization Information</div>
                </div>
                <div className="main__form-block">
                  <div className="partner-info">
                    <div className="partner-info__row">
                      <div className="item" id="phone">
                        <Form.Item
                          className="input-wrapper"
                          name="phone"
                          rules={yupSync('phone', validationSchema, true)}
                        >
                          <PhoneNumberInput
                            disable={isVerifiedPhone}
                            mask="(999) 999 9999"
                            name="Phone Number"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setContractNumber(e.target.value);
                              setPhoneError(false);
                            }}
                            className={`input ${phoneError ? 'error' : ''}`}
                            value={undefined}
                            onBlur={() => validateFormOnBlur('phone')}
                            placeholder={'Phone Number'}
                            hasError={() =>
                              !!form.getFieldError('phone').length
                            }
                          />
                        </Form.Item>
                        {phoneError && (
                          <div
                            className="error-text"
                            style={{ margin: '-30px 0 10px 0' }}
                          >
                            Please, enter a valid Phone
                          </div>
                        )}
                      </div>
                      {isVerifiedPhone ? (
                        <div className="new-member__varified">
                          <img
                            src={checkcircle}
                            style={{ margin: '0 10px 0 0' }}
                            alt="checked"
                          />
                          Verified
                        </div>
                      ) : (
                        <div className="new-member__varification-wrapper">
                          <Button
                            className={`new-member__varification-btn violet-btn ${
                              ContractNumber
                                ? ContractNumber.includes('_') ||
                                  isDisableSendPhone
                                  ? 'disable'
                                  : ''
                                : 'disable'
                            }`}
                            onClick={() => {
                              setClickOnSendPhone(true);
                              if (
                                !ContractNumber ||
                                ContractNumber.includes('_') ||
                                isDisableSendPhone
                              ) {
                              } else {
                                varificationFunc('phone');
                              }
                            }}
                          >
                            Send Verification SMS
                          </Button>
                          {timePhone > 0 && timePhone < 30 ? (
                            <div className="timer">
                              You can resend the code in {formatTime(timePhone)}
                            </div>
                          ) : (
                            <></>
                          )}

                          <Button
                            className={`new-member__varification-btn blue-btn ${
                              isSuccessPhone ? '' : 'disable'
                            }`}
                            onClick={() => {
                              if (isSuccessPhone) {
                                setVerifyType('phone');
                                setIsCodeModal(true);
                              }
                            }}
                          >
                            Verify Phone Number
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="partner-info__row">
                      <div className="item" id="email">
                        <Form.Item
                          className="input-wrapper"
                          name="email"
                          rules={yupSync('email', validationSchema, true)}
                        >
                          <CustomInput
                            disabled={isVerifiedEmail}
                            name="Email address"
                            className="input"
                            maxLength={240}
                            onKeyPress={handleKeyPress}
                            placeholder="Email address"
                            onBlur={() => validateFormOnBlur('email')}
                            onChange={(e) => setEmail(e.target.value)}
                            error={emailError ? 'err' : ''}
                          />
                        </Form.Item>
                        {emailError && (
                          <div
                            className="error-text"
                            style={{ margin: '-30px 0 10px 0' }}
                          >
                            Please, enter a valid Email
                          </div>
                        )}
                      </div>
                      {isVerifiedEmail ? (
                        <div className="new-member__varified">
                          <img
                            src={checkcircle}
                            style={{ margin: '0 10px 0 0' }}
                            alt="check"
                          />
                          Verified
                        </div>
                      ) : (
                        <div className="new-member__varification-wrapper">
                          <Button
                            className={`new-member__varification-btn violet-btn ${
                              email
                                ? !emailRegex.test(email) || isDisableSendEmail
                                  ? 'disable'
                                  : ''
                                : 'disable'
                            }`}
                            onClick={() => {
                              setClickOnSendEmail(true);
                              if (
                                !emailRegex.test(email) ||
                                isDisableSendEmail
                              ) {
                              } else {
                                varificationFunc('email');
                              }
                            }}
                          >
                            Send Verification Email
                          </Button>
                          {timeEmail > 0 && countEmailRequest >= 1 ? (
                            <div className="timer">
                              You can resend the code in {formatTime(timeEmail)}
                            </div>
                          ) : (
                            <></>
                          )}
                          <Button
                            className={`new-member__varification-btn blue-btn ${
                              isSuccessEmail ? '' : 'disable'
                            }`}
                            onClick={() => {
                              if (isSuccessEmail) {
                                setVerifyType('email');
                                setIsCodeModal(true);
                              }
                            }}
                          >
                            Verify Email
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="partner-info__row">
                      <Form.Item
                        className="input-wrapper"
                        name="ssn"
                        rules={yupSync('ssn', validationSchema, true)}
                      >
                        <PhoneNumberInput
                          mask="999999999"
                          name="SSN for Verification"
                          onChange={() => {}}
                          className="input"
                          value={undefined}
                          onBlur={() => validateFormOnBlur('ssn')}
                          placeholder={'123456789'}
                          hasError={() => !!form.getFieldError('ssn').length}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main__btns">
              {' '}
              <Button
                className="blue-btn"
                htmlType="submit"
                onClick={() => setClickOnSave(true)}
                disabled={needCheckVin && !vinCheckSuccess && !acceptAnyway}
              >
                Next
              </Button>
            </div>
          </Form>
        </div>
      </div>

      {confirmationModal && (
        <ConfirmationModal
          openModal={confirmationModal}
          setOpenModal={setConfirmationModal}
          type="warning"
          title={
            location.pathname.includes('edit')
              ? 'Are you sure you want to discard the Application Editing?'
              : 'Are you sure you want to discard the Application Creation?'
          }
          cancelText="No"
          confirmText="Yes"
          cancel={() => {
            setClickOnSave(false);
            setConfirmationModal(false);
          }}
          confirm={() => {
            if (isClickOnHome) {
              navigate('/dashboard');
            } else {
              navigate('/applications');
            }
          }}
          closeIcon={true}
        />
      )}
      {successPopup && data?.data && (
        <ConfirmationModal
          openModal={successPopup}
          setOpenModal={setSuccessPopup}
          type="success"
          title="Application was successfully created in status Draft "
          subtitle={`Application ID: ${data?.data?.public_id}`}
          cancelText=""
          confirmText="Okay"
          confirm={() => {
            setSuccessPopup(false);
            navigate(`/applications/edit/${data?.data?.id}`);
          }}
          closeIcon={false}
          maskClosable={false}
        />
      )}
      {confirmationSaveDraftModal && (
        <ConfirmationModal
          openModal={confirmationSaveDraftModal}
          setOpenModal={setConfirmationSaveDraftModal}
          type="success"
          title="Are you sure you want to save new application in status Draft?"
          cancelText="NO"
          confirmText="YES"
          confirm={() => creacteApplicationFunc()}
          cancel={() => setConfirmationSaveDraftModal(false)}
          closeIcon={false}
          maskClosable={false}
        />
      )}
      {confirmationRemoveModal && (
        <ConfirmationModal
          openModal={confirmationRemoveModal}
          setOpenModal={setConfirmationRemoveModal}
          type="success"
          title="Are you sure you want to delete the applicantion?"
          subtitle={`#ID: ${applicantId}`}
          cancelText="NO"
          confirmText="YES"
          confirm={() => {
            setClickOnSave(false);
            removeApplicationDataReq();
          }}
          cancel={() => setConfirmationRemoveModal(false)}
          closeIcon={false}
          maskClosable={false}
        />
      )}
      {isCodeModal && (
        <CodeModal
          openModal={isCodeModal}
          setOpenModal={setIsCodeModal}
          form={form}
          type={verifyType}
          setIsVerifiedPhone={setIsVerifiedPhone}
          setIsVerifiedEmail={setIsVerifiedEmail}
        />
      )}
    </>
  );
};
