import React, {useEffect, useRef, useState} from "react";
import {Input} from "antd";

interface CodeInputI {
    setCode:  React.Dispatch<React.SetStateAction<string>>
    isVerifyError: boolean
    isVerifyLoading: boolean
    resetPurchase: any
}
export const CodeInput = ({
                              setCode,
                              isVerifyError,
                              isVerifyLoading,
                              resetPurchase
}:CodeInputI) => {
    const [otp, setOtp] = useState<string[]>(["", "", "", "", ""]);
    const inputRefs = useRef<any[]>([]);

    const handleOtpChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const value = e.target.value;
        const newOtp = [...otp];

        if (value.match(/[0-9]/gi)) {
            newOtp[index] = value;
            setOtp(newOtp);

            if (value && index < otp.length - 1) {
                const nextInput = e.currentTarget
                    .nextElementSibling as HTMLInputElement | null;
                if (nextInput) {
                    nextInput.focus();
                }
            }
        } else if (
            e.nativeEvent instanceof InputEvent &&
            e.nativeEvent.inputType === "deleteContentBackward"
        ) {
            newOtp[index] = "";
            setOtp(newOtp);
        }
    };

    const handleOnPasteOtp = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const data = e.clipboardData.getData("text");
        const value = data.split("");
        const isValidValue = value.every((item) => item.match(/[0-9]/gi));

        if (value.length === otp.length && isValidValue) {
            setOtp(value);
        }
    };

    const handleKeyUp = (
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number
    ) => {
        const value = e.currentTarget.value;
        const input = e.currentTarget;

        if (e.nativeEvent.key === "Backspace" && value === "") {
            const previousSibling =
                input.previousElementSibling as HTMLInputElement | null;

            if (previousSibling) {
                const newOtp = [...otp];
                newOtp[index - 1] = "";
                setOtp(newOtp);

                previousSibling.focus();
            }
        }
    };

    useEffect(() => {
        setCode(otp.join(''))
    },[otp])

    return (
        <div>
            {otp.map((digit, index) => (
                <Input
                    key={index}
                    ref={(input) => (inputRefs.current[index] = input)}
                    className={`code-input-field ${isVerifyError && 'input-error'}`}
                    type="text"
                    maxLength={1}
                    value={digit}
                    disabled={isVerifyLoading}
                    onChange={(e) => handleOtpChange(e, index)}
                    onPaste={handleOnPasteOtp}
                    onKeyDown={(e) => handleKeyUp(e, index)}
                    onInput={()=> {resetPurchase()}}
                    // onBlur={handleInputFocus}
                />
            ))}
        </div>
    )
}