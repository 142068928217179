import { IOffer } from '../../../../../api/types/offer';
import { Tooltip } from 'antd';
import { MinusIcon } from '../../../../../components/icons/MibusIcon';
import { PlusIcon } from '../../../../../components/icons/PlusIcon';
import info from '../../../../../assets/info-circle.svg';
import './index.scss';
import { OkinusItem } from './OkinusItem';
import { RevolvingLine } from './RevolvingLine';
import { CommonItem } from './CommonItem';
import {MedallionItem} from "./MedallionItem";

interface OfferItemProps {
  offer: any;
  openItem?: number | null;
  selectOption?: any;
  setOpenItem?: any;
  setSelectOption?: any;
  isWithdrawn?: boolean;
}

export enum LoanTypesEnum {
  LeaseToOwn = 'Lease-to-own',
  RevolvingLine = 'Revolving line',
}

export const OfferItem = ({
  offer,
  openItem,
  selectOption,
  setOpenItem,
  setSelectOption,
  isWithdrawn = false,
}: OfferItemProps) => {
  const aprTooltip = (item: any) => {
    return (
      <div>
        <p>Deferred Interest Language</p>
        <p>
          {`No Interest if Paid in Full Within ${item.term}. Interest will accrue and be charged to your Account at ${item.rate}% APR from purchase date if the purchase is not paid in full within ${item.term}, or your account becomes 180 days past due, or is charged off for any reason. 
Monthly minimum payments required. As a reminder, paying only the monthly minimum payment amount each month may not pay off your purchase within ${item.term}. You may have to make additional or increased payments during the deferred interest period to avoid having to pay the accrued deferred interest.`}
        </p>
      </div>
    );
  };
  const minMonthPayTooltip = (item: any) => {
    return (
      <div>
        <p>Monthly Payment Example</p>
        <p>
          Purchase Amount:{' '}
          <strong>
            $
            {item.offer_value
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </strong>
        </p>
        <p>
          Estimated Monthly Payment Amount:{' '}
          <strong>${item?.monthly_payment}</strong>
        </p>
        <p>
          Estimated Number of Months to Repay:{' '}
          <strong>{item?.data?.term_in_month}</strong>
        </p>
        <p>
          Estimated Total Amount of Payments:{' '}
          <strong>${item.data.total_amount_of_payments}</strong>
        </p>
        <p>
          The above estimates assume the Account is maintained in good standing,
          there are no other balances currently or in the future, required
          monthly payments are received timely, and there are no taxes or other
          ancillary charges are added to the purchase amount.
          <strong>
            If there are additional purchases or balances in the future or if
            the Account is not maintained in good standing, the required monthly
            payment amount will increase and it will take longer to pay off the
            purchase.
          </strong>{' '}
          For the complete terms and conditions of the required minimum payment
          calculation, please see the terms and conditions of the Account in the
          following screens
        </p>
        <p>
          <strong>Monthly Minimum Payment.</strong> Your Monthly Minimum Payment
          due will be the sum of:{' '}
        </p>
        <p>1) Each fixed payment amount applicable to each Purchase, plus </p>
        <p>2) 5% of the Regular Balance</p>
        <p>
          Each Purchase will have a fixed payment amount which we calculate when
          the Purchase is posted to your Account. The fixed payment amount for a
          Purchase will be the greater of $1, or 5%, of the original Purchase
          amount, rounded to the nearest dollar
        </p>
      </div>
    );
  };

  return (
    <div className="OfferItem">
      <div className={`OfferItem__item`}>
        <div>{offer.name}</div>
        {openItem === offer.id ? (
          <div
            className="OfferItem__minus"
            onClick={() => {
              setOpenItem(null);
              setSelectOption(null);
            }}
          >
            <MinusIcon fill={'#1F44DD'} width="23" height="23" />
          </div>
        ) : (
          <div
            className="OfferItem__plus"
            onClick={() => {
              setOpenItem(offer.id);
              setSelectOption(null);
            }}
          >
            <PlusIcon fill={'white'} width="25" height="25" />
          </div>
        )}
      </div>

      <div
        className={`OfferItem__openItem ${
          openItem === offer.id ? 'open' : 'closed'
        }`}
      >
        {offer?.banks?.map((elem: any) => {
          return (
            <>
              <div className="OfferItem__head">
                <div className="OfferItem__title">
                  <img
                    src={elem.bank_logo}
                    className="OfferItem__logo"
                    alt="bank logo"
                  />
                    <span>{elem.bank_name}</span>
                    {offer.name === "Promotional" && <span className="OfferItem__offer_name">{elem.loan_type}</span>}

                  {/* <div className="OfferItem__i">
                  <InfoIcon fill={'black'} />
                </div> */}
                </div>
              </div>
              <div className="OfferItem__line"></div>
              {elem.offers?.map((item: any, index: number) => {
                if (
                  offer.name === LoanTypesEnum.LeaseToOwn &&
                  elem.bank_name === 'Okinus'
                ) {
                  return (
                    <OkinusItem
                      openItem={openItem}
                      offer={offer}
                      selectOption={selectOption}
                      item={item}
                      elem={elem}
                      index={index}
                      setSelectOption={setSelectOption}
                    />
                  );
                } else if (elem.bank_name === 'Medallion') {
                    return <MedallionItem
                        openItem={openItem}
                        offer={offer}
                        selectOption={selectOption}
                        item={item}
                        elem={elem}
                        index={index}
                        setSelectOption={setSelectOption}
                    />
                } else if (offer.name === LoanTypesEnum.RevolvingLine) {
                  return (
                    <RevolvingLine
                      openItem={openItem}
                      offer={offer}
                      selectOption={selectOption}
                      item={item}
                      elem={elem}
                      index={index}
                      setSelectOption={setSelectOption}
                    />
                  );
                } else {
                  return (
                    <CommonItem
                      openItem={openItem}
                      offer={offer}
                      selectOption={selectOption}
                      item={item}
                      elem={elem}
                      index={index}
                      setSelectOption={setSelectOption}
                    />
                  );
                }
              })}
            </>
          );
        })}
      </div>
    </div>
  );
};
