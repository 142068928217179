import { CreateMainAccount } from "../MainAccounts/CreateMainAccount/CreateMainAccount";
import { CreateAdmin } from "../ManageAdmins/CreateAdmin/CreateAdmin";
import { CreatePartner } from "../Partners/CreatePartner/CreatePartner";
import useProfileController from "./profile.controller";

export const EditProfilePage = () => {
  const { isAdmin, isParnter, isMainAcc } = useProfileController();

  if (isAdmin) {
    return <CreateAdmin isProfile />;
  }

  if (isParnter) {
    return <CreatePartner isProfile />;
  }

  if (isMainAcc) {
    return <CreateMainAccount isProfile />;
  }

  return null;
};
