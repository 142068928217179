import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from './general';
import {
    GetLenderByIdRes,
    LendersTableParams,
    LoanTypeRes,
} from './types/lenders';

export const lendersApi = createApi({
    reducerPath: 'lendersApi',
    baseQuery,
    endpoints: (builder) => ({
        editLender: builder.mutation({
            query: (data) => ({
                url: `/lenders/${data.id}`,
                method: 'PUT',
                body: data.data,
            }),
        }),
        getLendersList: builder.query<any, LendersTableParams>({
            query: (params) => ({
                url: '/lenders/',
                method: 'GET',
                params: params,
            }),
        }),
        getLoanTypes: builder.query<LoanTypeRes, void>({
            query: () => ({
                url: '/loan-types/',
                method: 'GET',
            }),
        }),
        getLender: builder.query<GetLenderByIdRes, number | string>({
            query: (id) => ({
                url: `/lenders/${id}`,
                method: 'GET',
            }),
        }),
        getFico: builder.query<any, void>({
            query: () => ({
                url: `/fico-ranges/`,
                method: 'GET',
            }),
        }),
        changeLendersStatus: builder.mutation({
            query: (data) => ({
                url: `/lenders/${data.id}/status`,
                method: 'PUT',
                body: data.key,
            }),
        }),
        validateCredentials: builder.mutation({
            query: (data) => ({
                url: `/lenders/${data.bank_id}/validate-credentials`,
                method: 'POST',
                body: data.api,
            }),
        }),
    }),
});

export const {
    useEditLenderMutation,
    useLazyGetLendersListQuery,
    useChangeLendersStatusMutation,
    useLazyGetLoanTypesQuery,
    useLazyGetLenderQuery,
    useLazyGetFicoQuery,
    useGetFicoQuery,
    useValidateCredentialsMutation,
} = lendersApi;
