import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet } from 'react-router-dom';
import { ServerError } from '../pages/ErrorPages/ServerError';

export const PrivateRoutes = () => {
  const token = localStorage.getItem('token');

  return (
    <ErrorBoundary
      fallback={
        <>
          <ServerError />
        </>
      }
    >
      {token ? <Outlet /> : <Navigate to="/login" />}
    </ErrorBoundary>
  );
};
