import {createApi} from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import {
    LendersFunnelEditParams,
    LendersFunnelParams,
    LendersFunnelResponse,
    LendersFunnelSynk
} from "./types/lendersFunnel";

export const lendersFunnelApi = createApi({
    reducerPath: 'lendersFunnelApi',
    baseQuery,
    tagTypes: ['LendersFunnel'],
    endpoints: (builder) => ({
        getLendersFunnel: builder.query <LendersFunnelResponse,LendersFunnelParams>({
            query: (params) => ({
                url: '/lenders-funnel',
                method: 'GET',
                params,
            }),
            providesTags: ['LendersFunnel']
        }),
        editLenderFunnel: builder.mutation<LendersFunnelResponse, LendersFunnelEditParams>({
            query: ({ params, data }) => ({
                url: `/lenders-funnel`,
                method: 'POST',
                body: data,
                params,
            }),
            invalidatesTags: ['LendersFunnel']
        }),
        synkLenderFunnel: builder.mutation<LendersFunnelResponse, LendersFunnelSynk>({
            query: (data) => ({
                url: `/lenders-funnel`,
                method: 'PUT',
                body: data,
            }),
        }),
    })
})
export const {
    useEditLenderFunnelMutation,
    useLazyGetLendersFunnelQuery,
    useSynkLenderFunnelMutation,
} = lendersFunnelApi