export const TitleRecepientInfo = ({bank} : any) => {
    return (
        <div className="title-recepient-info">
            <div className="block__title">Title Recepient Info</div>
            <div className="lender-info">
                <div className="block__row">
                    {bank?.address && (
                        <div className="block__item small">
                            <div className="block__item-title">Address</div>
                            <div className="block__item-value">
                                {bank?.address}
                            </div>
                        </div>
                    )}
                    {bank?.city && (
                        <div className="block__item small">
                            <div className="block__item-title">City</div>
                            <div className="block__item-value">
                                {bank?.city}
                            </div>
                        </div>
                    )}
                </div>
                <div className="block__row">
                    {bank?.state && (
                        <div className="block__item small">
                            <div className="block__item-title">State</div>
                            <div className="block__item-value">
                                {bank?.state?.name}
                            </div>
                        </div>
                    )}
                    {bank?.zip_code && (
                        <div className="block__item small">
                            <div className="block__item-title">Zip Code</div>
                            <div className="block__item-value">
                                {bank?.zip_code}
                            </div>
                        </div>
                    )}
                </div>
                <div className="block__row">
                    {bank?.addressee && (
                        <div className="block__item small">
                            <div className="block__item-title">Addressee</div>
                            <div className="block__item-value">
                                {bank?.addressee}
                            </div>
                        </div>
                    )}
                    {bank?.email && (
                        <div className="block__item small">
                            <div className="block__item-title">Email</div>
                            <div className="block__item-value">
                                {bank?.email}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}