import {
  useGetAccountTypesQuery,
  useGetMainAccountsQuery,
} from '../../api/common';
import { useNavigate } from 'react-router-dom';
import plus from '../../assets/plus.svg';
import { useState, useEffect } from 'react';
import { CustomInput } from '../../customFields/CustomInput';
import { CustomSelect } from '../../customFields/CustomSelect';
import { PartnersTable } from '../../components/Tables/PartnersTable';
import { useLazyGetPartnersListQuery } from '../../api/partners';
import searchIcon from '../../assets/searchIcon.svg';
import cancel from '../../assets/x-close.svg';
import { TableParams } from '../../api/types/partners';
import { Spinner } from '../../components/Spinner/Spinner';
import { AccountTypesItem, MainAccountItem } from '../../api/types/common';
import { useAppSelector } from '../../hooks';
import { ConfirmationModal } from '../../components/Modals/ConfirmationModal';
import './index.scss';
import useDebounce from '../../hooks/useDebounce';
import { UserI } from '../../reducers/profile/profileSlice';
import { Segmented, message } from 'antd';

export interface idsI {
  id: number;
  key: { status_key: number };
}

export interface TablePartnersDataI {
  length?: number;
  id: number;
  company_name: string;
  account_types?: AccountTypesItem[];
  city: string;
  state: string;
  phone: string;
  status: string;
  logo: string;
  main_account?: {
    last_name?: string;
    first_name?: string;
    company_name: string;
  };
}

export const Partners = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<TablePartnersDataI[]>([]);
  const { data: accountTypesData } = useGetAccountTypesQuery();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [openAddNewPartnerModal, setOpenAddNewPartnerModal] = useState(false);
  const [searchMainAcc, setSearchMainAcc] = useState('');
  const debouncedSearchMainAcc = useDebounce(searchMainAcc);
  const { data: mainAccounts } = useGetMainAccountsQuery({
    search: debouncedSearchMainAcc,
  });
  const [type, setType] = useState<string | number>('Partner');

  const [tableParams, setTableParams] = useState<TableParams>({
    per_page: 50,
    search: '',
    account_type_id: null,
    order_by: '',
    order: 'desc',
    status_key: '',
    page: 1,
    main_account_id: null,
  });
  const [messageApi, contextHolder] = message.useMessage();
  const [getList, { data, isLoading, isError }] = useLazyGetPartnersListQuery();
  const user = useAppSelector((state: { profile: UserI }) => state.profile);
  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchInput = useDebounce(searchInput);

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    setTableParams((prev: TableParams) => ({
      ...prev,
      search: debouncedSearchInput,
    }));
  }, [debouncedSearchInput]);

  useEffect(() => {
    if (!user.email) return;
    if (user.role.key === 'partner') {
      navigate('/403');
    }
  }, [user.email]);

  useEffect(() => {
    setTableParams((prev: TableParams) => ({ ...prev, page: 1 }));
  }, [tableParams.search, tableParams.account_type_id, tableParams.status_key]);

  useEffect(() => {
    getList(tableParams);
  }, [tableParams, trigger]);

  useEffect(() => {
    if (isError) {
      errorMessage('Fetch partners failed');
    }
  }, [isError]);

  useEffect(() => {
    if (!data?.data) return;
    const res: TablePartnersDataI[] = data?.data.map((elem) => {
      return {
        id: elem.id,
        company_name: elem.company_name,
        account_types: elem.account_types,
        city: elem.city,
        state: elem.state,
        phone: elem.contact_number,
        status: elem.status,
        logo: elem.logo,
        main_account: elem.main_account,
        item_id: elem.item_id,
      };
    });
    setTableData(res);
  }, [data?.data]);

  return (
    <div className="container">
      {contextHolder}
      {isLoading && <Spinner size="large" />}
      <div className="members">
        <div className="members__row">
          <div className="members__title">Partners</div>
          {user?.role?.key === 'main_account' ? (
            <></>
          ) : (
            <div
              className="members__add"
              onClick={() => {
                if (
                  user.role.key === 'super_admin' ||
                  user.role.key === 'admin'
                ) {
                  setOpenAddNewPartnerModal(true);
                }
                if (user.role.key === 'main_account') {
                  navigate('/new-partner/partner');
                }
              }}
            >
              <img src={plus} className="members__add-image" /> Add new
            </div>
          )}
        </div>

        <div className="members__table">
          <div className="members__filters" style={{ margin: '0 0 10px 0' }}>
            <CustomInput
              style={{ width: '350px', margin: '0 16px 0 0' }}
              className="input-white-small"
              name="Search"
              placeholder="Search by Company Name or ID"
              value={searchInput}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchInput(e.target.value)
              }
              prefix={<img src={searchIcon} />}
              suffix={
                tableParams.search ? (
                  <img
                    className="cancel-btn"
                    style={{ margin: '0 0 0 0' }}
                    src={cancel}
                    onClick={() => {
                      setSearchInput('');
                      setTableParams((prev: TableParams) => ({
                        ...prev,
                        search: '',
                      }));
                    }}
                  />
                ) : (
                  <></>
                )
              }
            />

            {user?.role?.key !== 'main_account' && (
              <div
                className="members__select"
                style={{ width: '27%', margin: '0 16px 0 0' }}
              >
                <CustomSelect
                  allowClear
                  options={accountTypesData?.data?.map(
                    (elem: AccountTypesItem) => {
                      return { value: elem.name, label: elem.id };
                    }
                  )}
                  name="Account Type"
                  className="input-white-small"
                  placeholder="Show All"
                  onChange={(value: any) =>
                    setTableParams((prev: TableParams) => ({
                      ...prev,
                      account_type_id: value,
                    }))
                  }
                  suffixIcon={
                    tableParams.account_type_id ? (
                      <img
                        className="cancel-btn"
                        src={cancel}
                        onClick={(e) => {
                          setTableParams((prev: TableParams) => ({
                            ...prev,
                            account_type_id: null,
                          }));
                        }}
                      />
                    ) : (
                      false
                    )
                  }
                />
              </div>
            )}

            {user?.role?.key !== 'main_account' && (
              <div
                className="members__select"
                style={{ width: '27%', margin: '0 16px 0 0' }}
              >
                <CustomSelect
                  allowClear
                  className="input-white-small"
                  name="Main Account"
                  options={mainAccounts?.data?.map((elem: MainAccountItem) => {
                    return {
                      value: elem.name,
                      label: elem.id,
                      item_id: elem.item_id,
                    };
                  })}
                  placeholder="Show All"
                  onSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchMainAcc(e.target.value)
                  }
                  onChange={(value: any) =>
                    setTableParams((prev: TableParams) => ({
                      ...prev,
                      main_account_id: value,
                    }))
                  }
                  suffixIcon={
                    tableParams.main_account_id ? (
                      <img
                        className="cancel-btn"
                        src={cancel}
                        onClick={(e) => {
                          e.stopPropagation();
                          setTableParams((prev: TableParams) => ({
                            ...prev,
                            main_account_id: null,
                          }));
                        }}
                      />
                    ) : (
                      false
                    )
                  }
                  isSearchSelect={true}
                />
              </div>
            )}

            <div className="members__select" style={{ width: '140px' }}>
              <CustomSelect
                allowClear
                options={[
                  { value: 'Active', label: '2' },
                  { value: 'Pending', label: '0' },
                  { value: 'Paused', label: '1' },
                ]}
                name="Status"
                className="input-white-small"
                placeholder="Show All"
                onChange={(value: any) =>
                  setTableParams((prev: TableParams) => ({
                    ...prev,
                    status_key: value,
                  }))
                }
                suffixIcon={
                  tableParams.status_key ? (
                    <img
                      style={{
                        width: '20px',
                        margin: '-5px 0 0 0',
                        cursor: 'pointer',
                      }}
                      src={cancel}
                      onClick={(e) => {
                        e.stopPropagation();
                        setTableParams((prev: TableParams) => ({
                          ...prev,
                          status_key: '',
                        }));
                      }}
                    />
                  ) : (
                    false
                  )
                }
              />
            </div>
          </div>
          <PartnersTable
            data={tableData}
            setTableParams={setTableParams}
            tableParams={tableParams}
            metaData={data}
            setTrigger={setTrigger}
            setOpenAddNewPartnerModal={setOpenAddNewPartnerModal}
          />
        </div>
      </div>
      {openAddNewPartnerModal && (
        <ConfirmationModal
          openModal={openAddNewPartnerModal}
          setOpenModal={setOpenAddNewPartnerModal}
          type={'informative'}
          title={'Choose partner type'}
          subtitle={
            <div style={{ margin: '10px 0 20px 0' }}>
              <Segmented
                options={['Partner', 'Related Partner', 'External Partner']}
                value={type}
                onChange={setType}
              />
            </div>
          }
          cancelText={'Cancel'}
          confirmText={'Next'}
          confirm={() => {
            if (type === 'Partner') {
              navigate('/new-partner/partner');
            }
            if (type === 'Related Partner') {
              navigate('/new-partner/related_partner');
            }
            if (type === 'External Partner') {
              navigate('/new-partner/external_partner');
            }
          }}
          cancel={() => setOpenAddNewPartnerModal(false)}
          closeIcon={true}
          className={'informative'}
        />
      )}
    </div>
  );
};
