import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../../assets/logo.svg';
import { useAppDispatch } from '../../hooks';
import * as Yup from 'yup';
import { useLoginMutation } from '../../api/auth';
import image from '../../assets/mainLoginImage.svg';
import './index.scss';
import { Button, Checkbox, Form, message } from 'antd';
import { CustomInput } from '../../customFields/CustomInput';
import { yupSync } from '../../utils';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { ForgotPassword } from './ForgotPassword';
import { Spinner } from '../../components/Spinner/Spinner';
import { SuccessPopup } from './SuccessPopup';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { CustomErrorType } from '../../types.ts/commonTypes';
import { isApiError } from '../../utils/general';

interface FormData {
  email: string;
  password: string;
  remember_me?: boolean;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is invalid')
    .required('This field is required'),
  password: Yup.string().required('This field is required'),
});

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<FormData>();
  const [isOpenForgotPasswordModal, setIsOpenForgotPasswordModal] =
    useState<boolean>(false);
  const [isSuccessPopup, setIsSuccessPopup] = useState<boolean>(false);

  const [login, { data, isSuccess, isLoading, originalArgs, isError, error }] =
    useLoginMutation();
  const { handleKeyPress } = usePreventSpaceTyping();
  const [searchParams] = useSearchParams();
  const [credError, setCredError] = useState<string>('');

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      if (data && data.access_token) {
        localStorage.setItem('token', data.access_token);
        navigate('/dashboard');
      }
    }
    if (isError) {
      setCredError((error as CustomErrorType)?.data.message);
    }
  }, [isSuccess, isError]);

  const loginFunc = async (params: FormData) => {
    try {
      await login(params).unwrap();
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Auth failed');
    }
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: FormData) => {
        const formData = {
          email: values.email.toLowerCase(),
          password: values.password,
          remember_me: values.remember_me,
        };
        loginFunc(formData);
      })
      .catch(() => {});
  };

  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
    if (name === 'password') {
      setCredError('');
    }
  };

  return (
    <>
      {contextHolder}
      <div className="auth">
        <div className="auth__block block">
          <div className="block__login">
            <div className="block__title">Log in</div>
            <div className="block__subtitle"></div>
            <div className="block__form">
              <Form form={form} onFinish={handleFormSubmit}>
                <Form.Item
                  className="input-wrapper"
                  name="email"
                  rules={yupSync('email', validationSchema, true)}
                >
                  <CustomInput
                    name="Email"
                    className="input"
                    maxLength={100}
                    onKeyPress={handleKeyPress}
                    placeholder="example@gmail.com"
                    onBlur={() => validateFormOnBlur('email')}
                    error={credError}
                    onChange={() => setCredError('')}
                  />
                </Form.Item>
                <Form.Item
                  className="input-wrapper"
                  name="password"
                  rules={yupSync('password', validationSchema, true)}
                >
                  <CustomInput
                    name="Password"
                    className="input"
                    maxLength={100}
                    type="password"
                    onKeyPress={handleKeyPress}
                    placeholder="********"
                    onBlur={() => validateFormOnBlur('password')}
                    error={credError}
                    onChange={() => setCredError('')}
                  />
                </Form.Item>
                <div
                  className="error-text"
                  style={{ margin: '-20px 0 15px 0' }}
                >
                  {credError}
                </div>

                <div
                  className="block__forgot"
                  onClick={() => setIsOpenForgotPasswordModal(true)}
                >
                  Forgot the password?
                </div>
                <div className="block__info-wrap info-wrap">
                  <Button
                    className="blue-btn"
                    htmlType="submit"
                    style={{ height: '32px' }}
                  >
                    Log In {isLoading && <Spinner />}
                  </Button>
                  <Form.Item
                    className="info-wrap__remember"
                    name="remember_me"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox className="info-wrap__checkbox">
                      <div className="info-wrap__title">Remember me</div>
                    </Checkbox>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="auth__block block">
          <img src={image} className="block__image" />
        </div>
      </div>

      {isOpenForgotPasswordModal && (
        <ForgotPassword
          openModal={isOpenForgotPasswordModal}
          setOpenModal={setIsOpenForgotPasswordModal}
          setIsSuccessPopup={setIsSuccessPopup}
        />
      )}
      {isSuccessPopup && (
        <SuccessPopup
          openModal={isSuccessPopup}
          setOpenModal={setIsSuccessPopup}
        />
      )}
    </>
  );
};
