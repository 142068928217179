import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import {
  TableParamsGetTotals,
  TotalsData,
  FoundingData,
  TopsI,
  StatusesI,
} from './types/dashboard';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery,
  endpoints: (builder) => ({
    getTotals: builder.query<TotalsData, TableParamsGetTotals>({
      query: (params) => ({
        url: '/dashboard/totals',
        method: 'GET',
        params: params,
      }),
    }),
    getFounding: builder.query<FoundingData, TableParamsGetTotals>({
      query: (params) => ({
        url: '/dashboard/funding',
        method: 'GET',
        params: params,
      }),
    }),
    getFees: builder.query<FoundingData, TableParamsGetTotals>({
      query: (params) => ({
        url: '/dashboard/fees',
        method: 'GET',
        params: params,
      }),
    }),
    getTops: builder.query<TopsI, TableParamsGetTotals>({
      query: (params) => ({
        url: '/dashboard/top',
        method: 'GET',
        params: params,
      }),
    }),
    getStatuses: builder.query<StatusesI, TableParamsGetTotals>({
      query: (params) => ({
        url: '/dashboard/statuses',
        method: 'GET',
        params: params,
      }),
    }),
    getFirst: builder.query({
      query: () => ({
        url: '/dashboard/first',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetTotalsQuery,
  useLazyGetFoundingQuery,
  useLazyGetFeesQuery,
  useLazyGetTopsQuery,
  useLazyGetStatusesQuery,
  useGetFirstQuery,
} = dashboardApi;
