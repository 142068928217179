import { Button } from 'antd';
import { useLogoutMutation } from '../../api/common';
import { useNavigate } from 'react-router-dom';

export const Clients = () => {
  return (
    <div>
      <h1>Clients</h1>
    </div>
  );
};
