import {maskAccountNumber} from "../../utils/maskAccountNumber";
interface BankDetailsViewI {
    bank_details: {
        bank_name:string
        legal_business_name:string
        routing_number:number
        accounting_number:number
        account_holder_name:string
    }
}
export const BankDetailsView = ({ bank_details }:BankDetailsViewI) => {
    return <div
        className="block__common-info"
        style={{ padding: '24px 0 0px 0' }}
    >
        <div className="block__title">Banking Information</div>
        <div className="block__row">
            <div className="block__item small">
                <div className="block__item-title">Bank Name</div>
                <div className="block__item-value">
                    {bank_details.bank_name}
                </div>
            </div>
            <div className="block__item small">
                <div className="block__item-title">Legal Business Name</div>
                <div className="block__item-value">
                    {bank_details.legal_business_name}
                </div>
            </div>
        </div>
        <div className="block__row">
            <div className="block__item small">
                <div className="block__item-title">Routing Number</div>
                <div className="block__item-value">
                    {bank_details.routing_number}
                </div>
            </div>
            <div className="block__item small">
                <div className="block__item-title">Account Number</div>
                <div className="block__item-value">
                    {maskAccountNumber(
                        bank_details.accounting_number
                    )}
                </div>
            </div>
        </div>
        <div className="block__item">
            <div className="block__item-title">Account Holder's Name</div>
            <div className="block__item-value">
                {bank_details.account_holder_name}
            </div>
        </div>
    </div>
}