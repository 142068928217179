import { iconI } from "./types";

export const LendersIcon = ({ fill, style }: iconI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      style={style}
    >
      <path
        d="M19.9003 6.73684H1.37903C1.28574 6.73684 1.25645 6.61082 1.34018 6.56968L9.7576 2.43343C10.3139 2.16009 10.9654 2.16009 11.5217 2.43343L19.9391 6.56968C20.0228 6.61082 19.9936 6.73684 19.9003 6.73684Z"
         stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M2.09375 18.6113L19.4687 18.6113"
         stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.0273 10.0088L16.0273 14.8027"
         stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.22656 10.0088L5.22656 14.8027"
         stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.7031 10.0088L10.7031 14.8027"
         stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
