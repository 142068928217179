import { Table, Tooltip } from 'antd';
import { useRef } from 'react';
import arrow from '../../assets/arrow-down.svg';
import noData from '../../assets/noData.svg';
import NoUser from '../../assets/NoUser.svg';
import type { ColumnsType } from 'antd/es/table';
import { Pagination } from './Pagination';
import { useAppSelector } from '../../hooks';
import {
  TableParamsReports,
  TableItemReportApplications,
  TableDataReportsApplications,
  Totals,
} from '../../api/types/reports';
import './index.scss';
import moment from 'moment';

interface PartnersTableProps {
  data: TableItemReportApplications[];
  setTableParams: React.Dispatch<React.SetStateAction<TableParamsReports>>;
  metaData: any;
  tableParams: TableParamsReports;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  dataTotals?: Totals;
}

export const ReportApplications = ({
  data,
  setTableParams,
  metaData,
  tableParams,
  setTrigger,
  dataTotals,
}: PartnersTableProps) => {
  const ref = useRef(null);
  const user = useAppSelector((state) => state.profile);

  const sorting = (name: string) => {
    setTableParams((prev: TableParamsReports) => ({
      ...prev,
      order_by: name,
      order:
        prev.order_by === name
          ? prev.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'asc',
    }));
  };

  const columns: ColumnsType<TableItemReportApplications> = [
    {
      title: (
        <div className="header-item" onClick={() => sorting('id')}>
          App ID
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'id' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      width: 70,
      dataIndex: 'id',
      key: 'id',
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('main_account')}>
          Main Account
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'main_account' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      width: 100,
      dataIndex: 'main_account',
      key: 'main_account',
      render: (text) => (
        <div className="table-item">
          <Tooltip title={text}>
            <div className="table-text">{text ? text : '-'}</div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('partner')}>
          Partner
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'partner' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      dataIndex: 'partner',
      key: 'partner',
      render: (text) => (
        <div className="table-item">
          <Tooltip title={text}>
            <div className="table-text"> {text}</div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('lender')}>
          Lender
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'lender' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      dataIndex: 'lender',
      key: 'lender',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text"> {text ? text : '-'}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('account_type')}>
          Account Type
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'account_type' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      width: 100,
      dataIndex: 'account_type',
      key: 'account_type',
      render: (text, record) => (
        <div className="table-item">
          <Tooltip title={text}>
            <div className="table-text"> {text ? text : '-'}</div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('product')}>
          Product
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'product' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      dataIndex: 'product',
      key: 'product',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text"> {text ? text : '-'}</div>
        </div>
      ),
    },
    {
      title: (
        <div
          className="header-item"
          onClick={() => sorting('application_status')}
        >
          App Status
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'application_status' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      width: 115,
      dataIndex: 'application_status',
      key: 'application_status',
      render: (text, record) => (
        <div className="table-item">
          {text === 'Offer Made' && (
            <div className="table-text status offerMade">{text}</div>
          )}
          {text === 'No Offer' && (
            <div className="table-text status noOffer">{text}</div>
          )}
          {text === 'Awaiting Signature' && (
            <div className="table-text status Awaiting">{text}</div>
          )}
          {text === 'Approved' && (
            <div className="table-text status Approved">{text}</div>
          )}
          {text === 'Draft' && (
            <div className="table-text status Draft">{text}</div>
          )}
        </div>
      ),
    },
    {
      title: (
        <div
          className="header-item"
          onClick={() => sorting('transaction_status')}
        >
          Trans Status
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'transaction_status' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      width: 115,
      dataIndex: 'transaction_status',
      key: 'transaction_status',
      render: (text, record) => (
        <div className="table-item">
          {text === 'In Progress' && (
            <div className="table-text status offerMade">{text}</div>
          )}
          {text === 'Timed Out' && (
            <div className="table-text status noOffer">{text}</div>
          )}
          {text === 'Withdrawn' && (
            <div className="table-text status Withdrawn">{text}</div>
          )}
          {text === 'Approved' && (
            <div className="table-text status Approved">{text}</div>
          )}
          {!text && '-'}
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('loan_amount')}>
          Loan Amount
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'loan_amount' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      width: 100,
      dataIndex: 'loan_amount',
      key: 'loan_amount',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">
            $
            {Math.round(text)
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('rate')}>
          Rate
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'rate' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      width: 60,
      dataIndex: 'rate',
      key: 'rate',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text"> {text}%</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('term')}>
          Term
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'term' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      width: 60,
      dataIndex: 'term',
      key: 'term',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('payment')}>
          Payment
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'payment' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      dataIndex: 'payment',
      key: 'payment',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">
            $
            {Math.round(text)
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('created_at')}>
          Date
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'created_at' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">{moment(text).format('MM/DD/YYYY')}</div>
        </div>
      ),
    },
  ];

  if (user.role.key === 'main_account' || user.role.key === 'partner') {
    const roleColumnIndex2 = columns.findIndex(
      (column) => column.key === 'main_account'
    );
    if (roleColumnIndex2 !== -1) {
      columns.splice(roleColumnIndex2, 1);
    }
  }
  if (user.role.key === 'partner') {
    const roleColumnIndex2 = columns.findIndex(
      (column) => column.key === 'partner'
    );
    if (roleColumnIndex2 !== -1) {
      columns.splice(roleColumnIndex2, 1);
    }
  }

  const customNoDataText = {
    emptyText: (
      <>
        {!tableParams.date_from && !tableParams.search && (
          <div className="no-data-found">
            <img src={NoUser} />
            <div className="no-data-found__text">No reports yet</div>
          </div>
        )}
        {(tableParams.date_from || tableParams.search) && (
          <div className="no-data-found">
            <img src={noData} />
            <div className="no-data-found__text">No results found</div>
            <div className="no-data-found__subtext">
              Try adjusting your search to find what you are looking for
            </div>
          </div>
        )}
      </>
    ),
  };

  return (
    <>
      <div>
        <Table
          ref={ref}
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={customNoDataText}
        />
      </div>

      {data?.length > 0 && (
        <Pagination
          setTableParams={setTableParams}
          metaData={metaData}
          tableParams={tableParams}
        />
      )}
    </>
  );
};
