import { FC } from 'react';
import './table.scss';
import { BankI } from '../../api/types/common';
import { PartnerItem } from '../../api/types/partners';

interface TableI {
  title: string;
  total: number | undefined;
  value: any;
}

export const TableForViewUserPage: FC<TableI> = ({ title, total, value }) => {
  return (
    <div className="table">
      <div className="table__row">
        <div className="table__title">{title}</div>
        <div className="table__total">
          Total:<span style={{ color: '#4D4D56' }}> {total}</span>
        </div>
      </div>
      <div
        className="table__list"
        style={value && value?.length <= 6 ? { overflowY: 'auto' } : {}}
      >
        {value?.length
          ? value?.map((elem: { name: string; id: number }) => {
              return (
                <div key={elem.id} className="table__item">
                  <div className="table__item-title">{elem.name}</div>
                  <div className="table__item-id">(ID: {elem.id})</div>
                </div>
              );
            })
          : '-'}
      </div>
    </div>
  );
};
