import { iconI } from './types';

export const ReportsIcon = ({ fill, style }: iconI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      style={style}
    >
      <g clipPath="url(#clip0_4835_9628)">
        <path
          d="M4.14804 3.0674C4.44441 2.86937 4.75561 2.69761 5.07805 2.55321C5.99263 2.14363 6.44992 1.93884 7.05829 2.33298C7.66665 2.72711 7.66665 3.37365 7.66665 4.66671V5.66671C7.66665 6.92379 7.66665 7.55233 8.05717 7.94285C8.4477 8.33338 9.07624 8.33338 10.3333 8.33338H11.3333C12.6264 8.33338 13.2729 8.33338 13.6671 8.94174C14.0612 9.55011 13.8564 10.0074 13.4468 10.922C13.3024 11.2444 13.1307 11.5556 12.9326 11.852C12.2367 12.8935 11.2476 13.7053 10.0903 14.1846C8.93305 14.664 7.65962 14.7894 6.43108 14.545C5.20253 14.3006 4.07404 13.6975 3.18831 12.8117C2.30257 11.926 1.69938 10.7975 1.45501 9.56895C1.21064 8.3404 1.33606 7.06698 1.81541 5.90972C2.29477 4.75245 3.10653 3.76332 4.14804 3.0674Z"
          stroke={fill}
        />
        <path
          d="M15.2973 3.71263C14.7561 2.33883 13.6611 1.24382 12.2873 0.702613C11.2596 0.297753 10.3333 1.22873 10.3333 2.3333V4.99997C10.3333 5.36816 10.6318 5.66664 11 5.66664H13.6666C14.7712 5.66664 15.7022 4.74033 15.2973 3.71263Z"
          stroke={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4835_9628">
          <rect width="16" height="16" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};
