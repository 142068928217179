import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import { AdminsTableResponse, TableParamsAdmin } from './types/admins';
import { ApplicantI, varificationBody } from './types/applicants';

export const applicantsApi = createApi({
  reducerPath: 'applicantsApi',
  baseQuery,
  endpoints: (builder) => ({
    getApplicantsList: builder.query<AdminsTableResponse, TableParamsAdmin>({
      query: (params) => ({
        url: '/applicants/list',
        method: 'GET',
        params: params,
      }),
    }),
    createApplication: builder.mutation({
      query: (arg) => ({
        url: 'partners/applicant-create',
        method: 'POST',
        body: arg,
      }),
    }),
    editApplication: builder.mutation({
      query: (data) => ({
        url: `/partners/applications/${data.id}`,
        method: 'PUT',
        body: data.data,
      }),
    }),
    getApplication: builder.query<ApplicantI, number | string>({
      query: (id) => ({
        url: `partners/applicant-show/${id}`,
        method: 'GET',
      }),
    }),
    sendVarificationCode: builder.mutation<string, varificationBody>({
      query: (data) => ({
        url: 'partners/send-verification-codes',
        method: 'POST',
        body: data,
      }),
    }),
    VerifyCode: builder.mutation<string, varificationBody>({
      query: (data) => {
        return {
          url: '/partners/check-verification-codes',
          method: 'POST',
          body: data,
        };
      },
    }),
    CheckVinCode: builder.mutation({
      query: (data) => {
        return {
          url: '/partners/check-vin-code',
          method: 'POST',
          body: data,
        };
      },
    }),
    editApplicants: builder.mutation({
      query: (data) => ({
        url: `/partners/applicant-update/${data.id}`,
        method: 'PUT',
        body: data.data,
      }),
    }),
    removeApplicant: builder.mutation<void, number>({
      query: (id) => ({
        url: `/partners/applicants/${id}`,
        method: 'DELETE',
      }),
    }),
    approveDelivery: builder.mutation({
      query: (id) => ({
        url: `/applicants/${id}/submit-delivery`,
        method: 'PUT',
      }),
    }),
    confirmDelivery: builder.mutation({
      query: (id) => ({
        url: `partners/applicant/${id}/approve`,
        method: 'PUT',
      }),
    }),
    checkRoutingNumber: builder.mutation({
      query: (data: { routing_number: string }) => {
        return {
          url: '/partners/check-routing-number',
          method: 'POST',
          body: data,
        };
      },
    }),
    getBillPhone: builder.query({
      query: () => ({
        url: `/bill-phone/`,
        method: 'GET',
      }),
    }),
    getBillCode: builder.query({
      query: () => ({
        url: `/bill-code/`,
        method: 'GET',
      }),
    }),
    storeBillCode: builder.mutation({
      query: (data: { code: string }) => {
        return {
          url: '/bill-code',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLazyGetApplicantsListQuery,
  useCreateApplicationMutation,
  useEditApplicationMutation,
  useLazyGetApplicationQuery,
  useSendVarificationCodeMutation,
  useVerifyCodeMutation,
  useEditApplicantsMutation,
  useRemoveApplicantMutation,
  useCheckVinCodeMutation,
  useApproveDeliveryMutation,
  useConfirmDeliveryMutation,
  useCheckRoutingNumberMutation,
  useLazyGetBillPhoneQuery,
  useLazyGetBillCodeQuery,
  useStoreBillCodeMutation,
} = applicantsApi;
