import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import {
  TableDataReportsAccount,
  TableParamsReports,
  TableDataReportsAccountType,
  ParamsTotals,
  TableDataReportsApplications,
  TotalsData,
} from './types/reports';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery,
  endpoints: (builder) => ({
    getReportsAccountTotals: builder.query<TotalsData, ParamsTotals>({
      query: (data) => ({
        url: `/reports/${data.type}/totals`,
        method: 'GET',
        params: data.data,
      }),
    }),
    getReportsAccount: builder.query<
      TableDataReportsAccount,
      TableParamsReports
    >({
      query: (params) => ({
        url: '/reports/accounts',
        method: 'GET',
        params: params,
      }),
    }),
    getReportsAccountTypes: builder.query<
      TableDataReportsAccountType,
      TableParamsReports
    >({
      query: (params) => ({
        url: '/reports/account_types',
        method: 'GET',
        params: params,
      }),
    }),
    getReportsProducts: builder.query<
      TableDataReportsAccountType,
      TableParamsReports
    >({
      query: (params) => ({
        url: '/reports/loan_products',
        method: 'GET',
        params: params,
      }),
    }),
    getReportsLenders: builder.query<
      TableDataReportsAccountType,
      TableParamsReports
    >({
      query: (params) => ({
        url: '/reports/banks',
        method: 'GET',
        params: params,
      }),
    }),
    getReportsApplications: builder.query<
      TableDataReportsApplications,
      TableParamsReports
    >({
      query: (params) => ({
        url: '/reports/applicants',
        method: 'GET',
        params: params,
      }),
    }),
    getPartnersList: builder.query<any, any>({
      query: (params) => ({
        url: `/partners/search`,
        method: 'GET',
        params: params,
      }),
    }),
  }),
});

export const {
  useLazyGetReportsAccountQuery,
  useLazyGetReportsAccountTotalsQuery,
  useLazyGetReportsAccountTypesQuery,
  useLazyGetReportsProductsQuery,
  useLazyGetReportsLendersQuery,
  useLazyGetReportsApplicationsQuery,
  useLazyGetPartnersListQuery,
} = reportsApi;
