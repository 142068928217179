import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useLazyGetApplicationHistoryQuery,
  useLazyGetCustomerByIdQuery, useLazyGetCustomerConcoraBalanceQuery,
} from "../../api/customers";
import { TableParamsApp } from "../../api/types/applicants";

export default function useCustomerRecordController() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [
    getCustomerById,
    {
      data: customerInfo,
      isSuccess: isCustomerInfoSuccess,
      isLoading: isCustomerInfoLoading,
    },
  ] = useLazyGetCustomerByIdQuery();
  const [getConcoraBalance, {data: concoraBalance, isLoading: isLoadingBalance}] = useLazyGetCustomerConcoraBalanceQuery()
  const [
    getApplicationHistoryList,
    {
      data: applicationHistoryList,
      isSuccess: isApplicationHistoryListSuccess,
      isLoading: isApplicationHistoryListLoading,
    },
  ] = useLazyGetApplicationHistoryQuery();

  const [trigger, setTrigger] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableParams, setTableParams] = useState<TableParamsApp>({
    per_page: 50,
    search: '',
    order_by: '',
    order: 'desc',
    status_key: '',
    page: 1,
    loan_product_id: null,
    application_status: null,
    transaction_status: null,
    application_date_from: null,
    application_date_to: null,
  });

  const handleGetConcoraBalance = () => {
    id && getConcoraBalance(+id)
  }
  const refetchCustomer = () => {
    id && getCustomerById(+id)
    id && getConcoraBalance(+id)
  }

  const refetchApplicationHistoryList = () => {
    id && getApplicationHistoryList({
      id: +id,
      params: tableParams,
    });
  }

  useEffect(() => {
    if (id) {
      getCustomerById(+id);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getApplicationHistoryList({
        id: +id,
        params: tableParams,
      });
    }
  }, [tableParams, trigger, id]);

  useEffect(() => {
    if (!applicationHistoryList?.data) return;

    setTableData(applicationHistoryList?.data);
  }, [applicationHistoryList?.data]);

  useEffect(() => {
    setTableParams((prev: TableParamsApp) => ({ ...prev, page: 1 }));
  }, [tableParams.status_key]);

  return {
    navigate,
    customerInfo,
    isCustomerInfoLoading,
    options: {
      list: applicationHistoryList,
      isApplicationHistoryListSuccess,
      isApplicationHistoryListLoading,
    },
    tableData,
    setTableData,
    setTableParams,
    tableParams,
    trigger,
    setTrigger,
    handleGetConcoraBalance,
    concoraBalance,
    isLoadingBalance,
    refetchCustomer,
    refetchApplicationHistoryList
  };
}
