import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout, message } from 'antd';
import './index.scss';
import { useLazyGetMeQuery } from '../../api/common';
import { Header } from '../Header/Header';
import { Navigation } from '../Navigation/Navigation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setUser } from '../../reducers/profile/profileSlice';
import { setMessage } from '../../reducers/common/commonSlice';

interface MainLayoutProps {}

export const MainLayout: React.FC<MainLayoutProps> = () => {
  const [getMe, { data }] = useLazyGetMeQuery();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const messageText = useAppSelector((state) => state.common.isSuccessMessage);
  const success = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (messageText) {
      success(messageText);
      dispatch(setMessage(''));
    }
  }, [messageText]);

  useEffect(() => {
    if (data) {
      dispatch(setUser(data.data));
    }
  }, [data]);

  useEffect(() => {
    getMe();
  }, []);

  return (
    <Layout>
      {contextHolder}
      <Navigation />

      <div style={{ width: '85%' }}>
        <Header />
        <Outlet />
      </div>
    </Layout>
  );
};
