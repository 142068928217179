import { Button, Table, Tooltip, Dropdown } from 'antd';
import { useState, useEffect, useRef } from 'react';
import arrow from '../../assets/arrow-down.svg';
import Property from '../../assets/Property.svg';
import PropertyHover from '../../assets/PropertyHover.svg';
import noData from '../../assets/noData.svg';
import NoUser from '../../assets/NoUser.svg';
import view from '../../assets/view.svg';
import email from '../../assets/email.svg';
import activate from '../../assets/activate.svg';
import pause from '../../assets/Stopwatch Pause.svg';
import type { ColumnsType } from 'antd/es/table';
import { Pagination } from './Pagination';
import { useClickOutside } from '../../hooks/useClickOutSide';
import { ClockIcon } from '../icons/ClockIcon';
import { useNavigate } from 'react-router-dom';
import { idsI } from '../../pages/Partners/Partners';
import './index.scss';
import { TableParamsAdmin } from '../../api/types/admins';
import {
  MainAccTableItem,
  MainAccTableResponse,
} from '../../api/types/mainAcc';
import {
  useChangeStatusMutation,
  useResendInvitationMutation,
} from '../../api/mainAccounts';
import { useAppDispatch } from '../../hooks';
import { setMessage } from '../../reducers/common/commonSlice';

interface PartnersTableProps {
  data: MainAccTableItem[];
  setTableParams: React.Dispatch<React.SetStateAction<TableParamsAdmin>>;
  metaData: MainAccTableResponse | undefined;
  tableParams: TableParamsAdmin;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MainAccountsTable = ({
  data,
  setTableParams,
  metaData,
  tableParams,
  setTrigger,
}: PartnersTableProps) => {
  const ref = useRef(null);
  const [openActionId, setOpenActionId] = useState<number | null>(null);
  useClickOutside(ref, () => setOpenActionId(null));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const sorting = (name: string) => {
    setTableParams((prev: TableParamsAdmin) => ({
      ...prev,
      order_by: name,
      order:
        prev.order_by === name
          ? tableParams.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'asc',
    }));
  };

  const [resetInvitation, { isSuccess: isSuccessResetInv }] =
    useResendInvitationMutation();
  const [changeStatus, { isSuccess }] = useChangeStatusMutation();

  useEffect(() => {
    setTrigger((prev: boolean) => !prev);

    if (isSuccessResetInv) {
      dispatch(setMessage('Link resent successfully'));
    }
  }, [isSuccessResetInv, setTrigger, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage('The status has been successfully changed'));
      setTrigger((prev: boolean) => !prev);
    }
  }, [isSuccess, setTrigger, dispatch]);

  const changeStatusFunc = (id: number, status: number) => {
    if (status === 2 || status === 1) {
      const ids: idsI = {
        id: id,
        key: { status_key: status },
      };
      changeStatus(ids);
    }
    if (status === 0) {
      resetInvitation(id + '');
    }
    setOpenActionId(null);
  };

  const columns: ColumnsType<MainAccTableItem> = [
    {
      title: (
        <div className="header-item">
          ID
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'item_id' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('item_id')}
            alt="arrow"
          />
        </div>
      ),
      width: 70,
      dataIndex: 'item_id',
      key: 'item_id',
      render: (text) => (
        <div className="table-item">
          <div className="table-text blue">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Company Name
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'company_name' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('company_name')}
            alt="arrow"
          />
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text) => (
        <div className="table-item">
          <Tooltip title={text}>
            <div className="table-text">{text}</div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: <div className="header-item AccTypes">Account Type</div>,
      dataIndex: 'account_types',
      key: 'account_types',
      render: (text, record) => (
        <div className="table-item">
          <Tooltip
            title={record.account_types
              ?.map((elem: any) => elem.name)
              .join(', ')}
          >
            <div className="table-text">
              {record.account_types?.length
                ? record.account_types?.map((elem: any) => elem.name).join(', ')
                : '-'}
            </div>
          </Tooltip>
        </div>
      ),
    },

    {
      title: (
        <div className="header-item">
          WL
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'white_labeled_account' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('white_labeled_account')}
            alt="arrow"
          />
        </div>
      ),
      width: 50,
      dataIndex: 'white_labeled_account',
      key: 'white_labeled_account',
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text === 1 ? 'Yes' : 'No'}</div>
        </div>
      ),
    },
    {
      title: <div className="header-item">Phone</div>,
      dataIndex: 'contact_number',
      key: 'contact_number',
      width: 130,
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Status
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'status' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('status')}
            alt="arrow"
          />
        </div>
      ),
      width: 150,
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        const items = [
          {
            key: '1',
            label:
              text === 'Active' ? (
                <span className="table-dropdown-text">Pause</span>
              ) : text === 'Paused' ? (
                <span className="table-dropdown-text">Activate</span>
              ) : (
                <span className="table-dropdown-text">
                  Resend Invitation email
                </span>
              ),
            icon:
              text === 'Active' ? (
                <img src={pause} alt="pause" />
              ) : text === 'Paused' ? (
                <img src={activate} alt="activate" />
              ) : (
                <img src={email} alt="email" />
              ),
          },
          {
            key: '2',
            label: <span className="table-dropdown-text">View</span>,
            icon: <img src={view} alt="view" />,
          },
        ];
        const handleDropdownClick = (
          key: string,
          e:
            | React.MouseEvent<HTMLElement, MouseEvent>
            | React.KeyboardEvent<HTMLElement>
        ) => {
          e.stopPropagation();
          if (key === '1') {
            text === 'Active' && changeStatusFunc(record.id, 1);
            text === 'Paused' && changeStatusFunc(record.id, 2);
            text === 'Pending' && changeStatusFunc(record.id, 0);
          } else if (key === '2') {
            navigate(`/main_accounts/${record.id}`);
          }
        };
        return (
          <div className="table-item table-status">
            <div
              className={
                text === 'Active'
                  ? 'table-text status Approved'
                  : text === 'Paused'
                  ? 'table-text status noOffer'
                  : text === 'Pending'
                  ? 'table-text status Pending'
                  : ''
              }
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {text === 'Pending' && <ClockIcon fill="#7C24C2" />}
                {text}
              </div>
            </div>
            <Dropdown
              placement="bottomRight"
              menu={{
                items,
                onClick: (info) => handleDropdownClick(info.key, info.domEvent),
              }}
              onOpenChange={(open) => {
                open && setOpenActionId(record.id);
                !open && setOpenActionId(null);
              }}
            >
              <img
                src={record.id === openActionId ? PropertyHover : Property}
                style={{ cursor: 'pointer', margin: '0 0 0 15px' }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                alt="dots"
              />
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const customNoDataText = {
    emptyText: (
      <>
        {!tableParams.status_key && !tableParams.search && (
          <div className="no-data-found">
            <img src={NoUser} alt="no-user" />
            <div className="no-data-found__text">No users yet</div>
            <div className="no-data-found__subtext">
              Add new Main Account to start work with them
            </div>
            <Button
              className="blue-btn"
              style={{ margin: '24px 0 0 0' }}
              onClick={() => navigate('/new-main-account')}
            >
              + Add Main Account
            </Button>
          </div>
        )}
        {(tableParams.status_key || tableParams.search) && (
          <div className="no-data-found">
            <img src={noData} alt="no-data" />
            <div className="no-data-found__text">No results found</div>
            <div className="no-data-found__subtext">
              Try adjusting your search to find what you are looking for
            </div>
          </div>
        )}
      </>
    ),
  };

  return (
    <>
      <div>
        <Table
          ref={ref}
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={customNoDataText}
          onRow={(record) => {
            return {
              onClick: () => navigate(`/main_accounts/${record.id}`),
            };
          }}
        />
      </div>
      {data?.length > 0 && (
        <Pagination
          setTableParams={setTableParams}
          metaData={metaData}
          tableParams={tableParams}
        />
      )}
    </>
  );
};
