import { iconI } from './types';

export const DeleteIcon = ({ fill }: iconI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M9.33594 7.15785C9.57819 6.48329 10.2319 6 11.0002 6C11.7686 6 12.4222 6.48329 12.6645 7.15785"
        stroke={fill}
        strokeWidth="1.1"
        strokeLinecap="round"
      />
      <path
        d="M16 8.31641H6"
        stroke={fill}
        strokeWidth="1.1"
        strokeLinecap="round"
      />
      <path
        d="M15.0196 9.76367L14.7491 13.7577C14.645 15.2947 14.5929 16.0632 14.0841 16.5317C13.5753 17.0002 12.7927 17.0002 11.2275 17.0002H10.7726C9.20743 17.0002 8.42484 17.0002 7.91602 16.5317C7.40719 16.0632 7.35513 15.2947 7.25102 13.7577L6.98047 9.76367"
        stroke={fill}
        strokeWidth="1.1"
        strokeLinecap="round"
      />
      <path
        d="M9.5293 11.21L9.82341 14.1046"
        stroke={fill}
        strokeWidth="1.1"
        strokeLinecap="round"
      />
      <path
        d="M12.4709 11.21L12.1768 14.1046"
        stroke={fill}
        strokeWidth="1.1"
        strokeLinecap="round"
      />
    </svg>
  );
};
