import { Switch } from 'antd';
import { FC } from 'react';
import './index.scss';

interface CustomSwitcherI {
  name: string;
  onClick: React.Dispatch<React.SetStateAction<boolean>>;
  chacked: boolean;
  disabled: boolean;
}

export const CustomSwitcher: FC<CustomSwitcherI> = ({
  name,
  onClick,
  chacked,
  disabled,
}) => {
  const onChange = (checked: boolean) => {
    onClick(checked);
  };
  return (
    <div className="switch">
      <div className="custom-field__title">{name}</div>
      <Switch
        disabled={disabled}
        defaultChecked
        onChange={onChange}
        size="small"
        checked={chacked}
      />
    </div>
  );
};
