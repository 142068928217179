import { Button } from "antd";
import logo from "../../assets/logo.svg";
import attention from "../../assets/attention.svg";
import { useNavigate } from "react-router-dom";
import RectangleErr from "../../assets/RectangleErr.svg";

export const ServerError = () => {
  const navigate = useNavigate();

  return (
    <div className="token-page">
      <img className="token-page__rectangle rectangle" src={RectangleErr} />
      <div className="token-page__logo-container">
        <img src={logo} />
      </div>
      <div className="token-page__code-container">
        <img src={attention} className="token-page__image" />
        <div className="token-page__title expired-title">Oops!</div>
        <div className="token-page__subtitle">Something went wrong</div>
        <Button className="blue-btn" onClick={() => navigate("/dashboard")}>
          Go to Dashboard
        </Button>
      </div>
    </div>
  );
};
