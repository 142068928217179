import { Input } from 'antd';
import React from 'react';
import PlacesAutocomplete, { Suggestion } from 'react-places-autocomplete';
// import { Spinner } from '../../../shared/common/components';

type Props = {
  address: string;
  clearAddress: any;
  onChange: any;
  onAddressSelect: any;
};

type State = {
  address: string;
};

export class LocationSearchInput extends React.Component<Props, State> {
  state: State = {
    address: this.props.address,
  };

  handleAddressChange = (address: string): void => {
    this.setState({ address });
  };

  componentWillReceiveProps(nextProps: Props): void {
    if (nextProps.address !== this.props.address) {
      this.setState({ address: nextProps.address });
    }
  }

  render(): JSX.Element {
    const { address } = this.state;

    return (
      <PlacesAutocomplete
        onChange={this.handleAddressChange}
        onSelect={this.props.onAddressSelect}
        value={address}
        searchOptions={{
          componentRestrictions: { country: 'us' },
          types: ['address'],
        }}
      >
        {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
          <React.Fragment>
            <Input
              className="input"
              {...getInputProps({
                id: 'address-input',
              })}
              placeholder="Address"
            />
            <div className="autocomplete-dropdown-container">
              {loading ? (
                <div>
                  {/* <Spinner /> */}
                  Loading...
                </div>
              ) : null}
              {suggestions?.map((suggestion: Suggestion) => {
                const className = suggestion?.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion?.active
                  ? {
                      backgroundColor: '#fafafa',
                      cursor: 'pointer',
                      padding: 5,
                    }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };

                const spread: any = {
                  ...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  }),
                };

                return (
                  <div {...spread} key={suggestion?.id}>
                    <div>{suggestion?.description}</div>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
      </PlacesAutocomplete>
    );
  }
}
