import { useNavigate } from 'react-router-dom';
import plus from '../../assets/plus.svg';
import { useState, useEffect } from 'react';
import { CustomInput } from '../../customFields/CustomInput';
import { CustomSelect } from '../../customFields/CustomSelect';
import searchIcon from '../../assets/searchIcon.svg';
import cancel from '../../assets/x-close.svg';
import { Spinner } from '../../components/Spinner/Spinner';
import { useAppSelector } from '../../hooks';
import { TableParamsAdmin } from '../../api/types/admins';
import { TableParams } from '../../api/types/partners';
import { useLazyGetMainAccountsListQuery } from '../../api/mainAccounts';
import { MainAccountsTable } from '../../components/Tables/MainAccountsTable';
import { MainAccTableItem } from '../../api/types/mainAcc';
import './index.scss';
import useDebounce from '../../hooks/useDebounce';
import { message } from 'antd';

export interface idsI {
  id: number;
  key: { status_key: number };
}

export interface TableAdminsDataI {
  length?: number;
  id: number;
  company_name: string;
  city: string;
  state: string;
  phone: string;
  status: string;
  logo: string;
}

export const MainAccounts = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<MainAccTableItem[]>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [tableParams, setTableParams] = useState<
    TableParamsAdmin | TableParams
  >({
    per_page: 50,
    search: '',
    order_by: '',
    order: 'desc',
    status_key: '',
    page: 1,
  });
  const [getList, { data, isLoading, isError }] =
    useLazyGetMainAccountsListQuery();
  const user = useAppSelector((state) => state.profile);
  const [searchMainAcc, setSearchMainAcc] = useState('');
  const debouncedSearchMainAcc = useDebounce(searchMainAcc);

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (isError) {
      errorMessage('Fetch main accounts failed');
    }
  }, [isError]);

  useEffect(() => {
    setTableParams((prev: TableParams | TableParamsAdmin) => ({
      ...prev,
      search: debouncedSearchMainAcc,
    }));
  }, [debouncedSearchMainAcc]);

  useEffect(() => {
    if (!user.email) return;
    if (user.role.key === 'partner') {
      navigate('/403');
    }
  }, [user.email]);

  useEffect(() => {
    setTableParams((prev: TableParamsAdmin) => ({ ...prev, page: 1 }));
  }, [tableParams.search, tableParams.status_key]);

  useEffect(() => {
    getList(tableParams);
  }, [tableParams, trigger]);

  useEffect(() => {
    if (!data?.data) return;

    setTableData(data?.data);
  }, [data?.data]);

  return (
    <div className="container">
      {contextHolder}
      {isLoading && <Spinner size="large" />}
      <div className="members">
        <div className="members__row">
          <div className="members__title">Main Accounts</div>

          <div
            className="members__add"
            onClick={() => navigate('/new-main-account')}
          >
            <img src={plus} className="members__add-image" /> Add new
          </div>
        </div>

        <div className="members__table">
          <div className="members__filters" style={{ margin: '0 0 10px 0' }}>
            <CustomInput
              style={{ width: '350px', margin: '0 15px 0 0' }}
              className="input-white-small"
              name="Search"
              placeholder="Search by Company Name or ID"
              value={searchMainAcc}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchMainAcc(e.target.value)
              }
              prefix={<img src={searchIcon} />}
              suffix={
                searchMainAcc ? (
                  <img
                    className="cancel-btn"
                    src={cancel}
                    style={{ margin: '0 0 0 0' }}
                    onClick={() => setSearchMainAcc('')}
                  />
                ) : (
                  <></>
                )
              }
            />
            <div className="members__select">
              <CustomSelect
                style={{ width: '170px' }}
                allowClear
                options={[
                  { value: 'Active', label: '2' },
                  { value: 'Pending', label: '0' },
                  { value: 'Paused', label: '1' },
                ]}
                name="Status"
                className="input-white-small"
                placeholder="Show All"
                onChange={(value: any) =>
                  setTableParams((prev: TableParamsAdmin) => ({
                    ...prev,
                    status_key: value,
                  }))
                }
                suffixIcon={
                  tableParams.status_key ? (
                    <img
                      src={cancel}
                      className="cancel-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        setTableParams((prev: TableParamsAdmin) => ({
                          ...prev,
                          status_key: '',
                        }));
                      }}
                    />
                  ) : (
                    false
                  )
                }
              />
            </div>
          </div>
          <MainAccountsTable
            data={tableData}
            setTableParams={setTableParams}
            tableParams={tableParams}
            metaData={data}
            setTrigger={setTrigger}
          />
        </div>
      </div>
    </div>
  );
};
