export const TrashIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        d="M29.9008 22.6315C30.4612 21.8805 32.1314 21.8431 33.4504 20.8604C33.5476 20.7857 33.6559 20.7259 33.7643 20.6736C36.122 19.5601 38.5096 19.9301 40.2695 21.0323C40.2769 21.036 40.2807 21.0398 40.2844 21.0435C41.5959 22.1084 43.2026 20.5466 45.4744 21.9477C45.5229 21.9776 45.5678 22.015 45.5341 22.0636C44.2114 24.0514 42.2946 26.2596 38.8683 28.0345C38.816 28.0606 38.7562 28.0606 38.7076 28.0307L29.9493 22.8744C29.8634 22.8258 29.841 22.71 29.9008 22.6315Z"
        fill="#C1C0C5"
      />
      <path
        d="M34.0634 30.0556C23.7433 35.7089 15.6314 55.8896 17.1932 72.5393C15.7211 81.9253 71.189 86.6856 61.3135 60.4705C56.3589 49.4479 51.2288 35.9256 40.9871 30.0332L34.0634 30.0556Z"
        fill="#D6D6D6"
      />
      <path
        d="M33.6636 29.8501C32.4493 27.2906 31.4031 25.2543 29.8524 22.8741C29.8338 22.8442 29.8636 22.8106 29.8973 22.8181C31.4741 23.3038 33.1069 23.0124 35.6701 22.2912C35.6739 22.2912 35.6776 22.2875 35.6813 22.2875C37.1311 21.3534 38.7863 21.671 40.5126 22.4071C40.5163 22.4071 40.5163 22.4071 40.5201 22.4108C42.3846 22.7844 43.016 23.102 45.5008 21.9998C45.5344 21.9848 45.568 22.0222 45.5493 22.0521C43.6662 24.3874 42.1155 26.9245 40.8862 29.6521C40.8788 29.6633 40.8788 29.6745 40.8825 29.6894C41.1067 31.0159 33.8206 31.3036 33.6636 29.8501Z"
        fill="#D6D6D6"
      />
      <path
        d="M36.9969 31.5579C36.0964 31.5579 34.4823 31.4757 33.5295 30.9302C32.8083 30.5192 32.7822 29.9998 32.8345 29.7271C32.8718 29.5253 33.0886 29.3908 33.3165 29.4207C33.5444 29.4543 33.7013 29.6449 33.664 29.8429C33.649 29.9176 33.6154 30.0932 33.989 30.3062C34.5906 30.65 35.8573 30.8443 37.2921 30.8106C38.6895 30.7808 39.9823 30.5341 40.5802 30.1904C40.7932 30.0671 40.894 29.9513 40.8716 29.8466C40.8343 29.6449 40.9875 29.4543 41.2154 29.4207C41.4433 29.387 41.66 29.5216 41.7011 29.7233C41.746 29.9587 41.7198 30.4146 41.0398 30.8069C40.2962 31.2366 38.9025 31.5131 37.3108 31.5467C37.2211 31.5579 37.1165 31.5579 36.9969 31.5579Z"
        fill="#C1C0C5"
      />
      <path
        d="M42.9422 52.2495L38.2605 56.9312L33.5517 52.2495L30.7373 55.064L35.419 59.7727L30.7373 64.4545L33.5517 67.296L38.2605 62.5872L42.9422 67.296L45.7567 64.4545L41.075 59.7727L45.7567 55.064L42.9422 52.2495Z"
        fill="white"
      />
    </svg>
  );
};
