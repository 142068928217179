import { FadeLoader } from 'react-spinners';
import './spiner.scss';

export const Spinner = ({ size = 'small', color = 'white' }) => {
  return (
    <>
      {size === 'small' && (
        <div className="spinner">
          <FadeLoader
            color={color}
            height={3}
            margin={-13}
            radius={0}
            width={1.5}
          />
        </div>
      )}
      {size === 'medium' && (
        <div className="spinner">
          <FadeLoader
            color={color}
            height={8}
            margin={-3}
            radius={0}
            width={2}
          />
        </div>
      )}
      {size === 'large' && (
        <div className="spinner-wrapper">
          <div className="spinner">
            <FadeLoader
              color="white"
              height={10}
              margin={3}
              radius={3}
              width={3}
            />
          </div>
        </div>
      )}
    </>
  );
};
